import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import Card from '../Card';

interface ModalProps {
  onClick?: (e: any) => any;
  onDismiss?: () => void;
  flashMessage?: JSX.Element;
}

const Overlay = styled.div`
  align-items: flex-start;
  justify-content: center;
  cursor: pointer;
  display: flex;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.65);
  z-index: 9999;
  overflow-y: scroll;
`;

const ModalDialog = styled(Card)<ModalProps>`
  margin: 10vh 24px 0;
  max-width: 600px;
  width: 100%;
  z-index: 9999;
`;

const handleModalClick = (e: any) => e.stopPropagation();

const Modal: React.SFC<ModalProps> = ({ onDismiss, children, flashMessage }) => {
  const modalDiv = document.getElementById('modal');
  if (!modalDiv) return null;

  return ReactDOM.createPortal(
    <Overlay onClick={onDismiss}>
      <ModalDialog onClick={handleModalClick} flashMessage={flashMessage}>
        <div>{children}</div>
      </ModalDialog>
    </Overlay>,
    modalDiv
  );
};

export default Modal;
