import { createAsyncThunk } from '@reduxjs/toolkit';
import { DocumentsApi, DocumentDTO } from '@reposit/api-client';
import { DocumentEntity } from '../entities/entities.types';
import { AppState } from '../root.reducer';
import { createStandardDocumentsApi, runThunkWithAuth } from '../utils/api.utils';
import { CreateDocumentPayload } from './document.types';
import { AxiosResponse } from 'axios';
import { getErrorMessage } from '../../utils/common.utils';
import { setFlashMessage } from '../flash-messages/flash-messages.actions';
import { CREATE_DOCUMENT_STORE_KEY, createDocumentSuccess } from './document.actions';
import { FlashState } from '../../components/FlashMessage/index';

export const createDocumentThunk = createAsyncThunk<
  DocumentEntity,
  CreateDocumentPayload,
  {
    state: AppState;
  }
>('document/create-document', async (payload, thunkAPI) => {
  try {
    const documentsApi: DocumentsApi = createStandardDocumentsApi(thunkAPI.getState());
    const apiResponse: AxiosResponse<DocumentDTO> = await runThunkWithAuth(
      () => documentsApi.createDocument(payload.file, payload.file.name, payload.type),
      thunkAPI.dispatch
    );
    thunkAPI.dispatch(createDocumentSuccess({ id: apiResponse.data.id, isClaimItem: payload.isClaimItem }));
    return apiResponse.data;
  } catch (e) {
    const error = getErrorMessage(e);
    thunkAPI.dispatch(setFlashMessage({ key: CREATE_DOCUMENT_STORE_KEY, message: error, state: FlashState.ERROR }));
    throw e;
  }
});
