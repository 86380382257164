import { createReducer } from '@reduxjs/toolkit';
import {
  createOrganizationRecipientSuccess,
  deleteOrganizationRecipientSuccess,
  setIsAddAdminRecipientFormOpen,
  setIsAddInitialRecipientFormOpen,
  setOrganizationRecipientSuccess
} from './organization-recipient.actions';
import { OrganizationRecipientState } from './organization-recipient.types';

const initialState: OrganizationRecipientState = {
  organizationRecipients: [],
  isAddAdminRecipientFormOpen: false,
  isAddInitialRecipientFormOpen: false
};

export default createReducer(initialState, builder => {
  builder
    .addCase(deleteOrganizationRecipientSuccess, (state, action) => {
      const id = action.payload;
      state.organizationRecipients = state.organizationRecipients.filter(or => or !== id);
    })
    .addCase(createOrganizationRecipientSuccess, (state, action) => {
      const id = action.payload;
      state.organizationRecipients = [...state.organizationRecipients, id];
    })
    .addCase(setOrganizationRecipientSuccess, (state, action) => {
      const orgIds = action.payload;
      state.organizationRecipients = orgIds;
    })
    .addCase(setIsAddInitialRecipientFormOpen, (state, action) => {
      state.isAddInitialRecipientFormOpen = action.payload;
    })
    .addCase(setIsAddAdminRecipientFormOpen, (state, action) => {
      state.isAddAdminRecipientFormOpen = action.payload;
    });
});
