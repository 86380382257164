import SyncIcon from '../../../../assets/svg/sync-alt.svg';
import GreenTick from '../../../../assets/svg/tick-green.svg';
import Warning from '../../../../assets/svg/warning-orange.svg';

interface GetIconArgs {
  uploadOnly: boolean;
  hasSupplierUploadedEvidence: boolean;
  canRespond: boolean;
  itemIsSettledOnZero: boolean;
  hasCountered: boolean;
  hasAgreedProposal: boolean;
  hasGoneBack: boolean;
}

export const getIcon = ({
  uploadOnly,
  hasSupplierUploadedEvidence,
  itemIsSettledOnZero,
  canRespond,
  hasCountered,
  hasAgreedProposal,
  hasGoneBack
}: GetIconArgs) => {
  if (hasGoneBack) {
    return undefined;
  }
  if (uploadOnly) {
    if (hasSupplierUploadedEvidence || itemIsSettledOnZero) {
      return GreenTick;
    } else {
      return Warning;
    }
  }
  if (canRespond) {
    if (hasCountered) {
      if (hasAgreedProposal) {
        return GreenTick;
      }
      return SyncIcon;
    }
    // return SyncIcon;
  } else {
    return GreenTick;
  }
};
