import { get, startCase, toLower } from 'lodash';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { useDebounce } from 'use-debounce';
import FilterSvg from '../../../assets/svg/filter.svg';
import SortSvg from '../../../assets/svg/sort.svg';
import ClaimList from '../../../components/Claim/ClaimList/index';
import NoClaims from '../../../components/Claim/NoClaimsList/index';
import { ContentContainer, InnerWrapper } from '../../../components/Common/index';
import Error from '../../../components/Error/index';
import Filter from '../../../components/Filter/index';
import FlashMessage from '../../../components/FlashMessage/index';
import Loading from '../../../components/Loading/index';
import Pagination from '../../../components/Pagination/index';
import Search from '../../../components/Search/index';
import Sort from '../../../components/Sort/index';
import { Header1, P1 } from '../../../components/Typography/index';
import { ClaimFilter, ClaimSort } from '../../../constants/claims';
import { useIsOrganizationLandlord } from '../../../hooks/useIsLandlord';
import { getCurrentOrganizationId } from '../../../redux/auth/auth.selectors';
import {
  fetchClaimsRequested,
  FETCH_CLAIMS_STORE_KEY,
  goToPage,
  Page,
  updateFilters as updateFiltersActionCreator
} from '../../../redux/claim-list/claim-list.actions';
import { getClaimList, getClaimListFilters, getClaimListPagination } from '../../../redux/claim-list/claim-list.selectors';
import { ClaimListFilters } from '../../../redux/claim-list/claim-list.types';
import { DELETE_CLAIM_STORE_KEY, IMPROVE_CLAIM_STORE_KEY, PUBLISH_CLAIM_STORE_KEY } from '../../../redux/claim/claim.actions';
import { createErrorMessageSelector } from '../../../redux/error/error.selector';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import { getOrganization } from '../../../redux/selectors/organization.selectors';
import { FLASH_MESSAGE_TIMEOUT, useFlashMessage } from '../../FlashMessage/index';

const renderContent = (
  isClaimListFetching: boolean,
  claims: any,
  error: any,
  onNavigate: any,
  totalPages: number,
  currentPage: number,
  query: string
) => {
  if (error) return <Error error={error} />;
  if (isClaimListFetching) return <Loading />;
  if (!isClaimListFetching && claims.length === 0) return <NoClaims query={query} />;

  return (
    <Fragment>
      <ClaimList claims={claims} />
      <Pagination count={totalPages} currentPage={currentPage} onNavigate={onNavigate} />
    </Fragment>
  );
};

interface ClaimsListProps {}

interface Filter {
  value: ClaimFilter | '';
  label: string;
}

const baseFilters: Filter[] = [
  {
    value: '',
    label: 'All'
  },
  {
    value: ClaimFilter.DRAFT,
    label: 'Draft'
  },
  {
    value: ClaimFilter.RESOLVED,
    label: 'Resolved'
  }
];

const mediationOnlyFilters: Filter[] = [
  {
    value: ClaimFilter.AWAITING_INITIAL_TENANTS_RESPONSE,
    label: 'Awaiting Tenants Response (Initial Charges)'
  },
  {
    value: ClaimFilter.AWAITING_SUPPLIER_RESPONSE,
    label: 'Awaiting Your Response To Counter Offer'
  },
  {
    value: ClaimFilter.AWAITING_FINAL_TENANTS_RESPONSE,
    label: 'Awaiting Tenants Response (Final Proposal)'
  },
  {
    value: ClaimFilter.AWAITING_SUBSTANTIATION,
    label: 'Awaiting Final Evidence'
  },
  {
    value: ClaimFilter.DISPUTED,
    label: 'Disputed'
  }
];

const sortOptions = Object.values(ClaimSort).map(value => ({ label: startCase(toLower(value)), value }));

const ClaimsList: React.FC<ClaimsListProps> = () => {
  const dispatch = useDispatch();
  const getClaimListError = createErrorMessageSelector([FETCH_CLAIMS_STORE_KEY]);
  const claimListLoadingSelector = createLoadingSelector([FETCH_CLAIMS_STORE_KEY]);

  const isClaimListFetching = useSelector(claimListLoadingSelector);
  const currentOrganizationId = useSelector(getCurrentOrganizationId);
  const currentOrg = useSelector(getOrganization(currentOrganizationId));
  const mediationEnabled: boolean = get(currentOrg, 'settings.mediationEnabled', false);

  const error = useSelector(getClaimListError);

  const claims = useSelector(getClaimList);
  const { filter, sort, query } = useSelector(getClaimListFilters);
  const [debouncedQuery] = useDebounce(query, 500);
  const { page, count } = useSelector(getClaimListPagination);

  const fetchClaims = useCallback(() => dispatch(fetchClaimsRequested()), [dispatch]);
  const claimsGotoPage = useCallback((page: Page) => dispatch(goToPage(page)), [dispatch]);

  const updateFilters = useCallback((filters: Partial<ClaimListFilters>) => dispatch(updateFiltersActionCreator(filters)), [
    dispatch
  ]);

  const [flashMessagePayload, onDismissFlashMessage] = useFlashMessage([
    PUBLISH_CLAIM_STORE_KEY,
    IMPROVE_CLAIM_STORE_KEY,
    DELETE_CLAIM_STORE_KEY
  ]);

  const isOrgLandlord = useIsOrganizationLandlord();

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      updateFilters({ query });
    },
    [query, updateFilters]
  );

  useEffect(() => {
    fetchClaims();
  }, [filter, sort, debouncedQuery, fetchClaims, currentOrganizationId, page]);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const initialTitle = isOrgLandlord ? 'View, search and filter your Claims' : 'View, search and filter Claims associated with';

  const getFilters = (mediationEnabled: boolean): Filter[] => {
    if (mediationEnabled) {
      let filters = baseFilters;
      filters.splice(2, 0, ...mediationOnlyFilters);
      return filters;
    } else {
      return baseFilters;
    }
  };

  const [filters, setFilters] = useState<Filter[]>(baseFilters);
  useEffect(() => {
    setFilters(getFilters(mediationEnabled));
  }, [mediationEnabled]);

  return (
    <InnerWrapper>
      <Container>
        <Row>
          <Col sm={12}>
            <Header1>Claims</Header1>
            <P1 style={{ display: 'inline' }}>{initialTitle}</P1>
            {!isOrgLandlord && <P1 bold style={{ display: 'inline' }}>{` ${currentOrg && currentOrg.name}`}</P1>}
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <ContentContainer>
              <form onSubmit={handleSubmit}>
                <Search onChange={query => updateFilters({ query })} value={query} placeholder="Search claims" />
              </form>
            </ContentContainer>
          </Col>
        </Row>
        <Row>
          <Col lg={12} style={{ paddingBottom: 12 }}>
            <Sort
              label="Filter by status"
              onSelect={filter => updateFilters({ filter })}
              selected={filter}
              options={filters}
              icon={FilterSvg}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12} style={{ paddingBottom: 12 }}>
            <Filter
              label="Sort by date"
              onSelect={sort => updateFilters({ sort })}
              options={sortOptions}
              selected={sort}
              icon={SortSvg}
            />
          </Col>
        </Row>
        {flashMessagePayload ? (
          <Row>
            <Col lg={12} style={{ padding: '12px 10px 0' }}>
              <FlashMessage
                jumbo
                onDismiss={onDismissFlashMessage}
                timeRemaining={FLASH_MESSAGE_TIMEOUT}
                payload={flashMessagePayload}
              />
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col lg={12}>
            <ContentContainer>
              {renderContent(isClaimListFetching, claims, error, claimsGotoPage, count, page, query)}
            </ContentContainer>
          </Col>
        </Row>
      </Container>
    </InnerWrapper>
  );
};

export default ClaimsList;
