import React from 'react';
import { Formik, FormikProps } from 'formik';
import { Container, Col, Row } from 'react-grid-system';
import styled from 'styled-components';
import * as Yup from 'yup';
import FieldWithLabel from '../FormFields/FieldWithLabel/index';
import { Input } from '../FormFields/index';

const Action = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 36px 0 0;
`;

export interface AccountFormValues {
  email: string;
}

interface AccountFormProps {
  onSubmit: (values: AccountFormValues) => void;
  formActions: JSX.Element;
}

const Schema = Yup.object().shape({
  email: Yup.string()
    .email('Must be a valid email')
    .required('Required')
});

const AccountForm: React.SFC<AccountFormProps> = ({ onSubmit, formActions }) => {
  return (
    <Formik initialValues={{ email: '' }} onSubmit={onSubmit} validationSchema={Schema}>
      {({ values, handleChange, handleSubmit, handleBlur, touched, errors }: FormikProps<AccountFormValues>) => {
        return (
          <form onSubmit={handleSubmit}>
            <Container fluid>
              <Row>
                <Col lg={12} style={{ padding: 0 }}>
                  <FieldWithLabel label="Email address" touched={touched.email} error={errors.email}>
                    <Input
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="email"
                      touched={touched.email}
                      error={errors.email}
                    />
                  </FieldWithLabel>
                </Col>
              </Row>
              <Row>
                <Col lg={12} style={{ padding: 0 }}>
                  <Action>{formActions}</Action>
                </Col>
              </Row>
            </Container>
          </form>
        );
      }}
    </Formik>
  );
};

export default AccountForm;
