import { get } from 'lodash';
import { getCurrentUserId } from '../account/account.selectors';
import { UserEntity } from '../entities/entities.types';
import { AppState } from '../root.reducer';

export const getCurrentUser = (state: AppState): UserEntity => {
  const currentUserId = getCurrentUserId(state);
  return state.entities.user[currentUserId];
};

export const getIsAboutYouComplete = (state: AppState): boolean => {
  const currentUser = getCurrentUser(state);
  return get(currentUser, 'attributes.aboutYouComplete', false);
};

export const getShowClaimUploadsFeature = (state: AppState): boolean => {
  const currentUser = getCurrentUser(state);
  return get(currentUser, 'attributes.messages.featureClaimUploads', false);
};

export const getIsOnboardingComplete = (state: AppState): boolean => {
  const currentUser = getCurrentUser(state);
  return get(currentUser, 'attributes.completedOnboarding', false);
};

interface GetUserById {
  userId: string;
}

export const getUserById = (state: AppState, { userId }: GetUserById): UserEntity => {
  return state.entities.user[userId];
};

export const getUserEOTContact = (state: AppState): boolean => {
  const currentUser = getCurrentUser(state);
  // Defaults to true as endOfTenancyContact is potentially undefined
  return get(currentUser, 'attributes.endOfTenancyContact', true);
};

export const getUserEOTEmailsDisabled = (state: AppState): boolean => {
  const currentUser = getCurrentUser(state);
  // Defaults to false as endOfTenancyEmailsDisabled is potentially undefined
  return get(currentUser, 'attributes.endOfTenancyEmailsDisabled', false);
};

export const getUserProductUpdateEmailsDisabled = (state: AppState): boolean => {
  const currentUser = getCurrentUser(state);
  // Defaults to false as productUpdatesDisabled is potentially undefined
  return get(currentUser, 'attributes.productUpdatesDisabled', false);
};
