import {
  CreateOrganizationRecipientDTO,
  CreateOrganizationRecipientDTOGroupEnum,
  OrganizationRecipientDTO,
  OrganizationRecipientsApi,
  OrganizationRecipientDTOGroupEnum
} from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { FlashState } from '../../components/FlashMessage/index';
import { getErrorMessage } from '../../utils/common.utils';
import { getCurrentOrganizationId } from '../auth/auth.selectors';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import { FlashMessagesActionTypes, setFlashMessage } from '../flash-messages/flash-messages.actions';
import SCHEMA from '../schema';
import { getAllOrganizationRecipients, getOrganizationRecipientsById } from '../selectors/organization.selectors';
import { createOrganizationRecipientsApi, runSagaWithAuth } from '../utils/api.utils';
import {
  createOrganizationRecipientFailed,
  createOrganizationRecipientRequested,
  createOrganizationRecipientSuccess,
  CREATE_ORG_RECIPIENT_STORE_KEY,
  deleteOrganizationRecipientFailed,
  deleteOrganizationRecipientRequested,
  deleteOrganizationRecipientSuccess,
  DELETE_ORG_RECIPIENT_STORE_KEY,
  setIsAddAdminRecipientFormOpen,
  setIsAddInitialRecipientFormOpen,
  setOrganizationRecipientFailed,
  setOrganizationRecipientRequested,
  setOrganizationRecipientSuccess
} from './organization-recipient.actions';
import { DeleteOrganizationRecipient } from './organization-recipient.types';
import { OrganizationRecipientEntity } from '../entities/entities.types';

export function* setOrganizationRecipients({ payload }: { payload: null; type: string }) {
  try {
    const organizationId: string = yield select(getCurrentOrganizationId);
    const organizationRecipients: string[] = yield select(getAllOrganizationRecipients(organizationId));
    const ids: string[] = yield syncEntitiesAndGetResults(organizationRecipients, SCHEMA.organizationRecipients);
    yield put(setOrganizationRecipientSuccess(ids));
  } catch (e) {
    yield put(setOrganizationRecipientFailed(get(e, 'response.data.message', e)));
  }
}

export function* createOrganizationRecipient({ payload }: { payload: CreateOrganizationRecipientDTO; type: string }) {
  const groupText = payload.group === CreateOrganizationRecipientDTOGroupEnum.INITIAL ? `pre-tenancy` : 'tenancy management';
  try {
    const organizationId = yield select(getCurrentOrganizationId);
    const organizationRecipientsApi: OrganizationRecipientsApi = yield createOrganizationRecipientsApi();
    const { data }: AxiosResponse<OrganizationRecipientDTO> = yield call(
      runSagaWithAuth(() => organizationRecipientsApi.createOrganizationRecipient(organizationId, payload))
    );
    yield syncEntitiesAndGetResults(data, SCHEMA.organizationRecipient);
    yield put(createOrganizationRecipientSuccess(data.id));
    yield payload.group === CreateOrganizationRecipientDTOGroupEnum.ADMIN
      ? put(setIsAddAdminRecipientFormOpen(false))
      : put(setIsAddInitialRecipientFormOpen(false));

    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: CREATE_ORG_RECIPIENT_STORE_KEY,
        message: `Success! You have added a recipient to recieve all ${groupText} emails`,
        state: FlashState.SUCCESS
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put(
      setFlashMessage({
        key: CREATE_ORG_RECIPIENT_STORE_KEY,
        message: error,
        state: FlashState.ERROR,
        group: groupText
      })
    );
    yield put(createOrganizationRecipientFailed(get(e, 'response.data.message', e)));
  }
}

export function* deleteOrganizationRecipient({ payload }: { payload: DeleteOrganizationRecipient; type: string }) {
  try {
    const { recipientId, organizationId } = payload;
    const recipient: OrganizationRecipientEntity = yield select(getOrganizationRecipientsById(payload.recipientId));
    const organizationRecipientsApi: OrganizationRecipientsApi = yield createOrganizationRecipientsApi();
    yield call(runSagaWithAuth(() => organizationRecipientsApi.deleteOrganizationRecipient(recipientId, organizationId)));
    yield put(deleteOrganizationRecipientSuccess(recipientId));

    const groupText = recipient.group === OrganizationRecipientDTOGroupEnum.INITIAL ? `pre-tenancy` : 'tenancy management';

    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: DELETE_ORG_RECIPIENT_STORE_KEY,
        message: `Success! Recipient removed from ${groupText} emails`,
        state: FlashState.SUCCESS
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: DELETE_ORG_RECIPIENT_STORE_KEY, message: error, state: FlashState.ERROR })
    );
    yield put(deleteOrganizationRecipientFailed(get(e, 'response.data.message', e)));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchRecipientSagas() {
  yield takeLatest(createOrganizationRecipientRequested.type, createOrganizationRecipient);
  yield takeLatest(deleteOrganizationRecipientRequested.type, deleteOrganizationRecipient);
  yield takeLatest(setOrganizationRecipientRequested.type, setOrganizationRecipients);
}
