import { ClaimDTOStatusEnum } from '@reposit/api-client';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import TenantsIcon from '../../../assets/svg/tenants.svg';
import FlashMessage from '../../../components/FlashMessage/index';
import Loading from '../../../components/Loading/index';
import RepositCard from '../../../components/Reposit/RepositCard/index';
import { P1 } from '../../../components/Typography/index';
import { ClaimEntity, TenantEntity } from '../../../redux/entities/entities.types';
import {
  ADD_GUARANTOR_STORE_KEY,
  SET_GUARANTORS_CONFIRMED_STORE_KEY,
  UPDATE_GUARANTOR_STORE_KEY
} from '../../../redux/guarantor/guarantor.actions';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import { useFlashMessage } from '../../FlashMessage/index';
import TenantAndGuarantorList from './tenant-and-guarantor-list';
import { DisableComponent } from '../../../components/Common/index';
import { getClaimItemFormOpen } from '../../../redux/claim/claim.selector';

const IntroText = styled(P1)`
  padding: 1px 36px;
  margin-bottom: 36px;
`;

interface GuarantorConfirmationProps {
  tenants: TenantEntity[];
  tenancyId: string;
  claim: ClaimEntity;
}

const storeKeys = [ADD_GUARANTOR_STORE_KEY, UPDATE_GUARANTOR_STORE_KEY, SET_GUARANTORS_CONFIRMED_STORE_KEY];

const GuarantorConfirmation: React.FC<GuarantorConfirmationProps> = ({ tenants, tenancyId, claim }) => {
  const [flashMessage, onDismissFlashMessage] = useFlashMessage(storeKeys);

  const loadingSelector = createLoadingSelector([
    UPDATE_GUARANTOR_STORE_KEY,
    ADD_GUARANTOR_STORE_KEY,
    SET_GUARANTORS_CONFIRMED_STORE_KEY
  ]);
  const isLoading = useSelector(loadingSelector);
  const isClaimItemsFormOpen = useSelector(getClaimItemFormOpen);

  const claimInDraft = claim.status === ClaimDTOStatusEnum.DRAFT;
  const claimIsAwaitingEvidence = claim.status === ClaimDTOStatusEnum.AWAITINGSUBSTANTIATION;

  return (
    <DisableComponent disabled={isClaimItemsFormOpen}>
      <RepositCard
        title={claimIsAwaitingEvidence ? 'Tenants' : 'Guarantors'}
        flush
        icon={TenantsIcon}
        flashMessage={
          flashMessage ? (
            <FlashMessage onDismiss={onDismissFlashMessage} timeRemaining={3000} payload={flashMessage} />
          ) : (
            undefined
          )
        }
      >
        {isLoading ? (
          <Loading />
        ) : (
          <Fragment>
            {claimInDraft ? (
              <IntroText>Please confirm that these guarantor details are correct for each tenant.</IntroText>
            ) : null}
            <TenantAndGuarantorList tenants={tenants} tenancyId={tenancyId} editable={claimInDraft} />
          </Fragment>
        )}
      </RepositCard>
    </DisableComponent>
  );
};

export default GuarantorConfirmation;
