import React from 'react';
import styled from 'styled-components';
import WarningIcon from '../../../assets/svg/warning.svg';
import { P3 } from '../../Typography/index';

interface ErrorMessageProps {
  error?: string;
  noIcon?: boolean;
}

const Error = styled.div`
  padding: 0;
  margin: 8px 0 16px;
  min-height: 26px;
`;

const ErrorMessage = styled(P3)<ErrorMessageProps>`
  align-items: flex-start;
  color: ${props => props.theme.colors.negative};
  font-weight: bold;
  display: flex;
  line-height: 1;
  padding: 2px 0 0;
  ${props =>
    !props.noIcon
      ? `
&:before {
    background: ${`${props.theme.colors.negative} url(${WarningIcon}) no-repeat 3px 3px`};
    background-size: 8px;
    border-radius: 100px;
    color: #fff;
    content: '';
    font-size: 10px;
    flex: 0 0 14px;
    display: block;
    margin: -1px 8px 2px 0;
    height: 14px;
    width: 14px;
  }
  `
      : ''}
`;

const FormErrorMessage: React.SFC<ErrorMessageProps> = props => (
  <Error>
    <ErrorMessage {...props}>{props.error}</ErrorMessage>
  </Error>
);

export default FormErrorMessage;
