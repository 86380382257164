import React from 'react';
import styled from 'styled-components';
import RightIconWhite from '../../assets/svg/right-white.svg';
import RightIconPurple from '../../assets/svg/right-purple.svg';
import LeftIconWhite from '../../assets/svg/left-white.svg';
import DisabledIcon from '../../assets/svg/disabled.svg';

export type ButtonType = 'primary' | 'warning' | 'secondary' | 'tertiary' | 'white' | undefined;

interface ButtonProps {
  children: string;
  className?: string;
  buttonType?: ButtonType;
  onClick?: () => void;
  disabled?: boolean;
  selected?: boolean;
  mini?: string;
  reverse?: boolean;
  noArrow?: boolean;
  type?: 'button' | 'submit' | 'reset';
  style?: React.CSSProperties;
}

interface RoundButtonProps {
  icon: string;
}

const Button = styled.button<ButtonProps>`
  align-items: center;
  border: none;
  border-radius: 24px;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  flex-direction: ${props => (props.reverse ? 'row-reverse' : 'none')};
  font-family: ${props => props.theme.typography.face.primary};
  font-size: ${props => (props.mini ? '0.6em' : '0.75em')};
  font-weight: ${props => (props.mini ? 'normal' : 'bold')};
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: ${props => (props.mini ? '-0.1px' : '0.8px')};
  outline: none;
  text-decoration: none;
  padding: ${props => (props.mini ? '0.500em 1.5em  0.500em 1.75em' : '0.625em 1.5em 0.625em 1.75em')};
  text-transform: ${props => (props.mini ? 'normal' : 'uppercase')};
  transition: all 0.3s;

  &:active {
    box-shadow: inset 0px 6px 17px -6px rgba(0, 0, 0, 0.49);
  }

  &:after {
    display: ${props => (props.mini ? 'none' : 'block')};
  }

  &:disabled {
    background: ${props => props.theme.colors.disabled};
    color: ${props => props.theme.colors.placeholder};
    cursor: not-allowed;
    transition: all 0.3s;

    ${props =>
      !props.mini &&
      `
        &:after {
          background: transparent url(${DisabledIcon}) center center;
          background-size: 12px;
          object-fit: contain;
          display: block;
        }
    `}

    &:hover {
      background: ${props => props.theme.colors.disabled};
      color: ${props => props.theme.colors.placeholder};
    }
  }
`;

const PrimaryButton = styled(Button)<ButtonProps>`
  background-image: linear-gradient(
    to bottom,
    ${props => props.theme.colors.secondaryBrand},
    ${props => props.theme.colors.primaryBrand}
  );

  &:after {
    background: transparent url(${RightIconWhite}) center center;
    background-size: 12px;
    object-fit: contain;
    content: '';
    display: ${props => (props.mini ? 'none' : 'block')};
    display: ${props => (props.noArrow ? 'none' : 'block')};
    margin: 0 0 0 0.75em;
    height: 12px;
    width: 12px;
  }

  &:hover {
    background-image: linear-gradient(
      to bottom,
      ${props => props.theme.colors.primaryBrand},
      ${props => props.theme.colors.primaryBrand}
    );
  }

  &.warning {
    /* not sure about this but it helps to have a hover */
    background-image: linear-gradient(to bottom, ${props => props.theme.colors.negative}, #b01e1e);
    &:hover {
      background-image: linear-gradient(
        to bottom,
        ${props => props.theme.colors.negative},
        ${props => props.theme.colors.negative}
      );
    }
  }

  &.white {
    background: #fff;
    color: ${props => props.theme.colors.primaryBrand};

    &:after {
      background: transparent url(${RightIconPurple}) center center;
      background-size: 12px;
      object-fit: contain;
      content: '';
      display: ${props => (props.mini ? 'none' : 'block')};
      display: ${props => (props.noArrow ? 'none' : 'block')};
      margin: 0 0 0 0.75em;
      height: 12px;
      width: 12px;
    }
  }

  &:disabled {
    background: ${props => props.theme.colors.disabled};
    color: ${props => props.theme.colors.placeholder};
    cursor: not-allowed;

    ${props =>
      !props.mini &&
      `
        &:after {
          background: transparent url(${DisabledIcon}) center center;
          background-size: 12px;
          object-fit: contain;
          display: block;
        }
    `}

    &:hover {
      background: ${props => props.theme.colors.disabled};
      color: ${props => props.theme.colors.placeholder};
    }
  }
`;

const SecondaryButton = styled(Button)<ButtonProps>`
  background: ${props => props.theme.colors.tertiaryBrand};
  color: white;

  &:after {
    background: transparent;
    background-image: ${props => (props.reverse ? `url(${LeftIconWhite})` : `url(${RightIconWhite})`)};
    background-size: 12px;
    object-fit: contain;
    content: '';
    display: ${props => (props.mini ? 'none' : 'block')};
    display: ${props => (props.noArrow ? 'none' : 'block')};
    margin: ${props => (props.reverse ? '0 0.75em 0 0' : '0 0 0 0.75em')};
    height: 12px;
    width: 12px;
  }

  &:hover {
    background: ${props => props.theme.colors.primaryBrand};
  }
`;

export const TertiaryButton = styled(Button)<ButtonProps>`
  background: ${props => (props.selected ? props.theme.colors.secondaryBrand : '#fff')};
  border: ${props => (props.selected ? '1px solid #e0ecf7' : `solid 1px ${props.theme.colors.disabled}`)};
  color: ${props => (props.selected ? 'white' : props.theme.colors.body2)};
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.006em;

  &:hover {
    background: ${props => (props.selected ? props.theme.colors.primaryBrand : props.theme.colors.bgAccent)};
  }

  &:active {
    box-shadow: inset 0px 6px 17px -6px rgba(0, 0, 0, 0.1);
  }
`;

export const RoundButton = styled.div<RoundButtonProps>`
  background: ${props => props.theme.colors.secondaryBrand} url(${props => props.icon}) no-repeat center center;
  background-size: 7px;
  border: none;
  border-radius: 48px;
  cursor: pointer;
  display: block;
  outline: none;
  height: 24px;
  width: 24px;

  &:hover {
    background-color: ${props => props.theme.colors.tertiaryBrand};
  }
`;

export default ({
  buttonType,
  className,
  children,
  selected,
  mini,
  onClick,
  reverse,
  noArrow,
  disabled,
  type = 'submit',
  style
}: ButtonProps) => {
  switch (buttonType) {
    case 'tertiary':
      return (
        <TertiaryButton
          selected={selected}
          mini={mini}
          className={className}
          onClick={onClick}
          reverse={reverse}
          noArrow={noArrow}
          disabled={disabled}
          type={type}
          style={style}
        >
          {children}
        </TertiaryButton>
      );

    case 'secondary':
      return (
        <SecondaryButton
          selected={selected}
          mini={mini}
          className={className}
          onClick={onClick}
          reverse={reverse}
          noArrow={noArrow}
          disabled={disabled}
          type={type}
          style={style}
        >
          {children}
        </SecondaryButton>
      );

    case 'warning':
      return (
        <PrimaryButton
          selected={selected}
          mini={mini}
          className={'warning'}
          onClick={onClick}
          reverse={reverse}
          noArrow={noArrow}
          disabled={disabled}
          type={type}
          style={style}
        >
          {children}
        </PrimaryButton>
      );

    case 'white':
      return (
        <PrimaryButton
          selected={selected}
          mini={mini}
          className={'white'}
          onClick={onClick}
          reverse={reverse}
          noArrow={noArrow}
          disabled={disabled}
          type={type}
          style={style}
        >
          {children}
        </PrimaryButton>
      );

    default:
      return (
        <PrimaryButton
          selected={selected}
          mini={mini}
          className={className}
          onClick={onClick}
          reverse={reverse}
          noArrow={noArrow}
          disabled={disabled}
          type={type}
          style={style}
        >
          {children}
        </PrimaryButton>
      );
  }
};
