import { Formik, FormikProps, FormikState } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router';
import styled from 'styled-components';
import * as Yup from 'yup';
import RepositLogo from '../../../assets/png/primary-full-colour.png';
import { getBreakpoint } from '../../../base/style';
import { CardComponent } from '../../../components/Card/index';
import FieldWithLabel from '../../../components/FormFields/FieldWithLabel/index';
import { Input } from '../../../components/FormFields/index';
import { Header4, P2, P3 } from '../../../components/Typography/index';
import { getHasResetPassword } from '../../../redux/account/account.selectors';
import { resetPasswordRequested, RESET_PASSWORD_STORE_KEY } from '../../../redux/auth/auth.actions';
import { getAccessToken } from '../../../redux/auth/auth.selectors';
import { createErrorMessageSelector } from '../../../redux/error/error.selector';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import { AuthButton, AuthContainer, FormContainer, LogoContainer, LogoImage } from '../Login/login';

interface ResetPasswordFormProps {
  password: string;
  confirmedPassword: string;
}

const hasUpperCase = (value: string) => /[A-Z]/.test(value);
const hasLowerCase = (value: string) => /[a-z]/.test(value);
const hasNumber = (value: string) => /[0-9]/.test(value);
const hasSymbol = (value: string) => /[!@#$%^&*]/.test(value);

const hasValidPassword = (value: string) => {
  const numberOfMustBeValidConditions = 3;
  let validConditions = 0;
  const conditions = [hasLowerCase(value), hasUpperCase(value), hasNumber(value), hasSymbol(value)];
  conditions.forEach(condition => (condition ? validConditions++ : null));
  if (validConditions >= numberOfMustBeValidConditions) {
    return true;
  }
  return false;
};

const Schema = Yup.object().shape({
  password: Yup.string()
    .required('Required')
    .min(8, 'Minimum 8 Characters')
    .test('isValid', 'dummy', function(value) {
      return hasValidPassword(value);
    }),
  confirmedPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Required')
});

export const ResetPasswordCard = styled(CardComponent)`
  box-shadow: none;
  background: inherit;
  padding: 0;
  margin: 0;
  width: 100%;

  &:hover {
    box-shadow: none;
    transform: none;
  }

  @media all and (min-width: ${getBreakpoint('sm')}) {
    background: white;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 20px 0;
    width: 400px;
    height: 600px;

    &:hover {
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
    }
  }
`;

const ErrorTitle = styled(P3)<{ error: boolean }>`
  font-weight: bold;
  font-size: 13px;
  margin: 0;
  color: ${props => (props.error ? props.theme.colors.negative : props.theme.colors.positive)};
`;

const ErrorText = styled(P3)<{ error: boolean }>`
  font-weight: bold;
  font-size: 13px;
  margin: 0;
  color: ${props => (props.error ? 'white' : props.theme.colors.positive)};
`;
const PasswordContainer = styled.div`
  display: flex;
  height: 145px;
  border: 1px solid black;
  margin-bottom: 5px;
  position: absolute;
  top: -145px;
  background: #20252f;
  padding: 10px;
  z-index: 100;
  border-radius: 3px;
`;

const PasswordValidation: React.FC<{ values: FormikState<ResetPasswordFormProps>['values'] }> = ({ values }) => {
  const { password } = values;
  const hasLowerResult = hasLowerCase(password);
  const hasUpperResult = hasUpperCase(password);
  const hasNumberResult = hasNumber(password);
  const hasSymbolResult = hasSymbol(password);
  const isLongEnough = password.length >= 8;

  const hasValidPasswordResult = hasValidPassword(password);

  return (
    <PasswordContainer>
      <div>
        <ErrorTitle error={!isLongEnough} style={{ marginBottom: 8 }}>
          At least 8 characters in length
        </ErrorTitle>
        <ErrorTitle error={!hasValidPasswordResult} style={{ marginBottom: 2 }}>
          Contains at least 3 of the following 4 types of characters:
        </ErrorTitle>
        <ErrorText error={!hasLowerResult}>Lower case letters (a-z)</ErrorText>
        <ErrorText error={!hasUpperResult}>Upper case letters (A-Z)</ErrorText>
        <ErrorText error={!hasNumberResult}>Numbers (i.e. 0-9)</ErrorText>
        <ErrorText error={!hasSymbolResult}>Special characters (e.g. !@#$%^&*)</ErrorText>
      </div>
    </PasswordContainer>
  );
};

const FormView: React.FC<unknown> = () => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = React.useState('');
  const [isPasswordFocussed, setIsPasswordFocussed] = React.useState(false);
  const location = useLocation();
  const selector = createLoadingSelector([RESET_PASSWORD_STORE_KEY]);
  const errorSelector = createErrorMessageSelector([RESET_PASSWORD_STORE_KEY]);
  const isLoading = useSelector(selector);
  const error = useSelector(errorSelector);
  const params = new URLSearchParams(location.search);

  const token = params.get('token') || '';
  // + characters are changed to spaces in URLSearchParams
  const email = (params.get('email') || '').replace(/ /g, '+');

  // different in this container because we want the validations in these to show their messages after an error has occured
  // This doesn't follow auth0 but I thought it made sense for our situation
  useEffect(() => {
    if (error) {
      setErrorMessage('Something went wrong');
    }
  }, [error]);

  return (
    <>
      <LogoContainer>
        <LogoImage src={RepositLogo} />
        <Header4 style={{ marginTop: 24, fontSize: 24, color: '#2d333a', fontWeight: 400 }}>Change Password</Header4>
        <P2 style={{ color: '#2d333a', fontSize: 14 }}>{`Please enter a new password for ${email}`}</P2>
      </LogoContainer>
      <FormContainer>
        <Formik
          initialValues={{ password: '', confirmedPassword: '' }}
          validationSchema={Schema}
          onSubmit={data => {
            dispatch(resetPasswordRequested({ token, password: data.password, email: email || '' }));
          }}
        >
          {(props: FormikProps<ResetPasswordFormProps>) => {
            return (
              <form onSubmit={props.handleSubmit}>
                <FieldWithLabel label="Password" touched={props.touched.password} style={{ position: 'relative' }}>
                  {props.touched.password && props.errors.password && isPasswordFocussed ? (
                    <PasswordValidation values={props.values} />
                  ) : null}
                  <Input
                    name="password"
                    type="password"
                    value={props.values.password}
                    onChange={val => {
                      props.setTouched({ password: true });
                      props.handleChange(val);
                      setErrorMessage('');
                    }}
                    onBlur={val => {
                      props.handleBlur(val);
                      setIsPasswordFocussed(false);
                    }}
                    error={props.errors.password}
                    touched={props.touched.password}
                    onFocus={() => setIsPasswordFocussed(true)}
                  />
                </FieldWithLabel>
                <FieldWithLabel
                  label="Confirm Password"
                  error={errorMessage || props.errors.confirmedPassword}
                  touched={props.touched.confirmedPassword}
                >
                  <Input
                    name="confirmedPassword"
                    type="password"
                    value={props.values.confirmedPassword}
                    onChange={val => {
                      props.handleChange(val);
                      setErrorMessage('');
                    }}
                    onBlur={props.handleBlur}
                    error={props.errors.confirmedPassword}
                    touched={props.touched.confirmedPassword}
                  />
                </FieldWithLabel>
                <AuthButton type="submit" disabled={isLoading}>
                  Continue
                </AuthButton>
              </form>
            );
          }}
        </Formik>
      </FormContainer>
    </>
  );
};

const Success: React.FC<unknown> = () => {
  return (
    <>
      <LogoContainer>
        <LogoImage src={RepositLogo} />
        <Header4 style={{ marginTop: 24, fontSize: 24, color: '#2d333a', fontWeight: 400 }}>Password Reset</Header4>
        <P2 style={{ color: '#2d333a', fontSize: 14 }}>
          You have successfully reset your password. You will be redirected to login shortly.
        </P2>
      </LogoContainer>
    </>
  );
};

export const ResetPassword: React.FC<unknown> = () => {
  const token = useSelector(getAccessToken);
  const hasResetPassword = useSelector(getHasResetPassword);

  if (token) {
    return <Redirect to="/reposits" />;
  }

  return (
    <AuthContainer>
      <ResetPasswordCard>{hasResetPassword ? <Success /> : <FormView />}</ResetPasswordCard>
    </AuthContainer>
  );
};
