import { OrganizationBankAccountDTOAccountTypeEnum } from '@reposit/api-client';
import { Formik, FormikProps } from 'formik';
import React, { useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';
import { getBreakpoint } from '../../../base/style';
import Button from '../../../components/Button/index';
import FieldWithLabel from '../../../components/FormFields/FieldWithLabel/index';
import { Input } from '../../../components/FormFields/index';
import { StyledLink } from '../../../components/Typography/index';
import { setClaimAccountShowing } from '../../../redux/bank-account/bank-account.actions';
import { addDashesToSortCode } from '../../../utils/input.utils';
import { accountNumberMatcher } from '../../../utils/regex/accountNumber.regex';
import { sortCodeMatcher } from '../../../utils/regex/sortCode.regex';
import { BankAccountFormValues } from './index';

const SORT_CODE_MAX_LENGTH = 6;
const ACCOUNT_NUMBER_MAX_LENGTH = 9;

const Field = styled.div`
  min-height: 86px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    max-width: 96%;
  }
`;

const FieldAlt = styled.div`
  min-height: 86px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    float: right;
    max-width: 96%;
    width: 100%;
  }
`;

const Action = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 36px 0 0;
`;

const Link = styled(StyledLink)`
  margin: 0 20px;
`;

interface BankAccountFormProps {
  onSubmit: (values: BankAccountFormValues) => void;
  accountType: OrganizationBankAccountDTOAccountTypeEnum;
}

const initialValues: BankAccountFormValues = {
  accountName: '',
  sortCode: '',
  accountNumber: ''
};

const validationSchema = Yup.object().shape({
  accountName: Yup.string().required('Required'),
  sortCode: Yup.string()
    .required('Required')
    .min(6, 'Sort code must be 6 digits long')
    .max(6, 'Sort code must be 6 digits long')
    .matches(sortCodeMatcher, 'Incorrectly formatted sort code'),
  accountNumber: Yup.string()
    .required('Required')
    .min(8, 'Account number must be 8 digits long')
    .max(8, 'Account number must be 8 digits long')
    .matches(accountNumberMatcher, 'Incorrectly formatted account number')
});

const BankAccountForm: React.FC<BankAccountFormProps> = ({ onSubmit, accountType }) => {
  const dispatch = useDispatch();

  // we need to keep track of the previous sort code value to support
  // the behaviour of the input, to determine whether the user added or deleted a character
  const [previousSortCode, setPreviousSortCode] = useState<string>('');

  const removeDashes = (string: string) => {
    return string.split('-').join('');
  };

  const formActions = (isSubmitting: boolean) => {
    const claimForm = accountType === OrganizationBankAccountDTOAccountTypeEnum.CLAIM;
    return (
      <>
        {claimForm ? (
          <Link to="#" onClick={() => dispatch(setClaimAccountShowing(false))}>
            Cancel
          </Link>
        ) : null}
        <Button disabled={isSubmitting} type="submit">
          Add bank account
        </Button>
      </>
    );
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values: BankAccountFormValues) => onSubmit(values)}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          touched,
          errors,
          setFieldValue,
          isSubmitting
        }: FormikProps<BankAccountFormValues>) => {
          const displaySortCode = addDashesToSortCode(values.sortCode, previousSortCode);
          return (
            <form onSubmit={handleSubmit}>
              <Container fluid style={{ padding: 0 }}>
                <Col lg={12}>
                  <Row>
                    <Col lg={12} style={{ padding: 0 }}>
                      <FieldWithLabel label="Account name" touched={touched.accountName} error={errors.accountName}>
                        <Input
                          value={values.accountName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          name="accountName"
                          touched={touched.accountName}
                          error={errors.accountName}
                        />
                      </FieldWithLabel>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} style={{ padding: 0 }}>
                      <Field>
                        <FieldWithLabel label="Sort code" touched={touched.sortCode} error={errors.sortCode}>
                          <Input
                            value={displaySortCode}
                            name="sortCode"
                            onChange={e => {
                              if (e.target.value.length === 0 || removeDashes(e.target.value).length <= SORT_CODE_MAX_LENGTH) {
                                setPreviousSortCode(values.sortCode);
                                const noDashes = removeDashes(e.target.value);
                                setFieldValue('sortCode', noDashes);
                              }
                            }}
                            onBlur={handleBlur}
                            placeholder="e.g. 09-01-28"
                            error={errors.sortCode}
                            touched={touched.sortCode}
                          />
                        </FieldWithLabel>
                      </Field>
                    </Col>
                    <Col lg={6} style={{ padding: 0 }}>
                      <FieldAlt>
                        <FieldWithLabel label="Account number" touched={touched.accountNumber} error={errors.accountNumber}>
                          <Input
                            value={values.accountNumber}
                            name="accountNumber"
                            onChange={e => {
                              if (e.target.value.length === 0 || e.target.value.length < ACCOUNT_NUMBER_MAX_LENGTH) {
                                setFieldValue('accountNumber', e.target.value);
                              }
                            }}
                            onBlur={handleBlur}
                            placeholder="e.g. 32768987"
                            error={errors.accountNumber}
                            touched={touched.accountNumber}
                          />
                        </FieldWithLabel>
                      </FieldAlt>
                    </Col>
                  </Row>
                  <Action>{formActions(isSubmitting)}</Action>
                </Col>
              </Container>
            </form>
          );
        }}
      </Formik>
    </>
  );
};

export default BankAccountForm;
