import { RepositState } from './reposit.types';
import {
  RepositActionTypes,
  FETCH_TENANCY_ORDER_API_SUCCESS,
  FETCH_TENANCY_ORDER_API_FAILED,
  CLEAR_TENANCY_ORDER_HAS_FETCHED,
  SET_IS_TENANT_FORM_OPEN,
  SET_IS_PROPERTY_FORM_OPEN,
  SET_SELECTED_ADDRESS_ID,
  SET_CURRENT_ADDRESS_FORM,
  AddressForm,
  SET_IS_UPDATE_PROPERTY_MODAL_OPEN,
  SET_CURRENT_REPOSIT_ACTION_MODAL,
  SET_TENANT_EDIT_FORM_OPEN
} from './reposit.actions';

const initialState: RepositState = {
  hasTenancyOrderBeenFetched: false,
  isTenantFormOpen: false,
  isPropertyFormOpen: false,
  selectedAddressId: '',
  currentAddressForm: AddressForm.ADDRESS_LIST,
  isUpdatePropertyModalOpen: false,
  currentRepositActionModal: '',
  tenantEditFormsOpen: {}
};

export default (state = initialState, action: RepositActionTypes): RepositState => {
  switch (action.type) {
    // requested and failed are handled in loading and error reducers
    case FETCH_TENANCY_ORDER_API_SUCCESS: {
      return {
        ...state,
        hasTenancyOrderBeenFetched: true
      };
    }
    case FETCH_TENANCY_ORDER_API_FAILED: {
      return {
        ...state,
        hasTenancyOrderBeenFetched: true
      };
    }
    case CLEAR_TENANCY_ORDER_HAS_FETCHED: {
      return {
        ...state,
        hasTenancyOrderBeenFetched: false
      };
    }
    case SET_IS_TENANT_FORM_OPEN: {
      return {
        ...state,
        isTenantFormOpen: action.payload
      };
    }
    case SET_IS_PROPERTY_FORM_OPEN: {
      return {
        ...state,
        isPropertyFormOpen: action.payload
      };
    }

    case SET_SELECTED_ADDRESS_ID: {
      return {
        ...state,
        selectedAddressId: action.payload
      };
    }

    case SET_CURRENT_ADDRESS_FORM: {
      return {
        ...state,
        currentAddressForm: action.payload
      };
    }

    case SET_IS_UPDATE_PROPERTY_MODAL_OPEN: {
      return {
        ...state,
        isUpdatePropertyModalOpen: action.payload
      };
    }

    case SET_CURRENT_REPOSIT_ACTION_MODAL: {
      return {
        ...state,
        currentRepositActionModal: action.payload
      };
    }

    case SET_TENANT_EDIT_FORM_OPEN: {
      return {
        ...state,
        tenantEditFormsOpen: {
          ...state.tenantEditFormsOpen,
          [action.payload.tenantId]: action.payload.isOpen
        }
      };
    }
    default:
      return state;
  }
};
