import { ClaimItemDocumentDTO, ClaimItemDocumentsApi } from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { FlashState } from '../../components/FlashMessage/index';
import { getErrorMessage } from '../../utils/common.utils';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import { ClaimItemDocumentEntity, ClaimItemEntity } from '../entities/entities.types';
import { FlashMessagesActionTypes, setFlashMessage } from '../flash-messages/flash-messages.actions';
import SCHEMA from '../schema';
import { getClaimItemDocumentByDocumentId } from '../selectors/claim-item-document.selectors';
import { getClaimItemById } from '../selectors/claim-item.selectors';
import { createClaimItemDocumentsApi, runSagaWithAuth } from '../utils/api.utils';
import {
  ClaimItemDocumentActionTypes,
  createClaimItemDocumentFailed,
  createClaimItemDocumentSuccess,
  CREATE_CLAIM_ITEM_DOCUMENT_API_REQUESTED,
  CREATE_CLAIM_ITEM_DOCUMENT_STORE_KEY,
  deleteClaimItemDocumentFailed,
  deleteClaimItemDocumentSuccess,
  DELETE_CLAIM_ITEM_DOCUMENT_API_REQUESTED,
  DELETE_CLAIM_ITEM_DOCUMENT_STORE_KEY
} from './claim-item-document.actions';
import { CreateClaimItemDocumentPayload } from './claim-item-document.types';

export function* createClaimItemDocument({ payload }: { type: string; payload: CreateClaimItemDocumentPayload }) {
  try {
    const claimItemDocumentsApi: ClaimItemDocumentsApi = yield createClaimItemDocumentsApi();
    const apiResponse: AxiosResponse<ClaimItemDocumentDTO> = yield call(
      runSagaWithAuth(() =>
        claimItemDocumentsApi.addClaimItemDocument(
          payload.claimId,
          payload.claimItemId,
          payload.file,
          payload.file.name,
          payload.type,
          payload.claimItemProposalId
        )
      )
    );
    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.claimItemDocument);

    yield put<ClaimItemDocumentActionTypes>(
      createClaimItemDocumentSuccess({
        claimItemId: payload.claimItemId,
        claimItemDocumentId: apiResponse.data.id,
        claimItemProposalId: apiResponse.data.claimItemProposalId
      })
    );
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: CREATE_CLAIM_ITEM_DOCUMENT_STORE_KEY,
        message: 'Success! You have add a claim item document.',
        state: FlashState.SUCCESS
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: CREATE_CLAIM_ITEM_DOCUMENT_STORE_KEY, message: error, state: FlashState.ERROR })
    );
    yield put<ClaimItemDocumentActionTypes>(createClaimItemDocumentFailed(error));
  }
}

export function* deleteClaimItemDocument({ payload }: { type: string; payload: string }) {
  try {
    const claimItemDocument: ClaimItemDocumentEntity = yield select(getClaimItemDocumentByDocumentId(payload));
    const claimItem: ClaimItemEntity = yield select(state => getClaimItemById(state, claimItemDocument.claimItemId));
    const claimItemDocumentsApi: ClaimItemDocumentsApi = yield createClaimItemDocumentsApi();
    const apiResponse: AxiosResponse<ClaimItemDocumentDTO> = yield call(
      runSagaWithAuth(() =>
        claimItemDocumentsApi.deleteClaimItemDocument(claimItem.id, claimItem.claimId, claimItemDocument.document)
      )
    );
    yield put<ClaimItemDocumentActionTypes>(
      deleteClaimItemDocumentSuccess({
        claimItemDocumentId: claimItemDocument.id,
        claimItemId: claimItem.id,
        claimItemProposalId: apiResponse.data.claimItemProposalId
      })
    );
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: DELETE_CLAIM_ITEM_DOCUMENT_STORE_KEY,
        message: 'Success! You deleted the claim item document.',
        state: FlashState.SUCCESS
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<ClaimItemDocumentActionTypes>(deleteClaimItemDocumentFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: DELETE_CLAIM_ITEM_DOCUMENT_STORE_KEY, message: error, state: FlashState.ERROR })
    );
  }
}

// ****************
// WATCHERS
// ****************
export function* watchClaimItemDocumentsSagas() {
  yield takeLatest(CREATE_CLAIM_ITEM_DOCUMENT_API_REQUESTED, createClaimItemDocument);
  yield takeLatest(DELETE_CLAIM_ITEM_DOCUMENT_API_REQUESTED, deleteClaimItemDocument);
}
