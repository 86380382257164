import { AuthApi, LoginDTO, ResetPasswordDTO, TokenResponseDTO } from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import { getErrorMessage } from '../../utils/common.utils';
import { setHasRequestedPasswordReset } from '../account/account.actions';
import { createAuthApi, runSagaWithAuth } from '../utils/api.utils';
import {
  loginFailed,
  loginRequested,
  loginSuccess,
  resetPasswordFailed,
  resetPasswordRequested,
  resetPasswordRequestFailed,
  resetPasswordRequestRequested,
  resetPasswordRequestSuccess,
  resetPasswordSuccess,
  setPasswordFailed,
  setPasswordRequested,
  setPasswordSuccess,
  setToken
} from './auth.actions';
import { ResetPasswordPayload } from './auth.types';

export function* login({ payload }: { payload: LoginDTO; type: string }) {
  try {
    const authApi: AuthApi = yield createAuthApi();
    const apiResponse: AxiosResponse<TokenResponseDTO> = yield call(runSagaWithAuth(() => authApi.login(payload)));
    if (apiResponse.data.requiresReset) {
      yield put(setHasRequestedPasswordReset(true));
    } else if (apiResponse.data.accessToken) {
      yield put(setToken(apiResponse.data.accessToken));
      yield put(loginSuccess());
    }
    // might need to be smarter
    yield push('/reposits');
  } catch (e) {
    const error = getErrorMessage(e);
    yield put(loginFailed(error));
  }
}

export function* resetPasswordRequest({ payload }: { payload: string; type: string }) {
  try {
    const authApi: AuthApi = yield createAuthApi();
    yield call(runSagaWithAuth(() => authApi.resetPasswordRequest(payload)));
    yield put(resetPasswordRequestSuccess());
    yield put(setHasRequestedPasswordReset(true));
  } catch (e) {
    const error = getErrorMessage(e);
    yield put(resetPasswordRequestFailed(error));
  }
}

export function* resetPassword({ payload }: { payload: ResetPasswordPayload; type: string }) {
  try {
    const authApi: AuthApi = yield createAuthApi();
    yield call(runSagaWithAuth(() => authApi.resetPassword(payload)));
    yield put(resetPasswordSuccess(payload.email));
    yield delay(4000);
    yield put(push('/auth/login'));
  } catch (e) {
    const error = getErrorMessage(e);
    yield put(resetPasswordFailed(error));
  }
}

export function* setPassword({ payload }: { payload: ResetPasswordDTO; type: string }) {
  try {
    const authApi: AuthApi = yield createAuthApi();
    const apiResponse: AxiosResponse<TokenResponseDTO> = yield call(runSagaWithAuth(() => authApi.setPassword(payload)));
    if (apiResponse.data.accessToken) {
      yield put(setToken(apiResponse.data.accessToken));
      yield put(setPasswordSuccess());
      // might need to be smarter
      yield push('/reposits');
    }
  } catch (e) {
    const error = getErrorMessage(e);
    yield put(setPasswordFailed(error));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchAuthSagas() {
  yield takeLatest(loginRequested.type, login);
  yield takeLatest(resetPasswordRequestRequested.type, resetPasswordRequest);
  yield takeLatest(resetPasswordRequested.type, resetPassword);
  yield takeLatest(setPasswordRequested.type, setPassword);
}
