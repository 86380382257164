import React from 'react';
import { Formik, FormikProps } from 'formik';
import { Container, Col, Row } from 'react-grid-system';
import styled from 'styled-components';
import { get } from 'lodash';
import { getBreakpoint } from '../../base/style';
import * as Yup from 'yup';
import FieldWithLabel from '../FormFields/FieldWithLabel';
import { Input, RadioGroup } from '../FormFields';
import { Header4 } from '../Typography/index';
import { phoneNumberStringMatcher } from '../../utils/regex/phoneNumber.regex';

const options = [{ label: 'Yes', value: 'YES' }, { label: 'No', value: 'NO' }];

const Field = styled.div`
  min-height: 86px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    max-width: 96%;
  }
`;

const FieldAlt = styled.div`
  min-height: 86px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    float: right;
    max-width: 96%;
    width: 100%;
  }
`;

const Action = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 36px 0 0;
`;

interface Guarantor {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber?: string;
}

export interface TenantFormValues {
  firstName?: string;
  lastName?: string;
  email: string;
  requireGuarantor: string;
  guarantor?: Guarantor;
}

interface TenantFormProps {
  onSubmit: (tenant: TenantFormValues) => void;
  formActions: JSX.Element;
  initialValues?: TenantFormValues;
}

const Schema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  requireGuarantor: Yup.string().required('Required'),
  email: Yup.string()
    .email('Must be a valid email')
    .required('Required'),
  guarantor: Yup.object().when('requireGuarantor', {
    is: requireGuarantor => requireGuarantor === 'YES',
    then: Yup.object().shape(
      {
        firstName: Yup.string().required('Required'),
        lastName: Yup.string().required('Required'),
        email: Yup.string()
          .email('Must be a valid email')
          .required('Required'),
        mobileNumber: Yup.string()
          .nullable()
          .when('mobileNumber', {
            is: value => !!value,
            then: Yup.string()
              .min(11, 'Incorrectly formatted phone number')
              .max(15, 'Incorrectly formatted phone number')
              .matches(phoneNumberStringMatcher, 'Incorrectly formatted phone number')
          })
      },
      // needed for cyclic dependency on mobileNumber
      [['mobileNumber', 'mobileNumber']]
    ),
    otherwise: Yup.object().nullable()
  })
});

const TenantForm: React.SFC<TenantFormProps> = ({ onSubmit, formActions, initialValues }) => {
  const initValues = {
    firstName: get(initialValues, 'firstName', ''),
    lastName: get(initialValues, 'lastName', ''),
    email: get(initialValues, 'email', ''),
    requireGuarantor: get(initialValues, 'requireGuarantor', 'NO'),
    guarantor: get(initialValues, 'guarantor')
  };

  return (
    <Formik initialValues={initValues} onSubmit={onSubmit} validationSchema={Schema}>
      {({
        values,
        handleChange,
        handleSubmit,
        handleBlur,
        touched,
        errors,
        setFieldValue,
        setFieldTouched
      }: FormikProps<TenantFormValues>) => {
        return (
          <form onSubmit={handleSubmit}>
            <Container fluid>
              <Row>
                <Col lg={6} style={{ padding: 0 }}>
                  <Field>
                    <FieldWithLabel label="First name" touched={touched.firstName} error={errors.firstName}>
                      <Input
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="firstName"
                        touched={touched.firstName}
                        error={errors.firstName}
                      />
                    </FieldWithLabel>
                  </Field>
                </Col>
                <Col lg={6} style={{ padding: 0 }}>
                  <FieldAlt>
                    <FieldWithLabel label="Last name" touched={touched.lastName} error={errors.lastName}>
                      <Input
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="lastName"
                        touched={touched.lastName}
                        error={errors.lastName}
                      />
                    </FieldWithLabel>
                  </FieldAlt>
                </Col>
              </Row>
              <Row>
                <Col lg={12} style={{ padding: 0 }}>
                  <FieldWithLabel label="Email address" touched={touched.email} error={errors.email}>
                    <Input
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="email"
                      touched={touched.email}
                      error={errors.email}
                    />
                  </FieldWithLabel>
                </Col>
              </Row>
              <Row>
                <Col lg={12} style={{ padding: '15px 0 0 0' }}>
                  <Header4>Does this tenant require a guarantor?</Header4>
                  <RadioGroup
                    name="requireGuarantor"
                    options={options}
                    onChange={e => {
                      handleChange(e);
                      // have to set these manually annoyingly
                      // if it's set to {} the keys are undefined and formik does not like that
                      setFieldValue('guarantor.firstName', '');
                      setFieldValue('guarantor.lastName', '');
                      setFieldValue('guarantor.email', '');
                      setFieldValue('guarantor.mobileNumber', '');
                      setFieldTouched('guarantor.firstName', false);
                      setFieldTouched('guarantor.lastName', false);
                      setFieldTouched('guarantor.email', false);
                      setFieldTouched('guarantor.mobileNumber', false);
                    }}
                    onBlur={handleBlur}
                    selected={values.requireGuarantor}
                    error={errors.requireGuarantor}
                    touched={touched.requireGuarantor}
                  />
                  {values.requireGuarantor === 'YES' && (
                    <>
                      <Row style={{ paddingTop: 15 }}>
                        <Col lg={6}>
                          <Field>
                            <FieldWithLabel
                              label="Guarantor first name*"
                              touched={get(touched, 'guarantor.firstName', false)}
                              error={get(errors, 'guarantor.firstName', '')}
                            >
                              <Input
                                value={get(values, 'guarantor.firstName', '')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="guarantor.firstName"
                                touched={get(touched, 'guarantor.firstName', false)}
                                error={get(errors, 'guarantor.firstName', '')}
                              />
                            </FieldWithLabel>
                          </Field>
                        </Col>
                        <Col lg={6}>
                          <FieldAlt>
                            <FieldWithLabel
                              label="Guarantor last name*"
                              touched={get(touched, 'guarantor.lastName', false)}
                              error={get(errors, 'guarantor.lastName', '')}
                            >
                              <Input
                                value={get(values, 'guarantor.lastName', '')}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="guarantor.lastName"
                                touched={get(touched, 'guarantor.lastName', false)}
                                error={get(errors, 'guarantor.lastName', '')}
                              />
                            </FieldWithLabel>
                          </FieldAlt>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <FieldWithLabel
                            label="Guarantor email address*"
                            touched={get(touched, 'guarantor.email', false)}
                            error={get(errors, 'guarantor.email', '')}
                          >
                            <Input
                              value={get(values, 'guarantor.email', '')}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="guarantor.email"
                              touched={get(touched, 'guarantor.email', false)}
                              error={get(errors, 'guarantor.email', '')}
                            />
                          </FieldWithLabel>
                        </Col>
                        <Col lg={6}>
                          <FieldWithLabel
                            label="Guarantor mobile number"
                            touched={get(touched, 'guarantor.mobileNumber', false)}
                            error={get(errors, 'guarantor.mobileNumber', '')}
                          >
                            <Input
                              value={get(values, 'guarantor.mobileNumber', '')}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="guarantor.mobileNumber"
                              touched={get(touched, 'guarantor.mobileNumber', false)}
                              error={get(errors, 'guarantor.mobileNumber', '')}
                            />
                          </FieldWithLabel>
                        </Col>
                      </Row>
                    </>
                  )}
                </Col>
              </Row>
              <Row>
                <Col lg={12} style={{ padding: 0 }}>
                  <Action>{formActions}</Action>
                </Col>
              </Row>
            </Container>
          </form>
        );
      }}
    </Formik>
  );
};

export default TenantForm;
