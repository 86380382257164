import {
  AddressesApi,
  AuthApi,
  ClaimDocumentsApi,
  ClaimItemDocumentsApi,
  ClaimItemsApi,
  ClaimsApi,
  DocumentsApi,
  FeedbackApi,
  NetPromoterScoreApi,
  OrganizationBankAccountsApi,
  OrganizationInvitesApi,
  OrganizationRecipientsApi,
  OrganizationsApi,
  PropertiesApi,
  TenanciesApi,
  TenancyCheckoutsApi,
  TenancyExpiriesApi,
  TenancyInvitesApi,
  TenancyOrdersApi,
  TenancyUsersApi,
  UsersApi,
  ClaimProposalsApi,
  ClaimItemProposalsApi
} from '@reposit/api-client';
import axios, { AxiosInstance } from 'axios';
import { call, put, select } from 'redux-saga/effects';
import { appConfig } from '../../appConfig';
import { AppDispatch } from '../../index';
import { logout } from '../auth/auth.actions';
import { getAccessToken } from '../auth/auth.selectors';
import { AppState } from '../root.reducer';

const HTTP_UNAUTHORIZED = 401;

export const runSagaWithAuth = (saga: () => any) =>
  function*() {
    try {
      const result = yield call(() => saga());
      return result;
    } catch (e) {
      if (e.response.status === HTTP_UNAUTHORIZED) {
        yield put(logout());
      }
      throw e;
    }
  };

export const runThunkWithAuth = (thunk: () => Promise<any>, dispatch: AppDispatch) => {
  return thunk().catch(e => {
    if (e.response.status === HTTP_UNAUTHORIZED) {
      dispatch(logout());
    }
    throw e;
  });
};

export function* createAxiosInstance() {
  const accessToken = yield select(getAccessToken);
  return axios.create({
    baseURL: appConfig.api.basePath,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
}

export function createStandardAxiosInstance(state: AppState) {
  const accessToken = getAccessToken(state);
  return axios.create({
    baseURL: appConfig.api.basePath,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  });
}

export function* createTenanciesApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new TenanciesApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createClaimsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new ClaimsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createClaimItemsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new ClaimItemsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createClaimItemDocumentsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new ClaimItemDocumentsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createClaimDocumentsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new ClaimDocumentsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createUsersApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new UsersApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createTenancyOrdersApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new TenancyOrdersApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createOrganizationsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new OrganizationsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createAddressesApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new AddressesApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createTenancyCheckoutsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new TenancyCheckoutsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createOrganizationInviteApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new OrganizationInvitesApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createTenancyInviteApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new TenancyInvitesApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createTenancyExpiriesApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new TenancyExpiriesApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createDocumentsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new DocumentsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createFeedbackApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new FeedbackApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createNetPromoterScoreApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new NetPromoterScoreApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createPropertyApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new PropertiesApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createOrganizationRecipientsApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new OrganizationRecipientsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createAuthApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new AuthApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createOrganizationBankAccountApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new OrganizationBankAccountsApi({}, appConfig.api.basePath, axiosInstance);
}

export function* createTenancyUsersApi() {
  const axiosInstance: AxiosInstance = yield createAxiosInstance();
  return new TenancyUsersApi({}, appConfig.api.basePath, axiosInstance);
}

// NON GENERATOR

export function createStandardDocumentsApi(state: AppState) {
  const axiosInstance: AxiosInstance = createStandardAxiosInstance(state);
  return new DocumentsApi({}, appConfig.api.basePath, axiosInstance);
}

export function createStandardClaimItemDocumentsApi(state: AppState) {
  const axiosInstance: AxiosInstance = createStandardAxiosInstance(state);
  return new ClaimItemDocumentsApi({}, appConfig.api.basePath, axiosInstance);
}

export function createStandardClaimDocumentsApi(state: AppState) {
  const axiosInstance: AxiosInstance = createStandardAxiosInstance(state);
  return new ClaimDocumentsApi({}, appConfig.api.basePath, axiosInstance);
}

export function createStandardClaimProposalsApi(state: AppState) {
  const axiosInstance: AxiosInstance = createStandardAxiosInstance(state);
  return new ClaimProposalsApi({}, appConfig.api.basePath, axiosInstance);
}

export function createStandardClaimItemProposalsApi(state: AppState) {
  const axiosInstance: AxiosInstance = createStandardAxiosInstance(state);
  return new ClaimItemProposalsApi({}, appConfig.api.basePath, axiosInstance);
}

export function createStandardClaimsApi(state: AppState) {
  const axiosInstance: AxiosInstance = createStandardAxiosInstance(state);
  return new ClaimsApi({}, appConfig.api.basePath, axiosInstance);
}

export function createStandardTenanciesApi(state: AppState) {
  const axiosInstance: AxiosInstance = createStandardAxiosInstance(state);
  return new TenanciesApi({}, appConfig.api.basePath, axiosInstance);
}
