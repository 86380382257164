import React, { FC, Fragment } from 'react';
import styled from 'styled-components';

import Modal from '../../../components/Modal/index';
import { Header3, P2 } from '../../../components/Typography/index';
import Button from '../../../components/Button/index';

interface CreatePropertyModalProps {
  onSubmit: () => void;
  onDismiss: () => void;
}

const Action = styled.div`
  padding: 12px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

const CreatePropertyModal: FC<CreatePropertyModalProps> = ({ onSubmit, onDismiss }) => {
  return (
    <Modal onDismiss={onDismiss}>
      <Fragment>
        <Header3 style={{ marginBottom: 6 }}>Assign landlord to address</Header3>
        <P2 style={{ marginBottom: 24 }}>Would you like to assign this landlord to this property address?</P2>
        <Action>
          <Button buttonType="secondary" noArrow={true} onClick={onDismiss}>
            No, return to form
          </Button>
          <Button onClick={onSubmit}>Yes</Button>
        </Action>
      </Fragment>
    </Modal>
  );
};

export default CreatePropertyModal;
