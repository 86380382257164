import { createAction } from '@reduxjs/toolkit';
import { CreateOrganizationBankAccountDTO } from '@reposit/api-client';

export const CREATE_BANK_ACCOUNT_STORE_KEY = 'BANK_ACCOUNTS/CREATE_BANK_ACCOUNT';
export const DELETE_BANK_ACCOUNT_STORE_KEY = 'BANK_ACCOUNTS/DELETE_BANK_ACCOUNT';
export const GET_BANK_ACCOUNTS_STORE_KEY = 'BANK_ACCOUNTS/GET_BANK_ACCOUNTS';

export const createBankAccountRequested = createAction<CreateOrganizationBankAccountDTO>(
  'BANK_ACCOUNTS/CREATE_BANK_ACCOUNT_API_REQUESTED'
);
export const createBankAccountSuccess = createAction<string>('BANK_ACCOUNTS/CREATE_BANK_ACCOUNT_API_SUCCESS');
export const createBankAccountFailed = createAction<string>('BANK_ACCOUNTS/CREATE_BANK_ACCOUNT_API_FAILED');

export const deleteBankAccountRequested = createAction<string>('BANK_ACCOUNTS/DELETE_BANK_ACCOUNT_API_REQUESTED');
export const deleteBankAccountSuccess = createAction<string>('BANK_ACCOUNTS/DELETE_BANK_ACCOUNT_API_SUCCESS');
export const deleteBankAccountFailed = createAction<string>('BANK_ACCOUNTS/DELETE_BANK_ACCOUNT_API_FAILED');

export const getBankAccountsRequested = createAction('BANK_ACCOUNTS/GET_BANK_ACCOUNTS_API_REQUESTED');
export const getBankAccountsSuccess = createAction<string[]>('BANK_ACCOUNTS/GET_BANK_ACCOUNTS_API_SUCCESS');
export const getBankAccountsFailed = createAction<string>('BANK_ACCOUNTS/GET_BANK_ACCOUNTS_API_FAILED');

export const setClaimAccountShowing = createAction<boolean>('BANK_ACCOUNTS/SET_CLAIM_ACCOUNT_SHOWING');
