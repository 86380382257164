import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import CheckListIcon from '../../../assets/svg/onboarding/checklist-purple.svg';
import DocumentIcon from '../../../assets/svg/onboarding/document.svg';
import AgreeableCard from '../../../components/AgreeableCard/index';
import Button from '../../../components/Button/index';
import FlashMessage from '../../../components/FlashMessage';
import Actions from '../../../components/Onboarding/Actions/index';
import Title from '../../../components/Onboarding/Title/index';
import SecondaryPanel from '../../../components/SecondaryPanel/index';
import { Header4, P2 } from '../../../components/Typography/index';
import { AST_ADDENDUM_LINK } from '../../../constants/links';
import { updateASTAcceptanceRequested, UPDATE_AST_ACCEPTANCE_STORE_KEY } from '../../../redux/account/account.actions';
import { getCurrentOrganizationId } from '../../../redux/auth/auth.selectors';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import { getOrganization } from '../../../redux/selectors/organization.selectors';
import { FLASH_MESSAGE_TIMEOUT, useFlashMessage } from '../../FlashMessage';

const CardTitle = styled(Header4)`
  padding: 0 36px;
`;

const Subtitle = styled(P2)`
  margin: -12px 0 12px;
`;

const CardAction = styled.div`
  padding: 12px 0 0;
  text-align: right;
`;

const ASTDownload = styled.div`
  align-items: center;
  display: flex;

  &:before {
    background: transparent url(${DocumentIcon}) no-repeat center center;
    content: '';
    display: block;
    margin: 0 10px 0 0;
    height: 20px;
    width: 20px;
  }
`;

const ExternalLink = styled.a`
  text-decoration: none;
`;

const ExtraNote = styled(P2)`
  padding: 0 36px;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const AST: React.SFC<any> = () => {
  const [isChecked, changeChecked] = useState(false);
  const onChange = () => changeChecked(!isChecked);
  const dispatch = useDispatch();
  const onSubmit = () => dispatch(updateASTAcceptanceRequested());
  const isLoading = useSelector(createLoadingSelector([UPDATE_AST_ACCEPTANCE_STORE_KEY]));
  const [flashMessagePayload, onDismissFlashMessage] = useFlashMessage([UPDATE_AST_ACCEPTANCE_STORE_KEY]);

  const currentOrgId = useSelector(getCurrentOrganizationId);
  const currentOrg = useSelector(getOrganization(currentOrgId));
  const hasReposits =
    currentOrg && (currentOrg.reposits.draft > 0 || currentOrg.reposits.pending > 0 || currentOrg.reposits.complete > 0);

  useEffect(() => {
    if (hasReposits) {
      changeChecked(true);
    }
  }, [hasReposits]);

  return (
    <Fragment>
      <Title icon={CheckListIcon} caption="Using Reposit instead of a deposit just requires a simple update to your AST">
        Update your AST
      </Title>

      <AgreeableCard
        flush
        info="Have you updated your AST?"
        label="I have updated our AST"
        isChecked={isChecked}
        showCheckbox={!hasReposits}
        onChange={onChange}
      >
        <CardTitle>To update your AST, just follow these two simple steps:</CardTitle>
        {hasReposits && (
          <ExtraNote>
            Please note that your agent has already updated your tenancy agreement for you, so{' '}
            <BoldText>this step is only necessary if you decide to use Reposit again.</BoldText>
          </ExtraNote>
        )}
        <SecondaryPanel>
          <Header4>Step 1</Header4>
          <Subtitle>Download the Reposit AST addendum.</Subtitle>
          <CardAction style={{ textAlign: 'left' }}>
            <ASTDownload>
              <ExternalLink href={AST_ADDENDUM_LINK} target="_blank">
                <Button buttonType="secondary" noArrow>
                  REPOSIT AST ADDENDUM
                </Button>
              </ExternalLink>
            </ASTDownload>
          </CardAction>
        </SecondaryPanel>
        <SecondaryPanel>
          <Header4>Step 2</Header4>
          <Subtitle>Insert the addendum at the end of the the AST template you normally use.</Subtitle>
        </SecondaryPanel>
      </AgreeableCard>

      {flashMessagePayload && (
        <FlashMessage onDismiss={onDismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMEOUT} payload={flashMessagePayload} />
      )}
      <Actions>
        <Button disabled={!isChecked || isLoading} onClick={onSubmit}>
          Continue
        </Button>
      </Actions>
    </Fragment>
  );
};

export default AST;
