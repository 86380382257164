import { ClaimItemDTO, ClaimItemProposalDTO } from '@reposit/api-client';
import {
  CreateClaimItemPayload,
  DiscardClaimItemRequestedPayload,
  DiscardClaimItemSuccessPayload,
  UpdateClaimItemPayload
} from './claim-item.types';

export const CREATE_CLAIM_ITEM_STORE_KEY = `CLAIM/CREATE_CLAIM_ITEM`;
export const CREATE_CLAIM_ITEM_API_REQUESTED = `CLAIM/CREATE_CLAIM_ITEM_API_REQUESTED`;
export const CREATE_CLAIM_ITEM_API_SUCCESS = `CLAIM/CREATE_CLAIM_ITEM_API_SUCCESS`;
export const CREATE_CLAIM_ITEM_API_FAILED = `CLAIM/CREATE_CLAIM_ITEM_API_FAILED`;

export const UPDATE_CLAIM_ITEM_STORE_KEY = `CLAIM/UPDATE_CLAIM_ITEM`;
export const UPDATE_CLAIM_ITEM_API_REQUESTED = `CLAIM/UPDATE_CLAIM_ITEM_API_REQUESTED`;
export const UPDATE_CLAIM_ITEM_API_SUCCESS = `CLAIM/UPDATE_CLAIM_ITEM_API_SUCCESS`;
export const UPDATE_CLAIM_ITEM_API_FAILED = `CLAIM/UPDATE_CLAIM_ITEM_API_FAILED`;

export const DISCARD_CLAIM_ITEM_STORE_KEY = `CLAIM/DISCARD_CLAIM_ITEM`;
export const DISCARD_CLAIM_ITEM_API_REQUESTED = `CLAIM/DISCARD_CLAIM_ITEM_API_REQUESTED`;
export const DISCARD_CLAIM_ITEM_API_SUCCESS = `CLAIM/DISCARD_CLAIM_ITEM_API_SUCCESS`;
export const DISCARD_CLAIM_ITEM_API_FAILED = `CLAIM/DISCARD_CLAIM_ITEM_API_FAILED`;

export const CREATE_CLAIM_ITEM_PROPOSAL_SUCCESS = 'CLAIM_ITEM/CREATE_CLAIM_ITEM_PROPOSAL_SUCCESS';
export const UPDATE_CLAIM_ITEM_PROPOSAL_SUCCESS = 'CLAIM_ITEM/UPDATE_CLAIM_ITEM_PROPOSAL_SUCCESS';

/* -------------------------------------------- CREATE CLAIM ITEM -------------------------------------------- */
export function createClaimItemRequested(payload: CreateClaimItemPayload) {
  return {
    type: CREATE_CLAIM_ITEM_API_REQUESTED,
    payload
  } as const;
}
export function createClaimItemSuccess(payload: ClaimItemDTO) {
  return {
    type: CREATE_CLAIM_ITEM_API_SUCCESS,
    payload
  } as const;
}
export function createClaimItemFailed(error: string) {
  return {
    type: CREATE_CLAIM_ITEM_API_FAILED,
    payload: error
  } as const;
}

/* -------------------------------------------- UPDATE CLAIM ITEM -------------------------------------------- */
export function updateClaimItemRequested(payload: UpdateClaimItemPayload) {
  return {
    type: UPDATE_CLAIM_ITEM_API_REQUESTED,
    payload
  } as const;
}
export function updateClaimItemSuccess(payload: ClaimItemDTO) {
  return {
    type: UPDATE_CLAIM_ITEM_API_SUCCESS,
    payload
  } as const;
}
export function updateClaimItemFailed(error: string) {
  return {
    type: UPDATE_CLAIM_ITEM_API_FAILED,
    payload: error
  } as const;
}

/* -------------------------------------------- DISCARD CLAIM ITEM -------------------------------------------- */
export function discardClaimItemRequested(payload: DiscardClaimItemRequestedPayload) {
  return {
    type: DISCARD_CLAIM_ITEM_API_REQUESTED,
    payload
  } as const;
}
export function discardClaimItemSuccess(payload: DiscardClaimItemSuccessPayload) {
  return {
    type: DISCARD_CLAIM_ITEM_API_SUCCESS,
    payload
  } as const;
}
export function discardClaimItemFailed(error: string) {
  return {
    type: DISCARD_CLAIM_ITEM_API_FAILED,
    payload: error
  } as const;
}

export function createClaimItemProposalSuccess(payload: ClaimItemProposalDTO) {
  return {
    type: CREATE_CLAIM_ITEM_PROPOSAL_SUCCESS,
    payload
  } as const;
}

export function updateClaimItemProposalSuccess(payload: ClaimItemProposalDTO) {
  return {
    type: UPDATE_CLAIM_ITEM_PROPOSAL_SUCCESS,
    payload
  } as const;
}

export type ClaimItemActionTypes = ReturnType<
  | typeof createClaimItemRequested
  | typeof createClaimItemSuccess
  | typeof createClaimItemFailed
  | typeof updateClaimItemRequested
  | typeof updateClaimItemSuccess
  | typeof updateClaimItemFailed
  | typeof discardClaimItemRequested
  | typeof discardClaimItemSuccess
  | typeof discardClaimItemFailed
  | typeof createClaimItemProposalSuccess
  | typeof updateClaimItemProposalSuccess
>;
