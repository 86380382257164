import { ClaimDTOStatusEnum } from '@reposit/api-client';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { get } from 'lodash';

import Button from '../../../components/Button';
import SecondaryPanel from '../../../components/SecondaryPanel';
import { StyledLink } from '../../../components/Typography';
import { setCreateClaimItemsFormOpen } from '../../../redux/claim/claim.actions';
import { getClaimItemFormOpen } from '../../../redux/claim/claim.selector';
import { DocumentEntity } from '../../../redux/entities/entities.types';
import ClaimItemForm, { ClaimItemFormValues, ComponentAction } from './ClaimItemForm';
import ClaimLineItem from './ClaimLineItem';
import { AppState } from '../../../redux/root.reducer';
import {} from '../../../redux/selectors/claim.selectors';
import {
  getLatestAgentProposal,
  getProposalItemsByProposalId,
  ClaimItemProposal,
  getIsAgentDeciding
} from '../../../redux/selectors/mediation.selectors';
import { ClaimItem } from '../../../components/Library/SupplierOffer/ClaimItem/ClaimItem';

const ClaimItemsListActions = styled.div`
  padding: 24px 36px 0;
`;

const Link = styled(StyledLink)`
  font-size: 12px;
  margin: 0 30px;
  padding: 0;
`;

interface ClaimItemsProps {
  claimId: string;
  claimStatus: ClaimDTOStatusEnum;
  claimItems: ClaimItem[];
  createClaimItem: (values: ClaimItemFormValues) => void;
  claimItemDocs: DocumentEntity[];
  publishedAndEditable: boolean;
  tenantProposalItems: ClaimItemProposal[];
}

export const ClaimItems: React.FC<ClaimItemsProps> = ({
  claimId,
  claimStatus,
  claimItems,
  createClaimItem,
  claimItemDocs,
  publishedAndEditable,
  tenantProposalItems
}) => {
  const dispatch = useDispatch();
  const isClaimItemsFormOpen = useSelector(getClaimItemFormOpen);
  const [isUploading, setIsUploading] = useState(false);

  const proposal = useSelector((state: AppState) => getLatestAgentProposal(state, claimId));
  const items = useSelector((state: AppState) => getProposalItemsByProposalId(state, get(proposal, 'id', '')));
  const isAgentDeciding = useSelector((state: AppState) => getIsAgentDeciding(state, claimId));

  const lastClaimItem = claimItems.length === 1;

  const result = claimItems.map((claimItem: ClaimItem) => {
    const item = items.find(i => i.claimItem.id === claimItem.id);
    const proposal = item && item.proposal;

    const foundCounterProposal =
      isAgentDeciding &&
      tenantProposalItems &&
      tenantProposalItems.find(tenProp => {
        const claimItemId = get(item, 'claimItem.id', '');
        return claimItemId === tenProp.claimItem.id;
      });
    if (!proposal) return <></>;

    return (
      <ClaimLineItem
        claimStatus={claimStatus}
        publishedAndEditable={publishedAndEditable}
        key={claimItem.id}
        claimId={claimId}
        item={claimItem}
        proposal={foundCounterProposal ? foundCounterProposal.proposal : proposal}
        lastClaimItem={lastClaimItem}
      />
    );
  });

  const content = isClaimItemsFormOpen ? (
    <SecondaryPanel>
      <ClaimItemForm
        title="Add a new claim item"
        onSubmit={createClaimItem}
        claimItemDocs={claimItemDocs}
        showDeleteButton
        publishedAndEditable={publishedAndEditable}
        claimId={claimId}
        formActions={
          <>
            <Link to="#" onClick={() => dispatch(setCreateClaimItemsFormOpen(false))}>
              Cancel
            </Link>
            <Button buttonType="secondary" type="submit" disabled={isUploading}>
              Save Claim Item
            </Button>
          </>
        }
        action={ComponentAction.CREATE}
        onUploadComplete={() => setIsUploading(false)}
        onUploadStart={() => setIsUploading(true)}
      />
    </SecondaryPanel>
  ) : (
    claimStatus === 'DRAFT' && (
      <ClaimItemsListActions>
        <Button buttonType="secondary" onClick={() => dispatch(setCreateClaimItemsFormOpen(true))}>
          Add New Item
        </Button>
      </ClaimItemsListActions>
    )
  );

  return (
    <>
      {result}
      {content}
    </>
  );
};
