import React, { Fragment, useState } from 'react';
import Modal from '../../../../components/Modal/index';
import { RepositActionModalProps } from '../index';
import { Header3, P2 } from '../../../../components/Typography/index';
import Button from '../../../../components/Button/index';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { discardRepositRequested, DISCARD_REPOSIT_STORE_KEY } from '../../../../redux/reposit/reposit.actions';
import { createLoadingSelector } from '../../../../redux/loading/loading.selector';
import Loading from '../../../../components/Loading/index';
import FieldWithLabel from '../../../../components/FormFields/FieldWithLabel/index';
import TextArea from '../../../../components/FormFields/TextArea/index';

const Action = styled.div`
  padding: 12px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

const RepositDiscardModal: React.SFC<RepositActionModalProps> = ({ tenancyOrder, onDismiss }) => {
  const dispatch = useDispatch();
  const discardRepositLoadingSelector = createLoadingSelector([DISCARD_REPOSIT_STORE_KEY]);
  const isLoading = useSelector(discardRepositLoadingSelector);

  const [reason, setReason] = useState<string>('');
  const discardReposit = () => dispatch(discardRepositRequested({ tenancyOrderId: tenancyOrder.id, reason }));

  const content = (
    <Fragment>
      <Header3 style={{ marginBottom: 6 }}>Are you sure you wish to cancel this Reposit?</Header3>
      <P2 style={{ marginBottom: 24 }}>
        This Reposit will be permanently deleted from the system and removed from the tenants' dashboard.
      </P2>
      <P2>
        Any tenants who completed their Reposit payment will be refunded in full. This refund will be reflected in their account
        in the next 5-7 working days.
      </P2>

      <FieldWithLabel label="Why are you cancelling this Reposit?">
        <TextArea name="message" value={reason} onChange={e => setReason(e.target.value)} onBlur={() => {}} />
      </FieldWithLabel>

      <Action>
        <Button buttonType="secondary" noArrow={true} onClick={onDismiss}>
          No, return to form
        </Button>
        <Button onClick={discardReposit}>Yes</Button>
      </Action>
    </Fragment>
  );

  return <Modal onDismiss={onDismiss}>{isLoading ? <Loading /> : content}</Modal>;
};

export default RepositDiscardModal;
