import {
  CurrentUserInfoDTO,
  NetPromoterScoreApi,
  NetPromoterScoreDTOCategoryEnum,
  OrganizationDTO,
  OrganizationsApi,
  UpdateUserAboutMeDTO,
  UpdateYourCompanyDTO,
  UserDTO,
  UsersApi
} from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { FlashState } from '../../components/FlashMessage/index';
import { getNPSCategory } from '../../components/NPSModal/index';
import { getErrorMessage } from '../../utils/common.utils';
import { sendEventToGoogleAnalytics } from '../../utils/integrators/analytics.integrator';
import { logout, setCurrentOrganizationRoles, setOrganizationId } from '../auth/auth.actions';
import { getCurrentOrganizationId } from '../auth/auth.selectors';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import { FlashMessagesActionTypes, setFlashMessage } from '../flash-messages/flash-messages.actions';
import SCHEMA from '../schema';
import { createNetPromoterScoreApi, createOrganizationsApi, createUsersApi, runSagaWithAuth } from '../utils/api.utils';
import {
  dismissOrganizationMessageFailed,
  dismissOrganizationMessageRequested,
  dismissOrganizationMessageSuccess,
  dismissUserMessageFailed,
  dismissUserMessageRequested,
  dismissUserMessageSuccess,
  fetchMeFailed,
  fetchMeRequested,
  fetchMeSuccess,
  submitNPSFailed,
  submitNPSRequested,
  submitNPSSuccess,
  SUBMIT_NPS_STORE_KEY,
  updateAboutMeFailed,
  updateAboutMeRequested,
  updateAboutMeSuccess,
  updateASTAcceptanceFailed,
  updateASTAcceptanceRequested,
  updateASTAcceptanceSuccess,
  updateBankAccountsConfirmedFailed,
  updateBankAccountsConfirmedRequested,
  updateBankAccountsConfirmedSuccess,
  updateEndOfTenancyEmailsFailed,
  updateEndOfTenancyEmailsRequested,
  updateEndOfTenancyEmailsSuccess,
  updateInformedLandlordsFailed,
  updateInformedLandlordsRequested,
  updateInformedLandlordsSuccess,
  updateLandlordExtraInformationFailed,
  updateLandlordExtraInformationRequested,
  updateLandlordExtraInformationSuccess,
  updateNPSModal,
  updateProductEmailsFailed,
  updateProductEmailsRequested,
  updateProductEmailsSuccess,
  updateTermsAcceptedFailed,
  updateTermsAcceptedRequested,
  updateTermsAcceptedSuccess,
  updateWelcomeLandlordFailed,
  updateWelcomeLandlordRequested,
  updateWelcomeLandlordSuccess,
  updateYourCompanyFailed,
  updateYourCompanyRequested,
  updateYourCompanySuccess,
  UPDATE_ABOUT_ME_STORE_KEY,
  UPDATE_AST_ACCEPTANCE_STORE_KEY,
  UPDATE_BANK_DETAILS_CONFIRMED_STORE_KEY,
  UPDATE_EOT_NOTIFICATIONS_STORE_KEY,
  UPDATE_INFORMED_LANDLORDS_STORE_KEY,
  UPDATE_LANDLORD_EXTRA_INFORMATION_STORE_KEY,
  UPDATE_TERMS_ACCEPTED_STORE_KEY,
  UPDATE_WELCOME_LANDLORD_STORE_KEY,
  UPDATE_YOUR_COMPANY_STORE_KEY
} from './account.actions';
import { getSurveyEvent } from './account.selectors';
import { CreateNPS, DismissOrganizationMessage, DismissUserMessage } from './account.types';

// ****************
// WORKERS
// ****************

export function* fetchMe() {
  try {
    const usersApi: UsersApi = yield createUsersApi();
    const { data } = yield call(runSagaWithAuth(() => usersApi.me()));

    const { user, currentOrganizations, requiresNPSResponse }: CurrentUserInfoDTO = data;

    const currentOrganization = currentOrganizations && currentOrganizations.length ? currentOrganizations[0] : undefined;

    const id: string = yield syncEntitiesAndGetResults({ ...user, organizations: currentOrganizations }, SCHEMA.user);

    let currentOrganizationId = yield select(getCurrentOrganizationId) || null;

    if (!currentOrganizationId && currentOrganization) currentOrganizationId = currentOrganization.id;
    yield put(
      fetchMeSuccess({
        currentUserId: id,
        requiresNPSResponse
      })
    );
    // if the user only has one org - we want to set their id here
    if (currentOrganizations && currentOrganizations.length === 1) {
      yield put(setOrganizationId(currentOrganizations[0].id));
      yield put(setCurrentOrganizationRoles(currentOrganizations[0].roles));
    }
  } catch (e) {
    yield put(fetchMeFailed(get(e, 'response.data.message', e)));
    yield put(logout());
  }
}

export function* updateAboutMe({ payload }: { payload: UpdateUserAboutMeDTO; type: string }) {
  try {
    const usersApi: UsersApi = yield createUsersApi();

    const { data } = yield call(runSagaWithAuth(() => usersApi.updateAboutMe(payload)));
    yield syncEntitiesAndGetResults(data, SCHEMA.user);
    yield put(updateAboutMeSuccess());
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_ABOUT_ME_STORE_KEY,
        message: error,
        state: FlashState.ERROR
      })
    );
    yield put(updateAboutMeFailed(error));
  }
}

export function* updateYourCompany({ payload }: { payload: UpdateYourCompanyDTO; type: string }) {
  try {
    let currentOrganizationId = yield select(getCurrentOrganizationId);
    const orgApi: OrganizationsApi = yield createOrganizationsApi();
    const { data } = yield call(runSagaWithAuth(() => orgApi.updateYourCompany(currentOrganizationId, payload)));
    yield syncEntitiesAndGetResults(data, SCHEMA.organization);
    yield put(updateYourCompanySuccess());
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_YOUR_COMPANY_STORE_KEY,
        message: error,
        state: FlashState.ERROR
      })
    );
    yield put(updateYourCompanyFailed(get(e, 'response.data.message', e)));
  }
}

export function* updateASTAcceptance() {
  try {
    let currentOrganizationId = yield select(getCurrentOrganizationId);
    const orgApi: OrganizationsApi = yield createOrganizationsApi();
    const { data } = yield call(runSagaWithAuth(() => orgApi.updateASTAcceptance(currentOrganizationId)));
    yield syncEntitiesAndGetResults(data, SCHEMA.organization);
    yield put(updateASTAcceptanceSuccess());
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_AST_ACCEPTANCE_STORE_KEY,
        message: error,
        state: FlashState.ERROR
      })
    );
    yield put(updateASTAcceptanceFailed(get(e, 'response.data.message', e)));
  }
}

export function* updateInformedLandlords() {
  try {
    let currentOrganizationId = yield select(getCurrentOrganizationId);
    const orgApi: OrganizationsApi = yield createOrganizationsApi();
    const { data } = yield call(runSagaWithAuth(() => orgApi.updateInformedLandlords(currentOrganizationId)));
    yield syncEntitiesAndGetResults(data, SCHEMA.organization);
    yield put(updateInformedLandlordsSuccess());
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_INFORMED_LANDLORDS_STORE_KEY,
        message: error,
        state: FlashState.ERROR
      })
    );
    yield put(updateInformedLandlordsFailed(get(e, 'response.data.message', e)));
  }
}

export function* updateWelcomeLandlord() {
  try {
    let currentOrganizationId = yield select(getCurrentOrganizationId);
    const orgApi: OrganizationsApi = yield createOrganizationsApi();
    const { data } = yield call(runSagaWithAuth(() => orgApi.welcomeLandlord(currentOrganizationId)));
    yield syncEntitiesAndGetResults(data, SCHEMA.organization);
    yield put(updateWelcomeLandlordSuccess());
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_WELCOME_LANDLORD_STORE_KEY,
        message: error,
        state: FlashState.ERROR
      })
    );
    yield put(updateWelcomeLandlordFailed(get(e, 'response.data.message', e)));
  }
}

export function* updateLandlordExtraInformation() {
  try {
    let currentOrganizationId = yield select(getCurrentOrganizationId);
    const orgApi: OrganizationsApi = yield createOrganizationsApi();
    const { data } = yield call(runSagaWithAuth(() => orgApi.setLandlordInformationComplete(currentOrganizationId)));
    yield syncEntitiesAndGetResults(data, SCHEMA.organization);
    yield put(updateLandlordExtraInformationSuccess());
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_LANDLORD_EXTRA_INFORMATION_STORE_KEY,
        message: error,
        state: FlashState.ERROR
      })
    );
    yield put(updateLandlordExtraInformationFailed(get(e, 'response.data.message', e)));
  }
}

export function* updateTermsAccepted() {
  try {
    let currentOrganizationId = yield select(getCurrentOrganizationId);
    const orgApi: OrganizationsApi = yield createOrganizationsApi();
    const apiResponse: AxiosResponse<OrganizationDTO> = yield call(
      runSagaWithAuth(() => orgApi.updateTermsAccepted(currentOrganizationId))
    );
    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.organization);
    sendEventToGoogleAnalytics({ action: 'Accepted Terms', category: 'Supplier' });
    yield put(updateTermsAcceptedSuccess());
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_TERMS_ACCEPTED_STORE_KEY,
        message: error,
        state: FlashState.ERROR
      })
    );
    yield put(updateTermsAcceptedFailed(get(e, 'response.data.message', e)));
  }
}

export function* updateBankAccountsConfirmed() {
  try {
    let currentOrganizationId = yield select(getCurrentOrganizationId);
    const orgApi: OrganizationsApi = yield createOrganizationsApi();
    const { data } = yield call(runSagaWithAuth(() => orgApi.confirmBankAccounts(currentOrganizationId)));
    yield syncEntitiesAndGetResults(data, SCHEMA.organization);
    yield put(updateBankAccountsConfirmedSuccess());
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_BANK_DETAILS_CONFIRMED_STORE_KEY,
        message: error,
        state: FlashState.ERROR
      })
    );
    yield put(updateBankAccountsConfirmedFailed(error));
  }
}

export function* dismissOrganizationMessage({ payload }: { type: string; payload: DismissOrganizationMessage }) {
  try {
    const orgApi: OrganizationsApi = yield createOrganizationsApi();
    const { messageId } = payload;
    let organizationId = yield select(getCurrentOrganizationId);
    const { data } = yield call(runSagaWithAuth(() => orgApi.dismissOrganizationMessage(messageId, organizationId)));
    yield syncEntitiesAndGetResults(data, SCHEMA.organization);
    yield put(dismissOrganizationMessageSuccess());
  } catch (e) {
    yield put(dismissOrganizationMessageFailed(get(e, 'response.data.message', e)));
  }
}

export function* dismissUserMessage({ payload }: { type: string; payload: DismissUserMessage }) {
  try {
    const usersApi: UsersApi = yield createUsersApi();
    const { messageId } = payload;
    const { data } = yield call(runSagaWithAuth(() => usersApi.dismissUserMessage(messageId)));
    yield syncEntitiesAndGetResults(data, SCHEMA.user);
    yield put(dismissUserMessageSuccess());
  } catch (e) {
    yield put(dismissUserMessageFailed(get(e, 'response.data.message', e)));
  }
}

export function* submitNPS({ payload }: { payload: CreateNPS; type: string }) {
  try {
    const { score, feedback } = payload;

    let organizationId = yield select(getCurrentOrganizationId);
    let surveyEvent = yield select(getSurveyEvent);

    const npsApi: NetPromoterScoreApi = yield createNetPromoterScoreApi();
    yield call(
      runSagaWithAuth(() =>
        npsApi.createNetPromoterScore({
          score,
          feedback,
          organizationId,
          surveyEvent
        })
      )
    );
    yield put(submitNPSSuccess());
    if (getNPSCategory(score) !== NetPromoterScoreDTOCategoryEnum.PROMOTER) {
      yield put(updateNPSModal({ isOpen: false, surveyEvent: undefined, collectingTrustpilotReview: false }));
    } else {
      yield put(updateNPSModal({ isOpen: true, surveyEvent: undefined, collectingTrustpilotReview: true }));
    }
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: SUBMIT_NPS_STORE_KEY,
        message: 'Thank you for your feedback',
        state: FlashState.SUCCESS
      })
    );
    yield put(fetchMeRequested());
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: SUBMIT_NPS_STORE_KEY,
        message: error,
        state: FlashState.ERROR
      })
    );
    yield put(submitNPSFailed(get(e, 'response.data.message', e)));
  }
}

export function* updateEndOfTenancyEmails({ payload }: { payload: { enabled: boolean } }) {
  try {
    const usersApi: UsersApi = yield createUsersApi();
    const { data }: AxiosResponse<UserDTO> = yield call(
      runSagaWithAuth(() => usersApi.updateEmailSettings({ endOfTenancyContact: payload.enabled }))
    );
    yield syncEntitiesAndGetResults(data, SCHEMA.user);
    const endOfTenancyContact = get(data, 'attributes.endOfTenancyContact');
    yield put(updateEndOfTenancyEmailsSuccess({ endOfTenancyContact }));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_EOT_NOTIFICATIONS_STORE_KEY,
        message: `Success! You ${
          endOfTenancyContact
            ? 'may receive notifications about any Reposits and end of tenancy charges for this organisation'
            : 'will only receive notifications about Reposits and end of tenancy charges you create'
        }`,
        state: FlashState.SUCCESS
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put(setFlashMessage({ key: UPDATE_EOT_NOTIFICATIONS_STORE_KEY, message: error, state: FlashState.ERROR }));
    yield put(updateEndOfTenancyEmailsFailed(get(e, 'response.data.message', e)));
  }
}

export function* updateProductEmails({ payload }: { payload: { enabled: boolean } }) {
  try {
    const usersApi: UsersApi = yield createUsersApi();
    const { data }: AxiosResponse<UserDTO> = yield call(
      runSagaWithAuth(() => usersApi.updateEmailSettings({ productUpdatesDisabled: payload.enabled }))
    );
    yield syncEntitiesAndGetResults(data, SCHEMA.user);
    const productUpdatesDisabled = get(data, 'attributes.productUpdatesDisabled');
    yield put(updateProductEmailsSuccess({ productUpdatesDisabled }));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_EOT_NOTIFICATIONS_STORE_KEY,
        message: `Success! You ${
          productUpdatesDisabled
            ? 'will not receive product updates about Reposit via email'
            : 'may receive product upates about Reposits via email'
        }`,
        state: FlashState.SUCCESS
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put(setFlashMessage({ key: UPDATE_EOT_NOTIFICATIONS_STORE_KEY, message: error, state: FlashState.ERROR }));
    yield put(updateProductEmailsFailed(get(e, 'response.data.message', e)));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchAccountSagas() {
  yield takeLatest(fetchMeRequested.type, fetchMe);
  yield takeLatest(updateAboutMeRequested.type, updateAboutMe);
  yield takeLatest(updateYourCompanyRequested.type, updateYourCompany);
  yield takeLatest(updateASTAcceptanceRequested.type, updateASTAcceptance);
  yield takeLatest(updateInformedLandlordsRequested.type, updateInformedLandlords);
  yield takeLatest(updateTermsAcceptedRequested.type, updateTermsAccepted);
  yield takeLatest(updateBankAccountsConfirmedRequested.type, updateBankAccountsConfirmed);
  yield takeLatest(submitNPSRequested.type, submitNPS);
  yield takeLatest(updateWelcomeLandlordRequested.type, updateWelcomeLandlord);
  yield takeLatest(updateLandlordExtraInformationRequested.type, updateLandlordExtraInformation);
  yield takeLatest(updateEndOfTenancyEmailsRequested, updateEndOfTenancyEmails);
  yield takeLatest(updateProductEmailsRequested, updateProductEmails);
  yield takeLatest(dismissOrganizationMessageRequested.type, dismissOrganizationMessage);
  yield takeLatest(dismissUserMessageRequested.type, dismissUserMessage);
}
