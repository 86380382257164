import { ActiveFormActionTypes, SET_ACTIVE_FORMS, CLEAR_ACTIVE_FORMS } from './active-form.actions';
import { ActiveFormsState } from './active-forms.types';

const initialState: ActiveFormsState = {
  activeForms: []
};

export default (state: ActiveFormsState = initialState, action: ActiveFormActionTypes): ActiveFormsState => {
  switch (action.type) {
    case SET_ACTIVE_FORMS: {
      return {
        ...state,
        activeForms: [...state.activeForms, ...action.payload]
      };
    }

    case CLEAR_ACTIVE_FORMS: {
      return {
        ...state,
        activeForms: []
      };
    }

    default:
      return state;
  }
};
