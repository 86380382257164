import { ClaimState } from './claim.types';
import {
  ClaimActionTypes,
  FETCH_CLAIM_API_SUCCESS,
  FETCH_CLAIM_API_FAILED,
  CLEAR_CLAIM_HAS_FETCHED,
  SET_CURRENT_CLAIM_ACTION_MODAL,
  SET_CLAIM_ITEMS_FORM_OPEN
} from './claim.actions';

const initialState: ClaimState = {
  hasClaimBeenFetched: false,
  isClaimItemFormOpen: false,
  currentClaimActionModal: ''
};

export default (state = initialState, action: ClaimActionTypes): ClaimState => {
  switch (action.type) {
    // requested and failed are handled in loading and error reducers
    case FETCH_CLAIM_API_SUCCESS: {
      return {
        ...state,
        hasClaimBeenFetched: true
      };
    }
    case FETCH_CLAIM_API_FAILED: {
      return {
        ...state,
        hasClaimBeenFetched: true
      };
    }
    case CLEAR_CLAIM_HAS_FETCHED: {
      return {
        ...state,
        hasClaimBeenFetched: false
      };
    }
    case SET_CURRENT_CLAIM_ACTION_MODAL: {
      return {
        ...state,
        currentClaimActionModal: action.payload
      };
    }
    case SET_CLAIM_ITEMS_FORM_OPEN: {
      return {
        ...state,
        isClaimItemFormOpen: action.payload
      };
    }
    default:
      return state;
  }
};
