import { Location } from 'history';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { AppSubHeader } from '../../components/AppSubHeader/index';
import OnboardingSidebar from '../../components/Onboarding/Sidebar/index';
import useLogout from '../../hooks/useLogout';
import { getHasOrganizationRole } from '../../redux/auth/auth.selectors';
import {
  getIsOnboardingCompleteForUser,
  getNextOnboardingRoute,
  OnboardingRoutes,
  getIsOnboardingCompleteForOrg
} from '../../redux/selectors/onboarding.selectors';
import { getCurrentUser } from '../../redux/selectors/user.selectors';
import AboutYou from './AboutYou/index';
import AST from './AST/index';
import BankAccounts from './BankAccounts/index';
import Complete from './Complete/index';
import { TenancyGuide } from './TenancyGuide/index';
import TermsConditions from './Terms/index';
import UsefulDocuments from './UsefulDocuments/index';
import { WelcomeLandlord } from './Welcome/index';
import YourCompany from './YourCompany/index';
import { OrganizationWithRepositsAndRolesRolesEnum } from '@reposit/api-client';
import { getIsOnboarding } from '../../redux/account/account.selectors';
import { setIsOnboarding } from '../../redux/account/account.actions';
import { BlockedByTerms } from './BlockedByTerms/index';

interface OnboardingProps {
  match: any;
  location: Location<any>;
}

const StyledContainer = styled.div`
  padding: 110px 0;
`;

const OnboardingContainer: React.SFC<OnboardingProps> = ({ match, location }) => {
  const handleLogout = useLogout();
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const nextOnboardingRoute = useSelector(getNextOnboardingRoute);
  const hasOnboardingRole = useSelector(getHasOrganizationRole(OrganizationWithRepositsAndRolesRolesEnum.ONBOARDING));
  const hasUserCompletedOnboarding = useSelector(getIsOnboardingCompleteForUser);
  const hasOrgCompletedOnboarding = useSelector(getIsOnboardingCompleteForOrg);
  const isOnboarding = useSelector(getIsOnboarding);
  const locationIsNotNextRoute = location && location.pathname !== nextOnboardingRoute;

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [location.pathname]);

  // this allows the completed and blocked steps not to persist when needed
  useEffect(() => {
    if (
      !isOnboarding &&
      nextOnboardingRoute &&
      (nextOnboardingRoute !== OnboardingRoutes.COMPLETED_ONBOARDING && nextOnboardingRoute !== OnboardingRoutes.BLOCKED_BY_TERMS)
    ) {
      dispatch(setIsOnboarding(true));
    }
  }, [nextOnboardingRoute, isOnboarding, dispatch]);

  if (hasUserCompletedOnboarding && hasOrgCompletedOnboarding && !isOnboarding) {
    return <Redirect to="/reposits" />;
  }

  if (locationIsNotNextRoute && nextOnboardingRoute) {
    return <Redirect to={nextOnboardingRoute} />;
  }

  return (
    <StyledContainer>
      <AppSubHeader userEmail={get(currentUser, 'email', '')} onLogout={handleLogout} />
      <Container>
        <Row>
          <Col lg={3}>
            <OnboardingSidebar hasOnboardingRole={hasOnboardingRole} currentLocation={location} />
          </Col>
          <Col lg={9}>
            <Switch location={location}>
              <Route path={`${match.path}/nearly-there`} exact component={BlockedByTerms} />
              {hasOnboardingRole ? <Route path={`${match.path}/welcome`} exact component={WelcomeLandlord} /> : null}
              <Route path={`${match.path}/about-you`} exact component={AboutYou} />
              {hasOnboardingRole ? <Route path={`${match.path}/your-company`} exact component={YourCompany} /> : null}
              {hasOnboardingRole ? <Route path={`${match.path}/update-your-ast`} exact component={AST} /> : null}
              {hasOnboardingRole ? <Route path={`${match.path}/bank-accounts`} exact component={BankAccounts} /> : null}
              {hasOnboardingRole ? <Route path={`${match.path}/useful-documents`} exact component={UsefulDocuments} /> : null}
              <Route path={`${match.path}/terms-and-conditions`} exact component={TermsConditions} />
              <Route path={`${match.path}/complete`} exact component={Complete} />
              <Route path={`${match.path}/tenancy-guide`} exact component={TenancyGuide} />
              <Route path={match.path} component={() => <Redirect to={`${match.path}/about-you`} />} />
            </Switch>
          </Col>
        </Row>
      </Container>
    </StyledContainer>
  );
};

export default OnboardingContainer;
