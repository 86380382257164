import React from 'react';
import styled from 'styled-components';
import DownArrow from '../../assets/svg/down-white.svg';
import { RepositTheme } from '../../base/theme';

export interface AccountSelectorProps {
  activeAccount: string;
  onChange: ((event: React.ChangeEvent<HTMLSelectElement>) => void) | undefined;
  // TODO:  need to sort this out
  organizations: any[];
}

const StyledSelect = styled.div<{ disabled: boolean }>`
  width: 240px;
  height: 48px;
  margin: 24px 0 36px;
  overflow: hidden;
  background: #fff;
  transition: all 0.2s;
  position: relative;

  /* This can be uncommented when we go live with the multi-branch */

  ${props =>
    !props.disabled &&
    `
      &:after {
        background: transparent url('${DownArrow}') 1px -1px;
        background-size:10px;
        content: "";
        display: "block";
        position: absolute;
        pointer-events: none;
        top: 19px;
        right: 24px;
        height: 10px;
        width: 10px;
      } 

      &:hover {
        background: ${props.theme.colors.bgAccent};
      } 
  `}
`;

const Selector = styled.select<{ disabled: boolean }>`
  ${props =>
    props.disabled
      ? `background: transparent;`
      : `background-image: ${
          props.disabled
            ? 'transparent'
            : `linear-gradient(
          to bottom,
          ${props.theme.colors.secondaryBrand},
          ${props.theme.colors.primaryBrand}
        )`
        };`}

  border: none;
  border-bottom: 1px solid ${props => props.theme.colors.dividerLight};
  color: ${props => (props.disabled ? props.theme.colors.primaryBrand : 'white')};
  cursor: pointer;
  display: block;
  font-family: ${props => props.theme.typography.face.primary};
  font-size: 14px;
  font-weight: bold;
  padding: 14px 24px;
  outline: none;
  height: 48px;
  width: 100%;
  -webkit-appearance: none;
  padding-right: 42px;

  &::-ms-expand {
    display: none;
  }

  &:disabled {
    cursor: default;
  }
`;

const AccountSelector: React.SFC<AccountSelectorProps> = ({ onChange, activeAccount, organizations }) => {
  const disabled = organizations.length < 2;

  return (
    <StyledSelect disabled={disabled}>
      <Selector onChange={onChange} defaultValue={activeAccount} disabled={disabled}>
        {organizations.map(org => {
          return (
            <option key={org.id} value={org.id} style={{ color: RepositTheme.colors.body }}>
              {org.name}
            </option>
          );
        })}
      </Selector>
    </StyledSelect>
  );
};

export default AccountSelector;
