import React from 'react';
import styled from 'styled-components';
import { TenantEntity } from '../../redux/entities/entities.types';
import { TenantWithActions } from '../../redux/selectors/index';
import TenantListItem from './tenant-list-item';

interface TenantListProps {
  tenancyId: string;
  editMode: boolean;
  tenants: TenantWithActions[];
  disableRemove: boolean;
  onDelete: (id: string) => void;
  tenancyOrderStatus: string;
  tenancyOrderId: string;
}

const TenantListWrapper = styled.ul`
  background: #fff;
  margin: 22px 0;
  min-width: 200px;
  overflow: hidden;
  padding: 0;
`;

const sortTenantsByCreationDate = (a: TenantEntity, b: TenantEntity) => {
  return Number(new Date(a.createdAt)) - Number(new Date(b.createdAt));
};

const TenantList: React.SFC<TenantListProps> = ({
  tenancyId,
  onDelete,
  tenants,
  editMode,
  disableRemove,
  tenancyOrderStatus,
  tenancyOrderId
}) => {
  const sortedTenants = tenants.sort(sortTenantsByCreationDate);
  return (
    <TenantListWrapper>
      {sortedTenants.map(tenant => (
        <TenantListItem
          key={tenant.id}
          tenancyId={tenancyId}
          tenant={tenant}
          onDelete={onDelete}
          editMode={editMode}
          disableRemove={disableRemove}
          tenancyOrderStatus={tenancyOrderStatus}
          tenancyOrderId={tenancyOrderId}
        />
      ))}
    </TenantListWrapper>
  );
};

export default TenantList;
