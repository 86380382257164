import { OrganizationRecipientDTOGroupEnum, OrganizationWithRepositsAndRolesRolesEnum } from '@reposit/api-client';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { ContentContainer, InnerWrapper } from '../../../components/Common/index';
import { Header1, P1 } from '../../../components/Typography/index';
import { getCurrentOrganizationId, getHasOrganizationRole } from '../../../redux/auth/auth.selectors';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import {
  CREATE_ORG_RECIPIENT_STORE_KEY,
  setIsAddAdminRecipientFormOpen,
  setIsAddInitialRecipientFormOpen,
  setOrganizationRecipientRequested
} from '../../../redux/organization-recipient/organization-recipient.actions';
import {
  getIsAddAdminRecipientFormOpen,
  getIsAddInitialRecipientFormOpen
} from '../../../redux/organization-recipient/organization-recipient.selectors';
import ManageEOTNotifications from './ManageEOTNotifications';
import ManageRecipientGroups from './ManageRecipientGroups';

const FAQsLink = styled.a`
  color: ${props => props.theme.colors.primaryBrand};
  font-family: ${props => props.theme.typography.face.secondary};
  font-size: inherit;
  text-decoration: underline;
`;

interface NotificationsViewProps {}

const NotificationsView: React.FC<NotificationsViewProps> = () => {
  const dispatch = useDispatch();

  const isAdminFormOpen = useSelector(getIsAddAdminRecipientFormOpen);
  const isIntialFormOpen = useSelector(getIsAddInitialRecipientFormOpen);

  const currentOrgId = useSelector(getCurrentOrganizationId);

  useEffect(() => {
    dispatch(setOrganizationRecipientRequested());
  }, [dispatch, currentOrgId]);

  const setIsAdminFormOpen = (val: boolean) => {
    dispatch(setIsAddAdminRecipientFormOpen(val));
  };
  const setIsInitialFormOpen = (val: boolean) => {
    dispatch(setIsAddInitialRecipientFormOpen(val));
  };

  const isLoadingSelector = createLoadingSelector([CREATE_ORG_RECIPIENT_STORE_KEY]);
  const isLoading = useSelector(isLoadingSelector);
  const hasNotificationsRole = useSelector(getHasOrganizationRole(OrganizationWithRepositsAndRolesRolesEnum.NOTIFICATIONS));

  return (
    <InnerWrapper>
      <Container>
        <Row>
          <Col sm={12}>
            <Header1>Notifications</Header1>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg={12}>
            <ContentContainer>
              <P1 style={{ display: 'inline' }}>{`For more information, see our `}</P1>
              <P1 bold style={{ display: 'inline' }}>
                <FAQsLink href="https://support.reposit.co.uk/en/knowledge/notification-settings" target="_blank">
                  FAQs
                </FAQsLink>
              </P1>
              <ManageEOTNotifications />
              {hasNotificationsRole && (
                <>
                  <ManageRecipientGroups
                    group={OrganizationRecipientDTOGroupEnum.INITIAL}
                    isFormOpen={isIntialFormOpen}
                    setIsFormOpen={setIsInitialFormOpen}
                    isLoading={isLoading}
                  />
                  <ManageRecipientGroups
                    group={OrganizationRecipientDTOGroupEnum.ADMIN}
                    isFormOpen={isAdminFormOpen}
                    setIsFormOpen={setIsAdminFormOpen}
                    isLoading={isLoading}
                  />
                </>
              )}
            </ContentContainer>
          </Col>
        </Row>
      </Container>
    </InnerWrapper>
  );
};

export default NotificationsView;
