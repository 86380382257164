import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClaimDocumentsApi, ClaimItemDocumentDTO } from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { FlashState } from '../../components/FlashMessage/index';
import { standardSyncEntitiesAndGetResults } from '../entities/entities.sagas';
import { DocumentEntity, ClaimDocumentEntity } from '../entities/entities.types';
import { setFlashMessage } from '../flash-messages/flash-messages.actions';
import { AppState } from '../root.reducer';
import SCHEMA from '../schema';
import { createStandardClaimDocumentsApi, runThunkWithAuth } from '../utils/api.utils';
import {
  createClaimDocumentSuccess,
  deleteClaimDocumentSuccess,
  DELETE_CLAIM_DOCUMENT_STORE_KEY,
  CREATE_CLAIM_DOCUMENT_STORE_KEY
} from './claim-document.actions';
import { CreateClaimDocumentPayload, DeleteClaimDocumentPayload } from './claim-document.types';
import { getClaimDocumentByDocumentId } from '../selectors/claim-document.selectors';
import { getErrorMessage } from '../../utils/common.utils';

export const createClaimDocumentThunk = createAsyncThunk<
  DocumentEntity,
  CreateClaimDocumentPayload,
  {
    state: AppState;
  }
>('claim-document/create', async (payload, thunkAPI) => {
  const dispatch = thunkAPI.dispatch;
  const state = thunkAPI.getState();
  try {
    const claimDocumentsApi: ClaimDocumentsApi = createStandardClaimDocumentsApi(state);

    const apiResponse: AxiosResponse<ClaimItemDocumentDTO> = await runThunkWithAuth(
      () => claimDocumentsApi.addClaimDocument(payload.claimId, payload.file, payload.file.name, payload.type),
      dispatch
    );

    standardSyncEntitiesAndGetResults(apiResponse.data, SCHEMA.claimDocument, dispatch);
    dispatch(createClaimDocumentSuccess({ claimId: payload.claimId, claimDocumentId: apiResponse.data.id }));
    return apiResponse.data.document;
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch(setFlashMessage({ key: CREATE_CLAIM_DOCUMENT_STORE_KEY, message: error, state: FlashState.ERROR }));
    throw e;
  }
});

export const deleteClaimDocumentThunk = createAsyncThunk<
  void,
  DeleteClaimDocumentPayload,
  {
    state: AppState;
  }
>('claim-document/delete', async (payload, thunkAPI) => {
  const state = thunkAPI.getState();
  const dispatch = thunkAPI.dispatch;

  try {
    const claimDocumentsApi: ClaimDocumentsApi = createStandardClaimDocumentsApi(state);
    const claimDocument = getClaimDocumentByDocumentId(state, payload.documentId) as ClaimDocumentEntity;

    await runThunkWithAuth(() => claimDocumentsApi.deleteClaimDocument(payload.claimId, payload.documentId), dispatch);
    dispatch(deleteClaimDocumentSuccess({ claimId: payload.claimId, claimDocumentId: claimDocument ? claimDocument.id : '' }));
    dispatch(
      setFlashMessage({
        key: DELETE_CLAIM_DOCUMENT_STORE_KEY,
        message: 'Success! You have deleted a claim document.',
        state: FlashState.SUCCESS
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch(setFlashMessage({ key: DELETE_CLAIM_DOCUMENT_STORE_KEY, message: error, state: FlashState.ERROR }));
    throw e;
  }
});
