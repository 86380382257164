import { OrganizationRecipientDTO } from '@reposit/api-client';
import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getBreakpoint } from '../../../base/style';
import Button from '../../../components/Button/index';
import ConfirmationModal from '../../../components/ConfirmationModal/index';
import { P2 } from '../../../components/Typography/index';
import { getCurrentOrganizationId } from '../../../redux/auth/auth.selectors';
import { deleteOrganizationRecipientRequested } from '../../../redux/organization-recipient/organization-recipient.actions';

const OrganizationRecipientItem = styled.div<{ isOpen?: boolean }>`
  background: ${props => props.theme.colors.bgAccent};
  margin: 0 0 6px;
  padding: 0 24px 0;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    padding: ${props => (props.isOpen ? `34px` : `0 34px`)};
  }

  &:hover {
    background: ${props => (!props.isOpen ? props.theme.colors.bgHoverAccent : props.theme.colors.bgAccent)};
  }
`;

const OrganizationRecipientItemInner = styled.li`
  border-radius: 8px;
  cursor: default;
  box-sizing: border-box;
  margin: 0 0 6px;
  padding: 12px 0;
  height: 96px;

  @media all and (min-width: ${getBreakpoint('md')}) {
    height: 112px;
  }

  @media all and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
    height: 48px;
    margin: 0 0 6px;
    padding: 0;
  }
`;

const OrganizationRecipient = styled.div`
  @media all and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
  }
`;

const Email = styled(P2)`
  margin: 0;
`;

const RemoveButton = styled(Button)`
  background: #ffe6e6;
  border-color: #ffe6e6;
  color: ${props => props.theme.colors.negative};

  &:hover {
    background: ${props => props.theme.colors.negative};
    border-color: ${props => props.theme.colors.negative};
    color: #ffe6e6;
  }
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  margin: 12px 0 0;
  @media all and (min-width: ${getBreakpoint('lg')}) {
    margin: 0 0 0 auto;
  }
`;

interface OrganizationRecipientProps {
  data: OrganizationRecipientDTO;
}

const OrganizationRecipientComponent: React.FC<OrganizationRecipientProps> = props => {
  const organizationId = useSelector(getCurrentOrganizationId);
  const [showingDeleteModal, setShowingDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const email = props.data.email;
  const recipientId = props.data.id;
  const handleDelete = () => {
    dispatch(deleteOrganizationRecipientRequested({ organizationId, recipientId }));
    setShowingDeleteModal(false);
  };
  const handleDismissDelete = () => {
    setShowingDeleteModal(false);
  };
  const deleteTitle = 'Remove this person from the list?';
  return (
    <Fragment>
      {showingDeleteModal && (
        <ConfirmationModal
          title={deleteTitle}
          text={`Once removed they will no longer get these emails`}
          onDismiss={handleDismissDelete}
          onSubmit={handleDelete}
        />
      )}
      <OrganizationRecipientItem>
        <OrganizationRecipientItemInner>
          <OrganizationRecipient>
            <Email>{email}</Email>
          </OrganizationRecipient>
          <Actions>
            <RemoveButton buttonType="tertiary" mini="true" onClick={() => setShowingDeleteModal(true)}>
              Remove
            </RemoveButton>
          </Actions>
        </OrganizationRecipientItemInner>
      </OrganizationRecipientItem>
    </Fragment>
  );
};

export default OrganizationRecipientComponent;
