const webURL = 'https://staging.dashboard.reposit.co.uk';
const clientId = 'MyUhL2eZmS92eUZBr4UlLMWCLBZ4Ozo0';

export const appConfig = {
  links: {
    auth0: {
      logout: `https://reposit-staging.eu.auth0.com/v2/logout?client_id=${clientId}`,
      login: `https://reposit-staging.eu.auth0.com/authorize?response_type=token&client_id=${clientId}&redirect_uri=${webURL}/authenticate&audience=https://staging.api.com`
    },
    local: {
      login: `${webURL}/login`
    }
  },
  api: {
    basePath: 'https://staging.api.reposit.co.uk'
  },
  analytics: {
    trackers: [
      {
        name: 'staging',
        id: 'UA-81451405-6'
      }
    ]
  },
  hubspot: {
    accountId: '9159844'
  },
  trustpilot: {
    dataTemplateId: '56278e9abfbbba0bdcd568bc',
    dataBusinessUnitId: '621e39301c43ae269a8f7332',
    link: 'https://uk.trustpilot.com/review/test.dashboard.reposit.co.uk'
  },
  inMaintenanceMode: false,
  hotjar: {
    enabled: false,
    siteId: 0,
    hotjarVersion: 6
  }
};
