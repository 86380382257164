export enum ActiveFormOptions {
  PROPERTY = 'PROPERTY',
  TENANCY_DETAILS = 'TENANCY_DETAILS',
  TENANTS = 'TENANTS',
  REPOSIT_CONFIRM = 'REPOSIT_CONFIRM',
  REPOSIT_CHECKS = 'REPOSIT_CHECKS'
}

export interface ActiveFormsState {
  activeForms: ActiveFormOptions[];
}
