import { CreateOrganizationBankAccountDTOAccountTypeEnum, OrganizationBankAccountDTOAccountTypeEnum } from '@reposit/api-client';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import CheckListIcon from '../../../assets/svg/onboarding/checklist-purple.svg';
import Button from '../../../components/Button/index';
import FlashMessage from '../../../components/FlashMessage/index';
import Actions from '../../../components/Onboarding/Actions/index';
import Title from '../../../components/Onboarding/Title/index';
import { useIsOrganizationLandlord } from '../../../hooks/useIsLandlord';
import {
  updateBankAccountsConfirmedRequested,
  UPDATE_BANK_DETAILS_CONFIRMED_STORE_KEY
} from '../../../redux/account/account.actions';
import {
  createBankAccountRequested,
  getBankAccountsRequested,
  setClaimAccountShowing,
  DELETE_BANK_ACCOUNT_STORE_KEY,
  CREATE_BANK_ACCOUNT_STORE_KEY,
  GET_BANK_ACCOUNTS_STORE_KEY
} from '../../../redux/bank-account/bank-account.actions';
import { getBankAccounts, getIsClaimAccountShowing } from '../../../redux/bank-account/bank-account.selectors';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import { BankAccountFormValues } from '../../AccountSettings/BankAccounts/index';
import { FLASH_MESSAGE_TIMEOUT, useFlashMessage } from '../../FlashMessage/index';
import { FullPageLoader } from '../../../components/Loading/index';
import IndividualBankAccount from '../../AccountSettings/BankAccounts/IndividualBankAccount';

const Action = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 0 0 36px 15px;
`;

const BankAccounts: React.SFC<any> = () => {
  const dispatch = useDispatch();

  const [canContinue, setCanContinue] = useState<boolean>(false);
  const showClaimAccount = useSelector(getIsClaimAccountShowing);
  const [flashMessagePayload, onDismissFlashMessage] = useFlashMessage([UPDATE_BANK_DETAILS_CONFIRMED_STORE_KEY]);
  const isLoadingUpdateBankDetails = useSelector(createLoadingSelector([UPDATE_BANK_DETAILS_CONFIRMED_STORE_KEY]));

  const onSubmit = () => dispatch(updateBankAccountsConfirmedRequested());
  const addBankAccount = (values: BankAccountFormValues, accountType: OrganizationBankAccountDTOAccountTypeEnum) => {
    const parsedAccountType =
      accountType === OrganizationBankAccountDTOAccountTypeEnum.DEFAULT
        ? CreateOrganizationBankAccountDTOAccountTypeEnum.DEFAULT
        : CreateOrganizationBankAccountDTOAccountTypeEnum.CLAIM;
    dispatch(createBankAccountRequested({ ...values, accountType: parsedAccountType }));
  };

  const bankAccounts = useSelector(getBankAccounts);
  const hasPrimaryAccount = bankAccounts.some(ba => ba && ba.accountType === OrganizationBankAccountDTOAccountTypeEnum.DEFAULT);
  const isOrganizationLandlord = useIsOrganizationLandlord();

  const loadingSelector = createLoadingSelector([
    DELETE_BANK_ACCOUNT_STORE_KEY,
    CREATE_BANK_ACCOUNT_STORE_KEY,
    GET_BANK_ACCOUNTS_STORE_KEY
  ]);
  const isLoadingBankAccountForm = useSelector(loadingSelector);

  useEffect(() => {
    const hasClaimBankAccount = bankAccounts.some(ba => ba && ba.accountType === OrganizationBankAccountDTOAccountTypeEnum.CLAIM);
    if (hasClaimBankAccount && !showClaimAccount) {
      dispatch(setClaimAccountShowing(true));
    }
  }, [bankAccounts, dispatch, showClaimAccount]);

  useEffect(() => {
    dispatch(getBankAccountsRequested());
  }, [dispatch]);

  useEffect(() => {
    if (hasPrimaryAccount) {
      setCanContinue(true);
    } else {
      setCanContinue(false);
    }
  }, [hasPrimaryAccount]);

  const cardTitle = isOrganizationLandlord
    ? 'Please enter the details of the bank account you would like your claims to be paid into. You must add a bank account before you can continue.'
    : 'Please enter the details of the bank account you would like your monthly commission and claims to be paid into. If you require your claims to be paid into a different bank account to your commission, add it separately below.';

  return (
    <>
      <Title icon={CheckListIcon} caption={cardTitle}>
        Enter bank account details
      </Title>
      {isLoadingBankAccountForm ? (
        <FullPageLoader />
      ) : (
        <>
          <IndividualBankAccount
            title="Primary Bank Account"
            accountType={OrganizationBankAccountDTOAccountTypeEnum.DEFAULT}
            addBankAccount={addBankAccount}
            bankAccounts={bankAccounts}
            copy=""
          />
          {!isOrganizationLandlord ? (
            showClaimAccount ? (
              <IndividualBankAccount
                title="Claim Bank Account (Optional)"
                accountType={OrganizationBankAccountDTOAccountTypeEnum.CLAIM}
                addBankAccount={addBankAccount}
                bankAccounts={bankAccounts}
                copy=""
              />
            ) : (
              <Row>
                <Col lg={12} style={{ padding: 0 }}>
                  <Action>
                    <Button buttonType="secondary" onClick={() => dispatch(setClaimAccountShowing(true))}>
                      Add claim bank account
                    </Button>
                  </Action>
                </Col>
              </Row>
            )
          ) : null}

          {flashMessagePayload && (
            <FlashMessage onDismiss={onDismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMEOUT} payload={flashMessagePayload} />
          )}
          <Actions>
            <Button disabled={!canContinue || isLoadingUpdateBankDetails} onClick={onSubmit}>
              Continue
            </Button>
          </Actions>
        </>
      )}
    </>
  );
};

export default BankAccounts;
