import React, { useEffect } from 'react';
import moment from 'moment';
import { get } from 'lodash';

import TenancyDetails, { TenancyDetailsFormValues, TenancyTypes } from '../../../../components/TenancyDetails/index';
import { updateTenancyRequested, UPDATE_TENANCY_STORE_KEY } from '../../../../redux/reposit/reposit.actions';
import { useSelector, useDispatch } from 'react-redux';
import { createFlashMessagesSelector } from '../../../../redux/flash-messages/flash-messages.selector';
import { dismissFlashMessage } from '../../../../redux/flash-messages/flash-messages.actions';
import { TenancyOrderWithActions } from '../../../../redux/selectors/index';
import { DisableComponent } from '../../../../components/Common/index';
import ReactTooltip from 'react-tooltip';

interface TenancyContainerProps {
  disabled: boolean;
  isEditable: boolean;
  editMode: boolean;
  tenancyOrder?: TenancyOrderWithActions;
}

const TenancyContainer = ({ isEditable, editMode, tenancyOrder, disabled }: TenancyContainerProps) => {
  const getTenancyFlashMessageSelector = createFlashMessagesSelector([UPDATE_TENANCY_STORE_KEY]);

  const dispatch = useDispatch();
  const tenancy = get(tenancyOrder, 'tenancy');
  const tenancyId = get(tenancyOrder, 'tenancy.id');

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const tenancyDetailsFlashMessage = useSelector(getTenancyFlashMessageSelector);

  const dismissTenancyDetailsFlashMessage = () => dispatch(dismissFlashMessage([UPDATE_TENANCY_STORE_KEY]));

  const onTenancySubmit = (fields: TenancyDetailsFormValues) => {
    const params = {
      PPM: fields.PPM as number,
      letOnly: fields.tenancyType === TenancyTypes.LET_ONLY,
      endDate: moment(fields.tenancyEnd).format('YYYY-MM-DD'),
      startDate: moment(fields.tenancyStart).format('YYYY-MM-DD'),
      isNewTenancy: fields.isNewTenancy === 'YES' ? true : false
    };
    const updateTenancyRequestAction = updateTenancyRequested({
      tenancyId,
      updates: params
    });
    dispatch(updateTenancyRequestAction);
  };
  return (
    <DisableComponent disabled={disabled}>
      <TenancyDetails
        isEditable={isEditable}
        editMode={editMode}
        onSubmit={onTenancySubmit}
        tenancy={tenancy}
        flashMessage={tenancyDetailsFlashMessage}
        onDismissFlashMessage={dismissTenancyDetailsFlashMessage}
      />
    </DisableComponent>
  );
};

export default TenancyContainer;
