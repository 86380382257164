import { createAsyncThunk } from '@reduxjs/toolkit';
import { ClaimItemDocumentDTO, ClaimItemDocumentsApi } from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { FlashState } from '../../components/FlashMessage/index';
import { getErrorMessage } from '../../utils/common.utils';
import { standardSyncEntitiesAndGetResults } from '../entities/entities.sagas';
import { ClaimItemEntity, DocumentEntity } from '../entities/entities.types';
import { setFlashMessage } from '../flash-messages/flash-messages.actions';
import { AppState } from '../root.reducer';
import SCHEMA from '../schema';
import { getClaimItemDocumentByDocumentId } from '../selectors/claim-item-document.selectors';
import { getClaimItemById } from '../selectors/claim-item.selectors';
import {
  getClaimItemProposalByClaimItemIdAndProposalId,
  getLatestAgentProposalIdByClaimItemId
} from '../selectors/mediation.selectors';
import { createStandardClaimItemDocumentsApi, runThunkWithAuth } from '../utils/api.utils';
import {
  createClaimItemDocumentSuccess,
  CREATE_CLAIM_ITEM_DOCUMENT_STORE_KEY,
  deleteClaimItemDocumentSuccess,
  DELETE_CLAIM_ITEM_DOCUMENT_STORE_KEY
} from './claim-item-document.actions';

interface CreateClaimItemDocumentThunkPayload {
  claimItemId: string;
  file: File;
  claimItemProposalId: string;
}

export const createClaimItemDocumentThunk = createAsyncThunk<
  DocumentEntity,
  CreateClaimItemDocumentThunkPayload,
  {
    state: AppState;
  }
>('claim-item-document/create', async (payload, thunkAPI) => {
  const state = thunkAPI.getState();
  const dispatch = thunkAPI.dispatch;
  try {
    const claimItemDocumentsApi: ClaimItemDocumentsApi = createStandardClaimItemDocumentsApi(state);

    const claimItem = getClaimItemById(state, payload.claimItemId) as ClaimItemEntity;

    const apiResponse: AxiosResponse<ClaimItemDocumentDTO> = await runThunkWithAuth(
      () =>
        claimItemDocumentsApi.addClaimItemDocument(
          claimItem.claimId,
          payload.claimItemId,
          payload.file,
          payload.file.name,
          'CLAIM_ITEM',
          payload.claimItemProposalId
        ),
      dispatch
    );
    standardSyncEntitiesAndGetResults(apiResponse.data, SCHEMA.claimItemDocument, dispatch);
    dispatch(
      createClaimItemDocumentSuccess({
        claimItemId: payload.claimItemId,
        claimItemDocumentId: apiResponse.data.id,
        claimItemProposalId: apiResponse.data.claimItemProposalId
      })
    );
    return apiResponse.data.document;
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch(setFlashMessage({ key: CREATE_CLAIM_ITEM_DOCUMENT_STORE_KEY, message: error, state: FlashState.ERROR }));
    throw e;
  }
});

export const deleteClaimItemDocumentThunk = createAsyncThunk<
  void,
  string,
  {
    state: AppState;
  }
>('claim-item-document/delete', async (payload, thunkAPI) => {
  const state = thunkAPI.getState();
  const dispatch = thunkAPI.dispatch;

  try {
    const claimItemDocumentsApi: ClaimItemDocumentsApi = createStandardClaimItemDocumentsApi(state);

    const claimItemDocument = getClaimItemDocumentByDocumentId(payload)(state);
    if (claimItemDocument) {
      const claimItem = getClaimItemById(state, claimItemDocument.claimItemId);
      if (claimItem) {
        const proposalId = getLatestAgentProposalIdByClaimItemId(state, claimItem.id);
        if (proposalId) {
          const claimItemProposal = getClaimItemProposalByClaimItemIdAndProposalId(state, claimItem.id, proposalId);
          if (claimItemProposal) {
            await runThunkWithAuth(
              () => claimItemDocumentsApi.deleteClaimItemDocument(claimItem.id, claimItem.claimId, claimItemDocument.document),
              dispatch
            );

            thunkAPI.dispatch(
              deleteClaimItemDocumentSuccess({
                claimItemDocumentId: claimItemDocument.id,
                claimItemId: claimItem.id,
                claimItemProposalId: claimItemProposal.proposal.id
              })
            );
            thunkAPI.dispatch(
              setFlashMessage({
                key: DELETE_CLAIM_ITEM_DOCUMENT_STORE_KEY,
                message: 'Success! You deleted the claim item document.',
                state: FlashState.SUCCESS
              })
            );
            return;
          }
        }
      }
    }
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch(setFlashMessage({ key: DELETE_CLAIM_ITEM_DOCUMENT_STORE_KEY, message: error, state: FlashState.ERROR }));
    throw e;
  }
});
