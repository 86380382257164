import { unwrapResult } from '@reduxjs/toolkit';
import { ClaimProposalDTOStatusEnum, CreateClaimItemProposalDTO } from '@reposit/api-client';
import { get } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { DisableComponent } from '../../../components/Common/index';
import ClaimItem, {
  ClaimItem as IClaimItem,
  ClaimItemComponentStatus
} from '../../../components/Library/SupplierOffer/ClaimItem/ClaimItem';
import { useAppDispatch } from '../../../index';
import { createClaimItemProposalThunk, updateClaimItemProposalThunk } from '../../../redux/claim/claim.thunk';
import { ClaimItemProposalEntity } from '../../../redux/entities/entities.types';
import { AppState } from '../../../redux/root.reducer';
import {
  getAllAgentDocumentsAndProposalsForClaimItem,
  getIsAgentDeciding,
  getIsAgentNegotiating,
  getSecondAgentProposal
} from '../../../redux/selectors/mediation.selectors';

interface BaseMediationClaimItemsProps {
  claimItems: IClaimItem[];
  componentStatus: ClaimItemComponentStatus;
  claimId: string;
  hasGoneBack: boolean;
}

interface MediationClaimItemProps extends Omit<BaseMediationClaimItemsProps, 'claimItems' | 'claimId'> {
  createCounterProposal: (payload: CreateClaimItemProposalDTO) => Promise<ClaimItemProposalEntity>;
  updateCounterProposal: (payload: CreateClaimItemProposalDTO) => Promise<ClaimItemProposalEntity>;
  claimItem: IClaimItem;
  disabled: boolean;
  setOpenedClaimItem: (state: string | undefined) => void;
}

const MediationClaimItem: React.FC<MediationClaimItemProps> = ({
  claimItem,
  componentStatus,
  createCounterProposal,
  updateCounterProposal,
  disabled,
  setOpenedClaimItem,
  hasGoneBack
}) => {
  const isAgentDeciding = useSelector((state: AppState) => getIsAgentDeciding(state, claimItem.claimId));
  const isAgentNegotiating = useSelector((state: AppState) => getIsAgentNegotiating(state, claimItem.claimId));

  const canRespond =
    (isAgentDeciding || isAgentNegotiating) && !claimItem.isSettled && componentStatus !== ClaimItemComponentStatus.UPLOAD_ONLY;

  const allSupplierDocumentsAndProposals = useSelector((state: AppState) =>
    getAllAgentDocumentsAndProposalsForClaimItem(state, claimItem.id)
  );
  const allSupplierDocuments = allSupplierDocumentsAndProposals && [
    ...allSupplierDocumentsAndProposals.firstDocuments,
    ...allSupplierDocumentsAndProposals.secondDocuments
  ];

  const viewOnly = componentStatus === ClaimItemComponentStatus.VIEW_ONLY;

  return (
    <DisableComponent disabled={viewOnly ? false : disabled}>
      <ClaimItem
        claimItem={claimItem}
        canRespond={canRespond}
        componentStatus={componentStatus}
        allSupplierDocuments={allSupplierDocuments}
        createCounterProposal={createCounterProposal}
        updateCounterProposal={updateCounterProposal}
        setOpenedClaimItem={setOpenedClaimItem}
        hasGoneBack={hasGoneBack}
      />
    </DisableComponent>
  );
};

export const MediationClaimItems: React.FC<BaseMediationClaimItemsProps> = ({
  componentStatus,
  claimItems,
  claimId,
  hasGoneBack
}) => {
  const dispatch = useAppDispatch();
  const secondAgentProposal = useSelector((state: AppState) => getSecondAgentProposal(state, claimId));
  const claimProposalId = get(secondAgentProposal, 'id', '');
  const [openedClaimItem, setOpenedClaimItem] = useState<string | undefined>(undefined);

  return (
    <>
      {claimItems.sort(sortBySettledLast).map(claimItem => {
        const draftAgentProposal = claimItem.itemProposals.find(
          ip => ip.claimProposal.round === 3 && ip.claimProposal.status === ClaimProposalDTOStatusEnum.DRAFT
        );
        const claimItemProposalId = get(draftAgentProposal, 'id', '');
        const createCounterProposal = (payload: CreateClaimItemProposalDTO) => {
          return dispatch(createClaimItemProposalThunk({ claimId: claimItem.claimId, claimProposalId, payload })).then(
            unwrapResult
          );
        };
        const updateCounterProposal = (payload: CreateClaimItemProposalDTO) => {
          return dispatch(
            updateClaimItemProposalThunk({ claimId: claimItem.claimId, claimProposalId, claimItemProposalId, payload })
          ).then(unwrapResult);
        };
        const disabled = !!openedClaimItem && openedClaimItem !== claimItem.id;
        return (
          <MediationClaimItem
            key={claimItem.id}
            componentStatus={componentStatus}
            createCounterProposal={createCounterProposal}
            updateCounterProposal={updateCounterProposal}
            claimItem={claimItem}
            disabled={disabled}
            setOpenedClaimItem={setOpenedClaimItem}
            hasGoneBack={hasGoneBack}
          />
        );
      })}
    </>
  );
};

const sortBySettledLast = (a: IClaimItem, b: IClaimItem): number => {
  if (!a.isSettled && b.isSettled) {
    return -1;
  } else {
    return 1;
  }
};
