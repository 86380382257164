import { ClaimItemDTO, ClaimItemsApi } from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { FlashState } from '../../components/FlashMessage/index';
import { getErrorMessage } from '../../utils/common.utils';
import { FlashMessagesActionTypes, setFlashMessage } from '../flash-messages/flash-messages.actions';
import { createClaimItemsApi, runSagaWithAuth } from '../utils/api.utils';
import {
  ClaimItemActionTypes,
  createClaimItemFailed,
  createClaimItemSuccess,
  CREATE_CLAIM_ITEM_API_REQUESTED,
  CREATE_CLAIM_ITEM_STORE_KEY,
  discardClaimItemFailed,
  discardClaimItemSuccess,
  DISCARD_CLAIM_ITEM_API_REQUESTED,
  DISCARD_CLAIM_ITEM_STORE_KEY,
  updateClaimItemFailed,
  updateClaimItemSuccess,
  UPDATE_CLAIM_ITEM_API_REQUESTED,
  UPDATE_CLAIM_ITEM_STORE_KEY
} from './claim-item.actions';
import { CreateClaimItemPayload, UpdateClaimItemPayload, DiscardClaimItemRequestedPayload } from './claim-item.types';
import { DELETE_CLAIM_STORE_KEY } from '../claim/claim.actions';
import { push } from 'connected-react-router';
import { AppState } from '../root.reducer';
import {
  getAgentLatestPropsoalItemByClaimItemId,
  getFirstAgentProposal,
  ClaimItemProposal
} from '../selectors/mediation.selectors';
import { get } from 'lodash';
import SCHEMA from '../schema';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';

export function* createClaimItem({ payload }: { type: string; payload: CreateClaimItemPayload }) {
  try {
    const claimItemsApi: ClaimItemsApi = yield createClaimItemsApi();
    const apiResponse: AxiosResponse<ClaimItemDTO> = yield call(
      runSagaWithAuth(() => claimItemsApi.createClaimItem(payload.claimId, payload.data))
    );
    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.claimItem);
    yield put<ClaimItemActionTypes>(createClaimItemSuccess(apiResponse.data));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: CREATE_CLAIM_ITEM_STORE_KEY,
        message: 'Success! You have added a claim item.',
        state: FlashState.SUCCESS
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: CREATE_CLAIM_ITEM_STORE_KEY, message: error, state: FlashState.ERROR })
    );
    yield put<ClaimItemActionTypes>(createClaimItemFailed(error));
  }
}

export function* updateClaimItem({ payload }: { type: string; payload: UpdateClaimItemPayload }) {
  try {
    const claimItemsApi: ClaimItemsApi = yield createClaimItemsApi();
    const apiResponse: AxiosResponse<ClaimItemDTO> = yield call(
      runSagaWithAuth(() => claimItemsApi.updateClaimItem(payload.claimId, payload.claimItemId, payload.data))
    );
    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.claimItem);
    yield put<ClaimItemActionTypes>(updateClaimItemSuccess(apiResponse.data));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_CLAIM_ITEM_STORE_KEY,
        message: 'Success! You have updated a claim item.',
        state: FlashState.SUCCESS
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: UPDATE_CLAIM_ITEM_STORE_KEY, message: error, state: FlashState.ERROR })
    );
    yield put<ClaimItemActionTypes>(updateClaimItemFailed(error));
  }
}

export function* discardClaimItem({ payload }: { type: string; payload: DiscardClaimItemRequestedPayload }) {
  try {
    const { claimId, claimItemId, deactivateClaim } = payload;
    const claimItemsApi: ClaimItemsApi = yield createClaimItemsApi();
    yield call(runSagaWithAuth(() => claimItemsApi.deleteClaimItem(claimId, claimItemId)));

    const itemProposal: ClaimItemProposal = yield select((state: AppState) =>
      getAgentLatestPropsoalItemByClaimItemId(state, claimId, claimItemId)
    );
    const claimItemProposalId = get(itemProposal, 'proposal.id', '');
    const claimProposal = yield select((state: AppState) => getFirstAgentProposal(state, claimId));
    const claimProposalId = get(claimProposal, 'id', '');

    yield put<ClaimItemActionTypes>(discardClaimItemSuccess({ claimId, claimItemId, claimItemProposalId, claimProposalId }));

    if (deactivateClaim) {
      yield put(push(`/claims`));
      yield put<FlashMessagesActionTypes>(
        setFlashMessage({
          key: DELETE_CLAIM_STORE_KEY,
          message: 'Success! Your claim was deactivated.',
          state: FlashState.SUCCESS
        })
      );
    } else {
      yield put<FlashMessagesActionTypes>(
        setFlashMessage({
          key: DISCARD_CLAIM_ITEM_STORE_KEY,
          message: 'Success! You have removed a claim item.',
          state: FlashState.SUCCESS
        })
      );
    }
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: DISCARD_CLAIM_ITEM_STORE_KEY, message: error, state: FlashState.ERROR })
    );
    yield put<ClaimItemActionTypes>(discardClaimItemFailed(error));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchClaimItemsSagas() {
  yield takeLatest(CREATE_CLAIM_ITEM_API_REQUESTED, createClaimItem);
  yield takeLatest(UPDATE_CLAIM_ITEM_API_REQUESTED, updateClaimItem);
  yield takeLatest(DISCARD_CLAIM_ITEM_API_REQUESTED, discardClaimItem);
}
