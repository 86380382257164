import { get } from 'lodash';

import { PropertiesState } from './property.types';
import {
  PropertiesActionTypes,
  SEARCH_ORGANIZATION_PROPERTIES_API_SUCCESS,
  SEARCH_ORGANIZATION_PROPERTIES_API_FAILED,
  CLEAR_SEARCHED_PROPERTIES
} from './property.actions';
import { RepositActionTypes, UPDATE_PROPERTY_API_SUCCESS } from '../reposit/reposit.actions';

const initialState: PropertiesState = {
  searchedProperties: [],
  hasSearchedProperties: false
};

export default (state = initialState, action: PropertiesActionTypes | RepositActionTypes): PropertiesState => {
  switch (action.type) {
    case SEARCH_ORGANIZATION_PROPERTIES_API_SUCCESS:
      return {
        ...state,
        searchedProperties: action.payload,
        hasSearchedProperties: true
      };
    case SEARCH_ORGANIZATION_PROPERTIES_API_FAILED:
      return {
        ...state,
        hasSearchedProperties: true
      };
    case UPDATE_PROPERTY_API_SUCCESS:
      const newPropertyIsNotInCurrentList = !state.searchedProperties.find(id => id === get(action.payload, 'property.id'));
      return action.payload.property && newPropertyIsNotInCurrentList
        ? {
            ...state,
            searchedProperties: [...state.searchedProperties, action.payload.property.id]
          }
        : state;
    case CLEAR_SEARCHED_PROPERTIES:
      return {
        ...state,
        searchedProperties: [],
        hasSearchedProperties: false
      };

    default:
      return state;
  }
};
