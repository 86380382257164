import React from 'react';
import { useSelector } from 'react-redux';
import makeGetRepositSummary from '../../../redux/selectors/reposit.selectors';
import RepositSummary from '../../../components/Reposit/RepositSummary';
import { AppState } from '../../../redux/root.reducer';

export interface RepostSummaryContainerProps {
  tenancyOrderId: string;
  disableFlashMessages?: boolean;
}

export default (props: RepostSummaryContainerProps) => {
  const { tenancyOrderId, disableFlashMessages } = props;

  const getRepositSummary = makeGetRepositSummary();
  const summary = useSelector((state: AppState) => getRepositSummary(state, props));
  // sumarry can be undefined due to the selector
  // maybe need to handle this - even though in theory it shouldn't happen
  // maybe it could be a not found component
  if (!summary) return null;
  return (
    <RepositSummary
      key={tenancyOrderId}
      data={summary}
      disableFlashMessages={disableFlashMessages}
      tenancyOrderId={tenancyOrderId}
    />
  );
};
