import { CreateAddressWithOrgDTO, ExternalAddressDTO, UpdateYourCompanyDTO } from '@reposit/api-client';
import { Formik, FormikProps } from 'formik';
import { get, omit } from 'lodash';
import React, { useCallback } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';
import CompanyIcon from '../../../assets/svg/onboarding/company-purple.svg';
import Button from '../../../components/Button/index';
import FieldWithLabel from '../../../components/FormFields/FieldWithLabel/index';
import { Input } from '../../../components/FormFields/index';
import Actions from '../../../components/Onboarding/Actions/index';
import Title from '../../../components/Onboarding/Title/index';
import RepositCard from '../../../components/Reposit/RepositCard/index';
import { P1 } from '../../../components/Typography/index';
import {
  setAccountCurrentAddressForm,
  updateYourCompanyRequested,
  UPDATE_YOUR_COMPANY_STORE_KEY
} from '../../../redux/account/account.actions';
import { getAccountCurrentAddressForm } from '../../../redux/account/account.selectors';
import {
  searchAddressesRequested,
  SEARCH_ADDRESSES_STORE_KEY,
  setIsShowingSearchModal
} from '../../../redux/address/address.actions';
import { getIsShowingSearchModal, getSearchedAddresses } from '../../../redux/address/address.selectors';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import { AddressForm } from '../../../redux/reposit/reposit.actions';
import { getOrganization } from '../../../redux/selectors/organization.selectors';
import AddressLookupYourCompany from './AddressLookupYourCompany/index';
import FlashMessage from '../../../components/FlashMessage';
import { useFlashMessage, FLASH_MESSAGE_TIMEOUT } from '../../FlashMessage/index';
import countries from 'i18n-iso-countries';
import { UK_POSTCODE } from '../../../utils/regex/address.regex';
import { getCurrentOrganizationId } from '../../../redux/auth/auth.selectors';

const Text = styled(P1)`
  margin: -12px 0 0;
  padding: 0 0 24px;
`;

const Schema = Yup.object().shape({
  name: Yup.string().required('Required'),
  averageLetsPerMonth: Yup.number()
    .typeError('Must be a number')
    .integer('Must be a whole number')
    .min(1, 'Must be greater than 0')
    .max(1000, 'Must be less than 1,000')
    .required('Required'),
  // not sure if we can do this address validation better?
  // or if we need to validate this at all tbh
  address: Yup.object()
    .shape(
      {
        roomNumber: Yup.string(),
        flatNumber: Yup.string(),
        buildingNumber: Yup.string().when('buildingName', {
          is: buildingName => !buildingName,
          then: Yup.string().required('Either a building number or name is required'),
          otherwise: Yup.string()
        }),
        buildingName: Yup.string().when('buildingNumber', {
          is: buildingNumber => {
            return !buildingNumber;
          },
          then: Yup.string().required('Either a building number or name is required'),
          otherwise: Yup.string()
        }),
        street: Yup.string().required('Required'),
        postcode: Yup.string()
          .matches(UK_POSTCODE, 'Must be a UK postcode')
          .required('Required'),
        country: Yup.string().required('Required'),
        subStreet: Yup.string(),
        town: Yup.string(),
        county: Yup.string()
      },
      [['buildingNumber', 'buildingName']]
    )
    .required('Required')
});

export interface YourCompanyFormValues {
  name: string;
  averageLetsPerMonth: string;
  // again this shouldn't be optional
  address: Omit<ExternalAddressDTO, 'externalId'>;
}

export const baseEmptyAddress = { buildingNumber: '', street: '', postcode: '', country: '' };

const YourCompany: React.SFC<any> = () => {
  const dispatch = useDispatch();
  const handleSetCurrentAddressForm = useCallback(
    (value: AddressForm) => {
      dispatch(setAccountCurrentAddressForm(value));
    },
    [dispatch]
  );
  const setShowingSearchModal = useCallback(
    (value: boolean) => {
      dispatch(setIsShowingSearchModal(value));
    },
    [dispatch]
  );
  const searchAddresses = useCallback((postcode: string) => dispatch(searchAddressesRequested(postcode)), [dispatch]);

  const [flashMessagePayload, onDismissFlashMessage] = useFlashMessage([SEARCH_ADDRESSES_STORE_KEY]);
  const [yourCompanyFlashMessagePayload, onDismissYourCompanyFlashMessage] = useFlashMessage([UPDATE_YOUR_COMPANY_STORE_KEY]);

  const searchAddressessSelector = createLoadingSelector([SEARCH_ADDRESSES_STORE_KEY]);

  const currentOrgId = useSelector(getCurrentOrganizationId);
  const currentOrganization = useSelector(getOrganization(currentOrgId));
  const currentAccountAddressForm = useSelector(getAccountCurrentAddressForm);
  const isShowingSearchModal = useSelector(getIsShowingSearchModal);
  const isSearchAddressesLoading = useSelector(searchAddressessSelector);
  const searchedAddresses = useSelector(getSearchedAddresses);
  const isLoading = useSelector(createLoadingSelector([UPDATE_YOUR_COMPANY_STORE_KEY]));

  const onSubmit = useCallback(
    (values: YourCompanyFormValues) => {
      const flatNumber = get(values.address, 'flatNumber');
      const roomNumber = get(values.address, 'roomNumber');
      const modValues: UpdateYourCompanyDTO = {
        ...values,
        averageLetsPerMonth: parseInt(values.averageLetsPerMonth),
        address: {
          ...(omit(values.address, ['externalId']) as CreateAddressWithOrgDTO),
          buildingNumber: `${values.address.buildingNumber}`,
          flatNumber: flatNumber ? `${flatNumber}` : undefined,
          roomNumber: roomNumber ? `${roomNumber}` : undefined,
          organizationId: currentOrgId,
          country: countries.toAlpha3(values.address.country)
        }
      };
      dispatch(updateYourCompanyRequested(modValues));
    },
    [currentOrgId, dispatch]
  );

  const initialValues = {
    name: get(currentOrganization, 'name', ''),
    averageLetsPerMonth: get(currentOrganization, 'attributes.averageLetsPerMonth', ''),
    address: get(currentOrganization, 'attributes.address', baseEmptyAddress)
  };

  return (
    <Formik onSubmit={onSubmit} validationSchema={Schema} initialValues={initialValues}>
      {({
        values,
        handleChange,
        handleBlur,
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        handleSubmit,
        setValues
      }: FormikProps<YourCompanyFormValues>) => {
        const setAddress = (address?: ExternalAddressDTO) => {
          setValues({
            name: values.name,
            averageLetsPerMonth: values.averageLetsPerMonth,
            address: address || baseEmptyAddress
          });
        };
        return (
          <form onSubmit={handleSubmit}>
            <Title icon={CompanyIcon}>Tell us a bit about company</Title>

            <RepositCard title="What is the name of your company?">
              <Container fluid>
                <Row>
                  <Col sm={12} style={{ padding: 0 }}>
                    <FieldWithLabel label="Company Name" error={errors.name} touched={touched.name}>
                      <Input
                        value={values.name}
                        name="name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="e.g. Happy Lets LTD"
                        error={errors.name}
                        touched={touched.name}
                      />
                    </FieldWithLabel>
                  </Col>
                </Row>
              </Container>
            </RepositCard>

            <RepositCard title="How many lets does your agency usually complete per month?">
              <Container fluid>
                <Row>
                  <Col sm={12} style={{ padding: 0 }}>
                    <FieldWithLabel
                      label="Lets completed per month"
                      error={errors.averageLetsPerMonth}
                      touched={touched.averageLetsPerMonth}
                    >
                      <Input
                        name="averageLetsPerMonth"
                        value={`${values.averageLetsPerMonth}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="e.g. 5"
                        error={errors.averageLetsPerMonth}
                        touched={touched.averageLetsPerMonth}
                      />
                    </FieldWithLabel>
                  </Col>
                </Row>
              </Container>
            </RepositCard>
            <RepositCard
              title="What is your registered company address?"
              tooltip="We need this to generate invoices for your referral payments"
              flashMessage={
                flashMessagePayload ? (
                  <FlashMessage
                    onDismiss={onDismissFlashMessage}
                    timeRemaining={FLASH_MESSAGE_TIMEOUT}
                    payload={flashMessagePayload}
                  />
                ) : (
                  undefined
                )
              }
            >
              <Container fluid>
                <Row>
                  <Col sm={12} style={{ padding: 0 }}>
                    <Text>This is the address you’re registered with on Companies House</Text>
                    {/* This address lookup component needs to be looked at */}
                    {/* lots of repetition with the address component in property container here */}
                    {/* maybe this should be a container? */}
                    {/* but then it would always share the same store and data */}
                    <AddressLookupYourCompany
                      onCancel={() => null}
                      currentOrgId={currentOrgId}
                      searchedAddresses={searchedAddresses}
                      searchAddresses={searchAddresses}
                      isShowingSearchModal={isShowingSearchModal}
                      setShowingSearchModal={setShowingSearchModal}
                      currentAddressForm={currentAccountAddressForm}
                      setCurrentAddressForm={handleSetCurrentAddressForm}
                      isSearchAddressesLoading={isSearchAddressesLoading}
                      createManualAddress={() => {}}
                      showSaveFormAction={false}
                      showCancelAddingAddressFromPostcode={false}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      values={values}
                      touched={touched}
                      errors={errors}
                      setAddress={setAddress}
                      setFieldValue={setFieldValue}
                    />
                  </Col>
                </Row>
              </Container>
            </RepositCard>

            {yourCompanyFlashMessagePayload && (
              <FlashMessage
                onDismiss={onDismissYourCompanyFlashMessage}
                timeRemaining={FLASH_MESSAGE_TIMEOUT}
                payload={yourCompanyFlashMessagePayload}
              />
            )}
            <Actions>
              <Button disabled={isLoading} type="submit">
                Continue
              </Button>
            </Actions>
          </form>
        );
      }}
    </Formik>
  );
};

export default YourCompany;
