import { Formik, FormikProps } from 'formik';
import { get } from 'lodash';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';
import YouIcon from '../../../assets/svg/onboarding/you-purple.svg';
import { getBreakpoint } from '../../../base/style';
import Button from '../../../components/Button/index';
import FlashMessage from '../../../components/FlashMessage/index';
import FieldWithLabel from '../../../components/FormFields/FieldWithLabel/index';
import { Input } from '../../../components/FormFields/index';
import Actions from '../../../components/Onboarding/Actions/index';
import Title from '../../../components/Onboarding/Title/index';
import RepositCard from '../../../components/Reposit/RepositCard/index';
import { P1, P2 } from '../../../components/Typography/index';
import { useIsOrganizationLandlord } from '../../../hooks/useIsLandlord';
import { updateAboutMeRequested, UPDATE_ABOUT_ME_STORE_KEY } from '../../../redux/account/account.actions';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import { getCurrentUser } from '../../../redux/selectors/user.selectors';
import { phoneNumberStringMatcher } from '../../../utils/regex/phoneNumber.regex';
import { useFlashMessage } from '../../FlashMessage/index';

const LeftField = styled.div`
  padding-bottom: 16px;

  @media screen and (min-width: ${getBreakpoint('md')}) {
    max-width: 94%;
    padding-bottom: 0;
  }
`;

const RightField = styled.div`
  float: right;
  width: 100%;

  @media screen and (min-width: ${getBreakpoint('md')}) {
    max-width: 94%;
  }
`;

const Text = styled(P1)`
  margin: -12px 0 0;
  padding: 0 0 24px;
`;

const Schema = Yup.object().shape({
  firstName: Yup.string()
    .required('Required')
    .nullable(),
  lastName: Yup.string()
    .required('Required')
    .nullable(),
  mobileNumber: Yup.string()
    .min(11, 'Incorrectly formatted phone number')
    .max(15, 'Incorrectly formatted phone number')
    .matches(phoneNumberStringMatcher, 'Incorrectly formatted phone number')
    .required('Required'),
  officePhoneNumber: Yup.string()
    .nullable()
    .matches(phoneNumberStringMatcher, 'Incorrectly formatted phone number'),
  jobTitle: Yup.string()
});

export interface AboutYouFormValues {
  firstName: string;
  lastName: string;
  mobileNumber: string;
  officePhoneNumber?: string;
  jobTitle?: string;
}

const AboutYou: React.SFC<any> = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(createLoadingSelector([UPDATE_ABOUT_ME_STORE_KEY]));
  const currentUser = useSelector(getCurrentUser);
  const [flashMessage, onDismissFlashMessage] = useFlashMessage([UPDATE_ABOUT_ME_STORE_KEY]);
  const onSubmit = (values: AboutYouFormValues) => {
    dispatch(
      updateAboutMeRequested({ ...values, officePhoneNumber: values.officePhoneNumber ? values.officePhoneNumber : undefined })
    );
  };
  const firstName = get(currentUser, 'firstName', '');
  const lastName = get(currentUser, 'lastName', '');
  const mobileNumber = get(currentUser, 'attributes.mobileNumber', '');
  // undefined is the default behavior - but i wanted to add it in to be clear
  const officePhoneNumber = get(currentUser, 'attributes.officePhoneNumber', undefined);
  const jobTitle = get(currentUser, 'attributes.jobTitle', '');
  const isOrgLandlord = useIsOrganizationLandlord();

  return (
    <Formik
      initialValues={{
        firstName,
        lastName,
        mobileNumber,
        officePhoneNumber,
        jobTitle
      }}
      validationSchema={Schema}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit }: FormikProps<AboutYouFormValues>) => {
        return (
          <form onSubmit={handleSubmit}>
            <Title icon={YouIcon}>Tell us a bit about yourself</Title>
            <RepositCard title="What's your name?">
              <Container fluid>
                <Row>
                  <Col md={6} style={{ padding: 0 }}>
                    <LeftField>
                      <FieldWithLabel label="First Name" error={errors.firstName} touched={touched.firstName}>
                        <Input
                          value={values.firstName}
                          name="firstName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="e.g. John"
                          error={errors.firstName}
                          touched={touched.firstName}
                        />
                      </FieldWithLabel>
                    </LeftField>
                  </Col>
                  <Col md={6} style={{ padding: 0 }}>
                    <RightField>
                      <FieldWithLabel label="Last Name" error={errors.lastName} touched={touched.lastName}>
                        <Input
                          value={values.lastName}
                          name="lastName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Smith"
                          error={errors.lastName}
                          touched={touched.lastName}
                        />
                      </FieldWithLabel>
                    </RightField>
                  </Col>
                </Row>
              </Container>
            </RepositCard>

            <RepositCard
              title="What’s your contact number?"
              tooltip="To ensure a smooth service, we'll occasionally need to contact you about issues related to your tenancies that require your immediate attention"
              flashMessage={
                flashMessage ? (
                  <FlashMessage onDismiss={onDismissFlashMessage} timeRemaining={5000} payload={flashMessage} />
                ) : (
                  undefined
                )
              }
            >
              <Text>Don’t worry, we'll only contact you if something urgent comes up</Text>
              <Container fluid>
                <Row>
                  <Col md={6} style={{ padding: 0 }}>
                    <LeftField>
                      <FieldWithLabel label="Mobile Number" error={errors.mobileNumber} touched={touched.mobileNumber}>
                        <Input
                          value={values.mobileNumber}
                          name="mobileNumber"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="e.g. 07000 000 000"
                          error={errors.mobileNumber}
                          touched={touched.mobileNumber}
                        />
                      </FieldWithLabel>
                    </LeftField>
                  </Col>
                  <Col md={6} style={{ padding: 0 }}>
                    <RightField>
                      <FieldWithLabel
                        label="Office Phone Number (optional)"
                        error={errors.officePhoneNumber}
                        touched={touched.officePhoneNumber}
                      >
                        <Input
                          value={values.officePhoneNumber}
                          name="officePhoneNumber"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="e.g. 020 000 0001"
                          error={errors.officePhoneNumber}
                          touched={touched.officePhoneNumber}
                        />
                      </FieldWithLabel>
                    </RightField>
                  </Col>
                </Row>
              </Container>
            </RepositCard>
            {!isOrgLandlord && (
              <RepositCard title="What's your job role?" subtitle="Optional">
                <Container fluid>
                  <Row>
                    <Col sm={12} style={{ padding: 0 }}>
                      <FieldWithLabel label="Your Job Title" error={errors.jobTitle} touched={touched.jobTitle}>
                        <Input
                          value={values.jobTitle}
                          name="jobTitle"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="e.g. Lettings Director"
                          error={errors.jobTitle}
                          touched={touched.jobTitle}
                        />
                      </FieldWithLabel>
                    </Col>
                  </Row>
                </Container>
              </RepositCard>
            )}

            <Actions>
              <Button disabled={isLoading}>Continue</Button>

              <P2 style={{ margin: '0 20px 0 0' }}>You need to complete the information above to continue</P2>
            </Actions>
          </form>
        );
      }}
    </Formik>
  );
};

export default AboutYou;
