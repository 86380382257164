import React, { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Button from '../../../components/Button/index';
import CardMessage from '../../../components/CardMessage/index';
import { createClaimRequested } from '../../../redux/claim/claim.actions';
import styled from 'styled-components';
import ConfirmationModal from '../../../components/ConfirmationModal/index';
import { useAppDispatch } from '../../../index';
import { updateClosingRemindersEnabledThunk } from '../../../redux/reposit/reposit.thunk';

const BoldText = styled.span`
  font-weight: bold;
`;

interface ClaimFlashMessageCreateProps {
  tenancyId: string;
  daysRemaining: number;
  closingRemindersEnabled: boolean;
}

const renderDaysRemainingText = (daysRemaining: number): string => {
  if (daysRemaining === 1) {
    return `${daysRemaining} day`;
  } else {
    return `${daysRemaining} days`;
  }
};

export const ClaimFlashMessageCreate: React.FC<ClaimFlashMessageCreateProps> = ({
  tenancyId,
  daysRemaining,
  closingRemindersEnabled
}) => {
  const [showingModal, setShowingModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useAppDispatch();
  const createClaim = () => {
    dispatch(createClaimRequested({ tenancyId }));
  };
  const noChargesToAdd = () => {
    setShowingModal(true);
  };
  const updateClosingRemindersEnabled = async () => {
    setIsSubmitting(true);
    return dispatch(updateClosingRemindersEnabledThunk({ tenancyId, closingRemindersEnabled: false }))
      .then(() => setShowingModal(false))
      .finally(() => setIsSubmitting(false));
  };
  return (
    <>
      {showingModal && (
        <ConfirmationModal
          title={'No charges to add?'}
          text={`We will reduce the number of reminders you receive. However, we will still send you a notification as the deadline approaches to remind you that you have indicated there are no charges to raise.`}
          onDismiss={() => setShowingModal(false)}
          onSubmit={updateClosingRemindersEnabled}
          isSubmitting={isSubmitting}
        />
      )}
      <CardMessage
        subtitle={
          <Fragment>
            <BoldText>{closingRemindersEnabled ? 'Want to raise charges' : 'Changed your mind'}?</BoldText> You have{' '}
            <BoldText>{renderDaysRemainingText(daysRemaining)}</BoldText> left to add charges
          </Fragment>
        }
        cta={
          <>
            <Button buttonType="white" onClick={createClaim} style={{ marginRight: 10 }}>
              Add Charges
            </Button>
            {closingRemindersEnabled ? (
              <Button buttonType="white" onClick={noChargesToAdd}>
                No Charges To Add
              </Button>
            ) : null}
          </>
        }
        type="primary"
      />
    </>
  );
};
interface ClaimFlashMessagePublishProps {
  claimId: string;
  daysRemaining: number;
}

export const ClaimFlashMessagePublish: React.FC<ClaimFlashMessagePublishProps> = ({ claimId, daysRemaining }) => {
  return (
    <CardMessage
      title={'Submit your charges'}
      subtitle={
        <Fragment>
          You have <BoldText>{renderDaysRemainingText(daysRemaining)}</BoldText> left to publish your end of tenancy charges
        </Fragment>
      }
      cta={
        <NavLink to={`/claims/${claimId}`} style={{ textDecoration: 'none' }}>
          <Button buttonType="white">View charges</Button>
        </NavLink>
      }
      type="primary"
    />
  );
};

interface ClaimFlashMessageSubstantiateProps {
  claimId: string;
  daysRemaining: number;
}

export const ClaimFlashMessageSubstantiate: React.FC<ClaimFlashMessageSubstantiateProps> = ({ claimId, daysRemaining }) => {
  return (
    <CardMessage
      title={'Upload your evidence now'}
      subtitle={
        <Fragment>
          You have <BoldText>{renderDaysRemainingText(daysRemaining)}</BoldText> left to add required evidence
        </Fragment>
      }
      cta={
        <NavLink to={`/claims/${claimId}`} style={{ textDecoration: 'none' }}>
          <Button buttonType="white">Upload Evidence</Button>
        </NavLink>
      }
      type="secondary"
    />
  );
};

interface ClaimFlashMessageViewProps {
  claimId: string;
}

export const ClaimFlashMessageView: React.FC<ClaimFlashMessageViewProps> = ({ claimId }) => {
  return (
    <CardMessage
      title={'This Reposit has a Claim associated with it'}
      subtitle={<Fragment></Fragment>}
      cta={
        <NavLink to={`/claims/${claimId}`} style={{ textDecoration: 'none' }}>
          <Button buttonType="white">View Claim</Button>
        </NavLink>
      }
      type="primary"
    />
  );
};

interface ClaimFlashMessageProposalNeedsResponseProps {
  claimId: string;
  daysRemaining: number;
}

export const ClaimFlashMessageProposalNeedsResponse: React.FC<ClaimFlashMessageProposalNeedsResponseProps> = ({
  claimId,
  daysRemaining
}) => {
  return (
    <CardMessage
      title={'Respond now'}
      subtitle={
        <Fragment>
          You have <BoldText>{renderDaysRemainingText(daysRemaining)}</BoldText> left to respond to the tenant's proposed
          settlement
        </Fragment>
      }
      cta={
        <NavLink to={`/claims/${claimId}`} style={{ textDecoration: 'none' }}>
          <Button buttonType="white">Respond Now</Button>
        </NavLink>
      }
      type="secondary"
    />
  );
};

interface ClaimFlashMessageProposalNeedsFinishingProps {
  claimId: string;
  daysRemaining: number;
}

export const ClaimFlashMessageProposalNeedsFinishing: React.FC<ClaimFlashMessageProposalNeedsFinishingProps> = ({
  claimId,
  daysRemaining
}) => {
  return (
    <CardMessage
      title={'Finish your final proposal'}
      subtitle={
        <Fragment>
          You have <BoldText>{renderDaysRemainingText(daysRemaining)}</BoldText> left to submit your final proposal
        </Fragment>
      }
      cta={
        <NavLink to={`/claims/${claimId}`} style={{ textDecoration: 'none' }}>
          <Button buttonType="white">View Proposal</Button>
        </NavLink>
      }
      type="secondary"
    />
  );
};
