import { OrganizationBankAccountDTOAccountTypeEnum } from '@reposit/api-client';
import React from 'react';
import { Col, Row } from 'react-grid-system';
import NotesIcon from '../../../assets/svg/notes.svg';
import { ContentContainer } from '../../../components/Common/index';
import FlashMessage from '../../../components/FlashMessage/index';
import RepositCard from '../../../components/Reposit/RepositCard/index';
import { P1 } from '../../../components/Typography/index';
import { CREATE_BANK_ACCOUNT_STORE_KEY, DELETE_BANK_ACCOUNT_STORE_KEY } from '../../../redux/bank-account/bank-account.actions';
import { BankAccountEntity } from '../../../redux/entities/entities.types';
import { useFlashMessage } from '../../FlashMessage/index';
import BankAccountForm from './BankAccountForm';
import BankAccountInfo from './BankAccountInfo';
import { BankAccountFormValues } from './index';

interface IndividualBankAccountProps {
  accountType: OrganizationBankAccountDTOAccountTypeEnum;
  addBankAccount: (values: BankAccountFormValues, accountType: OrganizationBankAccountDTOAccountTypeEnum) => void;
  bankAccounts: BankAccountEntity[];
  title: string;
  copy: string;
}

const IndividualBankAccount: React.FC<IndividualBankAccountProps> = ({
  accountType,
  addBankAccount,
  bankAccounts,
  title,
  copy
}) => {
  const bankAccount = bankAccounts.find(ba => ba && ba.accountType === accountType);
  const [flashMessagePayload, onDismissFlashMessage] = useFlashMessage([
    CREATE_BANK_ACCOUNT_STORE_KEY,
    DELETE_BANK_ACCOUNT_STORE_KEY
  ]);

  const formattedAccountType = accountType === OrganizationBankAccountDTOAccountTypeEnum.DEFAULT ? 'primary' : 'claim';
  return (
    <>
      <Row>
        <Col md={12}>
          <RepositCard
            icon={NotesIcon}
            title={title}
            flashMessage={
              flashMessagePayload && flashMessagePayload.message.includes(formattedAccountType) ? (
                <FlashMessage onDismiss={onDismissFlashMessage} timeRemaining={5000} payload={flashMessagePayload} />
              ) : (
                undefined
              )
            }
          >
            <ContentContainer>
              <>
                {copy ? <P1>{copy}</P1> : null}
                {bankAccount ? (
                  <BankAccountInfo bankAccount={bankAccount} />
                ) : (
                  <BankAccountForm onSubmit={values => addBankAccount(values, accountType)} accountType={accountType} />
                )}
              </>
            </ContentContainer>
          </RepositCard>
        </Col>
      </Row>
    </>
  );
};

export default IndividualBankAccount;
