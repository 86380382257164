import { SearchTenancyOrderResultsDTO, TenancyOrdersApi } from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getCurrentOrganizationId } from '../auth/auth.selectors';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import SCHEMA from '../schema';
import { createTenancyOrdersApi, runSagaWithAuth } from '../utils/api.utils';
import {
  fetchRepositsFailed,
  fetchRepositsSuccess,
  FETCH_REPOSITS_API_REQUESTED,
  RepositListActionTypes
} from './reposit-list.actions';
import { getRepositListFilters, getRepositListPagination } from './reposit-list.selectors';
import { RepositListFilters, APISortOrder } from './reposit-list.types';
import { RepositSort } from '../../constants/reposit';

export const REPOSIT_LIST_PAGE_SIZE = 10;

// ****************
// WORKERS
// ****************

export function* fetchRepositList() {
  try {
    const filters: RepositListFilters = yield select(getRepositListFilters);
    const organizationId: string = yield select(getCurrentOrganizationId);
    const pagination = yield select(getRepositListPagination);

    const tenancyOrdersApi: TenancyOrdersApi = yield createTenancyOrdersApi();

    const sortDirection = filters.sort === RepositSort.CREATION_DATE ? 'DESC' : 'ASC';

    let sortBy: 'CREATED_AT' | 'START_DATE' | 'END_DATE';

    switch (filters.sort) {
      case RepositSort.CREATION_DATE:
        sortBy = APISortOrder.CREATED_AT;
        break;
      case RepositSort.START_DATE:
        sortBy = APISortOrder.START_DATE;
        break;
      case RepositSort.END_DATE:
        sortBy = APISortOrder.END_DATE;
        break;
    }

    const apiResponse: AxiosResponse<SearchTenancyOrderResultsDTO> = yield call(
      runSagaWithAuth(() =>
        tenancyOrdersApi.search(
          'REPOSIT',
          organizationId,
          pagination.page,
          REPOSIT_LIST_PAGE_SIZE,
          false,
          false,
          filters.query,
          filters.filter,
          filters.tenancyStatus,
          sortBy,
          sortDirection,
          undefined
        )
      )
    );

    const ids: string[] = yield syncEntitiesAndGetResults(apiResponse.data.results, SCHEMA.tenancyOrders);
    yield put<RepositListActionTypes>(fetchRepositsSuccess(apiResponse.data.count, ids));
  } catch (e) {
    yield put<RepositListActionTypes>(fetchRepositsFailed(get(e, 'response.data.message', e)));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchRepositListSagas() {
  yield takeLatest(FETCH_REPOSITS_API_REQUESTED, fetchRepositList);
}
