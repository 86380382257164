import { CreateDocumentDTOTypeEnum } from '@reposit/api-client';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';
import SupportingDocumentIcon from '../../assets/svg/document.svg';
import TenancyAgreementIcon from '../../assets/svg/edit-note.svg';
import AnnualInspectionIcon from '../../assets/svg/inspect.svg';
import CheckOutIcon from '../../assets/svg/inventory.svg';
import CheckInIcon from '../../assets/svg/key.svg';
import ReferencingIcon from '../../assets/svg/task.svg';
import DocumentIcon from '../../assets/svg/tenancy-details.svg';
import { useAppDispatch } from '../../index';
import { CREATE_CLAIM_DOCUMENT_STORE_KEY } from '../../redux/claim-document/claim-document.actions';
import { DocumentEntity } from '../../redux/entities/entities.types';
import { setFlashMessage } from '../../redux/flash-messages/flash-messages.actions';
import { FlashMessagePayload } from '../../redux/flash-messages/flash-messages.types';
import { DisableComponent } from '../Common';
import FlashMessage, { FlashState } from '../FlashMessage/index';
import { FileUploadCard } from '../Library/FileUpload/FileUploadCard';
import RepositCard from '../Reposit/RepositCard/index';
import { P1 } from '../Typography/index';

interface DocumentUploaderProps {
  disableUpload: boolean;
  documents: DocumentEntity[] | undefined;
  handleDelete: (documentId: string) => Promise<void>;
  createDocument: (file: File, type: string) => Promise<DocumentEntity>;
  flashMessage?: FlashMessagePayload;
  onDismissFlashMessage: () => void;
  isClaimInDraft: boolean;
  isDisabled: boolean;
  isClaimAwaitingEvidence: boolean;
  isMediationEnabled: boolean;
  isAllRentArrears: boolean;
}

const Text = styled(P1)`
  padding: 0 36px 16px;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

interface TenancyDocumentsDefinition {
  id: string;
  label: string;
  tooltip: string;
  required: boolean;
  icon: string;
}

const FileUploadContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0 20px 40px 20px;
`;

const Spacer = styled.div`
  padding: 0 10px 0 10px;
  margin-top: 20px;
  width: 31%;
  min-width: 270px;
`;

const ClaimDocumentUploader: React.FC<DocumentUploaderProps> = ({
  disableUpload,
  isClaimInDraft,
  documents,
  handleDelete,
  createDocument,
  flashMessage,
  onDismissFlashMessage,
  isDisabled: isClaimItemsFormOpen,
  isClaimAwaitingEvidence,
  isMediationEnabled,
  isAllRentArrears
}) => {
  const dispatch = useAppDispatch();

  const onFileError = (error: string) =>
    dispatch(setFlashMessage({ key: CREATE_CLAIM_DOCUMENT_STORE_KEY, message: error, state: FlashState.ERROR }));

  const TenancyDocuments: TenancyDocumentsDefinition[] = [
    {
      id: CreateDocumentDTOTypeEnum.INVENTORYCHECKOUT,
      label: 'Check-Out',
      tooltip:
        'The report produced at the end of tenancy. This documents the state of the property relative to the check-in inventory.',
      required: isAllRentArrears && !isClaimAwaitingEvidence && isMediationEnabled ? false : true,
      icon: CheckOutIcon
    },
    {
      id: CreateDocumentDTOTypeEnum.INVENTORYCHECKIN,
      label: 'Check-In',
      tooltip:
        'The original signed check-in report/inventory with date-stamped photos and descriptions. In the absence of a signature, you’ll need to provide proof that it was made available to all tenants.',
      required: isMediationEnabled ? isClaimAwaitingEvidence : true,
      icon: CheckInIcon
    },
    {
      id: CreateDocumentDTOTypeEnum.SIGNEDTENANCYAGREEMENT,
      label: 'Tenancy Agreement',
      tooltip:
        'The original signed tenancy agreement. If there was more than one tenancy agreement during this tenancy (due to a tenant swap etc), please attach all copies.',
      required: isMediationEnabled ? isClaimAwaitingEvidence : true,
      icon: TenancyAgreementIcon
    },
    {
      id: CreateDocumentDTOTypeEnum.REFERENCING,
      label: 'Referencing',
      tooltip:
        'Upload the third party referencing here for each individual tenant. If a guarantor was used, please ensure you also upload a copy of their referencing.',
      required: isMediationEnabled ? isClaimAwaitingEvidence : true,
      icon: ReferencingIcon
    },
    {
      id: CreateDocumentDTOTypeEnum.MIDTERMINSPECTION,
      label: 'Annual Inspection',
      tooltip:
        'Reports of any annual inspections carried out during the tenancy. This is only essential for tenancies that exceed 12 months in length. If multiple visits occurred, please enter the details of all visits.',
      required: false,
      icon: AnnualInspectionIcon
    },

    {
      id: CreateDocumentDTOTypeEnum.SUPPORTINGEVIDENCE,
      label: 'Additional Evidence',
      tooltip:
        'Please upload any additional evidence in support for the claim that does not fall under any of the above categories. You may upload more than one document.',
      required: false,
      icon: SupportingDocumentIcon
    }
  ];

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <DisableComponent disabled={isClaimItemsFormOpen}>
      <RepositCard
        title="Tenancy Documents"
        icon={DocumentIcon}
        flush
        flashMessage={
          flashMessage ? (
            <FlashMessage onDismiss={onDismissFlashMessage} timeRemaining={5000} payload={flashMessage} />
          ) : (
            undefined
          )
        }
        showDocumentUploadNotifier={!disableUpload}
      >
        <>
          {isClaimInDraft && (
            <>
              <Text style={{ marginBottom: '1rem', paddingBottom: 0 }}>
                Please upload the following additional documents to support your claim.{' '}
                <BoldText>These documents will be shared with the tenant(s).</BoldText>
              </Text>
              {/* <Text>
                Though we only require the Check-Out report upfront, other documents will be required later if the end of tenancy
                charges are not agreed by you and the tenant.
              </Text> */}
            </>
          )}
          <FileUploadContainer>
            {TenancyDocuments.map(requiredDoc => {
              const docs = (documents || []).filter(d => d.typeId === requiredDoc.id);
              return (
                <Spacer key={requiredDoc.id}>
                  <FileUploadCard
                    required={requiredDoc.required}
                    title={requiredDoc.label}
                    tooltip={requiredDoc.tooltip}
                    icon={requiredDoc.icon}
                    documents={docs}
                    onError={onFileError}
                    uploadFile={async (file: File) => {
                      return createDocument(file, requiredDoc.id);
                    }}
                    deleteFile={handleDelete}
                    readOnly={disableUpload}
                    showDeleteButton={!isClaimAwaitingEvidence}
                  />
                </Spacer>
              );
            })}
          </FileUploadContainer>
        </>
      </RepositCard>
    </DisableComponent>
  );
};

export default ClaimDocumentUploader;
