import React from 'react';
import { useSelector } from 'react-redux';
import Checkbox from '../../FormFields/Checkbox/index';
import styled from 'styled-components';
import { DisableComponent } from '../../Common/index';
import { getClaimItemFormOpen } from '../../../redux/claim/claim.selector';

export interface ClaimChecks {
  confirmClaim: boolean;
  confirmGuarantors: boolean;
}
interface ClaimChecksProps {
  editMode: boolean;
  disabled: boolean;
  onUpdate: (values: any) => void;
  checks: ClaimChecks;
}

const Question = styled.div`
  margin: 0 0 24px;
`;

export interface ClaimChecksFormValues {
  confirmClaim: boolean;
}

const ClaimChecks: React.FC<ClaimChecksProps> = ({ disabled, checks, onUpdate }) => {
  const isClaimItemsFormOpen = useSelector(getClaimItemFormOpen);

  const content = (
    <DisableComponent disabled={isClaimItemsFormOpen}>
      <form>
        <Question>
          <Checkbox
            name="confirmClaim"
            label="I confirm the details above are accurate and I understand that inaccurate or inadequate evidence might result in my claim being rejected"
            helpText=""
            isChecked={checks.confirmClaim}
            onChange={() =>
              onUpdate({
                ...checks,
                confirmClaim: !checks.confirmClaim
              })
            }
          />
        </Question>
        <Question>
          <Checkbox
            name="confirmGuarantors"
            label="I confirm the guarantor information is correct"
            helpText=""
            isChecked={checks.confirmGuarantors}
            onChange={() =>
              onUpdate({
                ...checks,
                confirmGuarantors: !checks.confirmGuarantors
              })
            }
          />
        </Question>
      </form>
    </DisableComponent>
  );

  return disabled ? <DisableComponent disabled={disabled}>{content}</DisableComponent> : content;
};

export default ClaimChecks;
