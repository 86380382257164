import React from 'react';
import styled from 'styled-components';
import { Header2, Caption } from '../../Typography/index';
import { getBreakpoint } from '../../../base/style';

const StyledTitle = styled.div`
  align-items: flex-start;
  display: flex;
  margin: 0 0 24px;
`;

interface TitleProps {
  icon?: string;
  caption?: string;
  bigIcon?: boolean;
}

const StyledCaption = styled(Caption)`
  margin: -0.25em 0 1em;
  font-size: 1.2em;
`;

const Icon = styled.i<TitleProps>`
  background: #fff url(${props => props.icon}) no-repeat center center;
  background-size: ${props => (props.bigIcon ? '36px' : '20px')};
  border-radius: 48px;
  display: none;
  height: 48px;
  margin: 0 20px 0 0;
  width: 48px;

  @media screen and (min-width: ${getBreakpoint('md')}) {
    display: block;
  }
`;

const Title: React.SFC<TitleProps> = ({ children, icon, caption, bigIcon }) => {
  return (
    <StyledTitle>
      <Icon icon={icon} bigIcon={bigIcon} />
      <div>
        <Header2>{children}</Header2> {caption && <StyledCaption>{caption}</StyledCaption>}
      </div>
    </StyledTitle>
  );
};

export default Title;
