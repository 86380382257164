export const FETCH_ORGANIZATION_INVITES_STORE_KEY = 'INVITE/FETCH_ORGANIZATION_INVITES';
export const FETCH_ORGANIZATION_INVITES_API_REQUESTED = `INVITE/FETCH_ORGANIZATION_INVITES_API_REQUESTED`;
export const FETCH_ORGANIZATION_INVITES_API_SUCCESS = 'INVITE/FETCH_ORGANIZATION_INVITES_API_SUCCESS';
export const FETCH_ORGANIZATION_INVITES_API_FAILED = 'INVITE/FETCH_ORGANIZATION_INVITES_API_FAILED';

export const CREATE_INVITE_STORE_KEY = 'INVITE/CREATE_INVITE';
export const CREATE_INVITE_API_REQUESTED = `INVITE/CREATE_INVITE_API_REQUESTED`;
export const CREATE_INVITE_API_SUCCESS = 'INVITE/CREATE_INVITE_API_SUCCESS';
export const CREATE_INVITE_API_FAILED = 'INVITE/CREATE_INVITE_API_FAILED';

export const RESEND_INVITE_STORE_KEY = 'INVITE/RESEND_INVITE';
export const RESEND_INVITE_API_REQUESTED = `INVITE/RESEND_INVITE_API_REQUESTED`;
export const RESEND_INVITE_API_SUCCESS = 'INVITE/RESEND_INVITE_API_SUCCESS';
export const RESEND_INVITE_API_FAILED = 'INVITE/RESEND_INVITE_API_FAILED';

export const DELETE_INVITE_STORE_KEY = 'INVITE/DELETE_INVITE';
export const DELETE_INVITE_API_REQUESTED = `INVITE/DELETE_INVITE_API_REQUESTED`;
export const DELETE_INVITE_API_SUCCESS = 'INVITE/DELETE_INVITE_API_SUCCESS';
export const DELETE_INVITE_API_FAILED = 'INVITE/DELETE_INVITE_API_FAILED';

export const TOGGLE_IS_INVITE_USER_FORM_OPEN = 'INVITE/TOGGLE_IS_INVITE_USER_FORM_OPEN';

export function fetchOrganizationInvitesRequested() {
  return {
    type: FETCH_ORGANIZATION_INVITES_API_REQUESTED
  } as const;
}

export function fetchOrganizationInvitesSuccess(payload: string[]) {
  return {
    type: FETCH_ORGANIZATION_INVITES_API_SUCCESS,
    payload
  } as const;
}

export function fetchOrganizationInvitesFailed(error: string) {
  return {
    type: FETCH_ORGANIZATION_INVITES_API_FAILED,
    payload: error
  } as const;
}

export function createInviteRequested(payload: string) {
  return {
    type: CREATE_INVITE_API_REQUESTED,
    payload
  } as const;
}

export function createInviteSuccess() {
  return {
    type: CREATE_INVITE_API_SUCCESS
  } as const;
}

export function createInviteFailed(error: string) {
  return {
    type: CREATE_INVITE_API_FAILED,
    payload: error
  } as const;
}

export function toggleIsInviteUserFormOpen() {
  return {
    type: TOGGLE_IS_INVITE_USER_FORM_OPEN
  } as const;
}

export function resendInviteRequested(payload: string) {
  return {
    type: RESEND_INVITE_API_REQUESTED,
    payload
  } as const;
}

export function resendInviteSuccess() {
  return {
    type: RESEND_INVITE_API_SUCCESS
  } as const;
}

export function resendInviteFailed(error: string) {
  return {
    type: RESEND_INVITE_API_FAILED,
    payload: error
  } as const;
}

export function deleteInviteRequested(payload: string) {
  return {
    type: DELETE_INVITE_API_REQUESTED,
    payload
  } as const;
}

export function deleteInviteSuccess(payload: string) {
  return {
    type: DELETE_INVITE_API_SUCCESS,
    payload
  } as const;
}

export function deleteInviteFailed(error: string) {
  return {
    type: DELETE_INVITE_API_FAILED,
    payload: error
  } as const;
}

export type InviteActionTypes = ReturnType<
  | typeof fetchOrganizationInvitesRequested
  | typeof fetchOrganizationInvitesSuccess
  | typeof fetchOrganizationInvitesFailed
  | typeof createInviteRequested
  | typeof createInviteSuccess
  | typeof createInviteFailed
  | typeof toggleIsInviteUserFormOpen
  | typeof resendInviteRequested
  | typeof resendInviteSuccess
  | typeof resendInviteFailed
  | typeof deleteInviteRequested
  | typeof deleteInviteSuccess
  | typeof deleteInviteFailed
>;
