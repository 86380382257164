import { FeedbackApi, SubmitFeedbackDTO } from '@reposit/api-client';
import { get } from 'lodash';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { FlashState } from '../../components/FlashMessage/index';
import { FlashMessagesActionTypes, setFlashMessage } from '../flash-messages/flash-messages.actions';
import { createFeedbackApi, runSagaWithAuth } from '../utils/api.utils';
import {
  FeedbackActionTypes,
  submitFeedbackFailed,
  submitFeedbackSuccess,
  SUBMIT_FEEDBACK_API_REQUESTED,
  SUBMIT_FEEDBACK_STORE_KEY,
  toggleFeedbackModal
} from './feedback.actions';
import { getCurrentOrganizationId } from '../auth/auth.selectors';

// ****************
// WORKERS
// ****************

export function* submitFeedback({ payload }: { type: string; payload: SubmitFeedbackDTO }) {
  try {
    const feedbackApi: FeedbackApi = yield createFeedbackApi();
    let organizationId = yield select(getCurrentOrganizationId);
    yield call(
      runSagaWithAuth(() =>
        feedbackApi.submitFeedback({
          subject: payload.subject,
          message: payload.message,
          application: payload.application,
          organizationId
        })
      )
    );

    yield put<FeedbackActionTypes>(submitFeedbackSuccess());
    yield put<FeedbackActionTypes>(toggleFeedbackModal());
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: SUBMIT_FEEDBACK_STORE_KEY,
        message: 'Thank you for your feedback',
        state: FlashState.SUCCESS
      })
    );
  } catch (e) {
    yield put<FeedbackActionTypes>(submitFeedbackFailed(get(e, 'response.data.message', e)));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchFeedbackSagas() {
  yield takeLatest(SUBMIT_FEEDBACK_API_REQUESTED, submitFeedback);
}
