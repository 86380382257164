import { SubmitFeedbackDTOSubjectEnum } from '@reposit/api-client';
import { startCase, toLower } from 'lodash';
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import Button from '../Button/index';
import Select from '../FormFields/Select/index';
import TextArea from '../FormFields/TextArea/index';
import Modal from '../Modal/index';
import { Header3, Header4, P2 } from '../Typography/index';
import { createErrorMessageSelector } from '../../redux/error/error.selector';
import { SUBMIT_FEEDBACK_STORE_KEY } from '../../redux/feedback/feedback.actions';
import { useSelector } from 'react-redux';

interface ConfirmationModalProps {
  onSubmit: (subject: SubmitFeedbackDTOSubjectEnum, message: string) => void;
  onDismiss: () => void;
}

const Action = styled.div`
  padding: 12px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

const MailLink = styled.a`
  color: ${props => props.theme.colors.primaryBrand};
  display: inline;
  font-family: ${props => props.theme.typography.face.secondary};
  font-size: inherit;
  text-decoration: underline;
`;

const FeedbackModal = ({ onSubmit, onDismiss }: ConfirmationModalProps) => {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const subjectOptions = Object.values(SubmitFeedbackDTOSubjectEnum).map(value => ({ label: startCase(toLower(value)), value }));
  const errorSelector = createErrorMessageSelector([SUBMIT_FEEDBACK_STORE_KEY]);
  const error = useSelector(errorSelector);

  return (
    <Modal onDismiss={onDismiss}>
      <Fragment>
        <Header3 style={{ marginBottom: 6 }}>Submit Feedback</Header3>
        <P2>
          We are always trying to improve our product and would welcome any thoughts or suggestions you may have about your
          experience using Reposit.
        </P2>
        <Select onChange={e => setSubject(e)} value={subject}>
          <option value={''}>Please select the subject for your feedback</option>
          {subjectOptions.map(option => {
            return (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            );
          })}
        </Select>
        <Header4 style={{ marginTop: 24 }}>Message</Header4>
        <TextArea
          name="message"
          value={message}
          onChange={e => setMessage(e.target.value)}
          onBlur={e => setMessage(e.target.value)}
        />
        <P2 style={{ marginTop: 24, color: '#f03838', fontWeight: 'bold' }}>
          {'If you require assistance with a specific Reposit or claim please contact us via live chat or '}
          <MailLink href="mailto:hello@reposit.co.uk.">email us</MailLink>. Feeback submissions are only periodically reviewed.
        </P2>
        <Action>
          <Button buttonType="secondary" noArrow={true} onClick={onDismiss}>
            Cancel
          </Button>
          <Button disabled={subject === '' || message === ''} onClick={() => onSubmit(subject as any, message)}>
            Send
          </Button>
        </Action>
        {error && (
          <P2 color={'#df6b6b'} style={{ marginTop: 24 }}>
            There was an issue submitting your feedback, please try again
          </P2>
        )}
      </Fragment>
    </Modal>
  );
};

export default FeedbackModal;
