import { AppState } from '../root.reducer';
import { PropertyDTO } from '@reposit/api-client';
import { denormalize } from 'normalizr';
import SCHEMA from '../schema';

export const getPropertiesSelector = (state: AppState) => state.entities.property;

export const getProperty = (id: string) => (state: AppState): PropertyDTO | undefined => {
  if (!state.entities.property) return undefined;
  return denormalize(state.entities.property[id], SCHEMA.property, state.entities);
};

export const getFlatProperties = (ids: string[]) => (state: AppState) => {
  return ids.map(id => getProperty(id)(state));
};
