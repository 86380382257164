import React, { Fragment } from 'react';
import RepositSummaryContainer from '../../../containers/Reposit/Summary';

interface RepositListProps {
  reposits: any[];
}

export default ({ reposits }: RepositListProps) => {
  const repositsList = reposits.map(tenancyOrderId => {
    return <RepositSummaryContainer key={tenancyOrderId} tenancyOrderId={tenancyOrderId} />;
  });

  return <Fragment>{repositsList}</Fragment>;
};
