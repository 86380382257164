import React from 'react';
import styled from 'styled-components';
import { Header1, Caption } from '../Typography/index';
import { AddressEntity } from '../../redux/entities/entities.types';
import { getAddressFirstLine, getAddressTownPostcode } from '../../utils/common.utils';

const PropertySummaryWrapper = styled.div`
  padding: 0 0 36px;
`;

const FirstLine = styled(Header1)`
  margin: 0 0 10px;
`;
const SecondLine = styled(Caption)`
  margin: 0;
`;

interface PropertySummaryProps {
  address: AddressEntity;
}

const PropertySummary: React.SFC<PropertySummaryProps> = ({ address }) => {
  return (
    <PropertySummaryWrapper>
      <FirstLine>{getAddressFirstLine(address)}</FirstLine>
      <SecondLine>{getAddressTownPostcode(address)}</SecondLine>
    </PropertySummaryWrapper>
  );
};

export default PropertySummary;
