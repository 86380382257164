import React, { useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import FormErrorMessage from '../ErrorMessage/index';
import { RoundButton } from '../../Button';
import HelpIcon from '../../../assets/svg/help.svg';

export const FieldLabel = styled.div`
  color: ${props => props.theme.colors.body};
  font-family: ${props => props.theme.typography.face.secondary};
  font-size: 0.875em;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  margin: 0 0 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  span {
    font-size: 12px;
    font-weight: normal;
    margin: 0 8px;
  }
`;

const Field = styled.div`
  margin: 0 0 8px;
`;

const HelpButton = styled(RoundButton)`
  display: flex;
  flex: 0 0 24px;
  margin: 0 12px;
`;

interface FieldWithLabelProps {
  label: string;
  style?: any;
  error?: string;
  touched?: boolean;
  description?: string;
  showTooltip?: boolean;
  tooltipText?: string;
}

const FieldWithLabel: React.SFC<FieldWithLabelProps> = ({
  label,
  description,
  children,
  error,
  touched,
  style,
  showTooltip,
  tooltipText
}) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <Field style={style}>
      <FieldLabel>
        {label} <span>{description}</span> {showTooltip && <HelpButton icon={HelpIcon} data-tip={tooltipText} />}
      </FieldLabel>
      {children}
      {error && touched && <FormErrorMessage error={error} />}
    </Field>
  );
};

export default FieldWithLabel;
