import React, { Fragment } from 'react';
import styled from 'styled-components';
import { ClaimDTOStatusEnum } from '@reposit/api-client';

import Button, { ButtonType } from '../../../components/Button/index';
import { DisableComponent } from '../../../components/Common/index';
import ClaimPublishModal from './modals/ClaimPublishModal';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentClaimActionModal } from '../../../redux/claim/claim.actions';
import { ClaimEntity } from '../../../redux/entities/entities.types';
import { getCurrentClaimActionModal, getClaimItemFormOpen } from '../../../redux/claim/claim.selector';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import { CREATE_DOCUMENT_STORE_KEY } from '../../../redux/document/document.actions';
import { CREATE_CLAIM_ITEM_DOCUMENT_STORE_KEY } from '../../../redux/claim-item-document/claim-item-document.actions';
import ClaimUploadEvidenceModal from './modals/ClaimUploadEvidenceModal';

interface ClaimActionsProps {
  isPublishable: boolean;
  isClaimConfirmed: boolean;
  isClaimFinalised: boolean;
  claimLimitExceeded: boolean;
  claim: ClaimEntity;
  claimDetails: string | undefined;
}

export interface ClaimActionModalProps {
  claim: ClaimEntity;
  isPublishable: boolean;
  onDismiss: () => void;
  claimLimitExceeded: boolean;
  claimDetails?: string | undefined;
}

interface ActionDefinition {
  id: string;
  label: string;
  statuses: string[];
  renderModal: (props: ClaimActionModalProps) => JSX.Element;
  buttonType: ButtonType;
  noArrow: boolean;
  isDisabled: boolean;
  shouldRender: () => boolean;
}

const generateActions = (
  isDisabled: boolean,
  isClaimFinalised: boolean,
  claim: ClaimEntity,
  claimDetails: string | undefined
): ActionDefinition[] => [
  {
    id: 'CREATE_CLAIM',
    label: 'Submit claim',
    statuses: [ClaimDTOStatusEnum.DRAFT],
    buttonType: 'primary',
    renderModal: props => (
      <ClaimPublishModal
        isPublishable={props.isPublishable}
        claim={props.claim}
        onDismiss={props.onDismiss}
        claimLimitExceeded={props.claimLimitExceeded}
        claimDetails={claimDetails}
      />
    ),
    noArrow: false,
    isDisabled,
    shouldRender: () => claim.status === 'DRAFT'
  },
  {
    id: 'SUBMIT_EVIDENCE',
    label: 'Submit Evidence',
    statuses: [ClaimDTOStatusEnum.AWAITINGSUBSTANTIATION],
    buttonType: 'primary',
    renderModal: props => (
      // isPublishable & claimLimitExceeded not needed
      <ClaimUploadEvidenceModal
        isPublishable={props.isPublishable}
        claim={props.claim}
        onDismiss={props.onDismiss}
        claimLimitExceeded={props.claimLimitExceeded}
      />
    ),
    noArrow: false,
    isDisabled: !isClaimFinalised,
    shouldRender: () => claim.status === ClaimDTOStatusEnum.AWAITINGSUBSTANTIATION
  }
];

const ActionsContainer = styled.div`
  margin: 36px 0 24px;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

const ClaimActions: React.FC<ClaimActionsProps> = ({
  isClaimConfirmed,
  isPublishable,
  claim,
  isClaimFinalised,
  claimLimitExceeded,
  claimDetails
}) => {
  const createUploadingSelector = createLoadingSelector([CREATE_DOCUMENT_STORE_KEY, CREATE_CLAIM_ITEM_DOCUMENT_STORE_KEY]);
  const isUploading = useSelector(createUploadingSelector);
  const isClaimItemsFormOpen = useSelector(getClaimItemFormOpen);
  const isButtonDisabled = !isClaimConfirmed || isUploading || isClaimItemsFormOpen;
  const actions = generateActions(isButtonDisabled, isClaimFinalised, claim, claimDetails);

  const dispatch = useDispatch();
  const showModal = (actionId: string) => dispatch(setCurrentClaimActionModal(actionId));
  const currentModal = useSelector(getCurrentClaimActionModal);
  const content = (
    <ActionsContainer>
      {actions.map(
        ({ id, buttonType, label, renderModal, noArrow, isDisabled, shouldRender }) =>
          shouldRender() && (
            <Fragment key={id}>
              <Button buttonType={buttonType} disabled={isDisabled} onClick={() => showModal(id)} noArrow={noArrow}>
                {label}
              </Button>
              {currentModal === id
                ? renderModal({ claim, onDismiss: () => showModal(''), isPublishable, claimLimitExceeded, claimDetails })
                : null}
            </Fragment>
          )
      )}
    </ActionsContainer>
  );

  const isClaimAwaitingEvidence = claim.status === ClaimDTOStatusEnum.AWAITINGSUBSTANTIATION;

  const isDisabled = !isClaimAwaitingEvidence && claim.status !== ClaimDTOStatusEnum.DRAFT;

  return <DisableComponent disabled={isDisabled}>{content}</DisableComponent>;
};

export default ClaimActions;
