export const TOGGLE_MENU = 'SYSTEM/TOGGLE_MENU';
export const FILE_UPLOAD_STORE_KEY = 'SYSTEM/FILE_UPLOAD';

export function toggleMenu() {
  return {
    type: TOGGLE_MENU
  } as const;
}

export type SystemActionTypes = ReturnType<typeof toggleMenu>;
