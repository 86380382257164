import React from 'react';
import styled from 'styled-components';
import Card from '../Card/index';
import { P2 } from '../Typography/index';
import Checkbox from '../FormFields/Checkbox/index';
import { getBreakpoint } from '../../base/style';

interface AgreeableCardProps {
  info?: string;
  label: string;
  flush?: boolean;
  isChecked: boolean;
  onChange: () => void;
  showCheckbox?: boolean;
}

const StyledCard = styled(Card)`
  padding-bottom: 22px;
`;

const Action = styled.div<{ flush?: boolean }>`
  align-items: center;
  margin: 22px 0 0;
  padding: ${props => (props.flush ? '0 36px' : '0')};

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    display: flex;
  }
`;

const InfoText = styled(P2)`
  color: ${props => props.theme.colors.warning};
  font-weight: 600;
  margin: 12px 0;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    margin: 0 20px 0 0;
  }
`;

const AgreeableCard: React.SFC<AgreeableCardProps> = ({
  children,
  info,
  label,
  flush,
  onChange,
  isChecked,
  showCheckbox = true
}) => {
  return (
    <StyledCard flush={flush}>
      <>
        <span>{children}</span>
        {showCheckbox ? (
          <Action flush={flush}>
            {info && <InfoText>{info}</InfoText>}
            <Checkbox name="agree" label={label} isChecked={isChecked} helpText="" onChange={onChange} />
          </Action>
        ) : null}
      </>
    </StyledCard>
  );
};

export default AgreeableCard;
