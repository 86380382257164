import { schema, Schema } from 'normalizr';
import {
  AddressDTO,
  LandlordDTO,
  PropertyDTO,
  UserDTO,
  TenantDTO,
  OrderDTO,
  OrganizationDTO,
  TenancyDTO,
  DocumentDTO,
  TenancyOrderWithTenancyDTO,
  OrderCustomerDTO,
  OrderCustomerActionDTO,
  ClaimDTO,
  CheckoutDTO,
  ClaimItemDTO,
  OrganizationInviteDTO,
  ClaimDocumentDTO,
  ClaimItemDocumentDTO,
  OrganizationRecipientDTO,
  OrganizationBankAccountDTO,
  ClaimProposalDTO,
  ClaimItemProposalDTO
} from '@reposit/api-client';

export interface SchemaDefinition {
  address: Schema<AddressDTO>;
  document: Schema<DocumentDTO>;
  landlord: Schema<LandlordDTO>;
  property: Schema<PropertyDTO>;
  user: Schema<UserDTO>;
  users: Array<Schema<UserDTO>>;
  tenant: Schema<TenantDTO>;
  order: Schema<OrderDTO>;
  organization: Schema<OrganizationDTO>;
  organizationInvite: Schema<OrganizationInviteDTO>;
  organizationInvites: Array<Schema<OrganizationInviteDTO>>;
  organizationRecipient: Schema<OrganizationRecipientDTO>;
  organizationRecipients: Array<Schema<OrganizationRecipientDTO>>;
  tenancy: Schema<TenancyDTO>;
  tenancyOrder: Schema<TenancyOrderWithTenancyDTO>;
  tenancies: Array<Schema<TenancyDTO>>;
  tenancyOrders: Array<Schema<TenancyOrderWithTenancyDTO>>;
  properties: Array<Schema<PropertyDTO>>;
  addresses: Array<Schema<AddressDTO>>;
  orderCustomer: Schema<OrderCustomerDTO>;
  orderCustomerAction: Schema<OrderCustomerActionDTO>;
  claim: Schema<ClaimDTO>;
  claims: Array<Schema<ClaimDTO>>;
  claimProposal: Schema<ClaimProposalDTO>;
  claimItemProposal: Schema<ClaimItemProposalDTO>;
  claimItem: Schema<ClaimItemDTO>;
  claimItemDocument: Schema<ClaimItemDocumentDTO>;
  checkout: Schema<CheckoutDTO>;
  claimDocument: Schema<ClaimDocumentDTO>;
  bankAccount: Schema<OrganizationBankAccountDTO>;
  bankAccounts: Array<Schema<OrganizationBankAccountDTO>>;
}

const SCHEMA: SchemaDefinition = {
  address: {},
  document: {},
  landlord: {},
  property: {},
  user: {},
  users: [],
  tenant: {},
  order: {},
  organization: {},
  organizationInvite: {},
  organizationInvites: [],
  organizationRecipient: {},
  organizationRecipients: [],
  tenancy: {},
  claim: {},
  claimProposal: {},
  claimItemProposal: {},
  tenancies: [],
  tenancyOrder: {},
  tenancyOrders: [],
  properties: [],
  addresses: [],
  orderCustomer: {},
  orderCustomerAction: {},
  claims: [],
  claimItem: {},
  checkout: {},
  claimDocument: {},
  claimItemDocument: {},
  bankAccount: {},
  bankAccounts: []
};

SCHEMA.checkout = new schema.Entity('checkout');

SCHEMA.address = new schema.Entity('address');

SCHEMA.document = new schema.Entity('document');

SCHEMA.orderCustomer = new schema.Entity('orderCustomer');
SCHEMA.orderCustomerAction = new schema.Entity('orderCustomerAction', {
  orderCustomer: SCHEMA.orderCustomer
});

SCHEMA.orderCustomer.define({
  actions: [SCHEMA.orderCustomerAction]
});
SCHEMA.tenancy = new schema.Entity('tenancy');

SCHEMA.claim = new schema.Entity('claim');

SCHEMA.claimDocument = new schema.Entity('claimDocument', {
  claim: SCHEMA.claim,
  document: SCHEMA.document
});

SCHEMA.claimItemDocument = new schema.Entity('claimItemDocument');

SCHEMA.claimItemProposal = new schema.Entity('claimItemProposal');

SCHEMA.claimItem = new schema.Entity('claimItem', {
  claim: SCHEMA.claim,
  claimItemDocuments: [SCHEMA.claimItemDocument],
  proposals: [SCHEMA.claimItemProposal]
});

SCHEMA.claimItemDocument.define({
  claimItem: SCHEMA.claimItem,
  document: SCHEMA.document,
  claimItemProposal: SCHEMA.claimItemProposal
});

SCHEMA.claimProposal = new schema.Entity('claimProposal', {
  claim: SCHEMA.claim,
  itemProposals: [SCHEMA.claimItemProposal]
});

SCHEMA.claimItemProposal.define({
  documents: [SCHEMA.claimItemDocument],
  claimProposal: SCHEMA.claimProposal
});

SCHEMA.organization = new schema.Entity('organization');

SCHEMA.landlord = new schema.Entity('landlord', {
  address: SCHEMA.address,
  organization: SCHEMA.organization
});

SCHEMA.property = new schema.Entity('property', {
  address: SCHEMA.address,
  landlord: SCHEMA.landlord,
  organization: SCHEMA.organization
});

SCHEMA.order = new schema.Entity('order', {
  orderCustomers: [SCHEMA.orderCustomer]
});

SCHEMA.user = new schema.Entity('user', {
  organizations: [SCHEMA.organization]
});

SCHEMA.users = [SCHEMA.user];

SCHEMA.organizationInvite = new schema.Entity('organizationInvite', {
  organization: SCHEMA.organization
});

SCHEMA.organizationInvites = [SCHEMA.organizationInvite];

SCHEMA.tenant = new schema.Entity('tenant', {
  user: SCHEMA.user
});

SCHEMA.tenancyOrder = new schema.Entity('tenancyOrder', {
  tenancy: SCHEMA.tenancy,
  order: SCHEMA.order
});

SCHEMA.checkout.define({
  tenancy: SCHEMA.tenancy,
  claim: SCHEMA.claim
});

SCHEMA.claim.define({
  checkout: SCHEMA.checkout,
  items: [SCHEMA.claimItem],
  documents: [SCHEMA.claimDocument],
  proposals: [SCHEMA.claimProposal]
});

SCHEMA.tenancy.define({
  orders: [SCHEMA.tenancyOrder],
  documents: [SCHEMA.document],
  property: SCHEMA.property,
  tenants: [SCHEMA.tenant],
  checkout: SCHEMA.checkout
});

SCHEMA.organizationRecipient = new schema.Entity('organizationRecipient');

SCHEMA.bankAccount = new schema.Entity('bankAccount', {
  organization: SCHEMA.organization
});

SCHEMA.organization.define({
  organizationRecipients: [SCHEMA.organizationRecipient],
  bankAccounts: [SCHEMA.bankAccount]
});

SCHEMA.organizationRecipients = [SCHEMA.organizationRecipient];
SCHEMA.bankAccounts = [SCHEMA.bankAccount];
SCHEMA.tenancies = [SCHEMA.tenancy];
SCHEMA.tenancyOrders = [SCHEMA.tenancyOrder];
SCHEMA.properties = [SCHEMA.property];
SCHEMA.addresses = [SCHEMA.address];
SCHEMA.claims = [SCHEMA.claim];

export default SCHEMA;
