import { CreateAddressWithOrgDTO, ExternalAddressDTO } from '@reposit/api-client';
import { FormikErrors, FormikTouched } from 'formik';
import { get, isEqual } from 'lodash';
import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import { getBreakpoint } from '../../../../base/style';
import Button from '../../../../components/Button/index';
import FieldWithLabel from '../../../../components/FormFields/FieldWithLabel/index';
import { Input } from '../../../../components/FormFields/index';
import SelectAddressModal from '../../../../components/SelectAddressModal/index';
import { P2, StyledLink } from '../../../../components/Typography/index';
import { AddressForm } from '../../../../redux/reposit/reposit.actions';
import { YourCompanyFormValues, baseEmptyAddress } from '../index';
import FormErrorMessage from '../../../../components/FormFields/ErrorMessage/index';
import countries from 'i18n-iso-countries';
import Select from '../../../../components/FormFields/Select/index';

const AddressLookupWrapper = styled.div`
  @media screen and (min-width: ${getBreakpoint('lg')}) {
    display: flex;
    align-items: flex-end;
  }
`;

const Field = styled.div`
  flex: 0 0 260px;
  margin: 12px 20px 0 0;
`;

const Action = styled.div`
  padding: 14px 0 6px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    padding: 0 0 10px;
  }
`;

const ManualLink = styled(StyledLink)`
  display: inline;
  margin: 0 6px;
`;

const AddressFormContainer = styled.div`
  margin: 12px 0 0;
  max-width: 65%;
`;

const PostcodeForm = ({
  searchAddresses,
  isSearchAddressesLoading
}: {
  searchAddresses: (postcode: string) => void;
  isSearchAddressesLoading: boolean;
}) => {
  const [postcode, setPostcode] = useState('');
  const onSubmit = () => {
    searchAddresses(postcode);
  };
  return (
    <AddressLookupWrapper>
      <Field>
        <FieldWithLabel label="Postcode">
          <Input
            name="postcode"
            placeholder="e.g. EC1M 3HH"
            value={postcode}
            onChange={e => setPostcode(e.target.value)}
            onBlur={() => {}}
          />
        </FieldWithLabel>
      </Field>
      <Action>
        <Button disabled={!postcode} buttonType="secondary" type="button" onClick={onSubmit}>
          {isSearchAddressesLoading ? 'Loading' : 'Search Postcode'}
        </Button>
      </Action>
    </AddressLookupWrapper>
  );
};

const Link = styled(StyledLink)`
  font-size: 12px;
  margin: 0;
`;

const FormActions = styled.div`
  align-items: center;
  border-top: 1px solid ${props => props.theme.colors.dividerDark};
  display: flex;
  justify-content: flex-end;
  margin: 36px 0 0;
  padding: 22px 0 0;
`;

const PostcodeLinkWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 6px 0 0;
`;

interface AddressLookupYourCompanyProps {
  onCancel: () => void;
  currentOrgId: string;
  searchAddresses: (postcode: string) => void;
  isShowingSearchModal: boolean;
  searchedAddresses?: ExternalAddressDTO[];
  setShowingSearchModal: (value: boolean) => void;
  currentAddressForm: AddressForm;
  setCurrentAddressForm: (value: AddressForm) => void;
  isSearchAddressesLoading: boolean;
  createManualAddress: (address: CreateAddressWithOrgDTO) => void;
  showSaveFormAction: boolean;
  showCancelAddingAddressFromPostcode: boolean;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.ChangeEvent<any>) => void;
  values: YourCompanyFormValues;
  touched: FormikTouched<YourCompanyFormValues>;
  errors: FormikErrors<YourCompanyFormValues>;
  setAddress: (address: ExternalAddressDTO) => void;
  setFieldValue: (name: string, value: string) => void;
}

export interface AddressFormValues {
  buildingNumber: string;
  street: string;
  postcode: string;
  country: string;
  roomNumber?: string;
  flatNumber?: string;
  buildingName?: string;
  town?: string;
  county?: string;
}

const AddressManualEntry = ({
  showAddressForm,
  showSaveFormAction,
  values,
  touched,
  handleChange,
  handleBlur,
  errors,
  setFieldValue
}: {
  showAddressForm: (value: AddressForm) => void;
  createManualAddress: (address: CreateAddressWithOrgDTO) => void;
  currentOrgId: string;
  showSaveFormAction: boolean;
  values: YourCompanyFormValues;
  touched: FormikTouched<YourCompanyFormValues>;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.ChangeEvent<any>) => void;
  errors: FormikErrors<YourCompanyFormValues>;
  setFieldValue: (name: string, value: string) => void;
}) => {
  countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
  const countryList = countries.getNames('en');

  return (
    <div>
      <AddressFormContainer>
        <FieldWithLabel
          label="Room Number / Letter"
          touched={get(touched, 'address.roomNumber')}
          error={get(errors, 'address.roomNumber')}
        >
          <Input
            name="address.roomNumber"
            value={values.address.roomNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={get(touched, 'address.roomNumber')}
            error={get(errors, 'address.roomNumber')}
          />
        </FieldWithLabel>
        <FieldWithLabel
          label="Flat Number / Letter"
          touched={get(touched, 'address.flatNumber')}
          error={get(errors, 'address.flatNumber')}
        >
          <Input
            name="address.flatNumber"
            value={values.address.flatNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={get(touched, 'address.flatNumber')}
            error={get(errors, 'address.flatNumber')}
          />
        </FieldWithLabel>
        <FieldWithLabel
          label="Building Number*"
          touched={get(touched, 'address.buildingNumber')}
          error={get(errors, 'address.buildingNumber')}
        >
          <Input
            type="number"
            name="address.buildingNumber"
            value={values.address.buildingNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={get(touched, 'address.buildingNumber')}
            error={get(errors, 'address.buildingNumber')}
          />
        </FieldWithLabel>
        <FieldWithLabel
          label="Building Name*"
          touched={get(touched, 'address.buildingName')}
          error={get(errors, 'address.buildingName')}
        >
          <Input
            name="address.buildingName"
            value={values.address.buildingName}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={get(touched, 'address.buildingName')}
            error={get(errors, 'address.buildingName')}
          />
        </FieldWithLabel>
        <FieldWithLabel label="Street*" touched={get(touched, 'address.street')} error={get(errors, 'address.street')}>
          <Input
            name="address.street"
            value={values.address.street}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={get(touched, 'address.street')}
            error={get(errors, 'address.street')}
          />
        </FieldWithLabel>
        <FieldWithLabel label="Town" touched={get(touched, 'address.town')} error={get(errors, 'address.town')}>
          <Input
            name="address.town"
            value={values.address.town}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={get(touched, 'address.town')}
            error={get(errors, 'address.town')}
          />
        </FieldWithLabel>
        <FieldWithLabel label="County" touched={get(touched, 'address.county')} error={get(errors, 'address.county')}>
          <Input
            name="address.county"
            value={values.address.county}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={get(touched, 'address.county')}
            error={get(errors, 'address.county')}
          />
        </FieldWithLabel>
        <FieldWithLabel label="Postcode*" touched={get(touched, 'address.postcode')} error={get(errors, 'address.postcode')}>
          <Input
            name="address.postcode"
            value={values.address.postcode}
            onChange={handleChange}
            onBlur={handleBlur}
            touched={get(touched, 'address.postcode')}
            error={get(errors, 'address.postcode')}
          />
        </FieldWithLabel>
        <FieldWithLabel label="Country*" touched={get(touched, 'address.country')} error={get(errors, 'address.country')}>
          <Select
            name="address.country"
            value={countries.toAlpha2(values.address.country) || 0}
            onChange={(value: any) => setFieldValue('address.country', value)}
            defaultValue={0}
          >
            <option value="">-</option>
            {Object.keys(countryList).map(country => (
              <option key={country} value={country}>
                {countryList[country]}
              </option>
            ))}
          </Select>
        </FieldWithLabel>
      </AddressFormContainer>
      <FormActions>
        <Link to="#" onClick={() => showAddressForm(AddressForm.POSTCODE)} style={{ margin: '0 16px' }}>
          Cancel adding an address
        </Link>
        {showSaveFormAction ? <Button>Save address</Button> : null}
      </FormActions>
    </div>
  );
};

const Postcode = ({
  showAddressForm,
  searchAddresses,
  isSearchAddressesLoading,
  showCancelAddingAddressFromPostcode,
  errors,
  touched
}: {
  showAddressForm: (value: AddressForm) => void;
  searchAddresses: (postcode: string) => void;
  isSearchAddressesLoading: boolean;
  showCancelAddingAddressFromPostcode: boolean;
  errors: FormikErrors<YourCompanyFormValues>;
  touched: FormikTouched<YourCompanyFormValues>;
}) => {
  return (
    <Fragment>
      <PostcodeForm searchAddresses={searchAddresses} isSearchAddressesLoading={isSearchAddressesLoading} />
      <PostcodeLinkWrapper>
        <P2 style={{ marginTop: 0, fontSize: '1em', marginBottom: 0 }}>
          or
          <ManualLink to="#" onClick={() => showAddressForm(AddressForm.FULL_ADDRESS)}>
            enter address manually
          </ManualLink>
        </P2>
        {showCancelAddingAddressFromPostcode ? (
          <Link to="#" onClick={() => showAddressForm(AddressForm.ADDRESS_LIST)}>
            Cancel adding an address
          </Link>
        ) : null}
      </PostcodeLinkWrapper>
      {errors && errors.address && touched && touched.address ? <FormErrorMessage error="Address must be completed" /> : null}
    </Fragment>
  );
};

const AddressLookupYourCompany = (props: AddressLookupYourCompanyProps) => {
  const { setCurrentAddressForm, setShowingSearchModal, showCancelAddingAddressFromPostcode, setAddress, values } = props;

  useEffect(() => {
    if (!isEqual(values.address, baseEmptyAddress)) {
      setCurrentAddressForm(AddressForm.FULL_ADDRESS);
    }
    return function cleanup() {
      setCurrentAddressForm(AddressForm.ADDRESS_LIST);
    };
  }, [setCurrentAddressForm, values.address]);

  if (props.currentAddressForm === AddressForm.FULL_ADDRESS) {
    return <AddressManualEntry {...props} showAddressForm={props.setCurrentAddressForm} />;
  }

  if (props.currentAddressForm === AddressForm.POSTCODE) {
    const handleSetManualDefaultValues = (address: ExternalAddressDTO) => {
      setAddress(address);
      setCurrentAddressForm(AddressForm.FULL_ADDRESS);
      setShowingSearchModal(false);
    };
    return (
      <Fragment>
        {props.isShowingSearchModal && props.searchedAddresses && props.searchedAddresses.length ? (
          <SelectAddressModal
            addresses={props.searchedAddresses}
            onDismiss={() => props.setShowingSearchModal(false)}
            onSubmit={handleSetManualDefaultValues}
            onManualAddressSelect={() => {
              props.setShowingSearchModal(false);
              setCurrentAddressForm(AddressForm.FULL_ADDRESS);
            }}
          />
        ) : null}
        <Postcode
          showAddressForm={props.setCurrentAddressForm}
          searchAddresses={props.searchAddresses}
          isSearchAddressesLoading={props.isSearchAddressesLoading}
          showCancelAddingAddressFromPostcode={showCancelAddingAddressFromPostcode}
          errors={props.errors}
          touched={props.touched}
        />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <PostcodeForm searchAddresses={props.searchAddresses} isSearchAddressesLoading={props.isSearchAddressesLoading} />
      <P2 style={{ marginTop: 12, fontSize: '1em', marginBottom: 0 }}>
        or
        <ManualLink
          to="#"
          onClick={() => {
            props.setCurrentAddressForm(AddressForm.POSTCODE);
          }}
        >
          add new address
        </ManualLink>
      </P2>
    </Fragment>
  );
};

export default AddressLookupYourCompany;
