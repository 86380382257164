import { unwrapResult } from '@reduxjs/toolkit';
import React, { Fragment, useState } from 'react';
import { Container } from 'react-grid-system';
import styled, { css } from 'styled-components';
import { useAppDispatch } from '../../../index';
import { acceptProposalThunk, counterTenantProposalThunk } from '../../../redux/claim/claim.thunk';
import Button from '../../Button/index';
import Card from '../../Card/index';
import ConfirmationModal from '../../ConfirmationModal/index';
import { Amount } from '../../Library/SupplierOffer/ClaimItem/components';
import { Header3, Header4, P2 } from '../../Typography/index';
import { penceToPounds } from '../../../utils/common.utils';

const ButtonStyles = css`
  margin: 0 20px;
  width: 140px;
  justify-content: center;
`;

const DecisionHeader = styled(Header3)`
  text-align: center;
`;

const AcceptButton = styled(Button)`
  ${ButtonStyles}
  
  background-image: ${props => `linear-gradient(to bottom, ${props.theme.colors.positive}, #669966)`};
  &:hover {
    background-image: ${props => `linear-gradient(to bottom, ${props.theme.colors.positive}, ${props.theme.colors.positive})`};
  }
`;

const RejectButton = styled(Button)`
  ${ButtonStyles}
  background-image: linear-gradient(to bottom, ${props => props.theme.colors.negative}, #b01e1e);
    &:hover {
      background-image: linear-gradient(
        to bottom,
        ${props => props.theme.colors.negative},
        ${props => props.theme.colors.negative}
      );
    }
`;

const BoldText = styled(Header4)`
  font-weight: bold;
  display: inline;
`;

enum ModalType {
  ACCEPT = 'ACCEPT',
  REJECT = 'REJECT'
}

interface ClaimDecisionProps {
  proposalAmount: number;
  proposalId: string;
  claimId: string;
  isAgentDeciding?: boolean;
  hasGoneBack: boolean;
  setHasGoneBack: (val: boolean) => void;
  tenantProposingNonZeroAmount: boolean;
}

export const ClaimDecision: React.FC<ClaimDecisionProps> = ({
  proposalAmount,
  isAgentDeciding,
  proposalId,
  claimId,
  hasGoneBack,
  setHasGoneBack,
  tenantProposingNonZeroAmount
}) => {
  const dispatch = useAppDispatch();

  const [modal, setModal] = useState<ModalType | undefined>(undefined);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const modalTitle = modal === ModalType.ACCEPT ? 'Accept Proposed Settlement' : 'Reject Proposed Settlement';
  const modalText =
    modal === ModalType.ACCEPT ? (
      <Fragment>
        <P2>
          By confirming {tenantProposingNonZeroAmount ? `you wish to collect the payment` : `you accept the proposal`}, the case
          will be considered settled and closed.
        </P2>
        {tenantProposingNonZeroAmount ? (
          <P2>
            You will receive the funds (<BoldText>£{penceToPounds(proposalAmount)}</BoldText>) within 3-5 working days.
          </P2>
        ) : null}
      </Fragment>
    ) : (
      <Fragment>
        <P2>You will need to make a final offer to the tenant.</P2>
      </Fragment>
    );

  const renderCardText = () =>
    isAgentDeciding || hasGoneBack ? (
      <>
        {tenantProposingNonZeroAmount ? (
          <P2>By accepting the tenant’s proposal you will receive an immediate payout and bypass any unnecessary disputes.</P2>
        ) : null}
        <P2>
          If you do not wish to accept this proposal, you may click reject. You will then be able to provide a final settlement
          amount back to the tenant(s).
        </P2>
        <P2>
          If you are unable to reach an agreement with the tenant, the case could then be disputed and undergo alternate dispute
          resolution.
        </P2>
        <P2>
          {tenantProposingNonZeroAmount ? (
            <BoldText>
              Do you wish to collect the tenant's payment of{' '}
              <Amount style={{ fontWeight: 'bold', display: 'inline' }} amount={proposalAmount} />?
            </BoldText>
          ) : (
            <BoldText>Do you wish to accept the tenant's proposal?</BoldText>
          )}
        </P2>
      </>
    ) : (
      <P2>
        You have rejected the tenant's proposal and will need to respond to each claim item individually. Once completed, please
        submit this for the tenant to review. You may also change your decision and accept the tenant's proposal at any time.
      </P2>
    );

  const handleOnSubmit = async () => {
    let claim;
    try {
      setIsSubmitting(true);
      if (modal === ModalType.ACCEPT) {
        claim = await dispatch(acceptProposalThunk({ claimId, claimProposalId: proposalId })).then(unwrapResult);
      } else {
        claim = await dispatch(counterTenantProposalThunk({ claimId, claimProposalId: proposalId })).then(unwrapResult);
      }
      setIsSubmitting(false);
      setModal(undefined);
      return claim;
    } catch (e) {
      setIsSubmitting(false);
      throw e;
    }
  };

  return (
    <Container>
      {!isAgentDeciding && !hasGoneBack ? (
        <Button type="button" buttonType="secondary" reverse onClick={() => setHasGoneBack(true)}>
          Back
        </Button>
      ) : null}
      <Card>
        <>
          {!isAgentDeciding && !hasGoneBack ? <DecisionHeader>Please provide your final proposal</DecisionHeader> : <></>}
          {renderCardText()}
          {isAgentDeciding || hasGoneBack ? (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <AcceptButton onClick={() => setModal(ModalType.ACCEPT)}>
                {tenantProposingNonZeroAmount ? 'Collect' : 'Accept'}
              </AcceptButton>
              <RejectButton
                onClick={() => {
                  if (hasGoneBack) {
                    setHasGoneBack(false);
                  } else {
                    setModal(ModalType.REJECT);
                  }
                }}
                disabled={!isAgentDeciding && !hasGoneBack}
              >
                Reject
              </RejectButton>
            </div>
          ) : null}
        </>
      </Card>
      {!!modal ? (
        <ConfirmationModal
          title={modalTitle}
          text={modalText}
          onDismiss={() => setModal(undefined)}
          onSubmit={handleOnSubmit}
          isSubmitting={isSubmitting}
        />
      ) : null}
    </Container>
  );
};
