import React, { Fragment } from 'react';
import styled from 'styled-components';
import Button from '../../../../components/Button/index';
import Modal from '../../../../components/Modal/index';
import { Header3, P2 } from '../../../../components/Typography/index';
import { useAppDispatch } from '../../../../index';
import { verifyClaimEvidenceThunk } from '../../../../redux/claim/claim.thunk';
import { ClaimActionModalProps } from '../index';

const Action = styled.div`
  padding: 12px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

const ClaimUploadEvidenceModal: React.SFC<ClaimActionModalProps> = ({ claim, onDismiss }) => {
  const dispatch = useAppDispatch();
  const submitEvidence = async () => {
    await dispatch(verifyClaimEvidenceThunk({ claimId: claim.id }));

    onDismiss();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  let content = (
    <Fragment>
      <Header3 style={{ marginBottom: 6 }}>Finalise Claim Evidence</Header3>
      <P2 style={{ marginBottom: 24 }}>
        Are you sure you would like to finalise the evidence on this claim? You will not be able to upload any further evidence.
      </P2>
      <Action>
        <Button buttonType="secondary" noArrow={true} onClick={onDismiss}>
          Okay, return to form
        </Button>
        <Button onClick={submitEvidence}>Yes</Button>
      </Action>
    </Fragment>
  );

  return <Modal onDismiss={onDismiss}>{content}</Modal>;
};

export default ClaimUploadEvidenceModal;
