import { OrganizationWithRepositsAndRoles } from '@reposit/api-client';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Button from '../../../../components/Button/index';
import Loading from '../../../../components/Loading/index';
import Modal from '../../../../components/Modal/index';
import { Header3, P2 } from '../../../../components/Typography/index';
import { getCurrentOrganizationId } from '../../../../redux/auth/auth.selectors';
import { createLoadingSelector } from '../../../../redux/loading/loading.selector';
import { publishRepositRequested, PUBLISH_REPOSIT_STORE_KEY } from '../../../../redux/reposit/reposit.actions';
import { getOrganization } from '../../../../redux/selectors/organization.selectors';
import { RepositActionModalProps } from '../index';

const Action = styled.div`
  padding: 12px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

const RepositCreateModal: React.SFC<RepositActionModalProps> = ({ tenancyOrder, onDismiss, isPublishable }) => {
  const dispatch = useDispatch();

  const publishRepositLoadingSelector = createLoadingSelector([PUBLISH_REPOSIT_STORE_KEY]);
  const isLoading = useSelector(publishRepositLoadingSelector);
  const currentOrgId = useSelector(getCurrentOrganizationId);
  const org = useSelector(getOrganization(currentOrgId)) as OrganizationWithRepositsAndRoles;

  const publishReposit = () => dispatch(publishRepositRequested(tenancyOrder.tenancy.id));

  let content = (
    <Fragment>
      <Header3 style={{ marginBottom: 6 }}>This Reposit is not yet complete</Header3>
      <P2 style={{ marginBottom: 24 }}>Please complete all sections to proceed.</P2>
      <Action>
        <Button buttonType="secondary" noArrow={true} onClick={onDismiss}>
          Okay, return to form
        </Button>
      </Action>
    </Fragment>
  );

  const header = `Are you sure you wish to create this Reposit for ${org.name}?`;

  if (isPublishable) {
    content = (
      <Fragment>
        <Header3 style={{ marginBottom: 6 }}>{header}</Header3>
        <P2 style={{ marginBottom: 24 }}>
          All tenants will be invited to the platform complete their next steps. If the information you have entered is incorrect
          you may not be able to change it later.
        </P2>
        <Action>
          <Button buttonType="secondary" noArrow={true} onClick={onDismiss}>
            No, return to form
          </Button>
          <Button onClick={publishReposit}>Yes</Button>
        </Action>
      </Fragment>
    );
  }

  return <Modal onDismiss={onDismiss}>{isLoading ? <Loading /> : content}</Modal>;
};

export default RepositCreateModal;
