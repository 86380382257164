import { UserEntity } from '../../redux/entities/entities.types';

export const identifyHubspotUser = (user: UserEntity) => {
  (window as any).hsConversationsSettings = {
    identificationEmail: user.email,
    identificationToken: user.hubspotVisitorId || ''
  };
};

export const loadHubspotWidget = () => {
  (window as any).HubSpotConversations.widget.load();
};
