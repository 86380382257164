import { createAction } from '@reduxjs/toolkit';
import { UpdateUserAboutMeDTO, UpdateYourCompanyDTO, UpdateUserEmailSettingsDTO } from '@reposit/api-client';
import { AddressForm } from '../reposit/reposit.actions';
import { CreateNPS, UpdateNPSModal, DismissOrganizationMessage, DismissUserMessage } from './account.types';

export const FETCH_ME_STORE_KEY = 'ACCOUNT/FETCH_ME';
export const UPDATE_ABOUT_ME_STORE_KEY = 'ACCOUNT/UPDATE_ABOUT_ME';
export const UPDATE_YOUR_COMPANY_STORE_KEY = 'ACCOUNT/UPDATE_YOUR_COMPANY';
export const UPDATE_AST_ACCEPTANCE_STORE_KEY = 'ACCOUNT/UPDATE_AST_ACCEPTANCE';
export const UPDATE_INFORMED_LANDLORDS_STORE_KEY = 'ACCOUNT/UPDATE_INFORMED_LANDLORDS';
export const UPDATE_TERMS_ACCEPTED_STORE_KEY = 'ACCOUNT/UPDATE_TERMS_ACCEPTED';
export const UPDATE_BANK_DETAILS_CONFIRMED_STORE_KEY = 'ACCOUNT/UPDATE_BANK_DETAILS_CONFIRMED';
export const UPDATE_COMPLETED_ONBOARDING_STORE_KEY = 'ACCOUNT/UPDATE_COMPLETED_ONBOARDING';
export const UPDATE_WELCOME_LANDLORD_STORE_KEY = 'ACCOUNT/UPDATE_WELCOME_LANDLORD';
export const UPDATE_LANDLORD_EXTRA_INFORMATION_STORE_KEY = 'ACCOUNT/UPDATE_LANDLORD_EXTRA_INFORMATION';
export const SUBMIT_NPS_STORE_KEY = 'ACCOUNT/SUBMIT_NPS';
export const UPDATE_EOT_NOTIFICATIONS_STORE_KEY = 'ACCOUNT/UPDATE_EOT_NOTIFICATIONS';
export const HAS_REQUESTED_PASSWORD_RESET = 'AUTH/HAS_REQUESTED_PASSWORD_RESET';
export const HAS_RESET_PASSWORD = 'AUTH/HAS_RESET_PASSWORD';
export const IS_ONBOARDING = 'ACCOUNT/IS_ONBOARDING';

export const setAccountCurrentAddressForm = createAction<AddressForm>('ACCOUNT/SET_CURRENT_ADDRESS_FORM');

export const fetchMeRequested = createAction('ACCOUNT/FETCH_ME_API_REQUESTED');
export const fetchMeSuccess = createAction<{
  currentUserId: string;
  requiresNPSResponse: boolean;
}>('ACCOUNT/FETCH_ME_API_SUCCESS');
export const fetchMeFailed = createAction<string>('ACCOUNT/FETCH_ME_API_FAILED');

export const updateAboutMeRequested = createAction<UpdateUserAboutMeDTO>('ACCOUNT/UPDATE_ABOUT_ME_API_REQUESTED');
export const updateAboutMeSuccess = createAction('ACCOUNT/UPDATE_ABOUT_ME_API_SUCCESS');
export const updateAboutMeFailed = createAction<string>('ACCOUNT/UPDATE_ABOUT_ME_API_FAILED');

export const updateYourCompanyRequested = createAction<UpdateYourCompanyDTO>('ACCOUNT/UPDATE_YOUR_COMPANY_API_REQUESTED');
export const updateYourCompanySuccess = createAction('ACCOUNT/UPDATE_YOUR_COMPANY_API_SUCCESS');
export const updateYourCompanyFailed = createAction<string>('ACCOUNT/UPDATE_YOUR_COMPANY_API_FAILED');

export const updateASTAcceptanceRequested = createAction('ACCOUNT/UPDATE_AST_ACCEPTANCE_API_REQUESTED');
export const updateASTAcceptanceSuccess = createAction('ACCOUNT/UPDATE_AST_ACCEPTANCE_API_SUCCESS');
export const updateASTAcceptanceFailed = createAction<string>('ACCOUNT/UPDATE_AST_ACCEPTANCE_API_FAILED');

export const updateInformedLandlordsRequested = createAction('ACCOUNT/UPDATE_INFORMED_LANDLORDS_API_REQUESTED');
export const updateInformedLandlordsSuccess = createAction('ACCOUNT/UPDATE_INFORMED_LANDLORDS_API_SUCCESS');
export const updateInformedLandlordsFailed = createAction<string>('ACCOUNT/UPDATE_INFORMED_LANDLORDS_API_FAILED');

export const updateTermsAcceptedRequested = createAction('ACCOUNT/UPDATE_TERMS_ACCEPTED_API_REQUESTED');
export const updateTermsAcceptedSuccess = createAction('ACCOUNT/UPDATE_TERMS_ACCEPTED_API_SUCCESS');
export const updateTermsAcceptedFailed = createAction<string>('ACCOUNT/UPDATE_TERMS_ACCEPTED_API_FAILED');

export const updateBankAccountsConfirmedRequested = createAction('ACCOUNT/UPDATE_BANK_DETAILS_CONFIRMED_API_REQUESTED');
export const updateBankAccountsConfirmedSuccess = createAction('ACCOUNT/UPDATE_BANK_DETAILS_CONFIRMED_API_SUCCESS');
export const updateBankAccountsConfirmedFailed = createAction<string>('ACCOUNT/UPDATE_BANK_DETAILS_CONFIRMED_API_FAILED');

export const updateWelcomeLandlordRequested = createAction('ACCOUNT/UPDATE_WELCOME_LANDLORD_API_REQUESTED');
export const updateWelcomeLandlordSuccess = createAction('ACCOUNT/UPDATE_WELCOME_LANDLORD_API_SUCCESS');
export const updateWelcomeLandlordFailed = createAction<string>('ACCOUNT/UPDATE_WELCOME_LANDLORD_API_FAILED');

export const updateLandlordExtraInformationRequested = createAction('ACCOUNT/UPDATE_LANDLORD_EXTRA_INFORMATION_API_REQUESTED');
export const updateLandlordExtraInformationSuccess = createAction('ACCOUNT/UPDATE_LANDLORD_EXTRA_INFORMATION_API_SUCCESS');
export const updateLandlordExtraInformationFailed = createAction<string>('ACCOUNT/UPDATE_LANDLORD_EXTRA_INFORMATION_API_FAILED');

export const updateNPSModal = createAction<UpdateNPSModal>('ACCOUNT/UPDATE_NPS_MODAL');

export const submitNPSRequested = createAction<CreateNPS>('ACCOUNT/SUBMIT_NPS_REQUESTED');
export const submitNPSSuccess = createAction('ACCOUNT/SUBMIT_NPS_SUCCESS');
export const submitNPSFailed = createAction<string>('ACCOUNT/SUBMIT_NPS_FAILED');

export const updateEndOfTenancyEmailsRequested = createAction<{ enabled: boolean }>('ACCOUNT/UPDATE_EOT_NOTIFICATIONS_REQUESTED');
export const updateEndOfTenancyEmailsSuccess = createAction<UpdateUserEmailSettingsDTO>(
  'ACCOUNT/UPDATE_EOT_NOTIFICATIONS_SUCCESS'
);
export const updateEndOfTenancyEmailsFailed = createAction<UpdateUserEmailSettingsDTO>('ACCOUNT/UPDATE_EOT_NOTIFICATIONS_FAILED');

export const updateProductEmailsRequested = createAction<{ enabled: boolean }>('ACCOUNT/UPDATE_PRODUCT_EMAILS_REQUESTED');
export const updateProductEmailsSuccess = createAction<UpdateUserEmailSettingsDTO>('ACCOUNT/UPDATE_PRODUCT_EMAILS_SUCCESS');
export const updateProductEmailsFailed = createAction<UpdateUserEmailSettingsDTO>('ACCOUNT/UPDATE_PRODUCT_EMAILS_FAILED');

export const dismissOrganizationMessageRequested = createAction<DismissOrganizationMessage>(
  'ACCOUNT/DISMISS_ORGANIZATION_MESSAGE_REQUESTED'
);
export const dismissOrganizationMessageSuccess = createAction('ACCOUNT/DISMISS_ORGANIZATION_MESSAGE_SUCCESS');
export const dismissOrganizationMessageFailed = createAction<string>('ACCOUNT/DISMISS_ORGANIZATION_MESSAGE_FAILED');

export const dismissUserMessageRequested = createAction<DismissUserMessage>('ACCOUNT/DISMISS_USER_MESSAGE_REQUESTED');
export const dismissUserMessageSuccess = createAction('ACCOUNT/DISMISS_USER_MESSAGE_SUCCESS');
export const dismissUserMessageFailed = createAction<string>('ACCOUNT/DISMISS_USER_MESSAGE_FAILED');

export const setHasRequestedPasswordReset = createAction<boolean>(HAS_REQUESTED_PASSWORD_RESET);
export const setIsOnboarding = createAction<boolean>(IS_ONBOARDING);
