import { OrganizationRecipientDTOGroupEnum, OrganizationWithRepositsAndRoles } from '@reposit/api-client';
import { get } from 'lodash';
import { denormalize } from 'normalizr';
import { getCurrentOrganizationId } from '../auth/auth.selectors';
import { AppState } from '../root.reducer';
import SCHEMA from '../schema';
import { OrganizationRecipientEntity } from '../entities/entities.types';

export const getOrganization = (id: string) => (state: AppState): OrganizationWithRepositsAndRoles | undefined => {
  if (!state.entities.organization) return undefined;
  return denormalize(state.entities.organization[id], SCHEMA.organization, state.entities);
};

export const getShowBankAccountsWarning = (state: AppState): boolean => {
  const currentOrgId = getCurrentOrganizationId(state);
  const currentOrg = getOrganization(currentOrgId)(state);
  return get(currentOrg, 'attributes.messages.warningBankAccounts', false);
};

export const getShowBankAccountsFeature = (state: AppState): boolean => {
  const currentOrgId = getCurrentOrganizationId(state);
  const currentOrg = getOrganization(currentOrgId)(state);
  return get(currentOrg, 'attributes.messages.featureBankAccounts', false);
};

export const getIsYourCompanyComplete = (state: AppState): boolean => {
  const currentOrgId = getCurrentOrganizationId(state);
  const currentOrg = getOrganization(currentOrgId)(state);
  return get(currentOrg, 'attributes.yourCompanyComplete', false);
};

export const getIsWelcomeLandlordCompleted = (state: AppState): boolean => {
  const currentOrgId = getCurrentOrganizationId(state);
  const currentOrg = getOrganization(currentOrgId)(state);
  return get(currentOrg, 'attributes.welcomeLandlordCompleted', false);
};

export const getIsLandlordInformationCompleted = (state: AppState): boolean => {
  const currentOrgId = getCurrentOrganizationId(state);
  const currentOrg = getOrganization(currentOrgId)(state);
  return get(currentOrg, 'attributes.landlordInformationCompleted', false);
};

export const getIsUpdatedASTComplete = (state: AppState): boolean => {
  const currentOrgId = getCurrentOrganizationId(state);
  const currentOrg = getOrganization(currentOrgId)(state);
  return get(currentOrg, 'attributes.updatedAST', false);
};

export const getIsAcceptedTermsComplete = (state: AppState): boolean => {
  const currentOrgId = getCurrentOrganizationId(state);
  const currentOrg = getOrganization(currentOrgId)(state);
  return get(currentOrg, 'attributes.acceptedTerms', false);
};

export const getIsCompletedOnboardingComplete = (state: AppState): boolean => {
  const currentOrgId = getCurrentOrganizationId(state);
  const currentOrg = getOrganization(currentOrgId)(state);
  return get(currentOrg, 'attributes.completedOnboarding', false);
};

export const getIsBankAccountsConfirmed = (state: AppState): boolean => {
  const currentOrgId = getCurrentOrganizationId(state);
  const currentOrg = getOrganization(currentOrgId)(state);
  return get(currentOrg, 'attributes.bankAccountsConfirmed', false);
};
export const getIsInformedLandlordsComplete = (state: AppState): boolean => {
  const currentOrgId = getCurrentOrganizationId(state);
  const currentOrg = getOrganization(currentOrgId)(state);
  return get(currentOrg, 'attributes.informedLandlords', false);
};

export const getOrganizations = (ids: string[]) => (state: AppState): unknown[] => {
  return ids.map(id => state.entities.organization[id]);
};

export const getAllOrganizationRecipients = (organizationId: string) => (state: AppState) => {
  const organization = state.entities.organization[organizationId];
  const organizationRecipients = organization.organizationRecipients;
  return organizationRecipients;
};

export const getOrganizationRecipientsByGroup = (group?: OrganizationRecipientDTOGroupEnum) => (state: AppState) => {
  const recipients = state.recipients;
  const orgRecips = recipients.organizationRecipients.map(r => state.entities.organizationRecipient[r]);
  return orgRecips.filter(r => (group ? r.group === group : true));
};

export const getOrganizationRecipientsById = (id: string) => (state: AppState): OrganizationRecipientEntity => {
  return state.entities.organizationRecipient[id];
};
