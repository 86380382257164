import { CreateAddressWithOrgDTO, CreateLandlordWithOrgDTO } from '@reposit/api-client';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Address from '../../../components/Address/index';
import Landlord, { LandlordFormValues } from '../../../components/Landlord/index';
import Loading from '../../../components/Loading/index';
import { useIsOrganizationLandlord } from '../../../hooks/useIsLandlord';
import {
  fetchOrganizationAddressesRequested,
  FETCH_ORGANIZATION_ADDRESSES_STORE_KEY,
  searchAddressesRequested,
  SEARCH_ADDRESSES_STORE_KEY,
  setIsShowingSearchModal
} from '../../../redux/address/address.actions';
import {
  getCurrentOrganizationAddresses,
  getHaveCurrentOrganizationAddressesBeenFetched,
  getIsShowingSearchModal,
  getSearchedAddresses
} from '../../../redux/address/address.selectors';
import { getCurrentOrganizationId } from '../../../redux/auth/auth.selectors';
import { AddressEntity } from '../../../redux/entities/entities.types';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import {
  AddressForm,
  createAddressForRepositRequested,
  createTenancyWithPropertyRequested,
  setIsUpdatePropertyModalOpen,
  setRepositCurrentAddressForm,
  setSelectedAddressId,
  updatePropertyRequested
} from '../../../redux/reposit/reposit.actions';
import {
  getCurrentAddressForm,
  getIsUpdatePropertyModalOpen,
  getSelectedAddressId
} from '../../../redux/reposit/reposit.selectors';
import { getAddressesFromIds } from '../../../redux/selectors/index';
import { getCurrentUser } from '../../../redux/selectors/user.selectors';
import CreatePropertyModal from './CreatePropertyModal';

interface CreatePropertyProps {
  showForm: (bool: boolean) => void;
  addresses?: (AddressEntity)[];
  tenancyId: string;
  isNew: boolean;
}

const CreatePropertyContainer = ({ showForm, tenancyId, isNew }: CreatePropertyProps) => {
  const [landlordValues, setLandlordValues] = useState<LandlordFormValues>();
  const dispatch = useDispatch();
  const isLoadingSelector = createLoadingSelector([FETCH_ORGANIZATION_ADDRESSES_STORE_KEY]);
  const currentOrgId = useSelector(getCurrentOrganizationId);
  const currentUser = useSelector(getCurrentUser);

  const fetchOrganizationAddresses = useCallback(() => dispatch(fetchOrganizationAddressesRequested()), [dispatch]);
  const searchAddresses = useCallback((postcode: string) => dispatch(searchAddressesRequested(postcode)), [dispatch]);
  const selectAddressId = useCallback((id?: string) => dispatch(setSelectedAddressId(id)), [dispatch]);

  const createManualAddress = useCallback(
    (address: CreateAddressWithOrgDTO) => {
      dispatch(createAddressForRepositRequested(address));
    },
    [dispatch]
  );

  const setShowingSearchModal = useCallback(
    (value: boolean) => {
      dispatch(setIsShowingSearchModal(value));
    },
    [dispatch]
  );

  const handleSetCurrentAddressForm = useCallback(
    (value: AddressForm) => {
      dispatch(setRepositCurrentAddressForm(value));
    },
    [dispatch]
  );

  const showCreateModal = useCallback(
    (value: boolean) => {
      dispatch(setIsUpdatePropertyModalOpen(value));
    },
    [dispatch]
  );

  const searchAddressessSelector = createLoadingSelector([SEARCH_ADDRESSES_STORE_KEY]);

  const currentOrganizationAddressIds = useSelector(getCurrentOrganizationAddresses) || [];
  const currentOrganizationAddresses = useSelector(getAddressesFromIds(currentOrganizationAddressIds));
  const isAddressLoading = useSelector(isLoadingSelector);
  const hasAddressFetched = useSelector(getHaveCurrentOrganizationAddressesBeenFetched);
  const isShowingSearchModal = useSelector(getIsShowingSearchModal);
  const searchedAddresses = useSelector(getSearchedAddresses);
  const addressId = useSelector(getSelectedAddressId);
  const currentAddressForm = useSelector(getCurrentAddressForm);
  const isSearchAddressesLoading = useSelector(searchAddressessSelector);
  const isShowingCreateModal = useSelector(getIsUpdatePropertyModalOpen);
  const currentOrganizationId = useSelector(getCurrentOrganizationId);

  const shouldShowAddressList = !isAddressLoading && hasAddressFetched;

  const canEditLandlord = !!addressId;
  const isOrganizationLandlord = useIsOrganizationLandlord();

  const landlordRef = useRef<HTMLDivElement | null>(null);

  const onSubmit = () => {
    if (!landlordValues) return;
    const { landlordTypeId, firstName, lastName, email, companyName } = landlordValues;
    const landlord: CreateLandlordWithOrgDTO =
      landlordTypeId === 'SOLE_PERSON'
        ? {
            firstName,
            lastName,
            email,
            landlordTypeId,
            organizationId: currentOrgId
          }
        : {
            companyName,
            email,
            landlordTypeId,
            organizationId: currentOrgId
          };
    const property = {
      addressId,
      landlord,
      organizationId: currentOrgId
    };

    if (isNew) {
      dispatch(
        createTenancyWithPropertyRequested({
          order: {
            productId: 'REPOSIT'
          },
          organizationId: currentOrganizationId,
          property
        })
      );
    } else {
      dispatch(
        updatePropertyRequested({
          tenancyId,
          property
        })
      );
    }
  };

  useEffect(() => {
    return function cleanup() {
      selectAddressId('');
    };
  }, [selectAddressId]);

  useEffect(() => {
    if (currentAddressForm === AddressForm.ADDRESS_LIST) {
      fetchOrganizationAddresses();
    }
  }, [fetchOrganizationAddresses, currentAddressForm, currentOrgId]);

  if (!shouldShowAddressList) {
    return <Loading />;
  }

  return (
    <Fragment>
      {isShowingCreateModal && <CreatePropertyModal onDismiss={() => showCreateModal(false)} onSubmit={onSubmit} />}
      <Address
        fetchOrganizationAddresses={fetchOrganizationAddresses}
        onCancel={() => showForm(false)}
        currentOrgId={currentOrgId}
        currentOrganizationAddresses={currentOrganizationAddresses}
        shouldShowAddressList={shouldShowAddressList}
        selectAddressId={selectAddressId}
        addressId={addressId}
        searchAddresses={searchAddresses}
        isShowingSearchModal={isShowingSearchModal}
        searchedAddresses={searchedAddresses}
        setShowingSearchModal={setShowingSearchModal}
        currentAddressForm={currentAddressForm}
        setCurrentAddressForm={handleSetCurrentAddressForm}
        isSearchAddressesLoading={isSearchAddressesLoading}
        createManualAddress={createManualAddress}
        landlordRef={landlordRef}
      />
      <Landlord
        isOrganizationLandlord={isOrganizationLandlord}
        currentUser={currentUser}
        enabled={canEditLandlord}
        onSubmit={(values: LandlordFormValues) => {
          setLandlordValues(values);
          showCreateModal(true);
        }}
        landlordRef={landlordRef}
      />
    </Fragment>
  );
};

export default CreatePropertyContainer;
