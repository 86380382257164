import React, { useState } from 'react';
import styled from 'styled-components';
import HelpIcon from '../../../assets/svg/help.svg';
import { DocumentEntity } from '../../../redux/entities/entities.types';
import { RoundButton } from '../../Button/index';
import { Header3 } from '../../Typography/index';
import { FileUpload, FileUploadProgress } from './FileUpload';

const HeaderIcon = styled.i<{ icon: string }>`
  background: url(${props => props.icon}) no-repeat center center;
  background-size: 26px;
  border-radius: 48px;
  display: block;
  height: 48px;
  margin-left: -10px;
  width: 48px;
`;

const Container = styled.div<{ actionRequired: boolean }>`
  background: ${props => (props.actionRequired ? 'rgba(255,90,31,0.1)' : props.theme.colors.bgAccent)};
  border: ${props => (props.actionRequired ? '1px solid #ff5a1f' : 'none')};
  /* width: 260px; */
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
`;

const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
`;

interface FileUploadProps {
  required: boolean;
  title: string;
  icon: string;
  tooltip: string;
  documents: DocumentEntity[];
  uploadFile: (file: File) => Promise<DocumentEntity>;
  deleteFile: (id: string) => Promise<void>;
  onError: (error: string) => void;
  readOnly?: boolean;
  onUploadComplete?: () => void;
  showDeleteButton: boolean;
}

export const FileUploadCard: React.SFC<FileUploadProps> = ({
  title,
  icon,
  tooltip,
  documents,
  uploadFile,
  deleteFile,
  onError,
  required,
  readOnly,
  onUploadComplete,
  showDeleteButton
}) => {
  const [fileUploadProgress, setFileUploadProgress] = useState<FileUploadProgress | undefined>();

  return (
    <Container actionRequired={required && !documents.length}>
      <InnerContainer>
        <Header>
          <HeaderIcon icon={icon} />
          <Header3 style={{ marginBottom: 0, fontSize: '15px' }}>
            {title} {!readOnly && required ? '*' : ''}
          </Header3>
        </Header>
        {!readOnly ? (
          <RoundButton style={{ backgroundSize: 6, height: 20, width: 20 }} icon={HelpIcon} data-tip={tooltip} />
        ) : null}
      </InnerContainer>
      <FileUpload
        documents={documents}
        uploadFile={uploadFile}
        deleteFile={deleteFile}
        onError={onError}
        readOnly={readOnly}
        onUploadComplete={onUploadComplete}
        required={required}
        rounded
        showDeleteButton={showDeleteButton}
        fileUploadProgress={fileUploadProgress}
        setFileUploadProgress={setFileUploadProgress}
      />
    </Container>
  );
};
