import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import { PersistConfig, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import accountReducer from './account/account.reducer';
import activeFormReducer from './active-form/active-form.reducer';
import addressReducer from './address/address.reducer';
import { LOGOUT } from './auth/auth.actions';
import authReducer from './auth/auth.reducer';
import claimItemReducer from './claim-item/claim-item.reducer';
import claimListReducer from './claim-list/claim-list.reducer';
import claimReducer from './claim/claim.reducer';
import documentReducer from './document/document.reducer';
import enititiesReducer from './entities/entities.reducer';
import errorReducer from './error/error.reducer';
import feedbackReducer from './feedback/feedback.reducer';
import flashMessagesReducer from './flash-messages/flash-messages.reducer';
import inviteReducer from './invite/invite.reducer';
import loadingReducer from './loading/loading.reducer';
import recipientReducer from './organization-recipient/organization-recipient.reducer';
import propertiesReducer from './property/property.reducer';
import repositListReducer from './reposit-list/reposit-list.reducer';
import repositReducer from './reposit/reposit.reducer';
import systemReducer from './system/system.reducer';
import userReducer from './user/user.reducer';
import bankAccountsReducer from './bank-account/bank-account.reducers';
import guarantorReducer from './guarantor/guarantor.reducer';

const appReducer = (history: History<any>) =>
  combineReducers({
    account: accountReducer,
    address: addressReducer,
    claim: claimReducer,
    claimItem: claimItemReducer,
    claimList: claimListReducer,
    auth: authReducer,
    entities: enititiesReducer,
    loading: loadingReducer,
    error: errorReducer,
    flashMessages: flashMessagesReducer,
    user: userReducer,
    invite: inviteReducer,
    properties: propertiesReducer,
    reposit: repositReducer,
    repositList: repositListReducer,
    system: systemReducer,
    router: connectRouter(history),
    document: documentReducer,
    activeForms: activeFormReducer,
    feedback: feedbackReducer,
    recipients: recipientReducer,
    bankAccounts: bankAccountsReducer,
    guarantor: guarantorReducer
  });

export const rootReducer = (history: History, state: any, action: any) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }
  return appReducer(history)(state, action);
};
export type AppState = ReturnType<typeof rootReducer>;

const wrappedWithHistoryReducer = (history: History) => (state: any, action: any) => rootReducer(history, state, action);

const persistConfig: PersistConfig<AppState> = {
  key: 'root',
  storage,
  whitelist: ['auth']
};

export const getPersistedReducer = (history: History) => persistReducer(persistConfig, wrappedWithHistoryReducer(history));
