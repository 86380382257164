import React from 'react';
import { MenuItemDefinition } from '..';
import { NavItem, NavButton, Navigation } from '.';

interface MenuItemProps {
  item: MenuItemDefinition;
  isExpanded: boolean;
  setExpandedItem: (id: string) => void;
}

const MenuItem: React.FC<MenuItemProps> = ({ item, isExpanded, setExpandedItem }) => {
  const hasSubmenu = item.items && item.items.length > 0;

  if (!hasSubmenu) {
    return (
      <NavItem key={item.location}>
        <NavButton to={item.location} onClick={() => setExpandedItem(``)}>
          {item.label}
        </NavButton>
      </NavItem>
    );
  }

  return (
    <NavItem key={item.location}>
      <NavButton as="div" to="#" onClick={() => setExpandedItem(isExpanded ? `` : item.id)} subItem isOpen={isExpanded}>
        {item.label}
      </NavButton>
      <Navigation sub isOpen={isExpanded}>
        {item.items &&
          item.items.map(subItem => (
            <NavItem key={subItem.location} sub>
              <NavButton to={subItem.location} exact>
                {subItem.label}
              </NavButton>
            </NavItem>
          ))}
      </Navigation>
    </NavItem>
  );
};

export default MenuItem;
