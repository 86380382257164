export const FETCH_ORGANIZATION_USERS_STORE_KEY = 'USER/FETCH_ORGANIZATION_USERS';
export const FETCH_ORGANIZATION_USERS_API_REQUESTED = `USER/FETCH_ORGANIZATION_USERS_API_REQUESTED`;
export const FETCH_ORGANIZATION_USERS_API_SUCCESS = 'USER/FETCH_ORGANIZATION_USERS_API_SUCCESS';
export const FETCH_ORGANIZATION_USERS_API_FAILED = 'USER/FETCH_ORGANIZATION_USERS_API_FAILED';

export function fetchOrganizationUsersRequested() {
  return {
    type: FETCH_ORGANIZATION_USERS_API_REQUESTED
  } as const;
}

export function fetchOrganizationUsersSuccess(payload: string[]) {
  return {
    type: FETCH_ORGANIZATION_USERS_API_SUCCESS,
    payload
  } as const;
}

export function fetchOrganizationUsersFailed(error: string) {
  return {
    type: FETCH_ORGANIZATION_USERS_API_FAILED,
    payload: error
  } as const;
}

export type UserActionTypes = ReturnType<
  typeof fetchOrganizationUsersRequested | typeof fetchOrganizationUsersSuccess | typeof fetchOrganizationUsersFailed
>;
