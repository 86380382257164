import { filter } from 'lodash';
import { ClaimItemEntity, DocumentEntity } from '../entities/entities.types';
import { AppState } from '../root.reducer';

interface GetClaimItemsByClaimId {
  claimId?: string;
}

export const getClaimItemById = (state: AppState, id: string): ClaimItemEntity | undefined => {
  if (!state.entities.claimItem) return undefined;
  return state.entities.claimItem[id];
};

export const getClaimItemsByClaimId = (state: AppState, { claimId }: GetClaimItemsByClaimId): ClaimItemEntity[] => {
  if (!state.entities.claimItem) return [];

  if (!claimId) return [];

  return filter(state.entities.claimItem, item => item.claimId === claimId);
};

export const getDocumentsForClaimItem = (state: AppState, id: string): DocumentEntity[] => {
  const claimItem = getClaimItemById(state, id);
  if (!claimItem) return [];
  const claimItemDocIds = claimItem.claimItemDocuments;
  const claimItemDocs = claimItemDocIds.map(id => state.entities.claimItemDocument[id]);
  return claimItemDocs.map(itemDoc => state.entities.document[itemDoc.document]);
};
