import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Modal from '../../../../components/Modal/index';
import { Header3, P2 } from '../../../../components/Typography/index';
import Button from '../../../../components/Button/index';
import { createLoadingSelector } from '../../../../redux/loading/loading.selector';
import Loading from '../../../../components/Loading/index';
import { IMPROVE_CLAIM_STORE_KEY } from '../../../../redux/claim/claim.actions';

const Action = styled.div`
  padding: 12px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

interface ActionRequiredActionModalProps {
  onSubmit: () => void;
  onDismiss: (showModal: boolean) => void;
}

const ImproveClaimModal: React.SFC<ActionRequiredActionModalProps> = ({ onSubmit, onDismiss }) => {
  const improveClaimLoadingSelector = createLoadingSelector([IMPROVE_CLAIM_STORE_KEY]);
  const isLoading = useSelector(improveClaimLoadingSelector);

  let content = (
    <Fragment>
      <Header3 style={{ marginBottom: 6 }}>Are you sure you wish to improve this claim?</Header3>
      <P2 style={{ marginBottom: 24 }}>
        Once submitted your claim will be reviewed by our claims team. To ensure a smooth process please ensure you've included
        sufficient evidence to support your claim.
      </P2>
      <Action>
        <Button buttonType="secondary" noArrow={true} onClick={() => onDismiss(false)}>
          No, return to form
        </Button>
        <Button onClick={onSubmit}>Yes</Button>
      </Action>
    </Fragment>
  );

  return <Modal onDismiss={() => onDismiss(false)}>{isLoading ? <Loading /> : content}</Modal>;
};

export default ImproveClaimModal;
