import { OrganizationWithRepositsAndRoles } from '@reposit/api-client';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import DownArrow from '../../assets/svg/down-purple.svg';
import HelpIcon from '../../assets/svg/help.svg';
import TenantsIcon from '../../assets/svg/tenants.svg';
import { AppSubHeader } from '../../components/AppSubHeader/index';
import Button, { RoundButton } from '../../components/Button/index';
import Card from '../../components/Card/index';
import { ContentContainer } from '../../components/Common/index';
import { Header2, Header3 } from '../../components/Typography/index';
import useLogout from '../../hooks/useLogout';
import { setCurrentOrganizationRoles, setOrganizationId } from '../../redux/auth/auth.actions';
import { UserEntity } from '../../redux/entities/entities.types';
import { getOrganizations } from '../../redux/selectors/organization.selectors';
import { getCurrentUser } from '../../redux/selectors/user.selectors';

const StyledContainer = styled.div`
  padding-top: 100px;
`;
const StyledSelect = styled.div`
  overflow: hidden;
  background: #fff;
  transition: all 0.2s;
  position: relative;

  &:after {
        background: transparent url('${DownArrow}') 1px -1px;
        background-size:10px;
        content: "";
        display: "block";
        position: absolute;
        pointer-events: none;
        top: 19px;
        right: 24px;
        height: 10px;
        width: 10px;
      } 

      &:hover {
        background: ${props => props.theme.colors.bgAccent};
      } 
`;

const Selector = styled.select`
  background: transparent;
  border: 1px solid ${props => props.theme.colors.dividerLight};
  color: ${props => props.theme.colors.primaryBrand};
  cursor: pointer;
  display: block;
  font-family: ${props => props.theme.typography.face.primary};
  font-size: 14px;
  font-weight: bold;
  padding: 14px 24px;
  outline: none;
  height: 48px;
  width: 100%;
  -webkit-appearance: none;

  &::-ms-expand {
    display: none;
  }

  &:disabled {
    cursor: default;
  }
`;

const Action = styled.div`
  padding: 30px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

const PeopleImage = styled.img`
  width: 26px;
  margin-bottom: 1rem;
  margin-right: 20px;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const TitleInner = styled.div`
  display: flex;
  align-items: center;
`;

const TooltipButton = styled(RoundButton)`
  margin-bottom: 1rem;
`;

export const OrganizationSelection: React.FC = props => {
  const dispatch = useDispatch();
  const handleLogout = useLogout();
  const currentUser = useSelector(getCurrentUser) as UserEntity;
  const organizations = useSelector(getOrganizations(currentUser.organizations)) as OrganizationWithRepositsAndRoles[];
  const [orgId, setOrgId] = useState<string>('');

  const handleSubmit = () => {
    const org = organizations.find(uo => uo.id === orgId) as OrganizationWithRepositsAndRoles;
    dispatch(setCurrentOrganizationRoles(org.roles));
    dispatch(setOrganizationId(orgId));
    dispatch(push('/'));
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <StyledContainer>
      <AppSubHeader userEmail={currentUser.email} onLogout={handleLogout} />
      <Container>
        <Row>
          <Col sm={12}>
            <Header2>Account Selection</Header2>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg={12}>
            <ContentContainer>
              <Card>
                <Title>
                  <TitleInner>
                    <PeopleImage src={TenantsIcon} />
                    <Header3>My Accounts</Header3>
                  </TitleInner>
                  <TitleInner>
                    <TooltipButton
                      icon={HelpIcon}
                      data-tip={
                        'After choosing your account, all actions taken will affect the selected Reposit account only. You may choose another account at any time after login through the account selector in the side bar.'
                      }
                    />
                  </TitleInner>
                </Title>
                <StyledSelect>
                  <Selector value={orgId} onChange={e => setOrgId(e.currentTarget.value)}>
                    <option value="">Please select an account</option>
                    {organizations.map(o => (
                      <option value={o.id} key={o.id}>
                        {o.name}
                      </option>
                    ))}
                  </Selector>
                </StyledSelect>
                <Action>
                  <Button disabled={!orgId} onClick={handleSubmit}>
                    Submit
                  </Button>
                </Action>
              </Card>
            </ContentContainer>
          </Col>
        </Row>
      </Container>
    </StyledContainer>
  );
};
