import { RepositFilter, RepositSort } from '../../constants/reposit';
import {
  RepositListActionTypes,
  FETCH_REPOSITS_API_SUCCESS,
  REPOSITS_GOTO_PAGE,
  REPOSITS_UPDATE_FILTERS
} from './reposit-list.actions';
import { REPOSIT_LIST_PAGE_SIZE } from './reposit-list.saga';
import { RepositListState } from './reposit-list.types';

const initialState: RepositListState = {
  results: [],
  count: 0,
  page: 1,
  filters: {
    query: '',
    filter: RepositFilter.ALL,
    sort: RepositSort.CREATION_DATE,
    tenancyStatus: ''
  },
  lastPage: undefined
};

export default (state = initialState, action: RepositListActionTypes): RepositListState => {
  switch (action.type) {
    case FETCH_REPOSITS_API_SUCCESS:
      const last = Math.ceil(action.payload.count / REPOSIT_LIST_PAGE_SIZE);

      return {
        ...state,
        count: action.payload.count,
        lastPage: last,
        results: [...action.payload.ids]
      };

    case REPOSITS_GOTO_PAGE:
      const { count, page } = state;
      const lastPage = Math.ceil(count / REPOSIT_LIST_PAGE_SIZE);
      let p = action.payload;

      if (p === 'prev') p = page - 1;
      if (p === 'next') p = page + 1;
      if (p >= lastPage) p = lastPage;
      if (p <= 0) p = 1;

      return {
        ...state,
        page: p
      };

    case REPOSITS_UPDATE_FILTERS:
      return {
        ...state,
        page: 1,
        filters: {
          ...state.filters,
          ...action.payload
        }
      };

    default:
      return state;
  }
};
