import { CreateOrganizationBankAccountDTOAccountTypeEnum, OrganizationBankAccountDTOAccountTypeEnum } from '@reposit/api-client';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Button from '../../../components/Button/index';
import { InnerWrapper } from '../../../components/Common/index';
import { FullPageLoader } from '../../../components/Loading/index';
import { Header1, P1 } from '../../../components/Typography/index';
import { useIsOrganizationLandlord } from '../../../hooks/useIsLandlord';
import {
  createBankAccountRequested,
  CREATE_BANK_ACCOUNT_STORE_KEY,
  DELETE_BANK_ACCOUNT_STORE_KEY,
  getBankAccountsRequested,
  GET_BANK_ACCOUNTS_STORE_KEY,
  setClaimAccountShowing
} from '../../../redux/bank-account/bank-account.actions';
import { getBankAccounts, getIsClaimAccountShowing } from '../../../redux/bank-account/bank-account.selectors';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import IndividualBankAccount from './IndividualBankAccount';

const Action = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  padding: 0 0 36px 15px;
`;

interface BankAccountViewProps {}

export interface BankAccountFormValues {
  accountName: string;
  sortCode: string;
  accountNumber: string;
}

const BankAccountView: React.FC<BankAccountViewProps> = () => {
  const dispatch = useDispatch();
  const bankAccounts = useSelector(getBankAccounts);
  const showClaimAccount = useSelector(getIsClaimAccountShowing);
  const isOrgLandlord = useIsOrganizationLandlord();

  useEffect(() => {
    dispatch(getBankAccountsRequested());
  }, [dispatch]);

  useEffect(() => {
    const hasClaimBankAccount = bankAccounts.some(ba => ba && ba.accountType === OrganizationBankAccountDTOAccountTypeEnum.CLAIM);
    if (hasClaimBankAccount && !showClaimAccount) {
      dispatch(setClaimAccountShowing(true));
    }
  }, [bankAccounts, dispatch, showClaimAccount]);

  const loadingSelector = createLoadingSelector([
    DELETE_BANK_ACCOUNT_STORE_KEY,
    CREATE_BANK_ACCOUNT_STORE_KEY,
    GET_BANK_ACCOUNTS_STORE_KEY
  ]);
  const isLoading = useSelector(loadingSelector);

  const addBankAccount = (values: BankAccountFormValues, accountType: OrganizationBankAccountDTOAccountTypeEnum) => {
    const parsedAccountType =
      accountType === OrganizationBankAccountDTOAccountTypeEnum.DEFAULT
        ? CreateOrganizationBankAccountDTOAccountTypeEnum.DEFAULT
        : CreateOrganizationBankAccountDTOAccountTypeEnum.CLAIM;
    dispatch(createBankAccountRequested({ ...values, accountType: parsedAccountType }));
  };

  const getTitle = (accountType: OrganizationBankAccountDTOAccountTypeEnum) => {
    return accountType === OrganizationBankAccountDTOAccountTypeEnum.DEFAULT
      ? 'Primary Bank Account'
      : 'Claim Bank Account (Optional)';
  };

  const getCopy = (accountType: OrganizationBankAccountDTOAccountTypeEnum, isOrgLandlord: boolean) => {
    if (accountType === OrganizationBankAccountDTOAccountTypeEnum.DEFAULT && bankAccounts.length < 2 && !isOrgLandlord) {
      return 'Bank account details for monthly commission and claim payments. If you require your claim payments to be paid into a different bank account, please add it below.';
    } else if (accountType === OrganizationBankAccountDTOAccountTypeEnum.DEFAULT && !isOrgLandlord) {
      return 'Bank account details for monthly commission payments.';
    } else {
      return 'Bank account details for claim payments.';
    }
  };

  return (
    <InnerWrapper>
      <Container>
        <Row>
          <Col sm={12}>
            <Header1>Bank Accounts</Header1>
            {!isOrgLandlord ? (
              <P1>View, add and remove bank account details used for monthly commission and claim payments.</P1>
            ) : null}
          </Col>
        </Row>
      </Container>
      {isLoading ? (
        <FullPageLoader />
      ) : (
        <Container>
          <IndividualBankAccount
            accountType={OrganizationBankAccountDTOAccountTypeEnum.DEFAULT}
            addBankAccount={addBankAccount}
            bankAccounts={bankAccounts}
            title={getTitle(OrganizationBankAccountDTOAccountTypeEnum.DEFAULT)}
            copy={getCopy(OrganizationBankAccountDTOAccountTypeEnum.DEFAULT, isOrgLandlord)}
          />
          {!isOrgLandlord ? (
            showClaimAccount ? (
              <IndividualBankAccount
                accountType={OrganizationBankAccountDTOAccountTypeEnum.CLAIM}
                addBankAccount={addBankAccount}
                bankAccounts={bankAccounts}
                title={getTitle(OrganizationBankAccountDTOAccountTypeEnum.CLAIM)}
                copy={getCopy(OrganizationBankAccountDTOAccountTypeEnum.CLAIM, isOrgLandlord)}
              />
            ) : (
              <Row>
                <Col lg={12} style={{ padding: 0 }}>
                  <Action>
                    <Button buttonType="secondary" onClick={() => dispatch(setClaimAccountShowing(true))}>
                      Add claim bank account
                    </Button>
                  </Action>
                </Col>
              </Row>
            )
          ) : null}
        </Container>
      )}
    </InnerWrapper>
  );
};

export default BankAccountView;
