import React from 'react';
import styled from 'styled-components';
import WarningIcon from '../../assets/svg/warning.svg';
import TickIcon from '../../assets/svg/tick-white.svg';
import { getBreakpoint } from '../../base/style';
import { TenantCompletionStatus } from '../../redux/selectors/tenant.selectors';

interface StatusItemProps {
  label: string;
  status: string;
  tooltip?: string;
}

const Status = styled.div<StatusItemProps>`
  align-items: center;
  background: ${props =>
    props.status === TenantCompletionStatus.DONE ? props.theme.colors.positive : props.theme.colors.warning};
  border-right: 1px solid #fff;
  box-sizing: border-box;
  color: #fff;
  display: flex;
  font-family: ${props => props.theme.typography.face.secondary};
  font-weight: 700;
  font-size: 12px;
  height: 24px;
  margin: 0 0 1px;
  padding: 0 10px;
  width: 100%;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    margin: 0;
  }

  span {
    margin: 0 8px 0 0;
  }

  &:after {
    background: transparent url(${props => (props.status === TenantCompletionStatus.DONE ? TickIcon : WarningIcon)}) no-repeat
      center center;
    background-size: 12px;
    content: '';
    display: block;
    height: 12px;
    margin: 0 0 0 auto;
    width: 12px;
  }

  &:last-child {
    border: none;
  }
`;

const StatusItem: React.SFC<StatusItemProps> = ({ label, tooltip, status }) => {
  return (
    <Status status={status} label={label} data-tip={tooltip}>
      <span>{label}</span>
    </Status>
  );
};

export default StatusItem;
