import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import ReactTooltip from 'react-tooltip';
import { ContentContainer, InnerWrapper } from '../../../components/Common/index';
import CreateRepositHeader from '../../../components/CreateRepositHeader/index';
import { FullPageLoader } from '../../../components/Loading/index';
import RepositStatus from '../../../components/Reposit/RepositStatus/index';
import CheckoutAndExtendRepositModals from '../../../components/Reposit/RepositSummary/CheckoutAndExtendModals';
import { RepositSummaryClaimFlashMessage } from '../../../components/Reposit/RepositSummary/index';
import { useIsOrganizationLandlord } from '../../../hooks/useIsLandlord';
import { createErrorMessageSelector } from '../../../redux/error/error.selector';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import {
  clearTenancyOrderHasFetched,
  fetchTenancyOrderRequested,
  FETCH_TENANCY_ORDER_STORE_KEY,
  setCurrentRepositActionModal
} from '../../../redux/reposit/reposit.actions';
import { getCurrentRepositActionModal, getTenancyOrderHasBeenFetched } from '../../../redux/reposit/reposit.selectors';
import { AppState } from '../../../redux/root.reducer';
import { getCheckoutByTenancyOrderId } from '../../../redux/selectors/checkout.selectors';
import { getClaimByTenancyOrderId } from '../../../redux/selectors/claim.selectors';
import { getTenancyOrder } from '../../../redux/selectors/index';
import { isANotFoundError } from '../../../utils/common.utils';
import NotFoundView from '../../NotFound';
import Reposit from '../Reposit';

type ViewParams = { id: string };

export default (props: RouteComponentProps<ViewParams>) => {
  const tenancyOrderId = props.match.params.id;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTenancyOrderRequested(tenancyOrderId));
    return function cleanup() {
      dispatch(clearTenancyOrderHasFetched());
    };
  }, [tenancyOrderId, dispatch]);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const isLoadingSelector = createLoadingSelector([FETCH_TENANCY_ORDER_STORE_KEY]);
  const errorSelector = createErrorMessageSelector([FETCH_TENANCY_ORDER_STORE_KEY]);
  const hasTenancyOrderFetched = useSelector(getTenancyOrderHasBeenFetched);
  const isLoading = useSelector(isLoadingSelector);
  const error = useSelector(errorSelector);
  const tenancyOrder = useSelector((state: AppState) => getTenancyOrder(state, { tenancyOrderId }));

  const hasFetched = hasTenancyOrderFetched;

  const claim = useSelector((state: AppState) => getClaimByTenancyOrderId(state, { tenancyOrderId }));
  const checkout = useSelector((state: AppState) => getCheckoutByTenancyOrderId(state, { tenancyOrderId }));
  const isOrgLandlord = useIsOrganizationLandlord();

  const [isCheckoutModalShowing, setIsCheckoutModalShowing] = useState(false);
  const extendModalID = `SUMMARY_EXTEND_REPOSIT_${tenancyOrderId}`;
  const setModal = (payload: string) => dispatch(setCurrentRepositActionModal(payload));
  const currentModal = useSelector(getCurrentRepositActionModal);

  if (!hasFetched || isLoading) {
    return <FullPageLoader />;
  }

  if (!tenancyOrder || error) {
    if (isANotFoundError(error)) {
      return <NotFoundView />;
    } else {
      // TODO: we need an error component
      return <div>oh no something went wrong</div>;
    }
  }

  return (
    <InnerWrapper>
      {tenancyOrder.order.statusId === 'COMPLETE' || tenancyOrder.order.statusId === 'PENDING' ? (
        <Container>
          <Row>
            <Col sm={12}>
              <RepositStatus tenancyOrder={tenancyOrder} isOrgLandlord={isOrgLandlord} />
              <RepositSummaryClaimFlashMessage
                tenancyOrder={tenancyOrder}
                claim={claim}
                checkout={checkout}
                createCheckout={() => setIsCheckoutModalShowing(true)}
                extendReposit={() => setModal(extendModalID)}
              />
            </Col>
          </Row>
        </Container>
      ) : (
        <CreateRepositHeader isNew={false} status={tenancyOrder.order.statusId} isOrgLandlord={isOrgLandlord} />
      )}
      <ContentContainer
        style={{
          marginBottom: '96px'
        }}
      >
        <Reposit isEditable={tenancyOrder.order.statusId === 'DRAFT'} tenancyOrder={tenancyOrder} />
      </ContentContainer>

      <CheckoutAndExtendRepositModals
        tenancyOrder={tenancyOrder}
        isCheckoutModalShowing={isCheckoutModalShowing}
        currentModalIsExtendModalID={currentModal === extendModalID}
        dismissCheckoutModal={() => setIsCheckoutModalShowing(false)}
        dismissExtendModal={() => setModal('')}
      />
    </InnerWrapper>
  );
};
