import React from 'react';
import styled from 'styled-components';
import { Header4, P1, P3 } from '../Typography';
import Person from '../../assets/svg/person-active.svg';

interface DialogProps {
  message: string;
  days: number;
}

const Message = styled.div`
  display: flex;
  flex-wrap: nowrap;
  margin: 24px 0 0;
`;

const Avatar = styled.div`
  background: #fff url(${Person}) no-repeat center center;
  background-size: 18px 32px;
  border-radius: 48px;
  height: 48px;
  flex: 0 0 48px;
  margin: 0 12px 0 0;
  overflow: hidden;
  width: 48px;
`;

const Content = styled.div`
  flex: 0 1 100%;
`;

const Name = styled(Header4)`
  margin: 0;
  padding: 0;
`;

const MessageContent = styled.div`
  border-radius: 12px;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(
    267deg,
    ${props => props.theme.colors.primaryBrand},
    ${props => props.theme.colors.secondaryBrand}
  );
  margin: 6px 0;
  padding: 14px 20px 20px;
`;

const MessageMeta = styled(P3)`
  padding: 6px 0;
  text-align: right;
`;

const Dialog: React.FC<DialogProps> = ({ message, days }) => {
  return (
    <Message>
      <Avatar />
      <Content>
        <Name>Reposit Team:</Name>
        <MessageContent>
          <P1 color="#ffffff" style={{ margin: 0 }}>
            {message}
          </P1>
        </MessageContent>
        <MessageMeta>Request sent {days < 1 ? 'today' : `${days} days ago`}</MessageMeta>
      </Content>
    </Message>
  );
};

export default Dialog;
