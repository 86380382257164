import { DocumentDTO, DocumentsApi } from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { FlashState } from '../../components/FlashMessage/index';
import { getErrorMessage } from '../../utils/common.utils';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import { FlashMessagesActionTypes, setFlashMessage } from '../flash-messages/flash-messages.actions';
import SCHEMA from '../schema';
import { createDocumentsApi, runSagaWithAuth } from '../utils/api.utils';
import {
  createDocumentFailed,
  createDocumentSuccess,
  CREATE_DOCUMENT_API_REQUESTED,
  CREATE_DOCUMENT_STORE_KEY,
  DocumentActionTypes
} from './document.actions';
import { CreateDocumentPayload } from './document.types';

export function* createDocument({ payload }: { type: string; payload: CreateDocumentPayload }) {
  try {
    const documentsApi: DocumentsApi = yield createDocumentsApi();
    const apiResponse: AxiosResponse<DocumentDTO> = yield call(
      runSagaWithAuth(() => documentsApi.createDocument(payload.file, payload.file.name, payload.type))
    );
    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.document);
    yield put<DocumentActionTypes>(createDocumentSuccess({ isClaimItem: payload.isClaimItem, id: apiResponse.data.id }));
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: CREATE_DOCUMENT_STORE_KEY, message: error, state: FlashState.ERROR })
    );
    yield put<DocumentActionTypes>(createDocumentFailed(error));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchDocumentsSagas() {
  yield takeLatest(CREATE_DOCUMENT_API_REQUESTED, createDocument);
}
