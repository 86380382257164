import React from 'react';
import { useSelector } from 'react-redux';

import ClaimSummary from '../../../components/Claim/ClaimSummary/index';
import { AppState } from '../../../redux/root.reducer';
import { getClaimById, getTenancyByClaimId } from '../../../redux/selectors/claim.selectors';
import { getClaimItemsByClaimId } from '../../../redux/selectors/claim-item.selectors';
import { getAddressByClaimId } from '../../../redux/selectors/index';
import { getIsAgentDeciding, getIsTenantDeciding } from '../../../redux/selectors/mediation.selectors';

export interface ClaimSummaryContainerProps {
  claimId: string;
}

export default (props: ClaimSummaryContainerProps) => {
  const claim = useSelector((state: AppState) => getClaimById(state, props.claimId));
  const items = useSelector((state: AppState) => getClaimItemsByClaimId(state, props));
  const address = useSelector((state: AppState) => getAddressByClaimId(state, props.claimId));
  const tenancy = useSelector((state: AppState) => getTenancyByClaimId(state, props.claimId));
  const isAgentDeciding = useSelector((state: AppState) => getIsAgentDeciding(state, props.claimId));
  const isTenantDeciding = useSelector((state: AppState) => getIsTenantDeciding(state, props.claimId));

  if (!claim) return null;
  return (
    <ClaimSummary
      address={address}
      key={props.claimId}
      claim={claim}
      items={items}
      outcomeDeadline={tenancy && tenancy.outcomeDeadline}
      isAgentDeciding={isAgentDeciding}
      isTenantDeciding={isTenantDeciding}
    />
  );
};
