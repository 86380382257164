import { useSelector } from 'react-redux';
import { getCurrentOrganizationId } from '../redux/auth/auth.selectors';
import { getOrganization } from '../redux/selectors/organization.selectors';
import { OrganizationWithRepositsAndRolesOrganizationTypeIdEnum } from '@reposit/api-client';

export const useIsOrganizationLandlord = () => {
  const currentOrgId = useSelector(getCurrentOrganizationId);
  const currentOrg = useSelector(getOrganization(currentOrgId));
  return !!(currentOrg && currentOrg.organizationTypeId === OrganizationWithRepositsAndRolesOrganizationTypeIdEnum.PRIVATE);
};
