import { ClaimDTOStatusEnum, OrderWithOrderCustomersDTOStatusIdEnum } from '@reposit/api-client';
import { get } from 'lodash';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import CalendarIcon from '../../../assets/svg/calendar-blk.svg';
import { getBreakpoint } from '../../../base/style';
import { RepositTheme } from '../../../base/theme';
import {
  ClaimFlashMessageCreate,
  ClaimFlashMessageProposalNeedsFinishing,
  ClaimFlashMessageProposalNeedsResponse,
  ClaimFlashMessagePublish,
  ClaimFlashMessageSubstantiate,
  ClaimFlashMessageView
} from '../../../containers/Claim/ClaimMessage/index';
import { getTenancyById } from '../../../redux/entities/entities.selectors';
import { CheckoutEntity, ClaimEntity } from '../../../redux/entities/entities.types';
import { setCurrentRepositActionModal } from '../../../redux/reposit/reposit.actions';
import { getCurrentRepositActionModal } from '../../../redux/reposit/reposit.selectors';
import { AppState } from '../../../redux/root.reducer';
import { getTenancyOrder, RepositSummaryData, TenancyOrderWithActions } from '../../../redux/selectors';
import { getCheckoutById } from '../../../redux/selectors/checkout.selectors';
import { getClaimById } from '../../../redux/selectors/claim.selectors';
import {
  getIsAgentDeciding,
  getIsAgentNegotiating,
  getTenantProposalResponseDeadlineDaysRemaining
} from '../../../redux/selectors/mediation.selectors';
import { getAddressFirstLine } from '../../../utils/common.utils';
import Button from '../../Button';
import Card from '../../Card';
import CardMessage from '../../CardMessage/index';
import { Caption, Header3, Header5, P2, P3 } from '../../Typography';
import CheckoutAndExtendRepositModals from './CheckoutAndExtendModals';

interface RepositSummaryProps {
  data: RepositSummaryData;
  disableFlashMessages?: boolean;
  tenancyOrderId: string;
}

interface RepositStatusProps {
  status: string;
}

const RepositStatusColors: { [key: string]: string } = {
  PENDING: RepositTheme.colors.warning,
  COMPLETE: RepositTheme.colors.positive,
  CHECKED_OUT: RepositTheme.colors.body2,
  DRAFT: RepositTheme.colors.body2,
  CLOSED: RepositTheme.colors.negative
};

const SummaryContainer = styled.div`
  border-radius: 12px;
  margin: 0 5px 5px;
  padding: 20px 20px 32px 40px;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    display: flex;
    justify-content: space-between;
  }
`;

const ColLeft = styled.div``;

const ColRight = styled.div`
  flex: 0 0 280px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    flex-direction: column;
    max-width: 280px;
  }
`;

const RepositStatus = styled(P2)<RepositStatusProps>`
  align-items: center;
  color: ${props => RepositStatusColors[props.status]};
  display: flex;

  &:before {
    background: ${props => RepositStatusColors[props.status]};
    border-radius: 12px;
    content: '';
    display: block;
    height: 10px;
    margin: -2px 10px 0 0;
    width: 10px;
  }
`;

const Action = styled.div`
  display: flex;
  justify-content: space-between;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    justify-content: flex-end;
  }
`;

const SmallLabel = styled(P3)`
  margin: 0;
`;

const TenancyDates = styled.div`
  display: none;
  margin: 24px 0 0;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
  }
`;

const Label = styled(Header5)`
  align-items: center;
  display: flex;
  margin: 0 14px 0 0;
  padding: 0;

  &:before {
    background: transparent url(${CalendarIcon}) no-repeat center center;
    background-size: 16px;
    content: '';
    display: block;
    height: 20px;
    margin: -3px 10px 0 0;
    width: 20px;
  }
`;

const Dates = styled(P2)`
  color: ${props => props.theme.colors.body2};
  margin: 12px 0 0;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    margin: 1px 0 0;
    padding: 0;
  }
`;

const Landlord = styled.div`
  align-items: center;
  display: none;
  margin: 24px 0;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    align-items: none;
    display: block;
    text-align: right;
    margin: 24px 0 0;
  }
`;

const LandlordName = styled.span`
  color: ${props => props.theme.colors.body2};
  display: block;
  font-family: ${props => props.theme.typography.face.secondary};
  line-height: 1.5;
  letter-spacing: 0.09px;
  margin: 0 8px;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    margin: 4px 0 0;
  }
`;

const NoAddress = styled(P2)`
  font-style: italic;
`;

const Address = styled.div`
  width: 100%;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
  }
`;

const FirstLineAddress = styled(Header3)`
  line-height: 1.2;
  margin: 0;
  padding: 90;
`;

const TownPostcode = styled(Caption)`
  padding: 2px 0 0;
  @media screen and (min-width: ${getBreakpoint('lg')}) {
    margin: 0 0 0 12px;
  }
`;

const renderRepositStatus = (status: string) => {
  const statusMap: { [key: string]: string } = {
    DRAFT: 'Draft',
    COMPLETE: 'Complete',
    PENDING: 'Awaiting Tenant Actions',
    CHECKED_OUT: 'Checked Out',
    CLOSED: 'Closed'
  };

  return <RepositStatus status={status}>{statusMap[status]}</RepositStatus>;
};

interface RepositSummaryClaimFlashMessage {
  claim?: ClaimEntity;
  checkout?: CheckoutEntity;
  tenancyOrder?: TenancyOrderWithActions;
  createCheckout: () => void;
  extendReposit: () => void;
}

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  button {
    margin: 0 6px;
  }
`;

interface RepositFlashMessageEndingSoonProps {
  daysUntilCheckoutDeadline: number;
  createCheckout: () => void;
  extendReposit: () => void;
}

export const RepositFlashMessageEndingSoon: React.FC<RepositFlashMessageEndingSoonProps> = ({
  daysUntilCheckoutDeadline,
  createCheckout,
  extendReposit
}) => {
  return (
    <CardMessage
      title={'This Reposit is closing'}
      subtitle={<Fragment>You have {daysUntilCheckoutDeadline} day(s) to start a checkout or extend</Fragment>}
      cta={
        <ActionsContainer>
          <Button onClick={extendReposit} buttonType="white">
            Extend
          </Button>
          <Button onClick={createCheckout} buttonType="white">
            Checkout
          </Button>
        </ActionsContainer>
      }
      type="warning"
    />
  );
};

export const RepositSummaryClaimFlashMessage: React.FC<RepositSummaryClaimFlashMessage> = ({
  tenancyOrder,
  claim,
  checkout,
  createCheckout,
  extendReposit
}) => {
  const claimId = get(claim, 'id', '');
  const tenancy = tenancyOrder && tenancyOrder.tenancy;
  const order = tenancyOrder && tenancyOrder.order;
  const isOrderComplete = order && order.statusId === OrderWithOrderCustomersDTOStatusIdEnum.COMPLETE;
  const outcomeDeadlineDaysRemaining =
    tenancy && tenancy.outcomeDeadline ? moment(tenancy.outcomeDeadline).diff(moment().startOf('day'), 'day') : 0;

  const substantiationDeadlineDaysRemaining =
    claim && claim.substantiationDeadline ? moment(claim.substantiationDeadline).diff(moment().startOf('day'), 'day') : 0;
  const tenantProposalResponseDeadlineDaysRemaining = useSelector((state: AppState) =>
    getTenantProposalResponseDeadlineDaysRemaining(state, claimId)
  );

  const claimPublishable = claim && outcomeDeadlineDaysRemaining > 0 && claim.status === ClaimDTOStatusEnum.DRAFT;
  const claimNeedsSubstantiation = claim && claim.status === ClaimDTOStatusEnum.AWAITINGSUBSTANTIATION;
  const claimCreatable = checkout && !claim && outcomeDeadlineDaysRemaining >= 0;
  const claimProposalNeedsResponse = useSelector((state: AppState) => getIsAgentDeciding(state, claimId));
  const claimProposalNeedsFinishing = useSelector((state: AppState) => getIsAgentNegotiating(state, claimId));

  const endDate = tenancy && tenancy.endDate && moment(tenancy.endDate);
  const today = moment();
  const checkoutDeadline = tenancy && tenancy.outcomeDeadline && moment(tenancy.outcomeDeadline);
  const closingSoon = moment(checkoutDeadline).subtract(28, 'days');
  const daysUntilCheckoutDeadline = moment(checkoutDeadline)
    .startOf('day')
    .diff(moment(), 'day');

  if (!checkout && isOrderComplete && today.isAfter(closingSoon) && today.isBefore(checkoutDeadline)) {
    if (today.isAfter(endDate)) {
      return (
        <RepositFlashMessageEndingSoon
          daysUntilCheckoutDeadline={daysUntilCheckoutDeadline}
          createCheckout={createCheckout}
          extendReposit={extendReposit}
        />
      );
    }

    if (today.isBefore(endDate)) {
      return (
        <CardMessage
          title={'Your Reposit is ending soon!'}
          subtitle={<Fragment>You will need to create a checkout soon</Fragment>}
          type="secondary"
        />
      );
    }
  }

  if (claim && claimPublishable) {
    return <ClaimFlashMessagePublish claimId={claim.id} daysRemaining={outcomeDeadlineDaysRemaining} />;
  }
  if (claim && claimNeedsSubstantiation) {
    return <ClaimFlashMessageSubstantiate claimId={claim.id} daysRemaining={substantiationDeadlineDaysRemaining} />;
  }

  if (claim && claimProposalNeedsResponse) {
    return (
      <ClaimFlashMessageProposalNeedsResponse claimId={claim.id} daysRemaining={tenantProposalResponseDeadlineDaysRemaining} />
    );
  }

  if (claim && claimProposalNeedsFinishing) {
    return (
      <ClaimFlashMessageProposalNeedsFinishing claimId={claim.id} daysRemaining={tenantProposalResponseDeadlineDaysRemaining} />
    );
  }

  if (checkout && tenancy && tenancy.id && claimCreatable) {
    return (
      <ClaimFlashMessageCreate
        tenancyId={tenancy.id}
        daysRemaining={outcomeDeadlineDaysRemaining}
        closingRemindersEnabled={tenancy.closingRemindersEnabled}
      />
    );
  }

  if (claim) {
    return <ClaimFlashMessageView claimId={claim.id} />;
  }
  return null;
};

export default (props: RepositSummaryProps): JSX.Element => {
  const dispatch = useDispatch();
  const { tenancyOrderId } = props;
  const extendModalID = `SUMMARY_EXTEND_REPOSIT_${tenancyOrderId}`;
  const claim = useSelector((state: AppState) => getClaimById(state, props.data.claimId));
  const checkout = useSelector((state: AppState) => getCheckoutById(state, { checkoutId: props.data.checkoutId }));
  const tenancy = useSelector(getTenancyById(props.data.tenancyId));
  const tenancyOrder = useSelector((state: AppState) => getTenancyOrder(state, { tenancyOrderId }));
  const [isCheckoutModalShowing, setIsCheckoutModalShowing] = useState(false);

  const setModal = (payload: string) => dispatch(setCurrentRepositActionModal(payload));
  const currentModal = useSelector(getCurrentRepositActionModal);

  useEffect(() => {
    dispatch(setCurrentRepositActionModal(''));
  }, [dispatch]);

  const resolveStatus = () => {
    if (checkout) {
      return 'CHECKED_OUT';
    } else if (!checkout && tenancy && tenancy.completedAt) {
      return 'CLOSED';
    }
    return props.data.status;
  };

  return (
    <Fragment>
      <Card
        hover
        flush={true}
        style={{ padding: 1 }}
        flashMessage={
          !props.disableFlashMessages ? (
            <RepositSummaryClaimFlashMessage
              tenancyOrder={tenancyOrder}
              claim={claim}
              checkout={checkout}
              createCheckout={() => setIsCheckoutModalShowing(true)}
              extendReposit={() => setModal(extendModalID)}
            />
          ) : (
            undefined
          )
        }
      >
        <NavLink to={`/reposits/${props.data.id}`} style={{ textDecoration: 'none' }}>
          <SummaryContainer>
            <ColLeft>
              {renderRepositStatus(resolveStatus())}
              {props.data.address ? (
                <Address>
                  <FirstLineAddress>{getAddressFirstLine(props.data.address)}</FirstLineAddress>
                  <TownPostcode>{props.data.address.postcode}</TownPostcode>
                </Address>
              ) : (
                <NoAddress>You haven't added a property to this Reposit yet.</NoAddress>
              )}
              <TenancyDates>
                <Label>Tenancy dates</Label>
                <Dates>
                  {!props.data.startDate && !props.data.endDate ? (
                    'Not yet provided'
                  ) : (
                    <Fragment>
                      {moment(props.data.startDate).format('Do MMM YYYY')} - {moment(props.data.endDate).format('Do MMM YYYY')}
                    </Fragment>
                  )}{' '}
                </Dates>
              </TenancyDates>
            </ColLeft>
            <ColRight>
              <Action>
                <SmallLabel>Created {moment(props.data.createdAt).fromNow()}</SmallLabel>
              </Action>
              {props.data.landlord && (
                <Landlord>
                  <SmallLabel>Current Landlord</SmallLabel>
                  <LandlordName>{props.data.landlord}</LandlordName>
                </Landlord>
              )}
            </ColRight>
          </SummaryContainer>
        </NavLink>
      </Card>

      <CheckoutAndExtendRepositModals
        tenancyOrder={tenancyOrder}
        isCheckoutModalShowing={isCheckoutModalShowing}
        currentModalIsExtendModalID={currentModal === extendModalID}
        dismissExtendModal={() => setModal('')}
        dismissCheckoutModal={() => setIsCheckoutModalShowing(false)}
      />
    </Fragment>
  );
};
