import styled from 'styled-components';
import { getBreakpoint } from '../../base/style';
import { StyledLink, P2 } from '../Typography';
import RightDark from '../../assets/svg/right-dark.svg';

interface EditMode {
  editMode: boolean;
}

export const TenancyDetails = styled.div`
  position: relative;
  z-index: 10;
`;

export const PPM = styled.div`
  min-height: 86px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    max-width: 86%;
  }
`;

export const TenancyType = styled.div`
  min-height: 86px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    float: right;
    max-width: 86%;
    width: 100%;
  }
`;

export const IsNewTenancy = styled.div`
  min-height: 86px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    max-width: 86%;
    width: 100%;
  }
`;

export const Action = styled.div`
  text-align: right;
  margin: 16px 0 0;

  button:first-child {
    margin: 0 12px;
  }
`;

export const TenancyInfo = styled.div`
  margin: 0.75em 0 0;
  text-align: left;
  width: 100%;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    display: flex;
    justify-content: space-between;
  }
`;

export const Item = styled.div`
  margin: 0 50px 0 0;

  &:first-child {
    margin: 0 50px 0 0;
  }

  &:last-child {
    margin: 0;
    @media screen and (min-width: ${getBreakpoint('lg')}) {
    }
  }
`;

export const ItemTitle = styled.p`
  color: ${props => props.theme.colors.body};
  font-family: ${props => props.theme.typography.face.secondary};
  font-size: 1em;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: -0.2px;
  padding: 0 0 4px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    padding: 0 0 12px;
  }
`;

export const TenancyDates = styled.div<EditMode>`
  display: ${props => (props.editMode ? 'block' : 'flex')};
  justify-content: space-between;
  margin: 6px 0 0;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin: 0;
  }
`;

export const Until = styled.p<EditMode>`
  align-items: center;
  color: ${props => props.theme.colors.body2};
  display: flex;
  flex-direction: column;
  font-family: ${props => props.theme.typography.face.secondary};
  font-size: 12px;
  line-height: 1.5;
  letter-spacing: 0.1px;
  margin: ${props => (props.editMode ? '0' : '-4px 0 0')};
  padding: ${props => (props.editMode ? '22px 0 0' : '0 32px')};
  text-align: center;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    padding: ${props => (props.editMode ? '28px 0 0' : '8px 0 0')};
    margin: ${props => (props.editMode ? '0' : '-8px 0 0')};
    width: 100px;
  }

  &:before {
    background: transparent url(${RightDark}) no-repeat center center;
    background-size: 10px 18px;
    content: '';
    display: block;
    margin: 0 0 4px;
    height: 20px;
    width: 12px;
  }
`;

export const Field = styled.div`
  @media all and (min-width: ${getBreakpoint('lg')}) {
    width: calc(67% - 100px);
  }
`;

export const Link = styled(StyledLink)`
  margin: 0 20px;
`;

export const WarningMessage = styled(P2)`
  color: ${props => props.theme.colors.negative};
  font-weight: bold;
  margin: 0 0;
`;
