import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import CompanyIcon from '../../../assets/svg/onboarding/company.svg';
import Button from '../../../components/Button/index';
import Card from '../../../components/Card/index';
import FlashMessage from '../../../components/FlashMessage';
import Checkbox from '../../../components/FormFields/Checkbox/index';
import Actions from '../../../components/Onboarding/Actions/index';
import Title from '../../../components/Onboarding/Title/index';
import { P1 } from '../../../components/Typography/index';
import { SUPPLIER_AGREEMENT_LINK } from '../../../constants/links';
import { useIsOrganizationLandlord } from '../../../hooks/useIsLandlord';
import { updateTermsAcceptedRequested, UPDATE_TERMS_ACCEPTED_STORE_KEY } from '../../../redux/account/account.actions';
import { getCurrentOrganizationId } from '../../../redux/auth/auth.selectors';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import { getOrganization } from '../../../redux/selectors/organization.selectors';
import { FLASH_MESSAGE_TIMEOUT, useFlashMessage } from '../../FlashMessage';

const TermsLink = styled.a`
  color: ${props => props.theme.colors.primaryBrand};
  display: inline;
  font-family: ${props => props.theme.typography.face.secondary};
  font-size: inherit;
  text-decoration: underline;
`;

const TermsConditions: React.SFC<any> = () => {
  const [isChecked, changeChecked] = useState(false);
  const onChange = () => changeChecked(!isChecked);
  const dispatch = useDispatch();
  const onSubmit = () => dispatch(updateTermsAcceptedRequested());
  const isLoading = useSelector(createLoadingSelector([UPDATE_TERMS_ACCEPTED_STORE_KEY]));
  const [flashMessagePayload, onDismissFlashMessage] = useFlashMessage([UPDATE_TERMS_ACCEPTED_STORE_KEY]);
  const currentOrgId = useSelector(getCurrentOrganizationId);
  const currentOrg = useSelector(getOrganization(currentOrgId));
  const hasReposits =
    currentOrg && (currentOrg.reposits.draft > 0 || currentOrg.reposits.pending > 0 || currentOrg.reposits.complete > 0);
  const isOrgLandlord = useIsOrganizationLandlord();
  return (
    <Fragment>
      <Title icon={CompanyIcon}>Terms and Conditions</Title>
      <Card>
        {isOrgLandlord && hasReposits ? (
          <P1>
            Your letting agent has already accepted our terms for your existing Reposits. Please read and agree to our{' '}
            <TermsLink href={SUPPLIER_AGREEMENT_LINK} target="_blank">
              Terms and Conditions
            </TermsLink>
            , before accessing your dashboard and creating any new Reposits.
          </P1>
        ) : (
          <P1>
            Please read and agree to our{' '}
            <TermsLink href={SUPPLIER_AGREEMENT_LINK} target="_blank">
              Terms and Conditions
            </TermsLink>{' '}
            before accessing your Reposit dashboard.
          </P1>
        )}
      </Card>

      {flashMessagePayload && (
        <FlashMessage onDismiss={onDismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMEOUT} payload={flashMessagePayload} />
      )}
      <Actions>
        <Button disabled={!isChecked || isLoading} onClick={onSubmit}>
          Continue
        </Button>

        <div style={{ paddingLeft: 36, marginRight: 'auto' }}>
          <Checkbox
            name="agree"
            label="I agree to the terms and conditions"
            isChecked={isChecked}
            onChange={onChange}
            helpText=""
          />
        </div>
      </Actions>
    </Fragment>
  );
};

export default TermsConditions;
