import React from 'react';
import { TenancyOrderWithActions } from '../../../redux/selectors/index';
import RepositCheckoutModal from '../../../containers/Reposit/RepositActions/modals/RepositCheckoutModal';
import RepositExtendModal from '../../../containers/Reposit/RepositActions/modals/RepositExtendModal';

interface CheckoutAndExtendRepositModalsProps {
  isCheckoutModalShowing: boolean;
  currentModalIsExtendModalID: boolean;
  tenancyOrder?: TenancyOrderWithActions;
  dismissCheckoutModal: () => void;
  dismissExtendModal: () => void;
}

const CheckoutAndExtendRepositModals: React.FC<CheckoutAndExtendRepositModalsProps> = ({
  isCheckoutModalShowing,
  currentModalIsExtendModalID,
  tenancyOrder,
  dismissCheckoutModal,
  dismissExtendModal
}) => {
  return (
    <>
      {isCheckoutModalShowing ? (
        <RepositCheckoutModal isPublishable tenancyOrder={tenancyOrder} onDismiss={dismissCheckoutModal} />
      ) : (
        undefined
      )}
      {currentModalIsExtendModalID ? (
        <RepositExtendModal isPublishable tenancyOrder={tenancyOrder} onDismiss={dismissExtendModal} />
      ) : (
        undefined
      )}
    </>
  );
};

export default CheckoutAndExtendRepositModals;
