import { SubmitFeedback } from './feedback.types';

export const TOGGLE_FEEDBACK_MODAL = `FEEDBACK/TOGGLE_FEEDBACK_MODAL`;

export const SUBMIT_FEEDBACK_STORE_KEY = `FEEDBACK/SUBMIT_FEEDBACK`;
export const SUBMIT_FEEDBACK_API_REQUESTED = `FEEDBACK/SUBMIT_FEEDBACK_API_REQUESTED`;
export const SUBMIT_FEEDBACK_API_SUCCESS = `FEEDBACK/SUBMIT_FEEDBACK_API_SUCCESS`;
export const SUBMIT_FEEDBACK_API_FAILED = `FEEDBACK/SUBMIT_FEEDBACK_API_FAILED`;

export function toggleFeedbackModal() {
  return {
    type: TOGGLE_FEEDBACK_MODAL
  } as const;
}

export function submitFeedbackRequested(payload: SubmitFeedback) {
  return {
    type: SUBMIT_FEEDBACK_API_REQUESTED,
    payload
  } as const;
}

export function submitFeedbackSuccess() {
  return {
    type: SUBMIT_FEEDBACK_API_SUCCESS
  } as const;
}

export function submitFeedbackFailed(error: string) {
  return {
    type: SUBMIT_FEEDBACK_API_FAILED,
    payload: error
  } as const;
}

export type FeedbackActionTypes = ReturnType<
  typeof submitFeedbackRequested | typeof submitFeedbackSuccess | typeof submitFeedbackFailed | typeof toggleFeedbackModal
>;
