import { ClaimDTOStatusEnum } from '@reposit/api-client';
import { Formik, FormikProps } from 'formik';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';
import NotesIcon from '../../../assets/svg/notes.svg';
import Button from '../../../components/Button/index';
import { DisableComponent } from '../../../components/Common';
import FlashMessage from '../../../components/FlashMessage/index';
import FieldWithLabel from '../../../components/FormFields/FieldWithLabel/index';
import TextArea from '../../../components/FormFields/TextArea/index';
import RepositCard from '../../../components/Reposit/RepositCard/index';
import { P1, P2 } from '../../../components/Typography/index';
import { updateClaimRequested, UPDATE_CLAIM_STORE_KEY } from '../../../redux/claim/claim.actions';
import { getClaimItemFormOpen } from '../../../redux/claim/claim.selector';
import { AppState } from '../../../redux/root.reducer';
import { getClaimStatusById, getDetailsFromClaimById } from '../../../redux/selectors/claim.selectors';
import { FLASH_MESSAGE_TIMEOUT, useFlashMessage } from '../../FlashMessage/index';

interface SupportingInformationProps {
  claimId: string;
  isReadOnly?: boolean;
  setSupportingInformation: (value: string | undefined) => void;
}

export interface SupportingInformationFormValues {
  details?: string;
}

const Schema = Yup.object().shape({
  details: Yup.string()
});

const Action = styled.div`
  margin-top: 1.25em;
  text-align: right;
`;

const Message = styled(P1)`
  margin: 0;
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const storeKeys = [UPDATE_CLAIM_STORE_KEY];

const SupportingInformation: React.FC<SupportingInformationProps> = ({ claimId, isReadOnly, setSupportingInformation }) => {
  const dispatch = useDispatch();
  const claimStatus = useSelector((state: AppState) => getClaimStatusById(state, claimId));
  const details = useSelector((state: AppState) => getDetailsFromClaimById(state, claimId));
  const isClaimItemsFormOpen = useSelector(getClaimItemFormOpen);
  const submit = async (values: SupportingInformationFormValues) => {
    await dispatch(updateClaimRequested({ claimId, details: values.details! }));
    setSupportingInformation(undefined);
  };

  const [flashMessagePayload, onDismissFlashMessage] = useFlashMessage(storeKeys);
  const isDraft = claimStatus === ClaimDTOStatusEnum.DRAFT;
  const isAwaitingSubstantiation = claimStatus === ClaimDTOStatusEnum.AWAITINGSUBSTANTIATION;

  const displayDetails = (details: string) => {
    return (
      <>
        <P2 style={{ marginBottom: 4, fontWeight: 'bold' }}>Your response:</P2>
        <P2 style={{ fontStyle: 'italic' }}>{details}</P2>
      </>
    );
  };

  return (
    <DisableComponent disabled={isClaimItemsFormOpen}>
      <RepositCard
        title={'Supporting Information'}
        icon={NotesIcon}
        flashMessage={
          flashMessagePayload ? (
            <FlashMessage onDismiss={onDismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMEOUT} payload={flashMessagePayload} />
          ) : (
            undefined
          )
        }
      >
        {isReadOnly ? (
          <>
            <Message style={{ paddingBottom: 10 }}>
              A section for any other information that supports your claim. Any information given will be shared with the
              tenant(s).
            </Message>
            <P2>{details ? displayDetails(details) : 'No supporting information provided'}</P2>
          </>
        ) : (
          <Formik
            initialValues={{
              details
            }}
            validationSchema={Schema}
            onSubmit={values => {
              submit(values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit
            }: FormikProps<SupportingInformationFormValues>) => {
              return (
                <form onSubmit={handleSubmit}>
                  {isDraft || isAwaitingSubstantiation ? (
                    <>
                      <Message>
                        If there's any other information you think would support your claim, please enter it in the box below. To
                        save your response, you may press the <BoldText>save button</BoldText> at any time.
                      </Message>
                      <Message style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <BoldText>This information will be shared with the tenant(s).</BoldText>
                      </Message>
                    </>
                  ) : (
                    <Message>
                      A section for any other information that supports your claim. Any response given will be shared with the
                      tenant(s).
                    </Message>
                  )}
                  <FieldWithLabel label="">
                    <TextArea
                      disabled={isReadOnly}
                      value={values.details || ''}
                      onChange={event => {
                        handleChange(event);
                        // allows this input to be saved automatically when claim is published
                        setSupportingInformation(event.target.value);
                      }}
                      onBlur={handleBlur}
                      error={errors.details}
                      touched={touched.details}
                      name="details"
                    />
                  </FieldWithLabel>
                  <Action>{!isReadOnly ? <Button type="submit">Save</Button> : null}</Action>
                </form>
              );
            }}
          </Formik>
        )}
      </RepositCard>
    </DisableComponent>
  );
};

export default SupportingInformation;
