import moment from 'moment';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Button from '../../../../components/Button/index';
import Loading from '../../../../components/Loading';
import Modal from '../../../../components/Modal';
import { Header3, P2 } from '../../../../components/Typography/index';
import { createLoadingSelector } from '../../../../redux/loading/loading.selector';
import {
  checkoutRepositRequested,
  CHECKOUT_REPOSIT_STORE_KEY,
  FETCH_TENANCY_ORDER_STORE_KEY
} from '../../../../redux/reposit/reposit.actions';
import { AppState } from '../../../../redux/root.reducer';
import { getTenancyOrder } from '../../../../redux/selectors/index';
import { RepositActionModalProps } from '../index';

const Action = styled.div`
  padding: 12px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

const BoldText = styled.span`
  font-weight: bold;
`;

function calculateDaysRemaining(endDate: string | undefined, outcomeDeadline: string | undefined) {
  const endDateInPast = moment(endDate).isBefore(moment());
  if (endDateInPast) {
    return moment(outcomeDeadline).diff(moment().startOf('day'), 'day');
  } else {
    return moment(outcomeDeadline).diff(moment(endDate), 'day');
  }
}
const RepositCheckoutModal: React.SFC<RepositActionModalProps> = ({ tenancyOrder, onDismiss }) => {
  const dispatch = useDispatch();
  const fetchedTenancyOrder = useSelector((state: AppState) => getTenancyOrder(state, { tenancyOrderId: tenancyOrder.id }));
  const checkoutRepositLoadingSelector = createLoadingSelector([CHECKOUT_REPOSIT_STORE_KEY, FETCH_TENANCY_ORDER_STORE_KEY]);
  const isLoading = useSelector(checkoutRepositLoadingSelector);
  const checkoutReposit = () => dispatch(checkoutRepositRequested(tenancyOrder));
  const endDate = fetchedTenancyOrder && fetchedTenancyOrder.tenancy.lastEndDate;
  const outcomeDeadline = fetchedTenancyOrder && fetchedTenancyOrder.tenancy.outcomeDeadline;

  const daysRemaining = calculateDaysRemaining(endDate, outcomeDeadline);

  if (fetchedTenancyOrder && fetchedTenancyOrder.tenancy.checkout) {
    onDismiss();
  }

  const content = (
    <Fragment>
      <Header3 style={{ marginBottom: 6 }}>Ready to start the checkout process?</Header3>
      <P2>
        A Reposit should only be checked out once the tenants have moved out of the property and you have begun your end of
        tenancy processes.
      </P2>
      <P2>
        <BoldText>Please ensure that the above criteria are met before checking out this Reposit</BoldText>, as it cannot be
        reactivated or extended once the checkout process has begun.
      </P2>
      <P2 style={{ marginBottom: 24 }}>
        Once checked out, you will have <BoldText>{daysRemaining}</BoldText> days to raise any end of tenancy charges. If no
        charges are to be raised, no further action is required.
      </P2>
      <Action>
        <Button buttonType="secondary" noArrow={true} onClick={onDismiss}>
          No, return to form
        </Button>
        <Button onClick={checkoutReposit}>Yes</Button>
      </Action>
    </Fragment>
  );

  return <Modal onDismiss={onDismiss}>{isLoading ? <Loading /> : content}</Modal>;
};

export default RepositCheckoutModal;
