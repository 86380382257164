import { ClaimDocumentsApi, ClaimDocumentDTO } from '@reposit/api-client';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';

import { FlashState } from '../../components/FlashMessage/index';
import { getErrorMessage } from '../../utils/common.utils';
import { FlashMessagesActionTypes, setFlashMessage } from '../flash-messages/flash-messages.actions';
import { createClaimDocumentsApi, runSagaWithAuth } from '../utils/api.utils';
import {
  ClaimDocumentActionTypes,
  deleteClaimDocumentFailed,
  deleteClaimDocumentSuccess,
  DELETE_CLAIM_DOCUMENT_API_REQUESTED,
  DELETE_CLAIM_DOCUMENT_STORE_KEY,
  CREATE_CLAIM_DOCUMENT_API_REQUESTED,
  createClaimDocumentSuccess,
  CREATE_CLAIM_DOCUMENT_STORE_KEY,
  createClaimDocumentFailed
} from './claim-document.actions';
import { DeleteClaimDocumentPayload, CreateClaimDocumentPayload } from './claim-document.types';
import { getClaimDocumentByDocumentId } from '../selectors/claim-document.selectors';
import { AppState } from '../root.reducer';

import SCHEMA from '../schema';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';

export function* deleteClaimDocument({ payload }: { type: string; payload: DeleteClaimDocumentPayload }) {
  try {
    const claimDocumentsApi: ClaimDocumentsApi = yield createClaimDocumentsApi();
    yield call(runSagaWithAuth(() => claimDocumentsApi.deleteClaimDocument(payload.claimId, payload.documentId)));

    const claimDocument = yield select((state: AppState) => getClaimDocumentByDocumentId(state, payload.documentId));
    const claimDocumentId = claimDocument ? claimDocument.id : '';
    yield put<ClaimDocumentActionTypes>(deleteClaimDocumentSuccess({ claimId: payload.claimId, claimDocumentId }));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: DELETE_CLAIM_DOCUMENT_STORE_KEY,
        message: 'Success! You have deleted a claim document.',
        state: FlashState.SUCCESS
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: DELETE_CLAIM_DOCUMENT_STORE_KEY, message: error, state: FlashState.ERROR })
    );
    yield put<ClaimDocumentActionTypes>(deleteClaimDocumentFailed(error));
  }
}

export function* createClaimDocument({ payload }: { type: string; payload: CreateClaimDocumentPayload }) {
  try {
    const claimDocumentsApi: ClaimDocumentsApi = yield createClaimDocumentsApi();
    const apiResponse: AxiosResponse<ClaimDocumentDTO> = yield call(
      runSagaWithAuth(() => claimDocumentsApi.addClaimDocument(payload.claimId, payload.file, payload.file.name, payload.type))
    );
    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.claimDocument);
    yield put<ClaimDocumentActionTypes>(
      createClaimDocumentSuccess({ claimId: payload.claimId, claimDocumentId: apiResponse.data.id })
    );
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: CREATE_CLAIM_DOCUMENT_STORE_KEY,
        message: 'Success! You have created a claim document.',
        state: FlashState.SUCCESS
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: CREATE_CLAIM_DOCUMENT_STORE_KEY, message: error, state: FlashState.ERROR })
    );
    yield put<ClaimDocumentActionTypes>(createClaimDocumentFailed(error));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchClaimDocumentsSagas() {
  yield takeLatest(DELETE_CLAIM_DOCUMENT_API_REQUESTED, deleteClaimDocument);
  yield takeLatest(CREATE_CLAIM_DOCUMENT_API_REQUESTED, createClaimDocument);
}
