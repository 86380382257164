import { startCase, toLower } from 'lodash';
import React, { useState } from 'react';
import { Col, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Button from '../../../components/Button/index';
import Card from '../../../components/Card/index';
import ConfirmationModal from '../../../components/ConfirmationModal/index';
import { P1 } from '../../../components/Typography/index';
import { deleteBankAccountRequested, DELETE_BANK_ACCOUNT_STORE_KEY } from '../../../redux/bank-account/bank-account.actions';
import { BankAccountEntity } from '../../../redux/entities/entities.types';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import BankSvg from '../../../assets/svg/bank.svg';

const BankCard = styled(Card)`
  max-width: 350px;
  min-height: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const Icon = styled.img`
  height: 65px;
  width: 65px;
`;

const BankIconAndAccountNumberContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const AccountNumber = styled(P1)`
  margin-top: auto;
`;

const AccountName = styled(P1)`
  align-self: flex-start;
  margin-bottom: 0;
`;

const AccountNameContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const BankName = styled(P1)`
  align-self: flex-start;
  margin-bottom: 0;
`;

const BankNameContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;
interface BankAccountInfoProps {
  bankAccount: BankAccountEntity;
}

const BankAccountInfo: React.FC<BankAccountInfoProps> = ({ bankAccount }) => {
  const dispatch = useDispatch();
  const [showingDeleteModal, setShowingDeleteModal] = useState<boolean>(false);

  const deletingSelector = createLoadingSelector([DELETE_BANK_ACCOUNT_STORE_KEY]);
  const isDeleting = useSelector(deletingSelector);

  const handleDelete = () => {
    dispatch(deleteBankAccountRequested(bankAccount.id));
    setShowingDeleteModal(false);
  };
  const handleDismissDelete = () => {
    setShowingDeleteModal(false);
  };

  return (
    <>
      {showingDeleteModal && (
        <ConfirmationModal
          title="Remove Bank Account"
          text="Are you sure you want to remove this bank account?"
          onDismiss={handleDismissDelete}
          onSubmit={handleDelete}
        />
      )}
      <Row>
        <Col lg={12}>
          <BankCard flush style={{ display: 'flex', flexDirection: 'column', padding: '5px 0', width: '90%' }}>
            <AccountNameContainer>
              <AccountName>{bankAccount.accountName}</AccountName>
            </AccountNameContainer>
            <BankIconAndAccountNumberContainer>
              <Icon src={BankSvg} />
              <AccountNumber>{bankAccount.accountNumber}</AccountNumber>
            </BankIconAndAccountNumberContainer>
            <BankNameContainer>
              <BankName>{startCase(toLower(bankAccount.bankName))}</BankName>
            </BankNameContainer>
          </BankCard>
        </Col>
      </Row>
      <Actions>
        <Button buttonType="warning" onClick={() => setShowingDeleteModal(true)} disabled={isDeleting}>
          Remove bank account
        </Button>
      </Actions>
    </>
  );
};

export default BankAccountInfo;
