import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Button from '../../../../components/Button/index';
import Loading from '../../../../components/Loading/index';
import Modal from '../../../../components/Modal/index';
import { Header3, P2 } from '../../../../components/Typography/index';
import { publishClaimRequested, PUBLISH_CLAIM_STORE_KEY } from '../../../../redux/claim/claim.actions';
import { createLoadingSelector } from '../../../../redux/loading/loading.selector';
import { penceToPounds } from '../../../../utils/common.utils';
import { ClaimActionModalProps } from '../index';

const Action = styled.div`
  margin-top: 20px;
  padding: 12px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const ClaimCreateModal: React.SFC<ClaimActionModalProps> = ({
  claim,
  onDismiss,
  isPublishable,
  claimLimitExceeded,
  claimDetails
}) => {
  const dispatch = useDispatch();
  const publishClaimLoadingSelector = createLoadingSelector([PUBLISH_CLAIM_STORE_KEY]);
  const isLoading = useSelector(publishClaimLoadingSelector);

  const publishClaim = () => {
    dispatch(publishClaimRequested({ claimId: claim.id, claimDetails }));
  };

  let content = (
    <Fragment>
      <Header3 style={{ marginBottom: 6 }}>More information required</Header3>
      <P2 style={{ marginBottom: 24 }}>Please upload the required tenancy documents and at least one claim item.</P2>
      <Action>
        <Button buttonType="secondary" noArrow={true} onClick={onDismiss}>
          Okay, return to form
        </Button>
      </Action>
    </Fragment>
  );

  if (isPublishable) {
    content = (
      <Fragment>
        <Header3 style={{ marginBottom: 14 }}>Are you sure you wish to create this claim?</Header3>
        {claimLimitExceeded ? (
          <P2 style={{ marginBottom: 10 }}>
            You have raised charges that exceed the maximum liability for this Reposit.{' '}
            <BoldText>Your tenant will only be requested to settle up to £{penceToPounds(claim.limit)} via Reposit.</BoldText>
          </P2>
        ) : null}
        <P2 style={{ marginBottom: 10 }}>
          Once submitted you will be unable to add any further claim items. The tenant(s) will then be notified of the end of
          tenancy charges raised and have 7 days to respond.
        </P2>
        <Action>
          <Button buttonType="secondary" noArrow={true} onClick={onDismiss}>
            No, return to form
          </Button>
          <Button onClick={publishClaim}>Yes</Button>
        </Action>
      </Fragment>
    );
  }

  return <Modal onDismiss={onDismiss}>{isLoading ? <Loading /> : content}</Modal>;
};

export default ClaimCreateModal;
