import { OrganizationsApi, UserDTO } from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import SCHEMA from '../schema';
import { createOrganizationsApi, runSagaWithAuth } from '../utils/api.utils';
import {
  FETCH_ORGANIZATION_USERS_API_REQUESTED,
  UserActionTypes,
  fetchOrganizationUsersSuccess,
  fetchOrganizationUsersFailed
} from './user.actions';
import { getCurrentOrganizationId } from '../auth/auth.selectors';

// ****************
// WORKERS
// ****************

export function* fetchUsersForOrganization() {
  try {
    const organizationId: string = yield select(getCurrentOrganizationId);
    const organizationsApi: OrganizationsApi = yield createOrganizationsApi();
    const apiResponse: AxiosResponse<UserDTO[]> = yield call(
      runSagaWithAuth(() => organizationsApi.fetchUsersByOrganizationId(organizationId))
    );
    const ids: string[] = yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.users);
    yield put<UserActionTypes>(fetchOrganizationUsersSuccess(ids));
  } catch (e) {
    yield put<UserActionTypes>(fetchOrganizationUsersFailed(get(e, 'response.data.message', e)));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchUserSagas() {
  yield takeLatest(FETCH_ORGANIZATION_USERS_API_REQUESTED, fetchUsersForOrganization);
}
