import React, { useState } from 'react';
import styled from 'styled-components';
import { useIsMobile } from '../../../../hooks/useViewport';
import { DocumentEntity } from '../../../../redux/entities/entities.types';
import Card from '../../../Card/index';
import { P2, P3 } from '../../../Typography/index';
import { ItemProposal } from '../ClaimItem/ClaimItem';
import { Evidence } from './Evidence';
import { History } from './History';

interface AuditTrailProps {
  agentDocuments: DocumentEntity[];
  tenantDocuments: DocumentEntity[];
  itemProposals: ItemProposal[];
}

const AuditTrailCard = styled(Card)`
  flex-grow: 1;
  margin-top: 1rem;
  min-width: 270px;
  padding-bottom: 14px;
`;

type TabOptions = 'evidence' | 'history';

interface TabProps {
  currentTab: TabOptions;
  tab: TabOptions;
}

export const AuditTrailContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: space-around;
`;

const FlexBox = styled.div`
  display: flex;
  justify-content: center;
`;

export const DocumentLinks = styled.ul`
  padding: 0 0 0 14px;
  list-style: square;
  margin-top: 10px;
  line-height: 1.4em;
`;

export const DocumentLink = styled.li`
  margin: 0 0 6px;

  a {
    color: ${props => props.theme.colors.primaryBrand};
    display: inline;
    font-family: ${props => props.theme.typography.face.secondary};
  }
`;

export const EvidenceList: React.FC<{ documents?: DocumentEntity[] }> = ({ documents }) => {
  return documents && documents.length ? (
    <DocumentLinks>
      {documents.map(doc => (
        <DocumentLink key={doc.id}>
          <a href={doc.url} target="_blank" rel="noopener noreferrer">
            {doc.name}
          </a>
        </DocumentLink>
      ))}
    </DocumentLinks>
  ) : (
    <P3>No Evidence Provided</P3>
  );
};

const TabContainer = styled.div`
  display: flex;
  margin: auto;
`;

const Tab = styled.div<TabProps>`
  background-color: ${props => (props.currentTab === props.tab ? props.theme.colors.secondaryBrand : 'white')};
  width: 130px;
  padding: 6px 0;
  text-align: center;
  border: solid 1px #e9ecef;

  cursor: ${props => (props.currentTab === props.tab ? 'default' : 'pointer')};
`;

const HistoryTab = styled(Tab)`
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  border-left: none;
`;

const EvidenceTab = styled(Tab)`
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
`;

const TabHeader = styled(P2)<TabProps>`
  margin-bottom: 0;
  color: ${props => (props.currentTab === props.tab ? 'white' : props.theme.colors.body)};
`;

const AuditTrail: React.FC<AuditTrailProps> = ({ tenantDocuments, agentDocuments, itemProposals }) => {
  const [currentTab, setCurrentTab] = useState<TabOptions>('history');

  // this is here because we cannot access the inner styles of the card via styled components
  const isMobile = useIsMobile();

  const horizontalPadding = isMobile ? 26 : 40;

  return (
    <AuditTrailContainer>
      <AuditTrailCard style={{ padding: `24px ${horizontalPadding}px 14px` }}>
        <FlexBox>
          <TabContainer>
            <HistoryTab currentTab={currentTab} tab="history" onClick={() => setCurrentTab('history')}>
              <TabHeader tab="history" currentTab={currentTab}>
                History
              </TabHeader>
            </HistoryTab>
            <EvidenceTab currentTab={currentTab} tab="evidence" onClick={() => setCurrentTab('evidence')}>
              <TabHeader tab="evidence" currentTab={currentTab}>
                Evidence
              </TabHeader>
            </EvidenceTab>
          </TabContainer>
        </FlexBox>

        {currentTab === 'evidence' ? (
          <Evidence agentDocuments={agentDocuments} tenantDocuments={tenantDocuments} itemProposals={itemProposals} />
        ) : (
          <History itemProposals={itemProposals} />
        )}
      </AuditTrailCard>
    </AuditTrailContainer>
  );
};

export default AuditTrail;
