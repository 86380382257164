import React from 'react';
import { appConfig } from '../../appConfig';
const TrustBox = () => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null);
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if ((window as any).Trustpilot) {
      (window as any).Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale="en-GB"
      data-template-id={appConfig.trustpilot.dataTemplateId}
      data-businessunit-id={appConfig.trustpilot.dataBusinessUnitId}
      data-style-height="52px"
      data-style-width="100%"
    >
      <a href={appConfig.trustpilot.link} target="_blank" rel="noopener noreferrer">
        {' '}
        Trustpilot
      </a>
    </div>
  );
};
export default TrustBox;
