import { OrderDTOStatusIdEnum } from '@reposit/api-client';
import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getBreakpoint } from '../../base/style';
import { clearActiveForms, setActiveForms } from '../../redux/active-form/active-form.actions';
import { ActiveFormOptions } from '../../redux/active-form/active-forms.types';
import {
  addGuarantorRequested,
  ADD_GUARANTOR_STORE_KEY,
  setGuarantorModal,
  updateGuarantorRequested,
  UPDATE_GUARANTOR_STORE_KEY
} from '../../redux/guarantor/guarantor.actions';
import { getGuarantorModal } from '../../redux/guarantor/guarantor.selectors';
import { GuarantorModalPayload } from '../../redux/guarantor/guarantor.types';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import {
  resendTenantInviteRequested,
  RESEND_TENANT_INVITE_STORE_KEY,
  setTenantEditFormOpenStatus,
  updateTenantDetailsRequested,
  UPDATE_TENANT_DETAILS_STORE_KEY,
  sendTenantReminderEmailRequested,
  SEND_TENANT_REMINDER_EMAIL_STORE_KEY
} from '../../redux/reposit/reposit.actions';
import { getTenantEditFormsOpen } from '../../redux/reposit/reposit.selectors';
import { TenantWithActions } from '../../redux/selectors/index';
import { getTenantActions, RepositAction, TenantCompletionStatus } from '../../redux/selectors/tenant.selectors';
import Button from '../Button';
import Modal from '../Modal/index';
import TenantForm from '../TenantForm/index';
import TenantStatus from '../TenantStatus/index';
import { Header3, Header4, P2, P3, StyledLink } from '../Typography/index';
import { GuarantorFormValues } from './guarantor-form';
import { GuarantorModal } from './guarantor-modal';

interface TenantListItemProps {
  tenancyId: string;
  editMode: boolean;
  tenant: TenantWithActions;
  disableRemove: boolean;
  onDelete: (id: string) => void;
  tenancyOrderStatus: string;
  tenancyOrderId: string;
}

const TenantItem = styled.div<{ isOpen?: boolean }>`
  background: ${props => props.theme.colors.bgAccent};
  margin: 0 0 6px;
  padding: 0 24px 6px;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    padding: 0 34px 6px;
  }

  &:hover {
    background: ${props => (!props.isOpen ? props.theme.colors.bgHoverAccent : props.theme.colors.bgAccent)};
  }
`;

const TenantItemInner = styled.li`
  border-radius: 8px;
  cursor: default;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 6px;
  padding-top: 15px;
`;

const Tenant = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

const TenantName = styled(Header4)`
  font-size: 1em;
  margin: 0;
`;

const TenantEmail = styled(P2)`
  margin: 0 0 0 10px;
`;

const StatusMessage = styled(P3)`
  font-size: 10px;
  margin: 12px 6px 12px auto;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    padding: 0 20px;
  }
`;

const RemoveButton = styled(Button)`
  background: #ffe6e6;
  border-color: #ffe6e6;
  color: #f03838;

  &:hover {
    background: #f03838;
    border-color: #f03838;
    color: #ffe6e6;
  }
`;

const Action = styled.div`
  padding: 12px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

const TextLink = styled(P3)`
  margin: 0;
  padding: 0;

  button {
    background: none;
    color: ${props => props.theme.colors.primaryBrand};
    cursor: pointer;
    display: inline;
    border: none;
    margin: 0 0 0 10px;
    padding: 0;
    text-decoration: underline;
  }
`;

const TenantLiability = styled(P2)`
  background: ${props => props.theme.colors.warning};
  border-radius: 8px;
  color: #fff;
  margin: 12px 0 16px;
  padding: 2px 12px;
`;

const EditAction = styled.div`
  margin: 0 0 0 auto;
`;

const Link = styled(StyledLink)`
  margin: 0 20px;
`;

const TenantListItem: React.SFC<TenantListItemProps> = ({
  tenancyId,
  tenancyOrderId,
  tenant,
  onDelete,
  editMode,
  tenancyOrderStatus
}) => {
  const dispatch = useDispatch();
  const [isRemoveTenantModalShowing, showRemoveTenantModal] = useState<boolean>(false);
  const guarantorModal = useSelector(getGuarantorModal);
  const actions = getTenantActions(tenant);
  const isResendingTenantInviteSelector = createLoadingSelector([RESEND_TENANT_INVITE_STORE_KEY]);
  const isResendingTenantInvite = useSelector(isResendingTenantInviteSelector);
  const isSendingTenantReminderSelector = createLoadingSelector([SEND_TENANT_REMINDER_EMAIL_STORE_KEY]);
  const isSendingTenantReminder = useSelector(isSendingTenantReminderSelector);
  const isEditingSelector = createLoadingSelector([UPDATE_TENANT_DETAILS_STORE_KEY]);
  const isEditing = useSelector(isEditingSelector);
  const tenantLiability = tenant.tenantLiability || 1;
  const tenantFormOpens = useSelector(getTenantEditFormsOpen);
  const isEditFormOpen = tenantFormOpens[tenant.id];

  const modalLoadingSelector = createLoadingSelector([ADD_GUARANTOR_STORE_KEY, UPDATE_GUARANTOR_STORE_KEY]);
  const isModalLoading = useSelector(modalLoadingSelector);

  const isTenantEditable = (actions: RepositAction[]) => {
    const registerAction = actions.find((action: RepositAction) => action.id === 'REPOSIT_REGISTERED');
    return !!(
      registerAction &&
      registerAction.status === TenantCompletionStatus.INCOMPLETE &&
      tenancyOrderStatus === OrderDTOStatusIdEnum.PENDING
    );
  };

  const isTenantActionsComplete = (actions: RepositAction[]) => {
    return actions.some(a => a.status === TenantCompletionStatus.INCOMPLETE);
  };

  const tenantIsEditable = isTenantEditable(actions);
  const tenantStillHasIncompleteActions = isTenantActionsComplete(actions);

  const showEditButton = isTenantEditable(actions);

  const guarantorRequest = (values: GuarantorFormValues) => {
    const payload = { tenancyId, tenancyUserId: tenant.id, ...values };
    if (guarantorModal.modalState === 'add') {
      dispatch(addGuarantorRequested(payload));
    } else {
      dispatch(updateGuarantorRequested(payload));
    }
  };

  const dispatchGuarantorModal = (payload: GuarantorModalPayload) => {
    dispatch(setGuarantorModal(payload));
  };

  return (
    <TenantItem key={tenant.id}>
      {showEditButton && isEditFormOpen ? (
        <div style={{ padding: '24px 0' }}>
          <TenantForm
            onSubmit={values => {
              const data = {
                firstName: values.firstName || '',
                lastName: values.lastName || '',
                email: values.email,
                guarantor: values.guarantor
              };
              // if guarantor is obj with empty values
              // it should be null
              // as any until we can fix the api client :(
              if (values.guarantor && (!values.guarantor.firstName && !values.guarantor.lastName && !values.guarantor.email)) {
                data.guarantor = null as any;
              }
              dispatch(
                updateTenantDetailsRequested({
                  tenancyInviteId: tenant.id,
                  tenancyId,
                  tenancyOrderId,
                  data
                })
              );
            }}
            formActions={
              <Fragment>
                <Link
                  to="#"
                  onClick={() => {
                    dispatch(setTenantEditFormOpenStatus({ tenantId: tenant.id, isOpen: false }));
                    dispatch(clearActiveForms());
                  }}
                >
                  Cancel edit tenant
                </Link>
                <Button disabled={isEditing}>Save</Button>
              </Fragment>
            }
            initialValues={{
              firstName: tenant.firstName,
              lastName: tenant.lastName,
              email: tenant.email,
              requireGuarantor: tenant.guarantor ? 'YES' : 'NO',
              guarantor: tenant.guarantor
            }}
          />
        </div>
      ) : (
        <TenantItemInner>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Tenant>
              <TenantName>
                {tenant.firstName} {tenant.lastName}
              </TenantName>
              <TenantEmail>{tenant.email.length > 36 ? `${tenant.email.substring(0, 36)}…` : tenant.email}</TenantEmail>
            </Tenant>
            <StatusMessage></StatusMessage>

            {showEditButton && (
              <EditAction>
                <Button
                  mini="true"
                  buttonType="secondary"
                  noArrow
                  onClick={() => {
                    dispatch(setTenantEditFormOpenStatus({ tenantId: tenant.id, isOpen: true }));
                    dispatch(setActiveForms([ActiveFormOptions.TENANTS]));
                  }}
                >
                  Edit
                </Button>
              </EditAction>
            )}
            {editMode && (
              <EditAction>
                <RemoveButton buttonType="tertiary" mini="true" onClick={() => showRemoveTenantModal(true)}>
                  Remove
                </RemoveButton>
              </EditAction>
            )}
          </div>
          {tenant.guarantor ? (
            <div style={{ display: 'flex' }}>
              <P3 style={{ marginBottom: 0 }}>
                Guarantor: {tenant.guarantor.firstName} {tenant.guarantor.lastName} ({tenant.guarantor.email}
                {tenant.guarantor.mobileNumber ? `, ${tenant.guarantor.mobileNumber}` : ''})
              </P3>
              {!showEditButton && !editMode ? (
                <TextLink>
                  <button onClick={() => dispatchGuarantorModal({ isOpen: true, modalState: 'edit', tenantId: tenant.id })}>
                    Edit
                  </button>
                </TextLink>
              ) : null}
            </div>
          ) : null}
          {!tenant.guarantor && !showEditButton && !editMode ? (
            <div style={{ paddingTop: 10 }}>
              <Button
                buttonType="secondary"
                mini="true"
                onClick={() => dispatchGuarantorModal({ isOpen: true, modalState: 'add', tenantId: tenant.id })}
              >
                Add Guarantor
              </Button>
            </div>
          ) : null}
        </TenantItemInner>
      )}

      {tenantIsEditable && (
        <div style={{ margin: '17px 0 20px 0' }}>
          <TextLink>
            Invite sent - awaiting response
            <button
              disabled={isResendingTenantInvite}
              onClick={() => dispatch(resendTenantInviteRequested(tenancyId, tenant.id))}
            >
              {isResendingTenantInvite ? `Pending...` : `Re-send invite`}
            </button>
          </TextLink>
        </div>
      )}

      {!tenantIsEditable && tenantStillHasIncompleteActions && !editMode && (
        <div style={{ margin: '17px 0 20px 0' }}>
          <TextLink>
            Tenant has incomplete actions
            <button
              disabled={isSendingTenantReminder}
              onClick={() => dispatch(sendTenantReminderEmailRequested(tenancyId, tenant.id))}
            >
              {isSendingTenantReminder ? `Pending...` : `Send reminder email`}
            </button>
          </TextLink>
        </div>
      )}

      {}

      {!editMode && <TenantStatus actions={actions} />}

      {tenantLiability > 1 ? <TenantLiability>This tenant is liable for {tenantLiability} tenants</TenantLiability> : undefined}

      {isRemoveTenantModalShowing ? (
        <Modal onDismiss={() => showRemoveTenantModal(false)}>
          <Fragment>
            <Header3 style={{ marginBottom: 6 }}>Are you sure you want to remove this tenant?</Header3>
            <Action>
              <Button buttonType="secondary" noArrow={true} onClick={() => showRemoveTenantModal(false)}>
                No, return to form
              </Button>
              <Button onClick={() => onDelete(tenant.id)}>Yes</Button>
            </Action>
          </Fragment>
        </Modal>
      ) : null}

      {guarantorModal.isOpen && guarantorModal.modalState && guarantorModal.tenantId === tenant.id ? (
        <GuarantorModal
          modalType={guarantorModal.modalState}
          onSubmit={guarantorRequest}
          onDismiss={() => dispatchGuarantorModal({ isOpen: false, modalState: undefined })}
          initialValues={tenant.guarantor}
          isLoading={isModalLoading}
          tenant={tenant}
        />
      ) : null}
    </TenantItem>
  );
};

export default TenantListItem;
