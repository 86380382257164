import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import styled from 'styled-components';

import { Header1, P1, P2, Header3, Caption, Header4 } from '../../Typography/index';
import Panel from '../../Panel/index';
import { ClaimDTOStatusEnum } from '@reposit/api-client';
import Button from '../../Button/index';
import { NavLink } from 'react-router-dom';
import { Amount } from '../../Library/SupplierOffer/ClaimItem/components';

interface CreateClaimHeaderProps {
  substantiationDeadlineDaysRemaining: number;
  outcomeDeadlineDaysRemaining: number;
  responseDeadlineDaysRemaining: number;
  status: ClaimDTOStatusEnum;
  claimId: string;
  address: string;
  showAddress: boolean;
  redirectToReposit: () => void;
  initialAmount?: number;
  proposalAmount?: number;
  disputed: boolean;
  isAgentDeciding: boolean;
  hasGoneBack: boolean;
  hasAgentAcceptedTenantProposal: boolean;
  isMediationEnabled: boolean;
}

const RepositInfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const WhiteP2 = styled(P2)`
  color: #fff;
`;
const WhiteLink = styled.a`
  color: #fff;
  cursor: pointer;
  text-decoration: underline;
  :hover {
    color: #ddd;
  }
`;

const BoldText = styled.span`
  font-weight: bold;
  display: inline;
`;

const CreateClaimHeader: React.SFC<CreateClaimHeaderProps> = ({
  substantiationDeadlineDaysRemaining,
  outcomeDeadlineDaysRemaining,
  responseDeadlineDaysRemaining,
  status,
  claimId,
  address,
  redirectToReposit,
  initialAmount,
  disputed,
  proposalAmount,
  isAgentDeciding,
  hasGoneBack,
  hasAgentAcceptedTenantProposal,
  isMediationEnabled
}) => (
  <Container>
    {status === ClaimDTOStatusEnum.DRAFT ? (
      <Row>
        <Col sm={12}>
          <Header1>{`Create a Claim`}</Header1>
          <RepositInfoContainer>
            <Caption style={{ marginBottom: '1.3em' }}>{address}</Caption>
            <Button onClick={redirectToReposit} buttonType="secondary">
              View Reposit
            </Button>
          </RepositInfoContainer>

          <P1 style={{ marginBottom: '2.25rem' }}>
            Let's create a claim. The details you attach to this claim are important in proving the validity of a claim and
            ensuring you get paid out as quickly as possible.
          </P1>
        </Col>
      </Row>
    ) : null}
    {status !== ClaimDTOStatusEnum.DRAFT ? (
      <Row>
        <Col sm={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '1rem'
            }}
          >
            <Header1 style={{ margin: 0 }}>{`Claim`}</Header1>
            {initialAmount && isMediationEnabled ? (
              <Header4 style={{ margin: 0 }}>
                Original claim value: <Amount style={{ display: 'inline' }} amount={initialAmount} />
              </Header4>
            ) : null}
          </div>
          <RepositInfoContainer>
            <Caption style={{ marginBottom: '1.3em' }}>{address}</Caption>
            <Button onClick={redirectToReposit} buttonType="secondary" style={{ flexShrink: 0 }}>
              View Reposit
            </Button>
          </RepositInfoContainer>
          {status !== ClaimDTOStatusEnum.MEDIATION &&
          status !== ClaimDTOStatusEnum.AWAITINGSUBSTANTIATION &&
          !hasAgentAcceptedTenantProposal ? (
            <P1 style={{ marginBottom: '2.25rem' }}>
              Claims can be created at the end of a tenancy when you believe the tenants owe money for something they were liable
              for. The details you attach to the claim are important in proving the validity of a claim and ensuring you get paid
              out as quickly as possible.
            </P1>
          ) : null}
          {status === ClaimDTOStatusEnum.AWAITINGSUBSTANTIATION ? (
            <Panel>
              <Header3 color="#fff">Tenancy Document Upload Required</Header3>
              {disputed ? (
                <>
                  <WhiteP2>An agreement has not been reached and the tenant has requested a formal dispute.</WhiteP2>
                  <WhiteP2>
                    The independent adjudicator will only consider the information that has been provided to them. Make sure to
                    provide evidence for each individual charge being claimed as well as all standard tenancy documents.
                  </WhiteP2>
                </>
              ) : (
                <>
                  <WhiteP2>The tenant(s) have not responded to your end of tenancy charges.</WhiteP2>
                  <WhiteP2>
                    To ensure a successful payout, upload the remaining required documents for both the individual charges and
                    standard tenancy documents.
                  </WhiteP2>
                </>
              )}
              <WhiteP2>
                If you would like to learn more about the dispute process, more information can be found{' '}
                <WhiteLink href="https://support.reposit.co.uk/en/knowledge/what-happens-if-the-tenant-disagrees-with-my-claim">
                  here
                </WhiteLink>
                .
              </WhiteP2>
              <WhiteP2>
                This must be completed within <BoldText>{substantiationDeadlineDaysRemaining} days</BoldText>.
              </WhiteP2>
            </Panel>
          ) : null}
          {hasAgentAcceptedTenantProposal ? (
            <P1>
              You have accepted the tenant(s) proposal. Payment will be processed within 1 working day from accepting this
              proposal.
            </P1>
          ) : null}
        </Col>
      </Row>
    ) : null}

    {/* CTAs */}
    {/* TODO: we should probably show a message if the due date has passed and they can no longer publish the claim */}
    {!!outcomeDeadlineDaysRemaining && outcomeDeadlineDaysRemaining > 0 && status === ClaimDTOStatusEnum.DRAFT ? (
      <Row>
        <Col sm={12}>
          <Panel>
            <Header3 color="#fff">You have {outcomeDeadlineDaysRemaining} day(s) left to submit this claim</Header3>
            <WhiteP2>
              If you no longer wish to make a claim you can ignore this message and the claim will automatically deactivate in{' '}
              {outcomeDeadlineDaysRemaining} day(s).
            </WhiteP2>
          </Panel>
        </Col>
      </Row>
    ) : null}

    {outcomeDeadlineDaysRemaining === 0 && status === ClaimDTOStatusEnum.DRAFT ? (
      <Row>
        <Col sm={12}>
          <Panel>
            <Header3 color="#fff">Today is your last day to submit this claim</Header3>
            <WhiteP2>
              If you no longer wish to make a claim you can ignore this message and the claim will automatically deactivate
              tomorrow.
            </WhiteP2>
          </Panel>
        </Col>
      </Row>
    ) : null}

    {!!outcomeDeadlineDaysRemaining &&
    outcomeDeadlineDaysRemaining > 0 &&
    status === ClaimDTOStatusEnum.AWAITINGADDITIONALINFORMATION ? (
      <Row>
        <Col sm={12}>
          <Panel type="warning" style={{ paddingBottom: 36 }}>
            <Header3 color="#fff" style={{ margin: 0 }}>
              You need to improve your claim
            </Header3>
            <WhiteP2>We need you to help us substanciate your claim. To do this we require further evidence.</WhiteP2>
            <NavLink to={`/claims/${claimId}/action-required`} style={{ textDecoration: 'none' }}>
              <Button buttonType="white">Improve your claim</Button>
            </NavLink>
          </Panel>
        </Col>
      </Row>
    ) : null}

    {status === ClaimDTOStatusEnum.MEDIATION &&
    proposalAmount !== null &&
    proposalAmount !== undefined &&
    (isAgentDeciding || hasGoneBack) ? (
      <Row>
        <Col sm={12}>
          <Panel>
            <Header3 color="#fff">
              You've received a proposed settlement of{' '}
              <Amount
                style={{ display: 'inline', color: 'white', fontWeight: 'bold', fontFamily: 'inherit' }}
                amount={proposalAmount}
              />{' '}
              and have {responseDeadlineDaysRemaining} days to {proposalAmount === 0 ? 'respond' : 'collect payment'}
            </Header3>
            <WhiteP2>
              The tenant has reviewed the end of tenancy charges and corresponding evidence and has submitted a proposed
              settlement. This is an opportunity to avoid formal dispute.
            </WhiteP2>
          </Panel>
        </Col>
      </Row>
    ) : null}
  </Container>
);

export default CreateClaimHeader;
