import { CreateTenancyInviteDetailsDTO } from '@reposit/api-client';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import { TenantFormValues } from '../../../../components/TenantForm/index';
import Tenants from '../../../../components/Tenants/index';
import { clearActiveForms, setActiveForms } from '../../../../redux/active-form/active-form.actions';
import { ActiveFormOptions } from '../../../../redux/active-form/active-forms.types';
import { dismissFlashMessage } from '../../../../redux/flash-messages/flash-messages.actions';
import { createFlashMessagesSelector } from '../../../../redux/flash-messages/flash-messages.selector';
import {
  createTenantRequested,
  CREATE_TENANT_STORE_KEY,
  deleteTenantRequested,
  DELETE_TENANT_STORE_KEY,
  setIsTenantFormOpen,
  UPDATE_TENANT_DETAILS_STORE_KEY
} from '../../../../redux/reposit/reposit.actions';
import { getIsTenantFormOpen } from '../../../../redux/reposit/reposit.selectors';
import { TenancyOrderWithActions, TenantWithActions } from '../../../../redux/selectors/index';
import { ADD_GUARANTOR_STORE_KEY, UPDATE_GUARANTOR_STORE_KEY } from '../../../../redux/guarantor/guarantor.actions';

interface TenantsContainerProps {
  isInCreate: boolean;
  tenancyOrder?: TenancyOrderWithActions;
  editMode: boolean;
  disabled: boolean;
  disableAddNew: boolean;
  disableRemove: boolean;
  tenancyOrderStatus: string;
}

const TenantsContainer = ({
  disabled,
  tenancyOrder,
  editMode,
  disableAddNew,
  disableRemove,
  tenancyOrderStatus
}: TenantsContainerProps) => {
  const dispatch = useDispatch();
  useEffect(() => {
    ReactTooltip.rebuild();
  });
  const tenancyId = get(tenancyOrder, 'tenancy.id');
  const tenants: TenantWithActions[] = get(tenancyOrder, 'tenancy.tenants', []);

  const getTenantFlashMessageSelector = createFlashMessagesSelector([
    CREATE_TENANT_STORE_KEY,
    UPDATE_TENANT_DETAILS_STORE_KEY,
    DELETE_TENANT_STORE_KEY,
    ADD_GUARANTOR_STORE_KEY,
    UPDATE_GUARANTOR_STORE_KEY
  ]);
  const isTenantFormShowing = useSelector(getIsTenantFormOpen);
  const tenantsFlashMessage = useSelector(getTenantFlashMessageSelector);
  const addTenant = (tenant: TenantFormValues) => {
    const { email, firstName, lastName, guarantor } = tenant;
    // "as any" to fix the api client issue
    // TODO: PLEASE CHANGE THIS :(
    const details: CreateTenancyInviteDetailsDTO = { firstName, lastName, guarantor: null as any };
    if (guarantor && (guarantor.firstName && guarantor.lastName && guarantor.email)) {
      details.guarantor = guarantor;
    }
    dispatch(createTenantRequested({ tenancyId, email, details }));
  };
  const showTenantForm = (value: boolean) => {
    !!value ? dispatch(setActiveForms([ActiveFormOptions.TENANTS])) : dispatch(clearActiveForms());
    dispatch(setIsTenantFormOpen(value));
  };
  const deleteTenant = (tenancyInviteId: string) => dispatch(deleteTenantRequested({ tenancyInviteId, tenancyId }));
  const dismissTenantsFlashMessage = () =>
    dispatch(
      dismissFlashMessage([
        CREATE_TENANT_STORE_KEY,
        DELETE_TENANT_STORE_KEY,
        UPDATE_TENANT_DETAILS_STORE_KEY,
        ADD_GUARANTOR_STORE_KEY,
        UPDATE_GUARANTOR_STORE_KEY
      ])
    );

  return (
    <Tenants
      tenancyId={tenancyId}
      editMode={editMode}
      disabled={disabled}
      tenants={tenants}
      addTenant={addTenant}
      isTenantFormShowing={isTenantFormShowing}
      showTenantForm={showTenantForm}
      deleteTenant={deleteTenant}
      flashMessage={tenantsFlashMessage}
      onDismissFlashMessage={dismissTenantsFlashMessage}
      disableAddNew={disableAddNew}
      disableRemove={disableRemove}
      tenancyOrderStatus={tenancyOrderStatus}
    />
  );
};

export default TenantsContainer;
