import { NetPromoterScoreDTOCategoryEnum } from '@reposit/api-client';
import { range } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import CopyContentIcon from '../../assets/svg/content-copy.svg';
import { SUBMIT_NPS_STORE_KEY } from '../../redux/account/account.actions';
import { getIsCollectingTrustpilotReview } from '../../redux/account/account.selectors';
import { CreateNPS } from '../../redux/account/account.types';
import { createErrorMessageSelector } from '../../redux/error/error.selector';
import Button from '../Button/index';
import TextArea from '../FormFields/TextArea/index';
import Modal from '../Modal/index';
import { Header3, Header4, P1, P2, P3 } from '../Typography/index';
import { Action, LegendContainer, ScoreButton, ScoresContainer } from './components';
import TrustBox from './trust-box';

const THREE_SECONDS = 3000;

const Icon = styled.span`
  background: transparent center center;
  background-image: url(${CopyContentIcon});
  background-size: 16px;
  display: inline-block;
  height: 16px;
  width: 16px;
`;

const UserFeedback = styled(P2)`
  margin-top: 20px;
`;

const TextLink = styled.span`
  margin: 0;
  padding: 0;
  button {
    background: none;
    color: ${props => props.theme.colors.primaryBrand};
    cursor: pointer;
    display: inline;
    border: none;
    margin: 0 0 0 10px;
    padding: 0;
    text-decoration: underline;
  }
`;

const FeedbackQuote = styled.span`
  font-weight: lighter;
  font-style: italic;
  margin-right: 5px;
`;

const ReviewCopied = styled(P3)`
  color: ${props => props.theme.colors.positive};
  text-align: center;
`;

const TrustBoxContainer = styled.div`
  padding: 20px;
`;

export const getNPSCategory = (score: number): NetPromoterScoreDTOCategoryEnum => {
  if (score < 7) return NetPromoterScoreDTOCategoryEnum.DETRACTOR;
  if (score < 9) return NetPromoterScoreDTOCategoryEnum.PASSIVE;
  return NetPromoterScoreDTOCategoryEnum.PROMOTER;
};

const getQuestion = (score: number) => {
  const questions = {
    [NetPromoterScoreDTOCategoryEnum.DETRACTOR]:
      'Can you tell us more about your experience, so we can get it right the next time?',
    [NetPromoterScoreDTOCategoryEnum.PASSIVE]: 'Can you tell us more about your experience, so we can do even better next time?',
    [NetPromoterScoreDTOCategoryEnum.PROMOTER]: 'Can you tell us the reason(s) you scored us so highly?'
  };
  const category = getNPSCategory(score);
  return questions[category];
};

const copyReview = (message: string, setReviewCopied: React.Dispatch<React.SetStateAction<boolean>>) => {
  navigator.clipboard.writeText(message);
  setReviewCopied(true);
  setTimeout(() => {
    setReviewCopied(false);
  }, THREE_SECONDS);
};

const scores = range(0, 11);

interface NPSMessageFormProps {
  score: number;
  onChange: (value: string) => void;
  message: string;
}

const NPSMessageForm = ({ onChange, score, message }: NPSMessageFormProps) => {
  useEffect(() => {
    onChange('');
  }, [score, onChange]);
  return (
    <>
      <Header4 style={{ marginTop: 24 }}>{getQuestion(score)}</Header4>
      <TextArea name="message" value={message} onChange={e => onChange(e.target.value)} onBlur={() => {}} />
    </>
  );
};

interface NPSModalProps {
  onSubmit: (payload: CreateNPS) => void;
  onDismiss: () => void;
}

export const NPSModal = ({ onSubmit, onDismiss }: NPSModalProps) => {
  const [message, setMessage] = useState('');
  const [hoveredScore, setHoveredScore] = useState<number | undefined>(undefined);
  const [selectedScore, setSelectedScore] = useState<number | undefined>(undefined);

  const collectingTrustpilotReview = useSelector(getIsCollectingTrustpilotReview);
  const [reviewCopied, setReviewCopied] = useState<boolean>(false);

  const errorSelector = createErrorMessageSelector([SUBMIT_NPS_STORE_KEY]);
  const error = useSelector(errorSelector);

  return (
    <Modal onDismiss={onDismiss}>
      {collectingTrustpilotReview ? (
        <Fragment>
          <Header3 style={{ marginBottom: 10, textAlign: 'center' }}>Thank you for your feedback!</Header3>
          <P1>We would greatly appreciate if you could take a couple of minutes to leave a Trustpilot review.</P1>
          <TrustBoxContainer>
            <TrustBox />
          </TrustBoxContainer>
          {message ? (
            <Fragment>
              <UserFeedback>
                Your feedback: <FeedbackQuote>"{message}"</FeedbackQuote>{' '}
                <Icon onClick={() => copyReview(message, setReviewCopied)} /> Copy{' '}
              </UserFeedback>
              {reviewCopied ? <ReviewCopied>Feedback copied to clipboard</ReviewCopied> : null}
            </Fragment>
          ) : null}
          <Action>
            <TextLink>
              <button onClick={onDismiss}>Close</button>
            </TextLink>
          </Action>
        </Fragment>
      ) : (
        <Fragment>
          <Header3 style={{ marginBottom: 10, textAlign: 'center' }}>
            How likely is it that you would recommend Reposit to a friend or colleague?
          </Header3>

          <ScoresContainer onMouseLeave={() => setHoveredScore(undefined)}>
            {scores.map(s => {
              let active = false;
              const score = hoveredScore || hoveredScore === 0 ? hoveredScore : selectedScore;

              if (score !== undefined) {
                active = s <= score;
              }
              return (
                <ScoreButton
                  hoveredScore={hoveredScore}
                  selectedScore={selectedScore}
                  active={active}
                  onMouseOver={() => setHoveredScore(s)}
                  onClick={() => setSelectedScore(s)}
                  key={s}
                >
                  {s}
                </ScoreButton>
              );
            })}
          </ScoresContainer>
          <LegendContainer>
            <P2 style={{ marginBottom: 0 }}>
              <span style={{ fontWeight: 'bold' }}>0</span> - Not at all likely
            </P2>
            <P2 style={{ marginBottom: 0 }}>
              <span style={{ fontWeight: 'bold' }}>10</span> - Extremely likely
            </P2>
          </LegendContainer>
          {selectedScore !== undefined && <NPSMessageForm score={selectedScore} onChange={setMessage} message={message} />}
          {selectedScore !== undefined && (
            <Action>
              <Button
                disabled={selectedScore === undefined}
                onClick={() => {
                  if (selectedScore !== undefined) {
                    onSubmit({ score: selectedScore, feedback: message === '' ? undefined : message });
                  }
                }}
              >
                Submit
              </Button>
            </Action>
          )}

          {error && (
            <P2 color={'#df6b6b'} style={{ marginTop: 24 }}>
              There was an issue submitting your feedback, please try again
            </P2>
          )}
        </Fragment>
      )}
    </Modal>
  );
};
