import { AppState } from '../root.reducer';
import { DocumentEntity } from '../entities/entities.types';
import { getDocumentsByIds } from '../selectors/document.selectors';

export const getClaimItemDocumentIds = (state: AppState): string[] => state.document.claimItemDocumentIds;

export const getClaimItemDocuments = (state: AppState): DocumentEntity[] => {
  const ids = getClaimItemDocumentIds(state);
  return getDocumentsByIds(state, ids);
};
