import { denormalize } from 'normalizr';
import { sortBy, get } from 'lodash';

import { AppState } from '../root.reducer';
import SCHEMA from '../schema';
import {
  OrderCustomerActionDTO,
  TenancyDTO,
  OrderWithOrderCustomersDTO,
  OrderCustomerDTO,
  TenantDTO,
  CheckoutWithClaimDTO,
  PolicyDocumentDTO
} from '@reposit/api-client';
import { DocumentEntity } from '../entities/entities.types';

export interface GetTenancyOrderProps {
  tenancyOrderId: string;
}

export interface TenantWithActions extends TenantDTO {
  actions: OrderCustomerActionDTO[];
  tenantLiabilty: number;
}

export interface TenancyWithTenantActions extends Omit<TenancyDTO, 'tenants'> {
  tenants: TenantWithActions[];
  checkout: CheckoutWithClaimDTO;
}

export interface TenancyOrderWithActions {
  id: string;
  tenancy: TenancyWithTenantActions;
  order: OrderWithOrderCustomersDTO;
  createdAt: string;
  updatedAt: string;
}

export const getTenancyOrder = (state: AppState, props: GetTenancyOrderProps): TenancyOrderWithActions | undefined => {
  if (!state.entities.tenancyOrder) return undefined;

  let tenancyOrder = denormalize(state.entities.tenancyOrder[props.tenancyOrderId], SCHEMA.tenancyOrder, state.entities);

  if (tenancyOrder && tenancyOrder.tenancy && tenancyOrder.tenancy.PPM) {
    tenancyOrder.tenancy.PPM = (tenancyOrder.tenancy.PPM / 100).toFixed(2);
  }

  if (get(tenancyOrder, 'tenancy.tenants', []).length) {
    const orderCustomers = get<OrderCustomerDTO[]>(tenancyOrder, 'order.orderCustomers', []);
    const existingTenants = get(tenancyOrder, 'tenancy.tenants');
    const sortedTenants = sortBy(existingTenants, 'createdAt');
    tenancyOrder.tenancy.tenants = sortedTenants;

    if (orderCustomers.length) {
      tenancyOrder.tenancy.tenants = tenancyOrder.tenancy.tenants.map((tenant: TenantDTO) => {
        const userId = tenant.userId;
        const orderCustomer = orderCustomers.find(oc => oc.customer.userId === userId);
        return {
          ...tenant,
          actions: orderCustomer ? orderCustomer.actions : []
        };
      });
    }
  }

  return tenancyOrder;
};

export const getDocuments = (state: AppState, tenancyOrderId?: string): DocumentEntity[] => {
  if (!tenancyOrderId) return [];

  const tenancyOrder = getTenancyOrder(state, { tenancyOrderId });
  let docs: DocumentEntity[] = [];

  const tenancyDocs = get(tenancyOrder, 'tenancy.documents', []);
  docs.push(...tenancyDocs);

  let policyDocs = get(tenancyOrder, 'order.policy.documents', []);

  if (policyDocs.length) {
    policyDocs = policyDocs.map((policyDoc: PolicyDocumentDTO) => policyDoc.document);
    docs.push(...policyDocs);
  }

  docs = docs.map(doc => {
    if (doc.typeId === 'POLICY_INFO') doc.name = 'Policy Document';
    if (doc.typeId === 'POLICY') doc.name = 'Policy Summary';
    return doc;
  });

  return docs;
};
