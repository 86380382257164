import { PropertiesApi, SearchPropertiesResultsDTO } from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import SCHEMA from '../schema';
import { createPropertyApi, runSagaWithAuth } from '../utils/api.utils';
import {
  searchOrganizationPropertiesFailed,
  searchOrganizationPropertiesSuccess,
  SEARCH_ORGANIZATION_PROPERTIES_API_REQUESTED,
  PropertiesActionTypes
} from './property.actions';
import { SearchPropertiesRequest } from './property.types';
import { getCurrentOrganizationId } from '../auth/auth.selectors';

// ****************
// WORKERS
// ****************

export function* searchPropertiesForOrganization({ payload }: { type: string; payload: SearchPropertiesRequest }) {
  try {
    const organizationId: string = yield select(getCurrentOrganizationId);
    const propertiesApi: PropertiesApi = yield createPropertyApi();
    const apiResponse: AxiosResponse<SearchPropertiesResultsDTO> = yield call(
      runSagaWithAuth(() => propertiesApi.searchProperties(1, 10, payload.query, organizationId))
    );
    const ids: string[] = yield syncEntitiesAndGetResults(apiResponse.data.results, SCHEMA.properties);

    yield put<PropertiesActionTypes>(searchOrganizationPropertiesSuccess(ids));
  } catch (e) {
    yield put<PropertiesActionTypes>(searchOrganizationPropertiesFailed(get(e, 'response.data.message', e)));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchPropertiesSagas() {
  yield takeLatest(SEARCH_ORGANIZATION_PROPERTIES_API_REQUESTED, searchPropertiesForOrganization);
}
