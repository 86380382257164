import React from 'react';
import styled from 'styled-components';
import RepositLogo from '../../assets/png/icon-full-colour.png';
import { P2 } from '../Typography/index';
import { LogoutButton } from '../SideMenu/components/index';

const Header = styled.div`
  align-items: center;
  background: #fff;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.025);

  display: flex;
  height: 48px;
  margin: 0 0 60px;
  padding: 0 20px;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: calc(100% - 40px);
  z-index: 999;
`;

const Avatar = styled.img`
  width: 32px;
`;

const EmailAddress = styled(P2)`
  margin: 0 0 0 20px;
`;

const Logout = styled(LogoutButton)`
  font-family: ${props => props.theme.typography.face.secondary};
  margin-left: auto;
`;

interface OnboardingHeaderProps {
  userEmail: string;
  onLogout: () => void;
}

export const AppSubHeader: React.SFC<OnboardingHeaderProps> = ({ userEmail, onLogout }) => {
  return (
    <Header>
      <Avatar src={RepositLogo} alt="Reposit Logo" />
      <EmailAddress>Welcome {userEmail}</EmailAddress>
      <Logout onClick={onLogout}>Logout</Logout>
    </Header>
  );
};
