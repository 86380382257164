import React, { Fragment } from 'react';
import Title from '../../../components/Onboarding/Title/index';
import { P1 } from '../../../components/Typography/index';
import PendingIcon from '../../../assets/svg/onboarding/pending.svg';
import { useSelector } from 'react-redux';
import { getCurrentOrganizationId } from '../../../redux/auth/auth.selectors';
import { getOrganization } from '../../../redux/selectors/organization.selectors';

export const BlockedByTerms: React.SFC<any> = () => {
  const orgId = useSelector(getCurrentOrganizationId);
  const org = useSelector(getOrganization(orgId));
  const adminEmails = org && org.adminEmails ? org.adminEmails.join(', ') : '';
  return (
    <Fragment>
      <Title icon={PendingIcon} bigIcon>
        Nearly there
      </Title>
      <P1>Thanks for that! You have done everything you need to do to access Reposit.</P1>
      <P1 style={{ fontWeight: 'bold' }}>
        Before you can access your dashboard, an admin user for this account needs to complete their onboarding.
      </P1>
      <P1>Admin users: {adminEmails}.</P1>
    </Fragment>
  );
};
