import {
  OrganizationWithRepositsAndRoles,
  OrganizationWithRepositsAndRolesRolesEnum,
  SubmitFeedbackDTOApplicationEnum,
  SubmitFeedbackDTOSubjectEnum
} from '@reposit/api-client';
import { push } from 'connected-react-router';
import { find, get } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useLogout from '../../hooks/useLogout';
import { setCurrentOrganizationRoles, setOrganizationId } from '../../redux/auth/auth.actions';
import { getCurrentOrganizationId, getHasOrganizationRole } from '../../redux/auth/auth.selectors';
import { submitFeedbackRequested, toggleFeedbackModal } from '../../redux/feedback/feedback.actions';
import { getIsFeedbackModalOpen } from '../../redux/feedback/feedback.selectors';
import { getCurrentLocation } from '../../redux/selectors/index';
import { getOrganizations } from '../../redux/selectors/organization.selectors';
import { getCurrentUser } from '../../redux/selectors/user.selectors';
import { getIsMobileMenuOpen } from '../../redux/system/system.selectors';
import AccountSelector from '../AccountSelector';
import CurrentUser from '../CurrentUser';
import FeedbackModal from '../FeedbackModal/index';
import { FeebackButton, LogoutButton, Navigation, NavItem, SideMenuContainer } from './components';
import MenuItem from './components/MenuItem';

export interface MenuItemDefinition {
  id: string;
  label: string;
  location: string;
  items?: MenuItemDefinition[];
}

const getAccountItems = (hasBankAccountsRole: boolean): MenuItemDefinition[] => {
  const items = [
    {
      id: 'ACCOUNT_TEAM',
      label: 'Team',
      location: '/account/team'
    },
    {
      id: 'ACCOUNT_NOTIFICATIONS',
      label: 'Notifications',
      location: '/account/notifications'
    },
    {
      id: 'ACCOUNT_INTEGRATION',
      label: 'Integration',
      location: '/account/integration'
    }
  ];

  if (hasBankAccountsRole) {
    items.push({
      id: 'ACCOUNT_BANK_ACCOUNTS',
      label: 'Bank Accounts',
      location: '/account/bank-accounts'
    });
  }

  return items;
};

const getMenuItems = (hasBankAccountsRole: boolean): MenuItemDefinition[] => {
  return [
    {
      id: 'REPOSITS',
      label: 'Reposits',
      location: '/reposits'
    },
    {
      id: 'CLAIMS',
      label: 'Claims',
      location: '/claims'
    },
    {
      id: 'ACCOUNT',
      label: 'Account',
      location: '/account',
      items: getAccountItems(hasBankAccountsRole)
    },
    {
      id: 'RESOURCES',
      label: 'Resources',
      location: '/resources'
    }
  ];
};

interface SideMenuProps {}

const SideMenu: React.FC<SideMenuProps> = () => {
  const isMenuOpen = useSelector(getIsMobileMenuOpen);
  const currentUser = useSelector(getCurrentUser);
  const userOrganizations = useSelector(getOrganizations(currentUser.organizations)) as OrganizationWithRepositsAndRoles[];
  const hasBankAccountsRole = useSelector(getHasOrganizationRole(OrganizationWithRepositsAndRolesRolesEnum.BANKACCOUNTS));
  const [menuItems, setMenuItems] = useState<MenuItemDefinition[]>([]);

  const currentOrgId = useSelector(getCurrentOrganizationId);
  const dispatch = useDispatch();
  const handleLogout = useLogout();
  const location = useSelector(getCurrentLocation);
  const onOrgChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const orgId = event.currentTarget.value;
    const org = userOrganizations.find(uo => uo.id === orgId) as OrganizationWithRepositsAndRoles;
    dispatch(setCurrentOrganizationRoles(org.roles));
    dispatch(setOrganizationId(event.currentTarget.value));
    const isRepositPath = location.pathname.includes('/reposits/');
    const isClaimPath = location.pathname.includes('/claims/');
    const shouldRoute = isRepositPath || isClaimPath;
    if (shouldRoute) {
      if (isRepositPath) {
        dispatch(push('/reposits'));
      } else {
        dispatch(push('/claims'));
      }
    }
  };
  const [expandedItemId, setExpandedItem] = useState('');
  const isFeedbackModalOpen = useSelector(getIsFeedbackModalOpen);

  useEffect(() => {
    setMenuItems(getMenuItems(hasBankAccountsRole));
  }, [hasBankAccountsRole]);

  useEffect(() => {
    const currentItem = find(menuItems, o => {
      if (o.items && o.items.length) {
        return !!find(o.items, j => j.location === location.pathname);
      }
      return o.location === location.pathname;
    });
    currentItem && setExpandedItem(currentItem.id);
  }, [location.pathname, menuItems]);

  const toggleFeedback = () => {
    dispatch(toggleFeedbackModal());
  };

  const submitFeedback = (subject: SubmitFeedbackDTOSubjectEnum, message: string) => {
    dispatch(
      submitFeedbackRequested({
        message,
        subject,
        application: SubmitFeedbackDTOApplicationEnum.SUPPLIERAPPLICATION
      })
    );
  };

  return (
    <SideMenuContainer isOpen={isMenuOpen}>
      <CurrentUser name={get(currentUser, 'firstName', '')} />
      <AccountSelector activeAccount={currentOrgId} onChange={onOrgChange} organizations={userOrganizations} />
      <Navigation>
        {menuItems.map(item => (
          <MenuItem key={item.id} item={item} isExpanded={expandedItemId === item.id} setExpandedItem={setExpandedItem} />
        ))}
      </Navigation>
      <Navigation style={{ marginTop: 60 }}>
        <NavItem>
          <FeebackButton onClick={toggleFeedback}>Submit Feedback</FeebackButton>
          {isFeedbackModalOpen && <FeedbackModal onSubmit={submitFeedback} onDismiss={toggleFeedback} />}
        </NavItem>
      </Navigation>
      <Navigation>
        <NavItem>
          <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
        </NavItem>
      </Navigation>
    </SideMenuContainer>
  );
};

export default SideMenu;
