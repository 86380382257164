import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Header1, Caption } from '../Typography/index';
import styled from 'styled-components';
import { LogoutButton } from '../SideMenu/components';
import useLogout from '../../hooks/useLogout';

interface NoAccountProps {}

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100vh;
`;

const Inner = styled.div`
  text-align: center;
`;

const MailLink = styled.a`
  color: ${props => props.theme.colors.primaryBrand};
  display: inline;
  font-family: ${props => props.theme.typography.face.secondary};
  font-size: inherit;
  text-decoration: underline;
`;

const Logout = styled(LogoutButton)`
  font-family: ${props => props.theme.typography.face.secondary};
  justify-content: center;
  &:before {
    margin: 0 0 0 0;
    width: auto;
  }
`;

const NoAccount: React.FC<NoAccountProps> = props => {
  const handleLogout = useLogout();
  return (
    <Container>
      <Row>
        <Col sm={12}>
          <Wrapper>
            <Inner>
              <Header1>No Organisation Found</Header1>
              <Caption>
                It looks like your account is not associated with an organisation. Please contact{' '}
                <MailLink href="mailto:info@reposit.co.uk">Reposit</MailLink>.
              </Caption>
              <Logout onClick={handleLogout}>Logout</Logout>
            </Inner>
          </Wrapper>
        </Col>
      </Row>
    </Container>
  );
};

export default NoAccount;
