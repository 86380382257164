import React, { Fragment } from 'react';
import { ContentContainer, InnerWrapper } from '../../../components/Common';
import CreateRepositHeader from '../../../components/CreateRepositHeader/index';
import Reposit from '../Reposit';
import { useIsOrganizationLandlord } from '../../../hooks/useIsLandlord';

export default () => {
  const isOrgLandlord = useIsOrganizationLandlord();
  return (
    <Fragment>
      <InnerWrapper>
        <CreateRepositHeader isNew isOrgLandlord={isOrgLandlord} />
        <ContentContainer
          style={{
            marginBottom: '96px'
          }}
        >
          <Reposit isEditable={true} isNew />
        </ContentContainer>
      </InnerWrapper>
    </Fragment>
  );
};
