import { createReducer } from '@reduxjs/toolkit';
import {
  createBankAccountSuccess,
  deleteBankAccountSuccess,
  getBankAccountsSuccess,
  setClaimAccountShowing
} from './bank-account.actions';
import { BankAccountState } from './bank-account.types';

const initialState: BankAccountState = {
  bankAccountIds: [],
  claimAccountShowing: false
};

export default createReducer(initialState, builder => {
  builder
    .addCase(createBankAccountSuccess, (state, action) => {
      const bankAccountId = action.payload;
      state.bankAccountIds = [...state.bankAccountIds, bankAccountId];
    })
    .addCase(deleteBankAccountSuccess, (state, action) => {
      const bankAccountId = action.payload;
      state.bankAccountIds = state.bankAccountIds.filter(ba => ba !== bankAccountId);
    })
    .addCase(getBankAccountsSuccess, (state, action) => {
      const bankAccountIds = action.payload;
      state.bankAccountIds = bankAccountIds;
    })
    .addCase(setClaimAccountShowing, (state, action) => {
      state.claimAccountShowing = action.payload;
    });
});
