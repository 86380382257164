import React from 'react';
import styled from 'styled-components';
import { TenantEntity } from '../../../redux/entities/entities.types';
import TenantAndGuarantorItem from './tenant-and-guarantor-item';

const TenantListWrapper = styled.ul`
  background: #fff;
  margin: 22px 0;
  min-width: 200px;
  overflow: hidden;
  padding: 0;
`;

interface TenantAndGuarantorListProps {
  tenants: TenantEntity[];
  tenancyId: string;
  editable: boolean;
}

const sortTenantsByLastName = (a: TenantEntity, b: TenantEntity) => {
  if (a.lastName && b.lastName) {
    return a.lastName.localeCompare(b.lastName);
  }
  return 0;
};

const TenantAndGuarantorList: React.SFC<TenantAndGuarantorListProps> = ({ tenants, tenancyId, editable }) => {
  const sortedTenants = tenants.sort(sortTenantsByLastName);
  return (
    <TenantListWrapper>
      {sortedTenants.map(t => (
        <TenantAndGuarantorItem key={t.id} tenant={t} tenancyId={tenancyId} editable={editable} />
      ))}
    </TenantListWrapper>
  );
};

export default TenantAndGuarantorList;
