import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

import TenancyIcon from '../../../assets/svg/tenancy-details.svg';
import { getBreakpoint } from '../../../base/style';
import Button from '../../../components/Button/index';
import RepositCard from '../../../components/Reposit/RepositCard/index';
import { Header4, P2 } from '../../../components/Typography/index';

interface IntegrationsProps {
  integrationToken: string;
}

const Wrapper = styled.ul`
  background: #fff;
  margin: 22px 0 36px;
  min-width: 200px;
  overflow: hidden;
  padding: 0;
`;

const IntegrationItem = styled.div<{ isOpen?: boolean }>`
  background: ${props => props.theme.colors.bgAccent};
  margin: 0 0 6px;
  padding: 0 24px 0;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    padding: ${props => (props.isOpen ? `34px` : `0 34px`)};
  }

  &:hover {
    background: ${props => (!props.isOpen ? props.theme.colors.bgHoverAccent : props.theme.colors.bgAccent)};
  }
`;

const IntegrationItemInner = styled.li`
  border-radius: 8px;
  cursor: default;
  box-sizing: border-box;
  margin: 0 0 6px;
  padding: 12px 0;
  height: 96px;

  @media all and (min-width: ${getBreakpoint('md')}) {
    height: 112px;
  }

  @media all and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
    height: 48px;
    margin: 0 0 6px;
    padding: 0;
  }
`;

const Integration = styled.div`
  @media all and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
  }
`;

const IntegrationTitle = styled(Header4)`
  font-size: 1em;
  margin: 0;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    margin: 0 10px 0 0;
  }
`;

const IntegrationValue = styled(P2)`
  margin: 0;
`;

const RevealButton = styled(Button)`
  background: ${props => props.theme.colors.tertiaryBrand};
  border-color: ${props => props.theme.colors.tertiaryBrand};
  color: white;
  &:hover {
    background: ${props => props.theme.colors.primaryBrand};
    border-color: ${props => props.theme.colors.primaryBrand};
  }
`;

const HideButton = styled(Button)`
  background: #ffe6e6;
  border-color: #ffe6e6;
  color: ${props => props.theme.colors.negative};
  &:hover {
    background: ${props => props.theme.colors.negative};
    border-color: ${props => props.theme.colors.negative};
    color: #ffe6e6;
  }
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  margin: 12px 0 0;
  @media all and (min-width: ${getBreakpoint('lg')}) {
    margin: 0 0 0 auto;
  }
`;

const IntegrationInfo: React.FC<IntegrationsProps> = ({ integrationToken }) => {
  const [tokenVisible, toggleTokenVisible] = useState(false);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <RepositCard title="Integration" icon={TenancyIcon} tooltip="Your API key to integrate with Reposit's system." flush>
      <Wrapper>
        <IntegrationItem>
          <IntegrationItemInner>
            <Integration>
              <IntegrationTitle>API Key</IntegrationTitle>
              <IntegrationValue>{tokenVisible ? integrationToken : '******************************'}</IntegrationValue>
            </Integration>
            <Actions>
              {tokenVisible ? (
                <HideButton buttonType="tertiary" mini="true" onClick={() => toggleTokenVisible(!tokenVisible)}>
                  Hide
                </HideButton>
              ) : (
                <RevealButton buttonType="tertiary" mini="true" onClick={() => toggleTokenVisible(!tokenVisible)}>
                  Reveal
                </RevealButton>
              )}
            </Actions>
          </IntegrationItemInner>
        </IntegrationItem>
      </Wrapper>
    </RepositCard>
  );
};

export default IntegrationInfo;
