import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import AssignmentIcon from '../../../assets/svg/onboarding/assignment.svg';
import EOTIcon from '../../../assets/svg/onboarding/eot.svg';
import MidTenancyIcon from '../../../assets/svg/onboarding/mid-tenancy.svg';
import Button from '../../../components/Button/index';
import Actions from '../../../components/Onboarding/Actions/index';
import Title from '../../../components/Onboarding/Title/index';
import {
  updateLandlordExtraInformationRequested,
  UPDATE_LANDLORD_EXTRA_INFORMATION_STORE_KEY
} from '../../../redux/account/account.actions';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import RepositCard from '../../../components/Reposit/RepositCard/index';
import { P1, Header3 } from '../../../components/Typography/index';

const extendOrCheckoutLink = 'https://support.reposit.co.uk/en/knowledge/how-to-extend-or-check-out-a-reposit-2';
const eotChargesLink = 'https://support.reposit.co.uk/en/knowledge/how-to';

const DocumentLink = styled.a`
  color: ${props => props.theme.colors.primaryBrand};
  font-family: ${props => props.theme.typography.face.secondary};
  font-size: inherit;
  text-decoration: underline;
`;

export const TenancyGuide: React.SFC<any> = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(createLoadingSelector([UPDATE_LANDLORD_EXTRA_INFORMATION_STORE_KEY]));
  const onSubmit = () => dispatch(updateLandlordExtraInformationRequested());
  return (
    <Fragment>
      <Title icon={AssignmentIcon}>Tenancy Guide</Title>
      <Header3>Useful information for managing existing Reposits</Header3>

      <RepositCard icon={MidTenancyIcon} title="Mid tenancy checklist">
        {/* cannot overwrite the reset css for some reason???? Very annoying */}
        <ul style={{ listStyle: 'disc' }}>
          <li style={{ color: '#3A315E' }}>
            <P1>Check the property annually</P1>
          </li>
          <li style={{ color: '#3A315E' }}>
            <P1>Chase for any rent arrears the tenant may fall into</P1>
          </li>
          <div style={{ marginTop: 20 }}>
            <DocumentLink href={extendOrCheckoutLink} target="_blank" rel="noopener noreferrer" style={{ margin: '0 0 0 auto' }}>
              How to extend a Reposit
            </DocumentLink>
          </div>
        </ul>
      </RepositCard>
      <RepositCard icon={EOTIcon} title="End of tenancy checklist">
        <ul style={{ listStyle: 'disc' }}>
          <li style={{ color: '#3A315E' }}>
            <P1>Complete a comprehensive checkout report</P1>
          </li>
          <li style={{ color: '#3A315E' }}>
            <P1>Get in touch with the guarantor regarding outstanding charges </P1>
          </li>
        </ul>
        <div style={{ marginTop: 20 }}>
          <DocumentLink href={extendOrCheckoutLink} target="_blank" rel="noopener noreferrer">
            How to checkout a Reposit
          </DocumentLink>
        </div>

        <div style={{ marginTop: 20 }}>
          <DocumentLink href={eotChargesLink} target="_blank" rel="noopener noreferrer" style={{ margin: '0 0 0 auto' }}>
            How to raise the end of tenancy charges
          </DocumentLink>
        </div>
      </RepositCard>

      <Actions>
        <Button onClick={onSubmit} disabled={isLoading}>
          Continue
        </Button>
      </Actions>
    </Fragment>
  );
};
