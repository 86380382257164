import React, { Fragment, useState, useEffect } from 'react';
import styled from 'styled-components';
import TenantsIcon from '../../../assets/svg/tenants.svg';
import ReactTooltip from 'react-tooltip';
import Button from '../../../components/Button/index';
import RepositCard from '../../../components/Reposit/RepositCard/index';
import { StyledLink } from '../../../components/Typography/index';
import { OrganizationInviteEntity, UserEntity } from '../../../redux/entities/entities.types';
import TeamMember from './team-member';
import AccountForm, { AccountFormValues } from '../../../components/AccountForm/index';
import { useDispatch, useSelector } from 'react-redux';
import {
  createInviteRequested,
  CREATE_INVITE_STORE_KEY,
  RESEND_INVITE_STORE_KEY,
  DELETE_INVITE_STORE_KEY
} from '../../../redux/invite/invite.actions';
import FlashMessage from '../../../components/FlashMessage/index';
import { DELETE_ORGANIZATION_USER_STORE_KEY } from '../../../redux/organization-user/organization-user.actions';
import { useFlashMessage, FLASH_MESSAGE_TIMEOUT } from '../../FlashMessage/index';
import { getHasOrganizationRole } from '../../../redux/auth/auth.selectors';
import { OrganizationWithRepositsAndRolesRolesEnum } from '@reposit/api-client';

const TeamListWrapper = styled.ul`
  background: #fff;
  margin: 22px 0 36px;
  min-width: 200px;
  overflow: hidden;
  padding: 0;
`;

const AddTeamMemberContainer = styled.div`
  padding: 28px 36px 0;
`;

interface ManageTeamProps {
  teamData: (OrganizationInviteEntity | UserEntity)[];
}

const Link = styled(StyledLink)`
  margin: 0 20px;
`;

const ManageTeam: React.FC<ManageTeamProps> = ({ teamData }) => {
  const dispatch = useDispatch();
  const [isAddFormShowing, showInviteForm] = useState(false);
  const [flashMessagePayload, onDismissFlashMessage] = useFlashMessage([
    CREATE_INVITE_STORE_KEY,
    RESEND_INVITE_STORE_KEY,
    DELETE_INVITE_STORE_KEY,
    DELETE_ORGANIZATION_USER_STORE_KEY
  ]);

  const hasManageTeamRole = useSelector(getHasOrganizationRole(OrganizationWithRepositsAndRolesRolesEnum.MANAGETEAM));

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const onSubmit = (values: AccountFormValues) => {
    dispatch(createInviteRequested(values.email));
  };

  return (
    <RepositCard
      title="Manage Team"
      icon={TenantsIcon}
      tooltip="Add/remove team members associated with your account. Team members will be able to create and view Reposits and Claims."
      flush
      flashMessage={
        flashMessagePayload ? (
          <FlashMessage onDismiss={onDismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMEOUT} payload={flashMessagePayload} />
        ) : (
          undefined
        )
      }
    >
      <TeamListWrapper>
        {teamData.map(item => (
          <TeamMember key={item.id} data={item} />
        ))}
        {hasManageTeamRole ? (
          <AddTeamMemberContainer>
            {isAddFormShowing ? (
              <AccountForm
                onSubmit={onSubmit}
                formActions={
                  <Fragment>
                    <Link to="#" onClick={() => showInviteForm(false)}>
                      Cancel
                    </Link>
                    <Button>Add member</Button>
                  </Fragment>
                }
              />
            ) : (
              <Button buttonType="secondary" onClick={() => showInviteForm(true)}>
                Invite a team member
              </Button>
            )}
          </AddTeamMemberContainer>
        ) : null}
      </TeamListWrapper>
    </RepositCard>
  );
};

export default ManageTeam;
