import React, { Fragment } from 'react';
import Card from '../../Card';
import { Header3, P1 } from '../../Typography';
import Button from '../../Button';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { RepositFilter } from '../../../constants/reposit';

interface NoRepositsListProps {
  currentFilter: string;
  query: string;
}

const RepositCard = styled(Card)`
  padding: 18px 5px 2px;
  a {
    text-decoration: none;
  }
`;

const NoRepositsList: React.FC<NoRepositsListProps> = ({ currentFilter, query }) => {
  let content = (
    <Fragment>
      <Header3>You don’t have any Reposits yet.</Header3>
      <NavLink to="/reposits/new">
        <Button buttonType="primary">Create a Reposit</Button>
      </NavLink>
    </Fragment>
  );

  if (!currentFilter && !query)
    return (
      <RepositCard>
        <Fragment>{content}</Fragment>
      </RepositCard>
    );

  if (!currentFilter && query)
    return (
      <RepositCard>
        <Fragment>
          <Header3>You don't have any Reposits matching the search term "{query}".</Header3>
          <NavLink to="/reposits/new">
            <Button buttonType="primary">Create a Reposit</Button>
          </NavLink>
        </Fragment>
      </RepositCard>
    );

  switch (currentFilter) {
    case RepositFilter.DRAFT:
      content = (
        <Fragment>
          <Header3>You don’t currently have any draft Reposits.</Header3>
          <P1 style={{ marginBottom: 48 }}>
            A Reposit's status will be 'draft' when you have created it but not yet published it.
          </P1>
          <NavLink to="/reposits/new">
            <Button buttonType="primary">Create a Reposit</Button>
          </NavLink>
        </Fragment>
      );
      break;

    case RepositFilter.PENDING:
      content = (
        <Fragment>
          <Header3>You don’t currently have any pending Reposits.</Header3>
          <P1>
            A Reposit's status will be 'pending' when you have published it and are awaiting the tenants to complete the purchase.
          </P1>
        </Fragment>
      );
      break;

    case RepositFilter.COMPLETE:
      content = (
        <Fragment>
          <Header3>You don’t currently have any active Reposits.</Header3>
          <P1>A Reposit becomes active when all tenants have completed payment.</P1>
        </Fragment>
      );
      break;

    case RepositFilter.CHECKED_OUT:
      content = (
        <Fragment>
          <Header3>You don’t currently have any checked out Reposits.</Header3>
          <P1>A Reposit is 'checked out' once it has come to an end and the tenants have moved out.</P1>
        </Fragment>
      );
      break;

    default:
  }

  return (
    <RepositCard>
      <Fragment>{content}</Fragment>
    </RepositCard>
  );
};

export default NoRepositsList;
