import React, { MutableRefObject } from 'react';
import AddressLookup from '../AddressLookup/index';
import SecondaryPanel from '../SecondaryPanel';
import { AddressEntity } from '../../redux/entities/entities.types';
import { ExternalAddressDTO, CreateAddressWithOrgDTO } from '@reposit/api-client';
import { AddressForm } from '../../redux/reposit/reposit.actions';
interface AddressProps {
  onCancel: () => void;
  fetchOrganizationAddresses: () => void;
  currentOrgId: string;
  currentOrganizationAddresses?: (AddressEntity)[];
  shouldShowAddressList: boolean;
  selectAddressId: (id?: string) => void;
  addressId?: string;
  searchAddresses: (postcode: string) => void;
  isShowingSearchModal: boolean;
  searchedAddresses?: ExternalAddressDTO[];
  setShowingSearchModal: (value: boolean) => void;
  currentAddressForm: AddressForm;
  setCurrentAddressForm: (value: AddressForm) => void;
  isSearchAddressesLoading: boolean;
  createManualAddress: (address: CreateAddressWithOrgDTO) => void;
  landlordRef: MutableRefObject<HTMLDivElement | null>;
}

const Address = ({
  onCancel,
  fetchOrganizationAddresses,
  currentOrgId,
  currentOrganizationAddresses,
  selectAddressId,
  addressId,
  searchAddresses,
  isShowingSearchModal,
  searchedAddresses,
  setShowingSearchModal,
  currentAddressForm,
  setCurrentAddressForm,
  isSearchAddressesLoading,
  createManualAddress,
  landlordRef
}: AddressProps) => {
  return (
    <SecondaryPanel title="Address" subtitle="The address of the property">
      <AddressLookup
        fetchOrganizationAddresses={fetchOrganizationAddresses}
        onCancel={onCancel}
        currentOrgId={currentOrgId}
        currentOrganizationAddresses={currentOrganizationAddresses}
        selectAddressId={selectAddressId}
        addressId={addressId}
        searchAddresses={searchAddresses}
        isShowingSearchModal={isShowingSearchModal}
        searchedAddresses={searchedAddresses}
        setShowingSearchModal={setShowingSearchModal}
        currentAddressForm={currentAddressForm}
        setCurrentAddressForm={setCurrentAddressForm}
        isSearchAddressesLoading={isSearchAddressesLoading}
        createManualAddress={createManualAddress}
        landlordRef={landlordRef}
      />
    </SecondaryPanel>
  );
};

export default Address;
