import { ClaimDTO, ClaimsApi, NetPromoterScoreDTOSurveyEventEnum } from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { push } from 'connected-react-router';
import { get } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { FlashState } from '../../components/FlashMessage/index';
import { getErrorMessage } from '../../utils/common.utils';
import { updateNPSModal } from '../account/account.actions';
import { getRequiresNPSResponse } from '../account/account.selectors';
import { ClaimListActionTypes, clearFilters } from '../claim-list/claim-list.actions';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import { FlashMessagesActionTypes, setFlashMessage } from '../flash-messages/flash-messages.actions';
import SCHEMA from '../schema';
import { createClaimsApi, runSagaWithAuth } from '../utils/api.utils';
import {
  ClaimActionTypes,
  createClaimFailed,
  createClaimSuccess,
  CREATE_CLAIM_API_REQUESTED,
  CREATE_CLAIM_STORE_KEY,
  fetchClaimFailed,
  fetchClaimSuccess,
  FETCH_CLAIM_API_REQUESTED,
  improveClaimFailed,
  improveClaimSuccess,
  IMPROVE_CLAIM_API_REQUESTED,
  IMPROVE_CLAIM_STORE_KEY,
  publishClaimFailed,
  publishClaimSuccess,
  PUBLISH_CLAIM_API_REQUESTED,
  PUBLISH_CLAIM_STORE_KEY,
  setCurrentClaimActionModal,
  updateClaimFailed,
  updateClaimSuccess,
  UPDATE_CLAIM_API_REQUESTED,
  UPDATE_CLAIM_STORE_KEY
} from './claim.actions';
import {
  CreateClaimPayload,
  FetchClaimByIdPayload,
  ImproveClaimPayload,
  PublishClaimPayload,
  UpdateClaimPayload
} from './claim.types';

export function* createClaim({ payload }: { type: string; payload: CreateClaimPayload }) {
  try {
    const claimsApi: ClaimsApi = yield createClaimsApi();
    const apiResponse: AxiosResponse<ClaimDTO> = yield call(runSagaWithAuth(() => claimsApi.createClaim(payload)));
    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.claim);
    yield put<ClaimActionTypes>(createClaimSuccess());
    const claimId = get(apiResponse.data, 'id');

    yield put(push(`/claims/${claimId}`));

    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: CREATE_CLAIM_STORE_KEY,
        message: 'Success! Draft Claim has been created.',
        state: FlashState.SUCCESS
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: CREATE_CLAIM_STORE_KEY, message: error, state: FlashState.ERROR })
    );
    yield put<ClaimActionTypes>(createClaimFailed(error));
  }
}

export function* fetchClaimById({ payload }: { type: string; payload: FetchClaimByIdPayload }) {
  try {
    const claimsApi: ClaimsApi = yield createClaimsApi();
    const apiResponse: AxiosResponse<ClaimDTO> = yield call(runSagaWithAuth(() => claimsApi.findClaimById(payload.claimId)));
    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.claim);
    yield put<ClaimActionTypes>(fetchClaimSuccess());
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<ClaimActionTypes>(fetchClaimFailed(error));
  }
}

export function* updateClaim({ payload }: { type: string; payload: UpdateClaimPayload }) {
  try {
    const claimsApi: ClaimsApi = yield createClaimsApi();
    const apiResponse: AxiosResponse<ClaimDTO> = yield call(
      runSagaWithAuth(() => claimsApi.updateClaim(payload.claimId, { details: payload.details }))
    );

    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_CLAIM_STORE_KEY,
        message: 'Success! Claim has been updated.',
        state: FlashState.SUCCESS
      })
    );

    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.claim);
    yield put<ClaimActionTypes>(updateClaimSuccess(apiResponse.data));
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<ClaimActionTypes>(updateClaimFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_CLAIM_STORE_KEY,
        message: error,
        state: FlashState.ERROR
      })
    );
  }
}

export function* publishClaim({ payload }: { type: string; payload: PublishClaimPayload }) {
  try {
    const { claimId, claimDetails } = payload;
    const claimsApi: ClaimsApi = yield createClaimsApi();
    if (claimDetails) {
      yield call(runSagaWithAuth(() => claimsApi.updateClaim(claimId, { details: claimDetails })));
    }
    const apiResponse: AxiosResponse<ClaimDTO> = yield call(runSagaWithAuth(() => claimsApi.publishClaim(claimId)));

    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.claim);
    yield put<ClaimActionTypes>(publishClaimSuccess(apiResponse.data));
    yield put<ClaimActionTypes>(setCurrentClaimActionModal(''));
    yield put<ClaimListActionTypes>(clearFilters());
    yield put(push(`/claims`));

    const requiresNPSResponse: boolean = yield select(getRequiresNPSResponse);

    if (requiresNPSResponse) {
      yield put(
        updateNPSModal({
          isOpen: true,
          surveyEvent: NetPromoterScoreDTOSurveyEventEnum.CLAIMPUBLISHED,
          collectingTrustpilotReview: false
        })
      );
    }

    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: PUBLISH_CLAIM_STORE_KEY,
        message: 'Success! Claim has been published.',
        state: FlashState.SUCCESS
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<ClaimActionTypes>(publishClaimFailed(error));
    yield put<ClaimActionTypes>(setCurrentClaimActionModal(''));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: PUBLISH_CLAIM_STORE_KEY,
        message: error,
        state: FlashState.ERROR
      })
    );
  }
}

export function* improveClaim({ payload }: { type: string; payload: ImproveClaimPayload }) {
  try {
    const claimsApi: ClaimsApi = yield createClaimsApi();
    const apiResponse: AxiosResponse<ClaimDTO> = yield call(
      runSagaWithAuth(() => claimsApi.submitMoreInfoClaim(payload.claimId, { agentResponse: payload.response }))
    );

    yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.claim);
    yield put<ClaimActionTypes>(setCurrentClaimActionModal(''));
    yield put<ClaimActionTypes>(improveClaimSuccess(apiResponse.data));
    yield put<ClaimListActionTypes>(clearFilters());
    yield put(push(`/claims`));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: IMPROVE_CLAIM_STORE_KEY,
        message: 'Success! Claim has been improved.',
        state: FlashState.SUCCESS
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<ClaimActionTypes>(improveClaimFailed(error));
    yield put<ClaimActionTypes>(setCurrentClaimActionModal(''));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: IMPROVE_CLAIM_STORE_KEY,
        message: error,
        state: FlashState.ERROR
      })
    );
  }
}

// ****************
// WATCHERS
// ****************
export function* watchClaimsSagas() {
  yield takeLatest(CREATE_CLAIM_API_REQUESTED, createClaim);
  yield takeLatest(FETCH_CLAIM_API_REQUESTED, fetchClaimById);
  yield takeLatest(UPDATE_CLAIM_API_REQUESTED, updateClaim);
  yield takeLatest(PUBLISH_CLAIM_API_REQUESTED, publishClaim);
  yield takeLatest(IMPROVE_CLAIM_API_REQUESTED, improveClaim);
}
