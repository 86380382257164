import { ClaimDTOStatusEnum } from '@reposit/api-client';
import React from 'react';
import styled from 'styled-components';
import { Header4 } from '../../Typography/index';
import { RoundButton } from '../../Button/index';
import HelpIcon from '../../../assets/svg/help.svg';
import { ClaimEntity } from '../../../redux/entities/entities.types';

interface ClaimStatusProps {
  claim: ClaimEntity;
  isAgentDeciding: boolean;
  isTenantDeciding: boolean;
  hasGoneBack: boolean;
  isClaimDisputed: boolean;
}

const ClaimStatusBar = styled.div`
  align-items: center;
  background: #fff;
  border-radius: 12px;
  border: solid 1px ${props => props.theme.colors.inactive};
  display: flex;
  justify-content: center;
  margin: 0 5px 36px;
  padding: 11px 0 10px;
`;

const Message = styled(Header4)`
  align-content: center;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  justify-content: center;
  margin: 0;
  padding: 0;
  text-align: center;

  &.AWAITING_REVIEW,
  &.AWAITING_SUBSTANTIATION,
  &.MEDIATION {
    color: ${props => props.theme.colors.warning};
  }

  &.RESOLVED {
    color: ${props => props.theme.colors.positive};
  }
`;

const HelpButton = styled(RoundButton)`
  margin: -2px 12px 0;
`;

interface ClaimStatusItem {
  label: string;
  tooltip: string;
}

const claimStatusLabelMap: {
  [key in ClaimDTOStatusEnum]: ClaimStatusItem;
} = {
  MEDIATION: {
    label: 'Mediation',
    tooltip: 'Mediation'
  },
  AWAITING_SUBSTANTIATION: {
    label: 'Awaiting your remaining evidence',
    tooltip:
      'Your end of tenancy charges have not been agreed by the tenant(s). Please upload the remaining required documents in order for your claim to be processed.'
  },
  DRAFT: {
    label: 'Draft',
    tooltip: 'No progress will be made until you complete the required sections and submit the claim.'
  },
  AWAITING_REVIEW: {
    label: 'Awaiting review',
    tooltip: `Claims are reviewed by our internal claims team to ensure you've included all the necessary information
    to ensure you have a smooth experience. We'll notify you when this is complete.`
  },
  DECLINED: {
    label: 'Rejected',
    tooltip: 'This claim was rejected by our claims team. You should have received communication outlining the reasons for this.'
  },
  AWAITING_ADDITIONAL_INFORMATION: {
    label: 'Additional information required',
    tooltip: 'Our claims team has requested that you provide further details to support your claim'
  },
  AWAITING_RESPONDENTS: {
    label: 'Waiting for tenants to respond',
    tooltip: `Tenants are given the opportunity to accept or reject the claim based on the details you have provided.
    We'll let you know when we receive their response.`
  },
  AWAITING_ARBITRATION: {
    label: 'Awaiting arbitration',
    tooltip: `The tenants have disputed the claim so it has been submitted to an external arbitration team who will
    review the case. We'll let you know when we receive their response.`
  },
  ARBITRATION: {
    label: 'Awaiting arbitration',
    tooltip: `The tenants have disputed the claim so it has been submitted to an external arbitration team who will
     review the case. We'll let you know when we receive their response.`
  },
  ARBITRATED: {
    label: 'Waiting for tenants to settle adjudication',
    tooltip: `The independent adjudicator has now reviewed the case. We will attempt to recoup the funds from 
    the tenant(s). If we are unsuccessful in collecting funds from the tenant(s) we will forward the claim to 
    our insurer.`
  },
  INSURANCE_CLAIM: {
    label: 'Submitted to insurer',
    tooltip: `The claim has been submitted to our partnered insurer who is reviewing the claim.
     We'll let you know when we receive their response.`
  },
  RESOLVED: {
    label: 'Resolved',
    tooltip: 'The claim has been resolved, and no further action is needed.'
  },
  DEACTIVATED: {
    label: 'Closed',
    tooltip:
      'This claim has been closed. If you did not deactivate this claim yourself, our claims team should have contacted you with an explanation for this.'
  },
  AWAITING_PAYOUT: {
    label: 'Awaiting Payout',
    tooltip: ''
  },
  AWAITING_ARBITRATION_ADMIN_FEE: {
    label: 'Waiting for tenants to respond',
    tooltip: `Tenants are given the opportunity to accept or reject the claim based on the details you have provided.
    We'll let you know when we receive their response.`
  }
};

const getClaimStatus = (
  claim: ClaimEntity,
  isAgentDeciding: boolean,
  isTenantDeciding: boolean,
  isClaimDisputed: boolean,
  hasGoneBack: boolean
): [JSX.Element, string?] => {
  if (!claimStatusLabelMap[claim.status]) {
    return [<Message className={claim.status}>Unknown Status</Message>, 'Something has gone wrong, please contact us'];
  }

  if (claim.supplierPaidAt) {
    const resolved = claimStatusLabelMap[ClaimDTOStatusEnum.RESOLVED];
    return [<Message className={claim.status}>{resolved.label}</Message>, resolved.tooltip];
  }

  const awaitingPreDispute = {
    label: 'Awaiting your response',
    tooltip:
      'There has been a new proposed settlement put forward by the tenant(s). You must now decide to either collect this new total, or put forward a final proposal back to them.'
  };
  const awaitingFinalProposal = {
    label: 'Awaiting final proposal',
    tooltip: `Please provide your final proposal for each claim item. Once completed, please submit this below for the tenant to
    review`
  };

  const awaitingSubstantiationTenantUnresponded = {
    label: 'Awaiting your remaining evidence',
    tooltip:
      'The tenant(s) have not responded to your end of tenancy charges. Please upload the remaining required documents in order for your claim to be processed.'
  };

  if (claim.status === ClaimDTOStatusEnum.AWAITINGSUBSTANTIATION) {
    if (!isClaimDisputed) {
      return [
        <Message className={claim.status}>{awaitingSubstantiationTenantUnresponded.label}</Message>,
        awaitingSubstantiationTenantUnresponded.tooltip
      ];
    }
  }

  if (claim.status === ClaimDTOStatusEnum.MEDIATION) {
    if (isAgentDeciding || hasGoneBack) {
      return [<Message className={claim.status}>{awaitingPreDispute.label}</Message>, awaitingPreDispute.tooltip];
    } else if (isTenantDeciding) {
      const { label, tooltip } = claimStatusLabelMap['AWAITING_RESPONDENTS'];
      return [<Message className={claim.status}>{label}</Message>, tooltip];
    } else {
      return [<Message className={claim.status}>{awaitingFinalProposal.label}</Message>, awaitingFinalProposal.tooltip];
    }
  }

  const { label, tooltip } = claimStatusLabelMap[claim.status];
  return [<Message className={claim.status}>{label}</Message>, tooltip];
};

const ClaimStatus: React.SFC<ClaimStatusProps> = ({ claim, isAgentDeciding, isTenantDeciding, hasGoneBack, isClaimDisputed }) => {
  const [message, tooltip] = getClaimStatus(claim, isAgentDeciding, isTenantDeciding, isClaimDisputed, hasGoneBack);

  return (
    <ClaimStatusBar>
      {message}
      {tooltip && <HelpButton icon={HelpIcon} data-tip={tooltip} data-place="right" />}
    </ClaimStatusBar>
  );
};

export default ClaimStatus;
