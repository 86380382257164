import React from 'react';
import styled from 'styled-components';
import WhiteLogo from '../../assets/png/icon-white.png';

interface PanelProps {
  children?: JSX.Element | JSX.Element[];
  style?: any;
  type?: string;
}

const Panel = styled.div`
  border-radius: 12px;
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.1);
  background-image: linear-gradient(
    259deg,
    ${props => props.theme.colors.primaryBrand},
    ${props => props.theme.colors.secondaryBrand}
  );
  overflow: hidden;
  padding: 1.875em 2.625em 1.24em 2.625em;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 65%;
    height: 100%;
    background: transparent url(${WhiteLogo}) no-repeat center center;
    background-size: cover;
    opacity: 0.15;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const WarningPanel = styled(Panel)`
  background-image: linear-gradient(259deg, ${props => props.theme.colors.negative}, #8a0101);
`;

const Content = styled.div`
  position: relative;
  z-index: 2;
`;

export default ({ children, style, type }: PanelProps) => {
  let content = (
    <Panel style={style}>
      <Content>{children}</Content>
    </Panel>
  );
  if (type === 'warning') {
    content = (
      <WarningPanel style={style}>
        <Content>{children}</Content>
      </WarningPanel>
    );
  }
  return content;
};
