import { UserDTOUserTypeIdEnum } from '@reposit/api-client';
import { ConnectedRouter, getLocation } from 'connected-react-router';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appConfig } from './appConfig';
import { Wrapper } from './components/Common';
import { FullPageLoader } from './components/Loading';
import { NPSModal } from './components/NPSModal/index';
import FlashMessageContainer from './containers/FlashMessage/index';
import { useHubspot } from './hooks/useHubspot';
import useLogout from './hooks/useLogout';
import { history } from './index';
import { fetchMeRequested, FETCH_ME_STORE_KEY, submitNPSRequested, updateNPSModal } from './redux/account/account.actions';
import { getIsNPSModalOpen, getMeHasFetched } from './redux/account/account.selectors';
import { CreateNPS } from './redux/account/account.types';
import { getAccessToken, getCurrentOrganizationId } from './redux/auth/auth.selectors';
import { createLoadingSelector } from './redux/loading/loading.selector';
import { AppState } from './redux/root.reducer';
import { getOrganization } from './redux/selectors/organization.selectors';
import { getCurrentUser } from './redux/selectors/user.selectors';
import Routes from './Routes';
import {
  bootGoogleAnalytics,
  initialiseGoogleAnalytics,
  sendPageViewToGoogleAnalytics
} from './utils/integrators/analytics.integrator';
import { initialiseHotJar } from './utils/integrators/hotjar.integrator';
import { identifyHubspotUser, loadHubspotWidget } from './utils/integrators/hubspot.integrator';

initialiseGoogleAnalytics();
initialiseHotJar();

const App = () => {
  const getMeLoadingSelector = createLoadingSelector([FETCH_ME_STORE_KEY]);
  const token = useSelector(getAccessToken);
  const isGetMeLoading = useSelector(getMeLoadingSelector);
  const hasGetMeFetched = useSelector(getMeHasFetched);
  const isNPSModalOpen = useSelector(getIsNPSModalOpen);
  const dispatch = useDispatch();
  const handleLogout = useLogout();
  const onSubmitNPS = (payload: CreateNPS) => dispatch(submitNPSRequested(payload));
  const onDismissNPS = () =>
    dispatch(updateNPSModal({ isOpen: false, surveyEvent: undefined, collectingTrustpilotReview: false }));
  const currentOrganizationId = useSelector(getCurrentOrganizationId);
  const currentOrganization = useSelector(getOrganization(currentOrganizationId));
  const location = useSelector((state: AppState) => getLocation(state));
  const { hasLoaded } = useHubspot(appConfig.hubspot.accountId);
  const currentUser = useSelector(getCurrentUser);

  const isLoading = !!((isGetMeLoading && !hasGetMeFetched) || (token && !hasGetMeFetched));

  useEffect(() => {
    if (token) {
      dispatch(fetchMeRequested());
    }
  }, [dispatch, token]);

  useEffect(() => {
    window.scrollTo(0, 0);
    sendPageViewToGoogleAnalytics(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    if (currentUser && currentOrganization && hasLoaded) {
      bootGoogleAnalytics(currentUser);
      identifyHubspotUser(currentUser);
      loadHubspotWidget();
    }
  }, [currentUser, currentOrganization, hasLoaded]);

  if (isLoading) {
    return <FullPageLoader />;
  }

  const userType = currentUser ? currentUser.userTypeId : null;
  if (userType && userType === UserDTOUserTypeIdEnum.TENANT) {
    handleLogout();
    return null;
  }

  return (
    <Fragment>
      <ConnectedRouter history={history}>
        <Wrapper>
          {isNPSModalOpen ? <NPSModal onDismiss={onDismissNPS} onSubmit={onSubmitNPS} /> : null}
          <FlashMessageContainer />
          <Routes />
        </Wrapper>
      </ConnectedRouter>
    </Fragment>
  );
};

export default App;
