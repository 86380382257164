const REPOSIT_WEEKS_COVER = 8;

export const REPOSIT_POLICY_MAX_LIABILITY = 500000;

const calculateOneWeeksRent = (ppm: number): number => {
  return Math.ceil((ppm * 12) / 52);
};

export const calculateMaxLiabilityFromPPMForReposit = (ppm: number, weeksCover: number = REPOSIT_WEEKS_COVER): number => {
  return Math.ceil(calculateOneWeeksRent(ppm) * weeksCover);
};
