import { ClaimDTO } from '@reposit/api-client';
import {
  CreateClaimPayload,
  UpdateClaimPayload,
  ImproveClaimPayload,
  FetchClaimByIdPayload,
  PublishClaimPayload
} from './claim.types';

export const CREATE_CLAIM_STORE_KEY = `CLAIM/CREATE_CLAIM`;
export const CREATE_CLAIM_API_REQUESTED = `CLAIM/CREATE_CLAIM_API_REQUESTED`;
export const CREATE_CLAIM_API_SUCCESS = `CLAIM/CREATE_CLAIM_API_SUCCESS`;
export const CREATE_CLAIM_API_FAILED = `CLAIM/CREATE_CLAIM_API_FAILED`;

export const FETCH_CLAIM_STORE_KEY = `CLAIM/FETCH_CLAIM`;
export const FETCH_CLAIM_API_REQUESTED = `CLAIM/FETCH_CLAIM_API_REQUESTED`;
export const FETCH_CLAIM_API_SUCCESS = `CLAIM/FETCH_CLAIM_API_SUCCESS`;
export const FETCH_CLAIM_API_FAILED = `CLAIM/FETCH_CLAIM_API_FAILED`;

export const CLEAR_CLAIM_HAS_FETCHED = `CLAIM/CLEAR_CLAIM_HAS_FETCHED`;

export const PUBLISH_CLAIM_STORE_KEY = `CLAIM/PUBLISH_CLAIM`;
export const PUBLISH_CLAIM_API_REQUESTED = `CLAIM/PUBLISH_CLAIM_API_REQUESTED`;
export const PUBLISH_CLAIM_API_SUCCESS = `CLAIM/PUBLISH_CLAIM_API_SUCCESS`;
export const PUBLISH_CLAIM_API_FAILED = `CLAIM/PUBLISH_CLAIM_API_FAILED`;

export const UPDATE_CLAIM_STORE_KEY = `CLAIM/UPDATE_CLAIM`;
export const UPDATE_CLAIM_API_REQUESTED = `CLAIM/UPDATE_CLAIM_API_REQUESTED`;
export const UPDATE_CLAIM_API_SUCCESS = `CLAIM/UPDATE_CLAIM_API_SUCCESS`;
export const UPDATE_CLAIM_API_FAILED = `CLAIM/UPDATE_LAIM_API_FAILED`;

export const IMPROVE_CLAIM_STORE_KEY = `CLAIM/IMPROVE_CLAIM`;
export const IMPROVE_CLAIM_API_REQUESTED = `CLAIM/IMPROVE_CLAIM_API_REQUESTED`;
export const IMPROVE_CLAIM_API_SUCCESS = `CLAIM/IMPROVE_CLAIM_API_SUCCESS`;
export const IMPROVE_CLAIM_API_FAILED = `CLAIM/IMPROVE_CLAIM_API_FAILED`;

export const SET_CURRENT_CLAIM_ACTION_MODAL = `CLAIM/SET_CURRENT_CLAIM_ACTION_MODAL`;

export const SET_CLAIM_ITEMS_FORM_OPEN = 'CLAIM/SET_CLAIM_ITEMS_FORM_OPEN';

export const DELETE_CLAIM_STORE_KEY = 'CLAIM/DELETE_CLAIM';

export const ACCEPT_TENANT_PROPOSAL_STORE_KEY = 'CLAIM/ACCEPT_TENANT_PROPOSAL';
export const COUNTER_TENANT_PROPOSAL_STORE_KEY = 'CLAIM/COUNTER_TENANT_PROPOSAL';
export const PUBLISH_PROPOSAL_STORE_KEY = 'CLAIM/PUBLISH_PROPOSAL';
export const CREATE_ITEM_PROPOSAL_STORE_KEY = 'CLAIM/CREATE_ITEM_PROPOSAL';
export const UPDATE_ITEM_PROPOSAL_STORE_KEY = 'CLAIM/UPDATE_ITEM_PROPOSAL';

export const REMOVE_PROPOSAL_FROM_CLAIM = 'CLAIM/REMOVE_PROPOSAL_FROM_CLAIM';

export const SUBMIT_EVIDENCE_STORE_KEY = 'CLAIM/SUBMIT_EVIDENCE';

/* -------------------------------------------- CREATE CLAIM -------------------------------------------- */
export function createClaimRequested(payload: CreateClaimPayload) {
  return {
    type: CREATE_CLAIM_API_REQUESTED,
    payload
  } as const;
}
export function createClaimSuccess() {
  return {
    type: CREATE_CLAIM_API_SUCCESS
  } as const;
}
export function createClaimFailed(error: string) {
  return {
    type: CREATE_CLAIM_API_FAILED,
    payload: error
  } as const;
}

/* -------------------------------------------- FETCH CLAIM -------------------------------------------- */
export function fetchClaimRequested(payload: FetchClaimByIdPayload) {
  return {
    type: FETCH_CLAIM_API_REQUESTED,
    payload
  } as const;
}
export function fetchClaimSuccess() {
  return {
    type: FETCH_CLAIM_API_SUCCESS
  } as const;
}
export function fetchClaimFailed(error: string) {
  return {
    type: FETCH_CLAIM_API_FAILED,
    payload: error
  } as const;
}

/* -------------------------------------------- CLEAR TENANCY ORDER HAS FETCHED -------------------------------------------- */
export function clearClaimHasFetched() {
  return {
    type: CLEAR_CLAIM_HAS_FETCHED
  } as const;
}

/* -------------------------------------------- PUBLISH CLAIM -------------------------------------------- */
export function publishClaimRequested(payload: PublishClaimPayload) {
  return {
    type: PUBLISH_CLAIM_API_REQUESTED,
    payload
  } as const;
}

export function publishClaimSuccess(payload: ClaimDTO) {
  return {
    type: PUBLISH_CLAIM_API_SUCCESS,
    payload
  } as const;
}

export function publishClaimFailed(error: string) {
  return {
    type: PUBLISH_CLAIM_API_FAILED,
    payload: error
  } as const;
}

/* -------------------------------------------- UPDATE CLAIM -------------------------------------------- */
export function updateClaimRequested(payload: UpdateClaimPayload) {
  return {
    type: UPDATE_CLAIM_API_REQUESTED,
    payload
  } as const;
}

export function updateClaimSuccess(payload: ClaimDTO) {
  return {
    type: UPDATE_CLAIM_API_SUCCESS,
    payload
  } as const;
}

export function updateClaimFailed(error: string) {
  return {
    type: UPDATE_CLAIM_API_FAILED,
    payload: error
  } as const;
}

/* -------------------------------------------- IMPROVE CLAIM -------------------------------------------- */
export function improveClaimRequested(payload: ImproveClaimPayload) {
  return {
    type: IMPROVE_CLAIM_API_REQUESTED,
    payload
  } as const;
}

export function improveClaimSuccess(payload: ClaimDTO) {
  return {
    type: IMPROVE_CLAIM_API_SUCCESS,
    payload
  } as const;
}

export function improveClaimFailed(error: string) {
  return {
    type: IMPROVE_CLAIM_API_FAILED,
    payload: error
  } as const;
}

/* -------------------------------------------- SET CURRENT CLAIM ACTION MODAL -------------------------------------------- */
export function setCurrentClaimActionModal(payload: string) {
  return {
    type: SET_CURRENT_CLAIM_ACTION_MODAL,
    payload
  } as const;
}

export function setCreateClaimItemsFormOpen(isOpen: boolean) {
  return {
    type: SET_CLAIM_ITEMS_FORM_OPEN,
    payload: isOpen
  } as const;
}

export function removeProposalFromClaim(payload: { claimId: string; proposalId: string }) {
  return {
    type: REMOVE_PROPOSAL_FROM_CLAIM,
    payload
  } as const;
}

export type ClaimActionTypes = ReturnType<
  | typeof createClaimRequested
  | typeof createClaimSuccess
  | typeof createClaimFailed
  | typeof fetchClaimRequested
  | typeof fetchClaimSuccess
  | typeof fetchClaimFailed
  | typeof clearClaimHasFetched
  | typeof publishClaimRequested
  | typeof publishClaimSuccess
  | typeof publishClaimFailed
  | typeof improveClaimRequested
  | typeof improveClaimSuccess
  | typeof improveClaimFailed
  | typeof updateClaimRequested
  | typeof updateClaimSuccess
  | typeof updateClaimFailed
  | typeof setCurrentClaimActionModal
  | typeof setCreateClaimItemsFormOpen
  | typeof removeProposalFromClaim
>;
