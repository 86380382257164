import { omit } from 'lodash';

import { ClaimItemActionTypes, DISCARD_CLAIM_ITEM_API_SUCCESS } from '../../claim-item/claim-item.actions';
import {
  CREATE_CLAIM_ITEM_DOCUMENT_API_SUCCESS,
  ClaimItemDocumentActionTypes,
  DELETE_CLAIM_ITEM_DOCUMENT_API_SUCCESS
} from '../../claim-item-document/claim-item-document.actions';

export const claimItemEntityReducer = (state: any = {}, action: ClaimItemActionTypes | ClaimItemDocumentActionTypes) => {
  switch (action.type) {
    case DISCARD_CLAIM_ITEM_API_SUCCESS:
      return omit(state, action.payload.claimItemId);
    case CREATE_CLAIM_ITEM_DOCUMENT_API_SUCCESS:
      const claimItemId = action.payload.claimItemId;
      return {
        ...state,
        [claimItemId]: {
          ...state[claimItemId],
          claimItemDocuments: [...state[claimItemId].claimItemDocuments, action.payload.claimItemDocumentId]
        }
      };
    case DELETE_CLAIM_ITEM_DOCUMENT_API_SUCCESS:
      const claimItem = state[action.payload.claimItemId];
      return {
        ...state,
        [action.payload.claimItemId]: {
          ...claimItem,
          claimItemDocuments: claimItem.claimItemDocuments.filter((cid: any) => cid !== action.payload.claimItemDocumentId)
        }
      };
    default:
      return state;
  }
};
