import React from 'react';
import { AddressEntity } from '../../redux/entities/entities.types';
import { getDisplayAddress } from '../../utils/common.utils';
import Select from '../FormFields/Select/index';

interface AddressListProps {
  currentOrganizationAddresses?: (AddressEntity)[];
  setAddressId: (id?: string) => void;
  addressId?: string;
}

const AddressList: React.SFC<AddressListProps> = ({ currentOrganizationAddresses, addressId, setAddressId }) => {
  return (
    <Select onChange={setAddressId} value={addressId || 0}>
      <option value={0} disabled>
        Please select an address
      </option>
      {currentOrganizationAddresses &&
        currentOrganizationAddresses.map(address => {
          return (
            <option value={address.id} key={address.id}>
              {getDisplayAddress(address)}
            </option>
          );
        })}
    </Select>
  );
};

export default AddressList;
