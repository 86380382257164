import React, { useCallback } from 'react';
import { useDropzone, FileRejection } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import DisabledIcon from '../../assets/svg/disabled.svg';
import ImageIcon from '../../assets/svg/image.svg';
import RightIconWhite from '../../assets/svg/right-white.svg';
import { FILE_UPLOAD_MAX_LIMIT } from '../../constants/system';
import { DocumentEntity } from '../../redux/entities/entities.types';
import { setFlashMessage } from '../../redux/flash-messages/flash-messages.actions';
import { FILE_UPLOAD_STORE_KEY } from '../../redux/system/system.actions';
import Button from '../Button/index';
import { FlashState } from '../FlashMessage/index';
import { P2 } from '../Typography/index';

interface MultiDocUploaderProps {
  createDocument: (file: File) => void;
  documents?: DocumentEntity[];
  deleteDocument?: (id: string) => void;
  showDeleteButton: boolean;
  isDocumentUploading: boolean;
}

const DocUploader = styled.div`
  margin: 24px 0;
`;

const DocumentRow = styled.div`
  align-items: center;
  display: flex;
  margin: 0 0 16px;

  &:before {
    background: transparent url(${ImageIcon}) center center no-repeat;
    content: '';
    display: block;
    margin: 0 10px 0 0;
    height: 20px;
    width: 20px;
  }

  p {
    margin: 0;
  }
`;

const UploadButton = styled.button`
  align-items: center;
  border: none;
  border-radius: 24px;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  flex-direction: none;
  font-family: ${props => props.theme.typography.face.primary};
  font-size: 0.75em;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0.8px;
  outline: none;
  text-decoration: none;
  padding: 0.625em 1.5em 0.625em 1.75em;
  text-transform: uppercase;
  transition: all 0.3s;
  background: ${props => props.theme.colors.tertiaryBrand};
  color: white;

  &:after {
    background: transparent;
    background-image: url(${RightIconWhite});
    background-size: 12px;
    object-fit: contain;
    content: '';
    display: block;
    margin: 0 0 0 0.75em;
    height: 12px;
    width: 12px;
  }

  &:hover {
    background: ${props => props.theme.colors.primaryBrand};
  }

  &:active {
    box-shadow: inset 0px 6px 17px -6px rgba(0, 0, 0, 0.49);
  }

  &:disabled {
    background: ${props => props.theme.colors.disabled};
    color: ${props => props.theme.colors.placeholder};
    cursor: not-allowed;
    transition: all 0.3s;

    &:after {
      background: transparent url(${DisabledIcon}) center center;
      background-size: 12px;
      object-fit: contain;
      display: block;
    }

    &:hover {
      background: ${props => props.theme.colors.disabled};
      color: ${props => props.theme.colors.placeholder};
    }
  }
`;

const RemoveButton = styled(Button)`
  background: #ffe6e6;
  border-color: #ffe6e6;
  color: #f03838;
  margin: 0 0 0 12px;

  &:hover {
    background: #f03838;
    border-color: #f03838;
    color: #ffe6e6;
  }
`;

const Link = styled(P2)`
  a {
    color: inherit;
  }
`;

const MultiDocUploader: React.FC<MultiDocUploaderProps> = ({
  createDocument,
  documents,
  deleteDocument,
  showDeleteButton,
  isDocumentUploading
}) => {
  const dispatch = useDispatch();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      createDocument(acceptedFiles[0]);
    },
    [createDocument]
  );

  const onDropRejected = useCallback(
    (rejections: FileRejection[]) => {
      if (rejections[0].file.size > FILE_UPLOAD_MAX_LIMIT) {
        dispatch(
          setFlashMessage({
            key: FILE_UPLOAD_STORE_KEY,
            message: 'File too big',
            state: FlashState.WARNING
          })
        );
      } else {
        dispatch(
          setFlashMessage({
            key: FILE_UPLOAD_STORE_KEY,
            message: 'Something went wrong',
            state: FlashState.ERROR
          })
        );
      }
    },
    [dispatch]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    maxSize: FILE_UPLOAD_MAX_LIMIT,
    onDropRejected
  });

  let buttonLabel = documents && documents.length > 0 ? `Upload More Evidence` : `Upload Evidence`;
  buttonLabel = isDocumentUploading ? `Uploading...` : buttonLabel;

  return (
    <DocUploader>
      <div style={{ marginBottom: 36 }}>
        {documents &&
          documents.map(doc => {
            if (doc) {
              return (
                <DocumentRow key={doc.id}>
                  <Link style={{ display: 'inline' }}>
                    <a href={doc.url} target="_blank" rel="noopener noreferrer">
                      {doc.name}
                    </a>
                  </Link>
                  {showDeleteButton ? (
                    <RemoveButton
                      noArrow
                      mini="true"
                      type="button"
                      onClick={() => {
                        deleteDocument && deleteDocument(doc.id);
                      }}
                    >
                      Remove
                    </RemoveButton>
                  ) : null}
                </DocumentRow>
              );
            }
            return null;
          })}
      </div>
      <UploadButton {...getRootProps()} type="button" disabled={isDocumentUploading}>
        <input {...getInputProps()} />
        {buttonLabel}
      </UploadButton>
    </DocUploader>
  );
};

export default MultiDocUploader;
