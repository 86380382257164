import React from 'react';
import styled from 'styled-components';
import 'react-slidedown/lib/slidedown.css';
import { P2 } from '../../Typography/index';
import { getBreakpoint } from '../../../base/style';
import { ClaimItemEntity } from '../../../redux/entities/entities.types';

interface ClaimItemSummaryProps {
  items: ClaimItemEntity[];
}

const ClaimItemSummaryWrapper = styled.div`
  margin: 0 0 24px;

  .react-slidedown.claims-slider {
    transition-duration: 0.3s;
  }

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    margin: 0;
  }
`;

const Header = styled.div`
  align-items: center;
  display: flex;
`;

const ItemCount = styled(P2)`
  margin: 0 20px 0 0;
  padding: 0;
`;

const ClaimItemSummary: React.FC<ClaimItemSummaryProps> = ({ items }) => {
  return (
    <ClaimItemSummaryWrapper>
      <Header>
        <ItemCount>{`${items.length} Claim Items`}</ItemCount>
      </Header>
    </ClaimItemSummaryWrapper>
  );
};

export default ClaimItemSummary;
