import { put, takeLatest, select, call } from 'redux-saga/effects';
import { ClaimsApi, SearchClaimResultsDTO } from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { get } from 'lodash';

import SCHEMA from '../schema';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import { ClaimListFilters } from './claim-list.types';
import { createClaimsApi, runSagaWithAuth } from '../utils/api.utils';
import { fetchClaimsSuccess, fetchClaimsFailed, ClaimListActionTypes, FETCH_CLAIMS_API_REQUESTED } from './claim-list.actions';
import { getClaimListFilters, getClaimListPagination } from './claim-list.selectors';
import { getCurrentOrganizationId } from '../auth/auth.selectors';
import { ClaimSort, APISortClaim } from '../../constants/claims';

export const CLAIM_LIST_PAGE_SIZE = 10;

// ****************
// WORKERS
// ****************

export function* fetchClaimList() {
  try {
    const filters: ClaimListFilters = yield select(getClaimListFilters);
    const organizationId: string = yield select(getCurrentOrganizationId);
    const pagination = yield select(getClaimListPagination);

    const claimsApi: ClaimsApi = yield createClaimsApi();

    let sort: 'CREATED_AT' | 'PUBLISH_DEADLINE';

    switch (filters.sort) {
      case ClaimSort.CREATION_DATE:
        sort = APISortClaim.CREATED_AT;
        break;
      case ClaimSort.PUBLISH_DEADLINE:
        sort = APISortClaim.PUBLISH_DEADLINE;
        break;
    }

    const apiResponse: AxiosResponse<SearchClaimResultsDTO> = yield call(
      runSagaWithAuth(() =>
        claimsApi.searchClaim(organizationId, pagination.page, CLAIM_LIST_PAGE_SIZE, filters.query, filters.filter, sort, false)
      )
    );
    const ids: string[] = yield syncEntitiesAndGetResults(apiResponse.data.results, SCHEMA.claims);
    yield put<ClaimListActionTypes>(fetchClaimsSuccess(apiResponse.data.count, ids));
  } catch (e) {
    yield put<ClaimListActionTypes>(fetchClaimsFailed(get(e, 'response.data.message', e)));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchClaimListSagas() {
  yield takeLatest(FETCH_CLAIMS_API_REQUESTED, fetchClaimList);
}
