import React, { Fragment, MutableRefObject } from 'react';
import * as Yup from 'yup';
import { Formik, FormikProps } from 'formik';

import SecondaryPanel from '../SecondaryPanel/index';
import { P2 } from '../Typography/index';
import { Input, RadioGroup } from '../FormFields/index';
import styled from 'styled-components';
import Button from '../Button/index';
import FieldWithLabel from '../FormFields/FieldWithLabel/index';
import { UserEntity } from '../../redux/entities/entities.types';

interface LandlordProps {
  enabled: boolean;
  onSubmit: (fields: any) => void;
  isOrganizationLandlord: boolean;
  currentUser: UserEntity;
  landlordRef: MutableRefObject<HTMLDivElement | null>;
}

export interface LandlordFormValues {
  email: string;
  landlordTypeId: string;
  firstName?: string;
  lastName?: string;
  companyName?: string;
}

const Field = styled.div`
  margin: 12px 0 24px;
  max-width: 500px;
`;

const FormActions = styled.div`
  align-items: center;
  border-top: 1px solid ${props => props.theme.colors.dividerDark};
  display: flex;
  justify-content: flex-end;
  margin: 36px 0 0;
  padding: 22px 0 0;
`;

const Panel = styled.div`
  background: #edf1f9;
  padding: 20px 22px 8px;
`;

const Inner = styled.div`
  padding: 0 30px;
`;

const StaticMessage: React.FC = ({ children }) => (
  <Panel>
    <Inner>{children}</Inner>
  </Panel>
);

const renderCompanyLandlordForm = (props: FormikProps<LandlordFormValues>) => (
  <Fragment>
    <Field>
      <FieldWithLabel label="Company name" error={props.errors.companyName} touched={props.touched.companyName}>
        <Input
          name="companyName"
          value={props.values.companyName}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          placeholder="Example lets LTD"
          error={props.errors.companyName}
          touched={props.touched.companyName}
        />
      </FieldWithLabel>
    </Field>
  </Fragment>
);

const renderIndividualLandlordForm = (props: FormikProps<LandlordFormValues>) => (
  <Fragment>
    <Field>
      <FieldWithLabel label="First name" error={props.errors.firstName} touched={props.touched.firstName}>
        <Input
          name="firstName"
          value={props.values.firstName}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          placeholder="John"
          error={props.errors.firstName}
          touched={props.touched.firstName}
        />
      </FieldWithLabel>
    </Field>
    <Field>
      <FieldWithLabel label="Last name" error={props.errors.lastName} touched={props.touched.lastName}>
        <Input
          name="lastName"
          value={props.values.lastName}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          placeholder="Smith"
          error={props.errors.lastName}
          touched={props.touched.lastName}
        />
      </FieldWithLabel>
    </Field>
  </Fragment>
);

const Schema = Yup.object().shape({
  landlordTypeId: Yup.string().required('Required'),
  email: Yup.string()
    .email()
    .required('Required'),
  companyName: Yup.string().when('landlordTypeId', {
    is: (landlordTypeId: string) => landlordTypeId === 'COMPANY',
    then: Yup.string().required('Required'),
    otherwise: Yup.string()
  }),
  firstName: Yup.string().when('landlordTypeId', {
    is: (landlordTypeId: string) => landlordTypeId === 'SOLE_PERSON',
    then: Yup.string().required('Required'),
    otherwise: Yup.string()
  }),
  lastName: Yup.string().when('landlordTypeId', {
    is: (landlordTypeId: string) => landlordTypeId === 'SOLE_PERSON',
    then: Yup.string().required('Required'),
    otherwise: Yup.string()
  })
});

const Landlord: React.SFC<LandlordProps> = ({ enabled, onSubmit, isOrganizationLandlord, currentUser, landlordRef }) => {
  let content;
  if (!enabled) {
    content = (
      <StaticMessage>
        <P2 color="#666666">Landlord details may be filled in after an address has been added. </P2>
      </StaticMessage>
    );
  } else {
    const initialValues = isOrganizationLandlord
      ? {
          email: currentUser.email,
          companyName: '',
          firstName: currentUser.firstName,
          lastName: currentUser.lastName,
          landlordTypeId: 'SOLE_PERSON'
        }
      : { email: '', companyName: '', firstName: '', lastName: '', landlordTypeId: 'SOLE_PERSON' };
    content = (
      <Formik initialValues={initialValues} validationSchema={Schema} onSubmit={onSubmit}>
        {(props: FormikProps<LandlordFormValues>) => {
          return (
            <form style={{ marginTop: 18 }} onSubmit={props.handleSubmit}>
              <Field>
                <FieldWithLabel
                  label="Is the landlord a company or a sole person?"
                  error={props.errors.landlordTypeId}
                  touched={props.touched.landlordTypeId}
                >
                  <RadioGroup
                    name="landlordTypeId"
                    options={[{ label: 'Individual', value: 'SOLE_PERSON' }, { label: 'Company', value: 'COMPANY' }]}
                    selected={props.values.landlordTypeId}
                    onChange={e => {
                      props.resetForm();
                      props.handleChange(e);
                    }}
                    onBlur={props.handleBlur}
                  />
                </FieldWithLabel>
              </Field>
              {props.values.landlordTypeId === 'COMPANY' ? renderCompanyLandlordForm(props) : renderIndividualLandlordForm(props)}
              <Field>
                <FieldWithLabel label="Contact email address" error={props.errors.email} touched={props.touched.email}>
                  <Input
                    name="email"
                    value={props.values.email}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    placeholder="name@example.com"
                    error={props.errors.email}
                    touched={props.touched.email}
                  />
                </FieldWithLabel>
              </Field>

              <FormActions>
                <Button>Save landlord</Button>
              </FormActions>
            </form>
          );
        }}
      </Formik>
    );
  }

  return (
    <SecondaryPanel
      title="Landlord"
      subtitle="The details of the landlord for the property"
      style={{ borderRadius: '0 0 12px 12px' }}
      elRef={landlordRef}
    >
      {content}
    </SecondaryPanel>
  );
};

export default Landlord;
