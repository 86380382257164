import React from 'react';
import styled from 'styled-components';
import { getBreakpoint } from '../../../base/style';

const OnboardingActions = styled.div`
  align-items: center;
  border-top: 1px solid ${props => props.theme.colors.dividerDark};
  display: flex;
  flex-direction: row-reverse;
  margin: 48px 0 0;
  padding: 24px 0 0;

  a {
    text-decoration: none;
  }

  @media screen and (min-width: ${getBreakpoint('md')}) {
    margin: 20px 0 60px;
  }
`;

const Actions: React.SFC<any> = ({ children, style }) => {
  return <OnboardingActions style={style}>{children}</OnboardingActions>;
};

export default Actions;
