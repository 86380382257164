import { ClaimItemDocumentEntity } from '../entities/entities.types';
import { AppState } from '../root.reducer';

export const getClaimItemDocumentByDocumentId = (documentId: string) => (
  state: AppState
): ClaimItemDocumentEntity | undefined => {
  if (!state.entities.claimItemDocument) return undefined;
  const claimItemDocuments = Object.values(state.entities.claimItemDocument);
  return claimItemDocuments.find(cid => cid.document === documentId);
};
