import { omit } from 'lodash';

import { DELETE_TENANT_API_SUCCESS, RepositActionTypes } from '../../reposit/reposit.actions';

export const tenantEntityReducer = (state: any = {}, action: RepositActionTypes) => {
  if (action.type === DELETE_TENANT_API_SUCCESS) {
    return omit(state, action.payload.tenancyInviteId);
  }
  return state;
};
