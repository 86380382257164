import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import NotificationIcon from '../../../assets/svg/notification.svg';
import { getBreakpoint } from '../../../base/style';
import FlashMessage from '../../../components/FlashMessage/index';
import RepositCard from '../../../components/Reposit/RepositCard/index';
import { P1, P2 } from '../../../components/Typography/index';
import {
  updateEndOfTenancyEmailsRequested,
  UPDATE_EOT_NOTIFICATIONS_STORE_KEY,
  updateProductEmailsRequested
} from '../../../redux/account/account.actions';
import {
  getUserEOTContact,
  getUserEOTEmailsDisabled,
  getUserProductUpdateEmailsDisabled
} from '../../../redux/selectors/user.selectors';
import HelpIcon from '../../../assets/svg/help.svg';
import { FLASH_MESSAGE_TIMEOUT, useFlashMessage } from '../../FlashMessage/index';
import { RoundButton } from '../../../components/Button/index';
interface ManageEOTNotificationsProps {}

const Wrapper = styled.ul`
  background: #fff;
  margin: 22px 0 36px;
  min-width: 200px;
  overflow: hidden;
  padding: 0;
`;

const SettingItem = styled.div<{ isOpen?: boolean }>`
  background: ${props => props.theme.colors.bgAccent};
  margin: 0 0 6px;
  padding: 0 24px 0;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    padding: ${props => (props.isOpen ? `34px` : `0 34px`)};
  }

  &:hover {
    background: ${props => (!props.isOpen ? props.theme.colors.bgHoverAccent : props.theme.colors.bgAccent)};
  }
`;

const SettingItemInner = styled.li`
  border-radius: 8px;
  cursor: default;
  box-sizing: border-box;
  margin: 0 0 6px;
  padding: 12px 0;
  height: 96px;

  @media all and (min-width: ${getBreakpoint('md')}) {
    height: 112px;
  }

  @media all and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
    height: 48px;
    margin: 0 0 6px;
    padding: 0;
  }
`;

const Setting = styled.div`
  @media all and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
  }
`;

const TooltipButton = styled(RoundButton)`
  margin-left: 16px;
`;

const SettingValue = styled(P2)`
  margin: 0;
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  margin: 12px 0 0;
  @media all and (min-width: ${getBreakpoint('lg')}) {
    margin: 0 0 0 auto;
  }
`;

const IntroText = styled(P1)`
  padding: 1px 36px;
  margin-bottom: 36px;
`;

const ManageEOTNotifications: React.FC<ManageEOTNotificationsProps> = () => {
  const eotContact = useSelector(getUserEOTContact);
  const eotEmailsDisabled = useSelector(getUserEOTEmailsDisabled);
  const productUpdateEmailsDisabled = useSelector(getUserProductUpdateEmailsDisabled);

  const eotTooltipText = eotEmailsDisabled
    ? 'Your organisation has disabled end of tenancy emails for your account. You will still receive pre-tenancy emails'
    : `We may email you about Reposits or end of tenancy charges you didn't create. Enable this setting to stop receiving these additional emails. This does not affect notifications for Reposits and end of tenancy charges that you are personally responsible for`;

  const productUpdateEmailsTooltipText =
    'We may email you about product improvements & enhancements from time to time. Enable this setting to stop receiving these emails';

  const dispatch = useDispatch();
  const [flashMessagePayload, onDismissFlashMessage] = useFlashMessage([UPDATE_EOT_NOTIFICATIONS_STORE_KEY]);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const updateEOTEmails = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(
      updateEndOfTenancyEmailsRequested({
        enabled: !event.target.checked
      })
    );
  };

  const updateProductUpdateEmails = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(updateProductEmailsRequested({ enabled: event.target.checked }));
  };

  return (
    <RepositCard
      title="Email Settings"
      icon={NotificationIcon}
      flush
      flashMessage={
        flashMessagePayload ? (
          <FlashMessage onDismiss={onDismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMEOUT} payload={flashMessagePayload} />
        ) : (
          undefined
        )
      }
    >
      <IntroText>{`Change your personal email notification preferences`}</IntroText>
      <Wrapper>
        <SettingItem>
          <SettingItemInner>
            <Setting>
              <SettingValue>{`Only receive emails about Reposits and end of tenancy charges I create`}</SettingValue>
            </Setting>
            <Actions>
              <Toggle
                defaultChecked={eotEmailsDisabled ? true : !eotContact}
                icons={false}
                className="custom-toggle"
                onChange={updateEOTEmails}
                disabled={eotEmailsDisabled}
              />
              <TooltipButton icon={HelpIcon} data-tip={eotTooltipText} />
            </Actions>
          </SettingItemInner>
        </SettingItem>
        <SettingItem>
          <SettingItemInner>
            <Setting>
              <SettingValue>{`Disable product updates from Reposit`}</SettingValue>
            </Setting>
            <Actions>
              <Toggle
                defaultChecked={productUpdateEmailsDisabled}
                icons={false}
                className="custom-toggle"
                onChange={updateProductUpdateEmails}
              />
              <TooltipButton icon={HelpIcon} data-tip={productUpdateEmailsTooltipText} />
            </Actions>
          </SettingItemInner>
        </SettingItem>
      </Wrapper>
    </RepositCard>
  );
};

export default ManageEOTNotifications;
