import {
  ClaimItemDocumentActionTypes,
  CREATE_CLAIM_ITEM_DOCUMENT_API_SUCCESS,
  DELETE_CLAIM_ITEM_DOCUMENT_API_SUCCESS
} from '../../claim-item-document/claim-item-document.actions';
import { ClaimItemActionTypes, UPDATE_CLAIM_ITEM_PROPOSAL_SUCCESS } from '../../claim-item/claim-item.actions';

export const claimItemProposalEntityReducer = (state: any = {}, action: ClaimItemDocumentActionTypes | ClaimItemActionTypes) => {
  switch (action.type) {
    case CREATE_CLAIM_ITEM_DOCUMENT_API_SUCCESS:
      const claimItemProposal = state[action.payload.claimItemProposalId];
      return {
        ...state,
        [action.payload.claimItemProposalId]: {
          ...claimItemProposal,
          documents: [...claimItemProposal.documents, action.payload.claimItemDocumentId]
        }
      };
    case DELETE_CLAIM_ITEM_DOCUMENT_API_SUCCESS:
      const itemProposal = state[action.payload.claimItemProposalId];
      const newDocs = itemProposal.documents.filter((d: string) => d !== action.payload.claimItemDocumentId);
      return {
        ...state,
        [itemProposal.id]: {
          ...itemProposal,
          documents: newDocs
        }
      };
    case UPDATE_CLAIM_ITEM_PROPOSAL_SUCCESS:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          documents: action.payload.documents
        }
      };

    default:
      return state;
  }
};
