export const FILE_UPLOAD_MAX_LIMIT = 31457280; // 30mb
export const ALLOWED_FILE_UPLOAD_TYPES =
  'message/rfc822, text/plain, image/png, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/msword, text/html, video/mp4, application/pdf, application/octet-stream, image/heic, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/jpeg, application/vnd.oasis.opendocument.text, image/tiff, application/x-zip-compressed';

// apple mime types - https://stackoverflow.com/questions/1454777/apple-iwork-mime-types

export const acceptedFiles = {
  'application/pdf': ['.pdf'],
  'image/jpeg': ['.jpeg', '.jpg'],
  'image/png': ['.png'],
  'image/heic': ['.heic'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
  'application/vnd.ms-excel.sheet.macroEnabled.12': ['.xlsm'],
  'video/mp4': ['.mp4'],
  'video/quicktime': ['.mov'],
  'application/zip': ['.zip'],
  'application/vnd.ms-excel': ['.xls'],
  'text/csv': ['.csv'],
  'application/vnd.apple.numbers': ['.numbers'],
  'application/vnd.apple.pages': ['.pages']
};
