import {
  ClaimDTOStatusEnum,
  ClaimItemDTOTypeEnum,
  ClaimItemProposalDTOSettledSourceEnum,
  ClaimProposalDTOSourceEnum,
  ClaimProposalDTOStatusEnum,
  ClaimProposalDTOTypeEnum,
  CreateClaimItemProposalDTO
} from '@reposit/api-client';
import { get, startCase, toLower } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Visible } from 'react-grid-system';
import { useSelector } from 'react-redux';
import SlideDown from 'react-slidedown';
import { useIsMobile } from '../../../../hooks/useViewport';
import { ClaimItemProposalEntity, DocumentEntity } from '../../../../redux/entities/entities.types';
import { AppState } from '../../../../redux/root.reducer';
import { getClaimById } from '../../../../redux/selectors/claim.selectors';
import { Header3, Header4, P1, P2, P3 } from '../../../Typography/index';
import { StatusIcon } from '../../FileUpload/components';
import { AGENT_FINAL_ROUND, AGENT_FIRST_ROUND, TENANT_ROUND } from '../AuditTrail/History';
import { ClaimItemDropdown } from './ClaimItemDropdown';
import {
  AgreeButton,
  Amount,
  DescriptionContainer,
  DisagreeButton,
  EditButton,
  EditButtonContainer,
  EvidenceChecklistP2,
  EvidenceChecklistP3,
  ItemInfoContainer,
  Panel,
  UploadButton
} from './components';
import { getIcon } from './util';

export interface ClaimItem {
  id: string;
  description: string;
  type: ClaimItemDTOTypeEnum;
  claimId: string;
  itemProposals: ItemProposal[];
  isSettled: boolean;
}

export interface ItemProposal {
  id: string;
  claimProposal: ClaimProposal;
  settled?: boolean;
  settledSource?: ClaimItemProposalDTOSettledSourceEnum;
  amount: number;
  explanation?: string;
  documents?: DocumentEntity[];
  createdAt: string;
  updatedAt: string;
}

export interface ClaimProposal {
  id: string;
  type: ClaimProposalDTOTypeEnum;
  round: number;
  source: ClaimProposalDTOSourceEnum;
  status: ClaimProposalDTOStatusEnum;
  publishedAt?: string;
}
export enum ClaimItemComponentStatus {
  NEGOTIABLE = 'NEGOTIABLE',
  VIEW_ONLY = 'VIEW_ONLY',
  UPLOAD_ONLY = 'UPLOAD_ONLY'
}

interface ClaimItemProps {
  claimItem: ClaimItem;
  canRespond: boolean;
  componentStatus?: ClaimItemComponentStatus;
  allSupplierDocuments?: DocumentEntity[];
  createCounterProposal: (payload: CreateClaimItemProposalDTO) => Promise<ClaimItemProposalEntity>;
  updateCounterProposal: (payload: CreateClaimItemProposalDTO) => Promise<ClaimItemProposalEntity>;
  setOpenedClaimItem: (state: string | undefined) => void;
  hasGoneBack: boolean;
}

export enum ShowMoreType {
  EVIDENCE = 'EVIDENCE',
  FORM = 'FORM'
}

export enum ClaimItemState {
  INITIAL = 'INITIAL',
  VIEW_EVIDENCE = 'VIEW_EVIDENCE',
  REJECTED_TENANT_COUNTER = 'REJECTED_TENANT_COUNTER',
  UPLOADING_EVIDENCE = 'UPLOADING_EVIDENCE'
}

const ClaimItem: React.FC<ClaimItemProps> = ({
  claimItem,
  canRespond,
  componentStatus = ClaimItemComponentStatus.NEGOTIABLE,
  allSupplierDocuments,
  createCounterProposal,
  updateCounterProposal,
  setOpenedClaimItem,
  hasGoneBack
}) => {
  const [claimItemState, setClaimItemState] = useState<ClaimItemState>(ClaimItemState.INITIAL);

  const claim = useSelector((state: AppState) => getClaimById(state, claimItem.claimId));
  const claimInDraft = claim && claim.status === ClaimDTOStatusEnum.DRAFT;

  useEffect(() => {
    if (claimItemState === ClaimItemState.INITIAL) {
      setOpenedClaimItem(undefined);
    } else {
      setOpenedClaimItem(claimItem.id);
    }
  }, [claimItemState, claimItem, setOpenedClaimItem]);

  const viewOnly = componentStatus === ClaimItemComponentStatus.VIEW_ONLY || hasGoneBack;
  const uploadOnly = componentStatus === ClaimItemComponentStatus.UPLOAD_ONLY;
  const isNegotiable = componentStatus === ClaimItemComponentStatus.NEGOTIABLE;

  const initialAgentItemProposal = claimItem.itemProposals.find(ip => ip.claimProposal.round === AGENT_FIRST_ROUND);
  const initialAgentItemProposalAmount: number | undefined = get(initialAgentItemProposal, 'amount');

  const tenantItemProposal = claimItem.itemProposals.find(ip => ip.claimProposal.round === TENANT_ROUND);
  const tenantItemProposalAmount: number | undefined = get(tenantItemProposal, 'amount');

  const secondAgentItemProposal = claimItem.itemProposals.find(ip => ip.claimProposal.round === AGENT_FINAL_ROUND);
  const secondAgentItemProposalAmount: number | undefined = get(secondAgentItemProposal, 'amount');

  const itemIsSettledOnZero = !!(
    secondAgentItemProposal &&
    secondAgentItemProposal.settled &&
    secondAgentItemProposalAmount === 0
  );

  const toggleViewEvidence = () => {
    if (claimItemState === ClaimItemState.VIEW_EVIDENCE) {
      setClaimItemState(ClaimItemState.INITIAL);
    } else {
      setClaimItemState(ClaimItemState.VIEW_EVIDENCE);
    }
  };

  const draftAgentProposal = claimItem.itemProposals.find(
    ip => ip.claimProposal.round === 3 && ip.claimProposal.status === ClaimProposalDTOStatusEnum.DRAFT
  );

  const icon = getIcon({
    uploadOnly,
    hasSupplierUploadedEvidence: !!(allSupplierDocuments && allSupplierDocuments.length),
    itemIsSettledOnZero,
    canRespond,
    hasCountered: !!draftAgentProposal,
    hasAgreedProposal: !!(draftAgentProposal && draftAgentProposal.settled),
    hasGoneBack
  });

  const isSliderOpen =
    claimItemState === ClaimItemState.VIEW_EVIDENCE ||
    claimItemState === ClaimItemState.REJECTED_TENANT_COUNTER ||
    claimItemState === ClaimItemState.UPLOADING_EVIDENCE;

  const noActionTaken = claimItemState === ClaimItemState.INITIAL || claimItemState === ClaimItemState.VIEW_EVIDENCE;

  const isMobile = useIsMobile();

  const acceptProposal = async () => {
    // creating a new item proposal with the same amount as the tenant proposal
    await createCounterProposal({ claimItemId: claimItem.id, amount: tenantItemProposalAmount || 0 });
    setClaimItemState(ClaimItemState.INITIAL);
  };

  const renderButtons = () => {
    const ButtonComponent = noActionTaken ? EditButton : DisagreeButton;
    if (viewOnly) {
      return null;
    } else if (canRespond) {
      if (draftAgentProposal) {
        return (
          <EditButtonContainer>
            <ButtonComponent
              noArrow={isMobile}
              buttonType={'secondary'}
              style={{ width: isMobile ? '40%' : undefined }}
              onClick={() => setClaimItemState(noActionTaken ? ClaimItemState.REJECTED_TENANT_COUNTER : ClaimItemState.INITIAL)}
            >
              {noActionTaken ? 'Edit' : 'Back'}
            </ButtonComponent>
          </EditButtonContainer>
        );
      } else {
        return (
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 8 }}>
            {claimItemState !== ClaimItemState.REJECTED_TENANT_COUNTER ? (
              <AgreeButton noArrow={isMobile} buttonType={'primary'} onClick={acceptProposal}>
                Agree
              </AgreeButton>
            ) : null}

            <DisagreeButton
              noArrow={isMobile}
              buttonType={'primary'}
              onClick={() => setClaimItemState(noActionTaken ? ClaimItemState.REJECTED_TENANT_COUNTER : ClaimItemState.INITIAL)}
              style={{
                width: noActionTaken ? '50' : isMobile ? '100%' : undefined
              }}
            >
              {noActionTaken ? 'Disagree' : 'Back'}
            </DisagreeButton>
          </div>
        );
      }
    } else if (uploadOnly) {
      return (
        <UploadButton
          buttonType="secondary"
          noArrow={isMobile}
          mini="mini? mini!"
          onClick={() =>
            setClaimItemState(
              claimItemState === ClaimItemState.UPLOADING_EVIDENCE ? ClaimItemState.INITIAL : ClaimItemState.UPLOADING_EVIDENCE
            )
          }
        >
          {claimItemState === ClaimItemState.UPLOADING_EVIDENCE ? 'Hide' : 'Add Evidence'}
        </UploadButton>
      );
    } else {
      return null;
    }
  };

  const getAmountText = () => {
    if (claimItem.isSettled) {
      return 'Agreed settlement';
    }
    if (draftAgentProposal && !hasGoneBack) {
      if (draftAgentProposal.settled) {
        return 'Accepted proposal';
      } else {
        return 'Your proposal';
      }
    } else if (canRespond) {
      return 'Tenant proposal';
    } else {
      return 'Final proposed charge';
    }
  };

  const documentsRequiredToBeUploaded =
    !allSupplierDocuments || (allSupplierDocuments && !allSupplierDocuments.length && !itemIsSettledOnZero);

  const actionRequired =
    (canRespond &&
      !isSliderOpen &&
      !draftAgentProposal &&
      !!(tenantItemProposal && !tenantItemProposal.settled) &&
      isNegotiable &&
      !hasGoneBack) ||
    (uploadOnly && documentsRequiredToBeUploaded && !isSliderOpen);

  const dropdownProps = {
    claimItemState,
    tenantProposal: tenantItemProposal,
    initialAgentProposal: initialAgentItemProposal,
    secondAgentProposal: secondAgentItemProposal,
    canRespond,
    setClaimItemState,
    claimItem,
    allSupplierDocuments,
    createCounterProposal,
    updateCounterProposal,
    acceptProposal
  };

  const getAmount = () => {
    if (uploadOnly) {
      if (secondAgentItemProposalAmount || secondAgentItemProposalAmount === 0) {
        return secondAgentItemProposalAmount;
      }
      return initialAgentItemProposalAmount;
    }
    return draftAgentProposal && !hasGoneBack ? draftAgentProposal.amount : tenantItemProposalAmount;
  };

  return (
    <Panel actionRequired={actionRequired}>
      <Visible xs sm>
        <ItemInfoContainer column>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Header3 style={{ marginBottom: 0 }}>{claimItem.description}</Header3>
            {icon && !uploadOnly ? <StatusIcon icon={icon} style={{ marginLeft: '15px' }} /> : null}
          </div>
          {!uploadOnly ? (
            <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
              <P1 style={{ marginBottom: 0, marginRight: 5, width: 150 }}>{'Your initial charges'}:</P1>
              <Amount amount={initialAgentItemProposalAmount || 0} />
            </div>
          ) : null}
          <div style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}>
            <P1 style={{ marginBottom: 0, marginRight: 5, width: 150 }}>{getAmountText()}:</P1>
            <Amount amount={getAmount() || 0} />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: uploadOnly ? 'flex-start' : 'center',
              marginTop: 8,
              marginBottom: 6,
              flexDirection: uploadOnly ? 'column' : 'row'
            }}
          >
            <P2 style={{ marginBottom: 0, marginRight: 6 }}>{startCase(toLower(claimItem.type))}</P2>
            {uploadOnly ? (
              <div style={{ display: 'flex' }}>
                <P3 bold={documentsRequiredToBeUploaded}>
                  {documentsRequiredToBeUploaded
                    ? 'No evidence provided'
                    : `${allSupplierDocuments && allSupplierDocuments.length} document(s) uploaded`}
                </P3>
                {icon ? <StatusIcon icon={icon} style={{ marginLeft: '15px' }} /> : null}
              </div>
            ) : null}
            {claimItemState !== ClaimItemState.REJECTED_TENANT_COUNTER &&
            claimItemState !== ClaimItemState.UPLOADING_EVIDENCE &&
            !claimInDraft ? (
              <EvidenceChecklistP2 onClick={toggleViewEvidence}>
                <span>{claimItemState === ClaimItemState.VIEW_EVIDENCE ? 'Hide' : 'Show details'}</span>
              </EvidenceChecklistP2>
            ) : null}
          </div>
          {renderButtons()}
        </ItemInfoContainer>
      </Visible>
      <Visible md lg xl>
        <ItemInfoContainer column={false}>
          <DescriptionContainer>
            <Header4 style={{ marginBottom: 0 }}>{claimItem.description}</Header4>
            {!uploadOnly ? (
              <div style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
                <P3 style={{ marginBottom: 0, marginRight: 5 }}>{'Your initial proposal'}:</P3>
                <Amount amount={initialAgentItemProposalAmount || 0} />
              </div>
            ) : null}
            <div
              style={{
                display: 'flex',
                alignItems: uploadOnly ? 'flex-start' : 'center',
                marginTop: 8,
                flexDirection: uploadOnly ? 'column' : 'row'
              }}
            >
              <P3 style={{ marginBottom: 0, marginRight: 6 }}>{startCase(toLower(claimItem.type))}</P3>
              {uploadOnly ? (
                <div style={{ display: 'flex' }}>
                  <P3 bold={documentsRequiredToBeUploaded}>
                    {documentsRequiredToBeUploaded
                      ? 'No evidence provided'
                      : `${allSupplierDocuments && allSupplierDocuments.length} document(s) uploaded`}
                  </P3>
                  {icon ? <StatusIcon icon={icon} style={{ marginLeft: '15px' }} /> : null}
                </div>
              ) : null}
              {claimItemState !== ClaimItemState.REJECTED_TENANT_COUNTER &&
              claimItemState !== ClaimItemState.UPLOADING_EVIDENCE &&
              !claimInDraft ? (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <EvidenceChecklistP3 onClick={toggleViewEvidence}>
                    <span>{claimItemState === ClaimItemState.VIEW_EVIDENCE ? 'Hide' : 'Show details'}</span>
                  </EvidenceChecklistP3>
                </div>
              ) : null}
            </div>
          </DescriptionContainer>
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <P3 style={{ marginBottom: 0, marginRight: 5 }}>{getAmountText()}:</P3>
              <Amount amount={getAmount() || 0} />
              {icon && !uploadOnly ? <StatusIcon icon={icon} style={{ marginLeft: '15px' }} /> : null}
            </div>
            {renderButtons()}
          </div>
        </ItemInfoContainer>
      </Visible>
      <SlideDown closed={!isSliderOpen} className={`evidence-slider`}>
        <ClaimItemDropdown {...dropdownProps} />
      </SlideDown>
    </Panel>
  );
};
export default ClaimItem;
