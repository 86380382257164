import { SearchPropertiesRequest } from './property.types';

export const SEARCH_ORGANIZATION_PROPERTIES_STORE_KEY = 'PROPERTIES/SEARCH_ORGANIZATION_PROPERTIES';
export const SEARCH_ORGANIZATION_PROPERTIES_API_REQUESTED = `PROPERTIES/SEARCH_ORGANIZATION_PROPERTIES_API_REQUESTED`;
export const SEARCH_ORGANIZATION_PROPERTIES_API_SUCCESS = 'PROPERTIES/SEARCH_ORGANIZATION_PROPERTIES_API_SUCCESS';
export const SEARCH_ORGANIZATION_PROPERTIES_API_FAILED = 'PROPERTIES/SEARCH_ORGANIZATION_PROPERTIES_API_FAILED';

export const CLEAR_SEARCHED_PROPERTIES = `PROPERTIES/CLEAR_SEARCHED_PROPERTIES`;

export function searchOrganizationPropertiesRequested(payload: SearchPropertiesRequest) {
  return {
    type: SEARCH_ORGANIZATION_PROPERTIES_API_REQUESTED,
    payload
  } as const;
}

export function searchOrganizationPropertiesSuccess(payload: string[]) {
  return {
    type: SEARCH_ORGANIZATION_PROPERTIES_API_SUCCESS,
    payload
  } as const;
}

export function searchOrganizationPropertiesFailed(error: string) {
  return {
    type: SEARCH_ORGANIZATION_PROPERTIES_API_FAILED,
    payload: error
  } as const;
}

export function clearSearchedProperties() {
  return {
    type: CLEAR_SEARCHED_PROPERTIES
  } as const;
}

export type PropertiesActionTypes = ReturnType<
  | typeof searchOrganizationPropertiesRequested
  | typeof searchOrganizationPropertiesSuccess
  | typeof searchOrganizationPropertiesFailed
  | typeof clearSearchedProperties
>;
