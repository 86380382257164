import { PropertyDTO } from '@reposit/api-client';
import { get } from 'lodash';
import styled from 'styled-components';

import React, { Fragment, useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  updatePropertyRequested,
  UPDATE_PROPERTY_STORE_KEY,
  setIsPropertyFormOpen,
  CREATE_ADDRESS_STORE_KEY,
  createTenancyWithPropertyRequested,
  CREATE_TENANCY_WITH_PROPERTY_STORE_KEY
} from '../../../../redux/reposit/reposit.actions';
import { P1, Header3, P2, StyledLink } from '../../../../components/Typography/index';
import Button from '../../../../components/Button/index';
import Select from '../../../../components/FormFields/Select/index';
import CreatePropertyContainer from '../../../Property/CreateProperty/index';

import RepositCard from '../../../../components/Reposit/RepositCard/index';
import PropertyIcon from '../../../../assets/svg/property.svg';
import FlashMessage from '../../../../components/FlashMessage/index';
import Modal from '../../../../components/Modal/index';
import { getIsPropertyFormOpen } from '../../../../redux/reposit/reposit.selectors';
import { SEARCH_ADDRESSES_STORE_KEY } from '../../../../redux/address/address.actions';
import { TenancyOrderWithActions, getFlatProperties } from '../../../../redux/selectors/index';
import { getAddressFirstLine, getAddressTownPostcode } from '../../../../utils/common.utils';
import { useFlashMessage, FLASH_MESSAGE_TIMEOUT } from '../../../FlashMessage/index';
import { setActiveForms, clearActiveForms } from '../../../../redux/active-form/active-form.actions';
import { ActiveFormOptions } from '../../../../redux/active-form/active-forms.types';
import { DisableComponent } from '../../../../components/Common/index';
import { SearchableTextInput } from '../../../../components/SearchableTextInput/index';
import { getSearchedProperties, getHasSearchedProperties } from '../../../../redux/property/property.selectors';
import {
  searchOrganizationPropertiesRequested,
  clearSearchedProperties,
  SEARCH_ORGANIZATION_PROPERTIES_STORE_KEY
} from '../../../../redux/property/property.actions';
import { createLoadingSelector } from '../../../../redux/loading/loading.selector';
import { getCurrentOrganizationId } from '../../../../redux/auth/auth.selectors';

interface PropertyContainerProps {
  tenancyOrder?: TenancyOrderWithActions;
  disabled?: boolean;
  editMode: boolean;
  isEditable: boolean;
  isNew: boolean;
}

const PropertyListContainer = styled.div`
  padding: 1px 36px 0;
  margin-bottom: 36px;
`;

const Action = styled.div`
  padding: 24px 0 0;
  text-align: right;
`;

const renderAddressPickerModal = (callback: any) => (
  <Fragment>
    <Header3 style={{ marginBottom: 6 }}>Choose address</Header3>
    <P2 style={{ marginBottom: 24 }}>
      Select your address from the list below. If it’s not listed{' '}
      <StyledLink to="#" onClick={callback}>
        enter it manually
      </StyledLink>
    </P2>
    <Select onChange={console.log} value={''}>
      <option>Flat 1</option>
    </Select>
    <Action>
      <Button>Use this address</Button>
    </Action>
  </Fragment>
);

const renderReadOnly = (property: PropertyDTO, isEditable: boolean, setActiveForms: any) => {
  const landlord =
    property && property.landlord
      ? property.landlord.companyName || `${property.landlord.firstName} ${property.landlord.lastName}`
      : '';

  const firstLine = property && property.address ? getAddressFirstLine(property.address) : '';
  const postcode = property && property.address ? getAddressTownPostcode(property.address) : '';

  return (
    <div style={{ padding: '12px 35px 36px' }}>
      <P1 style={{ fontSize: 16 }}>
        {landlord} - {firstLine} {postcode}
      </P1>
      {isEditable && (
        <div style={{ textAlign: 'right' }}>
          <Button buttonType="secondary" onClick={setActiveForms}>
            Edit
          </Button>
        </div>
      )}
    </div>
  );
};

const ManualLink = styled(StyledLink)`
  display: inline;
  margin: 0 6px;
`;

const Link = styled(StyledLink)`
  margin: 0 20px;
`;

const useClearProperties = () => {
  const dispatch = useDispatch();
  const clear = useCallback(() => {
    dispatch(clearSearchedProperties());
  }, [dispatch]);
  useEffect(() => {
    return function clearProperties() {
      clear();
    };
  }, [dispatch, clear]);
  return clear;
};

const SearchableSelect = ({
  properties,
  searchProperties,
  handlePropertyChange,
  currentProperty,
  showForm,
  isNew,
  cancelEdit,
  updateProperty,
  propertyId
}: {
  properties: (PropertyDTO | undefined)[];
  cancelEdit: () => void;
  searchProperties: (term: string) => void;
  handlePropertyChange: (id?: string) => void;
  currentProperty: PropertyDTO;
  showForm: (bool: boolean) => void;
  isNew: boolean;
  updateProperty: (propertyId: string) => void;
  propertyId: string;
}) => {
  // EXTRA COMPONENT NEEDED TO SEPARATE THIS HAS PROPERTY CHANGED
  // better ux where the button is disabled if they haven't changed it
  const [hasPropertyChanged, setHasPropertyChanged] = useState(false);
  const loadingSelector = createLoadingSelector([SEARCH_ORGANIZATION_PROPERTIES_STORE_KEY]);
  const isSearching = useSelector(loadingSelector);
  const hasSearched = useSelector(getHasSearchedProperties);
  const clearhasSearched = useClearProperties();
  return (
    <PropertyListContainer>
      <SearchableTextInput
        name="property"
        items={properties}
        keyPath="id"
        searching={isSearching}
        onChange={searchProperties}
        onItemSelected={property => {
          setHasPropertyChanged(true);
          handlePropertyChange(property && property.id);
        }}
        initialValue={currentProperty}
        getValue={property => {
          if (property) {
            const landlord = property.landlord.companyName || `${property.landlord.firstName} ${property.landlord.lastName}`;
            return `${landlord}, ${getAddressFirstLine(property.address)}, ${getAddressTownPostcode(property.address)}`;
          } else {
            return '';
          }
        }}
        hasSearched={hasSearched}
        clearHasSearched={clearhasSearched}
      />
      <P2 style={{ marginTop: 16, fontSize: '1em', marginBottom: 0 }}>
        Don't see your property?
        <ManualLink to="#" onClick={() => showForm(true)}>
          Add a property
        </ManualLink>
      </P2>
      <Action>
        {!isNew && (
          <Link to="#" onClick={cancelEdit}>
            Cancel edit property
          </Link>
        )}
        <Button disabled={!hasPropertyChanged} onClick={() => updateProperty(propertyId)}>
          {isNew ? 'Create' : 'Update'}
        </Button>
      </Action>
    </PropertyListContainer>
  );
};

const renderContent = (
  editMode: boolean,
  propertyId: string,
  currentProperty: PropertyDTO,
  handlePropertyChange: (id?: string) => void,
  isFormShowing: boolean,
  showForm: (bool: boolean) => void,
  tenancyId: string,
  updateProperty: (propertyId: string) => void,
  isNew: boolean,
  isEditable: boolean,
  setActiveForms: any,
  cancelEdit: () => void,
  searchProperties: (term: string) => void,
  properties: (PropertyDTO | undefined)[]
) => {
  if (!editMode && !isNew) {
    return renderReadOnly(currentProperty, isEditable, setActiveForms);
  }

  if (isFormShowing) {
    return <CreatePropertyContainer showForm={showForm} tenancyId={tenancyId} isNew={isNew} />;
  }
  const props = {
    properties,
    searchProperties,
    handlePropertyChange,
    currentProperty,
    showForm,
    isNew,
    cancelEdit,
    updateProperty,
    propertyId
  };
  return <SearchableSelect {...props} />;
};

const PropertyInfoContainer = ({ disabled, tenancyOrder, isEditable, editMode, isNew }: PropertyContainerProps) => {
  const dispatch = useDispatch();

  const [isModalShowing, showModal] = useState(false);
  const currentProperty = get(tenancyOrder, 'tenancy.property');
  const currentPropertyId = get(currentProperty, 'id');
  const currentOrganizationId = useSelector(getCurrentOrganizationId);

  const searchedProperties = useSelector(getSearchedProperties);
  const properties = useSelector(getFlatProperties(searchedProperties));

  const [propertyId, setPropertyId] = useState(currentPropertyId || '');
  useEffect(() => {
    setPropertyId(currentPropertyId);
  }, [currentPropertyId]);
  const tenancyId = get(tenancyOrder, 'tenancy.id');

  const [flashMessagePayload, onDismissFlashMessage] = useFlashMessage([
    CREATE_TENANCY_WITH_PROPERTY_STORE_KEY,
    UPDATE_PROPERTY_STORE_KEY,
    CREATE_ADDRESS_STORE_KEY,
    SEARCH_ADDRESSES_STORE_KEY
  ]);

  const isFormShowing = useSelector(getIsPropertyFormOpen);

  let updateProperty: (propertyId: string) => void;
  if (isNew) {
    updateProperty = (propertyId: string) =>
      dispatch(
        createTenancyWithPropertyRequested({
          order: {
            productId: 'REPOSIT'
          },
          organizationId: currentOrganizationId,
          propertyId
        })
      );
  } else {
    updateProperty = (propertyId: string) => dispatch(updatePropertyRequested({ propertyId, tenancyId }));
  }
  const showForm = (value: boolean) => dispatch(setIsPropertyFormOpen(value));

  const handlePropertyChange = (id?: string) => {
    if (id) {
      setPropertyId(id);
    }
  };
  const cancelEdit = () => dispatch(clearActiveForms());

  const searchProperties = useCallback((query: string) => dispatch(searchOrganizationPropertiesRequested({ query })), [dispatch]);

  const content = (
    <RepositCard
      title="Property Information"
      icon={PropertyIcon}
      tooltip="Property information consists of the address and landlord of the property. These two things together make up a property."
      flush={true}
      flashMessage={
        flashMessagePayload ? (
          <FlashMessage onDismiss={onDismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMEOUT} payload={flashMessagePayload} />
        ) : (
          undefined
        )
      }
    >
      {renderContent(
        editMode,
        propertyId,
        currentProperty,
        handlePropertyChange,
        isFormShowing,
        showForm,
        tenancyId,
        updateProperty,
        isNew,
        isEditable,
        () => dispatch(setActiveForms([ActiveFormOptions.PROPERTY])),
        cancelEdit,
        searchProperties,
        properties
      )}
      {isModalShowing ? <Modal onDismiss={() => showModal(false)}>{renderAddressPickerModal(showForm)}</Modal> : <span />}
    </RepositCard>
  );

  return <DisableComponent disabled={disabled}>{content}</DisableComponent>;
};

export default PropertyInfoContainer;
