import { Formik, FormikProps, validateYupSchema, yupToFormErrors } from 'formik';
import { get } from 'lodash';
import React, { useState } from 'react';
import { Col, Row } from 'react-grid-system';
import styled from 'styled-components';
import * as Yup from 'yup';
import poundIcon from '../../../assets/svg/pound-sterling.svg';
import { getBreakpoint } from '../../../base/style';
import { useIsMobile } from '../../../hooks/useViewport';
import { DocumentEntity } from '../../../redux/entities/entities.types';
import { penceToPounds, poundsToPence } from '../../../utils/common.utils';
import { twoDPRegex } from '../../../utils/regex/number.regex';
import Button from '../../Button/index';
import FieldWithLabel, { FieldLabel } from '../../FormFields/FieldWithLabel/index';
import { Input } from '../../FormFields/index';
import { FileUpload, FileUploadProgress } from '../FileUpload/FileUpload';
import FormErrorMessage from '../../FormFields/ErrorMessage/index';
import { P3 } from '../../Typography/index';
import { CLAIM_UPLOAD_INFO } from '../../../constants/claims';

interface ExplanationProps {
  showOffer: boolean;
  showBorder?: boolean;
  required: boolean;
  uploadFile: (file: File) => Promise<DocumentEntity>;
  onError: (error: string) => void;
  onUploadComplete?: () => void;
  showDeleteButton: boolean;
  onSubmit: (values: ExplanationFormValues) => Promise<any>;
  isSubmitting: boolean;
  previousAmount: number;
  negotiationDocuments?: DocumentEntity[];
  negotiationAmount?: number;
  negotiationExplanation?: string;
  tenantProposalAmount?: number;
  deleteFile?: (id: string) => Promise<void>;
}

export interface ExplanationFormValues {
  amount?: number;
  documentIds?: string[];
  explanation?: string;
}

interface TextAreaProps {
  touched: boolean | undefined;
  error: string | undefined;
}

const Schema = Yup.object().shape({
  explanation: Yup.string().required('You must provide reasoning for your new proposal for this item'),
  amount: Yup.number()
    .when(['$previousAmount', '$tenantProposalAmount'], (previousAmount: number, tenantProposalAmount: number) =>
      Yup.number()
        .max(previousAmount / 100, `New offer cannot exceed your previous offer of £${penceToPounds(previousAmount)}.`)
        .min(
          tenantProposalAmount / 100,
          `New offer cannot be less than the tenant's proposed settlement of £${penceToPounds(tenantProposalAmount)}.`
        )
        .typeError('Amount must be a number')
        .required('Required')
        .test('two-decimals', 'Item amount must have a maximum of two decimal places', value => {
          return twoDPRegex.test(value);
        })
    )
    .typeError('Amount must be a number')
    .moreThan(0, 'Amount has to be above £0')
    .required('Required')
    .test('two-decimals', 'Item amount must have a maximum of two decimal places', value => {
      return twoDPRegex.test(value);
    }),
  documentIds: Yup.array(Yup.string())
});

const TextArea = styled.textarea<TextAreaProps>`
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  padding: 10px;
  font-family: ${props => props.theme.typography.face.primary};
  box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid ${props => (props.error && props.touched ? props.theme.colors.negative : 'rgba(0, 0, 0, 0.1)')};
  min-height: 110px;

  border-radius: 3px;
  outline: none;

  &:focus {
    box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    border: solid 1px ${props => props.theme.colors.body};
  }

  @media screen and (min-width: ${getBreakpoint('md')}) {
    max-height: 130px;
  }
`;

const Notifier = styled(P3)`
  cursor: pointer;
  text-decoration: underline;
  margin-bottom: 8px;
  display: inline-block;
`;

const UploadInfo = () => (
  <Notifier data-tip={CLAIM_UPLOAD_INFO} data-offset="{'right': 70}">
    What can I upload?
  </Notifier>
);

export const Explanation = (props: ExplanationProps) => {
  const [documents, setDocuments] = useState<DocumentEntity[]>(get(props, 'negotiationDocuments', []));
  const [fileUploadProgress, setFileUploadProgress] = useState<FileUploadProgress | undefined>();

  const initValues: ExplanationFormValues = {
    amount: props.negotiationAmount ? props.negotiationAmount / 100 : undefined,
    explanation: props.negotiationExplanation,
    documentIds: props.negotiationDocuments && props.negotiationDocuments.map(nd => nd.id)
  };

  const isMobile = useIsMobile();

  const SaveButton = styled(Button)`
    justify-content: center;
    font-size: 0.76em;
    width: 100%;
    padding: 8px 4px;

    @media screen and (min-width: ${getBreakpoint('md')}) {
      font-size: 0.68em;
      width: auto;
      // original - had to set because we changed it above - ugh
      padding: 0.5em 1.5em 0.5em 1.75em;
    }
  `;

  const FileUploadCol = styled(Col)`
    order: 2;
    margin-top: 8px;

    @media screen and (min-width: ${getBreakpoint('md')}) {
      order: 1;
      margin-top: 0px;
    }
  `;

  const TextAreaCol = styled(Col)`
    order: 1;
    margin-top: 8px;

    @media screen and (min-width: ${getBreakpoint('md')}) {
      order: 2;
      margin-top: 10px;
    }
  `;

  const handleDelete = async (id: string) => {
    if (props.deleteFile) {
      await props.deleteFile(id);
    }
    return setDocuments(currDocs => currDocs.filter(d => d.id !== id));
  };

  return (
    <Formik
      initialValues={initValues}
      validate={async values => {
        try {
          if (documents && documents.length) {
            values.documentIds = documents.map(doc => doc && doc.id);
          } else {
            // need this for when items are added and then removed
            // values.documentIds stays in state other wise
            values.documentIds = [];
          }

          await validateYupSchema(values, Schema, true, {
            previousAmount: props.previousAmount,
            tenantProposalAmount: props.tenantProposalAmount
          });
        } catch (e) {
          const formErrors = yupToFormErrors(e);
          throw formErrors;
        }
      }}
      onSubmit={values => {
        return props.onSubmit({
          ...values,
          amount: values.amount ? poundsToPence(values.amount) : undefined,
          documentIds: documents.map(doc => doc.id)
        });
      }}
    >
      {({ values, handleChange, handleSubmit, handleBlur, touched, errors }: FormikProps<ExplanationFormValues>) => {
        return (
          <div style={{ width: '100%' }}>
            <form onSubmit={handleSubmit}>
              {props.showOffer ? (
                <Row>
                  <Col xs={12} md={6}>
                    <FieldWithLabel label="Proposed Settlement*" touched={touched.amount} error={errors.amount}>
                      <Input
                        icon={poundIcon}
                        name="amount"
                        value={values.amount ? values.amount : ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched.amount}
                        error={errors.amount}
                        type="number"
                      />
                    </FieldWithLabel>
                  </Col>
                </Row>
              ) : null}

              <FieldLabel style={{ marginTop: 10 }}>Supporting Evidence</FieldLabel>
              <UploadInfo />

              <Row>
                <FileUploadCol xs={12} md={6}>
                  <FileUpload
                    {...props}
                    documents={documents}
                    deleteFile={handleDelete}
                    onUploadComplete={docs => {
                      if (docs) {
                        setDocuments(currDocs => [...currDocs, ...docs]);
                      }
                    }}
                    fileUploadProgress={fileUploadProgress}
                    setFileUploadProgress={setFileUploadProgress}
                  />
                </FileUploadCol>
                <TextAreaCol xs={12} md={6}>
                  <TextArea
                    placeholder="Enter your explanation"
                    name="explanation"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.explanation && values.explanation.trimStart()}
                    error={errors.explanation}
                    touched={touched.explanation}
                  />
                  {touched.explanation && errors.explanation ? <FormErrorMessage error={errors.explanation} /> : null}
                </TextAreaCol>
              </Row>
              <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', marginTop: 10 }}>
                <SaveButton noArrow={isMobile} buttonType="primary" disabled={props.isSubmitting}>
                  Save
                </SaveButton>
              </div>
            </form>
          </div>
        );
      }}
    </Formik>
  );
};
