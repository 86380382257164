import { Formik, FormikProps } from 'formik';
import moment from 'moment';
import React, { Dispatch, useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';
import poundIcon from '../../assets/svg/pound-sterling.svg';
import TenancyIcon from '../../assets/svg/tenancy-details.svg';
import { DATE_FORMAT } from '../../constants/dates';
import { AST_ADDENDUM_LINK, REFERENCING_CRITERIA } from '../../constants/links';
import { useIsOrganizationLandlord } from '../../hooks/useIsLandlord';
import { clearActiveForms, setActiveForms } from '../../redux/active-form/active-form.actions';
import { ActiveFormOptions } from '../../redux/active-form/active-forms.types';
import { FlashMessagePayload } from '../../redux/flash-messages/flash-messages.types';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import { UPDATE_TENANCY_STORE_KEY } from '../../redux/reposit/reposit.actions';
import { getExpiryDate } from '../../utils/common.utils';
import { calculateMaxLiabilityFromPPMForReposit, REPOSIT_POLICY_MAX_LIABILITY } from '../../utils/pricing';
import { twoDPRegex } from '../../utils/regex/number.regex';
import Button from '../Button/index';
import FlashMessage from '../FlashMessage/index';
import { Input, RadioGroup } from '../FormFields';
import Checkbox from '../FormFields/Checkbox';
import DatePicker from '../FormFields/DatePicker/index';
import FormErrorMessage from '../FormFields/ErrorMessage/index';
import FieldWithLabel from '../FormFields/FieldWithLabel/index';
import RepositCard from '../Reposit/RepositCard';
import SecondaryPanel from '../SecondaryPanel';
import { P1, P2 } from '../Typography/index';
import {
  Action,
  Field,
  IsNewTenancy,
  Item,
  ItemTitle,
  Link,
  PPM,
  TenancyDates,
  TenancyDetails,
  TenancyInfo,
  TenancyType,
  Until,
  WarningMessage
} from './styles';
import TenancyDateActions from './TenancyDateActions';

const Hyperlink = styled.a`
  color: ${props => props.theme.colors.primaryBrand};
  font-family: ${props => props.theme.typography.face.secondary};
  font-size: inherit;
  text-decoration: underline;
  display: inline;
  z-index: 100;
`;

interface TenancyDetailsProps {
  editMode: boolean;
  isEditable: boolean;
  onSubmit: (fields: TenancyDetailsFormValues) => void;
  flashMessage?: FlashMessagePayload;
  onDismissFlashMessage: () => void;
  tenancy?: any;
}

interface ExistingTenancyChecks {
  tenantHaveNoRentArrearsOrRentPaymentHolidays: boolean;
  tenantsPassedReferencing: boolean;
  tenantWillNeedToSignAddendum: boolean;
  noChangeToTenancyOrTenantFinances: boolean;
}

export interface TenancyDetailsFormValues {
  PPM?: number;
  tenancyType?: string;
  tenancyStart?: Date;
  tenancyEnd?: Date;
  isNewTenancy?: string;
}

export enum TenancyTypes {
  LET_ONLY = 'Let only',
  FULLY_MANAGED = 'Fully Managed'
}

const Schema = Yup.object().shape({
  PPM: Yup.number()
    .typeError('PPM must be a number')
    .min(250, 'PPM has to be above £250')
    .required('Required')
    .test('two-decimals', 'PPM must have a maximum of two decimal places', value => {
      return twoDPRegex.test(value);
    }),
  tenancyType: Yup.string().required('Required'),
  tenancyStart: Yup.date().required('Required'),
  tenancyEnd: Yup.date()
    .required('Required')
    .test('tenancy-end', 'End date must be after start date', function(value: Date) {
      if (!this.parent.tenancyStart) return true;
      const startMoment = moment(this.parent.tenancyStart);
      const endMoment = moment(value);
      return endMoment.isAfter(startMoment);
    })
});

const useRenderContent = (
  props: TenancyDetailsProps,
  dispatch: Dispatch<any>,
  existingTenancyChecks: ExistingTenancyChecks,
  onUpdateExistingTenancyChecks: (value: ExistingTenancyChecks) => void,
  isOrgLandlord: boolean
) => {
  const { tenancy } = props;
  let tenancyType;
  const [formPPM, setFormPPM] = useState<number | undefined>(tenancy && tenancy.PPM ? tenancy.PPM * 100 : undefined);
  const [ppmError, setPPMError] = useState<string>();
  useEffect(() => {
    if (formPPM) {
      const liability = calculateMaxLiabilityFromPPMForReposit(formPPM);
      if (liability > REPOSIT_POLICY_MAX_LIABILITY) {
        setPPMError('The cover for the ppm you have entered is capped at £5,000.');
      } else {
        if (ppmError) {
          setPPMError('');
        }
      }
    }
  }, [formPPM, ppmError]);
  // using an if statement here to avoid the nested ternary
  if (tenancy) {
    tenancyType = tenancy.letOnly ? TenancyTypes.LET_ONLY : TenancyTypes.FULLY_MANAGED;
  } else {
    tenancyType = isOrgLandlord ? TenancyTypes.FULLY_MANAGED : undefined;
  }

  const isComplete =
    tenancy && (tenancy.PPM && tenancy.startDate && tenancy.endDate && typeof tenancy.isNewTenancy === 'boolean');

  let isNewTenancy;
  if (isComplete) {
    isNewTenancy = tenancy.isNewTenancy === true ? 'YES' : 'NO';
  }

  const initialValues = {
    PPM: tenancy && tenancy.PPM ? tenancy.PPM : '',
    tenancyStart: tenancy && tenancy.startDate ? moment(tenancy.startDate, 'YYYY-MM-DD').toDate() : undefined,
    tenancyEnd:
      tenancy && tenancy.expiryDates && tenancy.expiryDates.length
        ? moment(getExpiryDate(tenancy.expiryDates).endDate, 'YYYY-MM-DD').toDate()
        : undefined,
    tenancyType,
    isNewTenancy
  };

  const loadingSelector = createLoadingSelector([UPDATE_TENANCY_STORE_KEY]);
  const isLoading = useSelector(loadingSelector);
  const allChecked = () => {
    return Object.values(existingTenancyChecks).every(item => item === true);
  };

  const dataPickerRef = useRef<any>(null);

  if (props.editMode) {
    return (
      <Formik
        initialValues={initialValues}
        validationSchema={Schema}
        onSubmit={formValues => {
          const ppm = formValues.PPM && Math.round(formValues.PPM * 100);
          props.onSubmit({
            ...formValues,
            PPM: ppm
          });
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          errors,
          touched
        }: FormikProps<TenancyDetailsFormValues>) => {
          return (
            <form onSubmit={handleSubmit}>
              <Container fluid>
                <Row>
                  <Col lg={10} style={{ padding: 0 }}>
                    <IsNewTenancy>
                      <FieldWithLabel
                        showTooltip={true}
                        tooltipText="If this Reposit is being created for a new tenancy, please select ‘New tenancy’. If you are using Reposit for an existing tenancy because the tenant is switching from a traditional deposit to a Reposit, please select ‘Existing tenancy’."
                        label="Is this Reposit for a new tenancy or an existing tenancy?"
                        error={errors.isNewTenancy}
                        touched={touched.isNewTenancy}
                      >
                        <RadioGroup
                          name="isNewTenancy"
                          options={[{ value: 'YES', label: 'New tenancy' }, { value: 'NO', label: 'Existing tenancy' }]}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          selected={values.isNewTenancy}
                          error={errors.isNewTenancy}
                          touched={touched.isNewTenancy}
                        />
                      </FieldWithLabel>
                    </IsNewTenancy>
                  </Col>
                  <Col lg={12} style={{ padding: 0 }}>
                    {!values.isNewTenancy && (
                      <Action>
                        <Link to="#" onClick={() => dispatch(clearActiveForms())}>
                          Cancel edit details
                        </Link>
                      </Action>
                    )}
                  </Col>
                </Row>
                {values.isNewTenancy && (
                  <>
                    <Row>
                      <Col lg={6} style={{ padding: 0 }}>
                        <PPM>
                          <FieldWithLabel label="PPM" error={errors.PPM || ppmError} touched={touched.PPM || !!ppmError}>
                            <Input
                              name="PPM"
                              value={`${values.PPM}`}
                              onChange={e => {
                                handleChange(e);
                                setFormPPM(Math.round(parseFloat(e.target.value) * 100));
                              }}
                              onBlur={handleBlur}
                              icon={poundIcon}
                              error={errors.PPM}
                              touched={touched.PPM}
                            />
                          </FieldWithLabel>
                        </PPM>
                      </Col>
                      {!isOrgLandlord && (
                        <Col lg={6} style={{ padding: 0 }}>
                          <TenancyType>
                            <FieldWithLabel label="Tenancy type" error={errors.tenancyType} touched={touched.tenancyType}>
                              <RadioGroup
                                name="tenancyType"
                                options={[
                                  { value: TenancyTypes.FULLY_MANAGED, label: 'Fully Managed' },
                                  { value: TenancyTypes.LET_ONLY, label: 'Let Only' }
                                ]}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                selected={values.tenancyType}
                                error={errors.tenancyType}
                                touched={touched.tenancyType}
                              />
                            </FieldWithLabel>
                          </TenancyType>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col lg={12} style={{ padding: 0 }}>
                        <TenancyDates editMode={true}>
                          <Field>
                            <FieldWithLabel
                              label={values.isNewTenancy === 'NO' ? 'Reposit start date' : 'Tenancy start'}
                              touched={touched.tenancyStart}
                              error={errors.tenancyStart}
                              showTooltip={values.isNewTenancy === 'NO'}
                              tooltipText="Please enter the Reposit ‘start date’ as the date you require the Reposit cover to start. Please note the tenants must have paid the Reposit fee and an interim inspection report must be completed by this date for the Reposit to be valid."
                            >
                              <DatePicker
                                name="tenancyStart"
                                value={values.tenancyStart}
                                dateFormat={DATE_FORMAT}
                                onBlur={handleBlur}
                                touched={touched.tenancyStart}
                                placeholder={'DD/MM/YYYY'}
                                error={errors.tenancyStart}
                                minDate={moment()
                                  .subtract(1, 'week')
                                  .toDate()}
                                onChange={date => {
                                  setFieldValue('tenancyStart', date);
                                }}
                              />
                            </FieldWithLabel>
                          </Field>
                          <Until editMode={true}>Until</Until>
                          <Field>
                            <FieldWithLabel label="Tenancy end" touched={touched.tenancyEnd} error={errors.tenancyEnd}>
                              <DatePicker
                                name="tenancyEnd"
                                value={values.tenancyEnd}
                                onBlur={handleBlur}
                                dateFormat={DATE_FORMAT}
                                touched={touched.tenancyEnd}
                                error={errors.tenancyEnd}
                                forwardedRef={dataPickerRef}
                                children={
                                  <TenancyDateActions
                                    initialDate={values.tenancyStart}
                                    onDateSelect={date => {
                                      dataPickerRef.current.setOpen(false);
                                      setFieldValue('tenancyEnd', date);
                                    }}
                                  />
                                }
                                placeholder={'DD/MM/YYYY'}
                                minDate={moment(values.tenancyStart)
                                  .add(1, 'days')
                                  .toDate()}
                                maxDate={moment(values.tenancyStart)
                                  .add(2, 'years')
                                  .toDate()}
                                onChange={date => setFieldValue('tenancyEnd', date)}
                              />
                            </FieldWithLabel>
                          </Field>
                        </TenancyDates>
                      </Col>
                    </Row>
                    {values.tenancyType === TenancyTypes.LET_ONLY && (
                      <Row>
                        <Col lg={12} style={{ padding: 0 }}>
                          <SecondaryPanel style={{ background: '#ffe6e6', marginBottom: 24, marginTop: 24 }}>
                            <WarningMessage>
                              Management of this Reposit will be transferred to the landlord once all tenants have paid
                            </WarningMessage>
                          </SecondaryPanel>
                        </Col>
                      </Row>
                    )}
                    {values.isNewTenancy === 'NO' && (
                      <>
                        <Row>
                          <Col lg={12} style={{ padding: 0, marginTop: 40, marginBottom: 20 }}>
                            <P2>Please confirm you have read and understood the eligibility criteria for Switchovers.</P2>
                            <P2>I confirm that:</P2>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12} style={{ marginBottom: 30 }}>
                            <Checkbox
                              name="tenantWillNeedToSignAddendum"
                              label={
                                <div>
                                  The landlord and tenant(s) have signed the{' '}
                                  <Hyperlink href={AST_ADDENDUM_LINK} target="_blank">
                                    Reposit addendum
                                  </Hyperlink>{' '}
                                  to the tenancy agreement to confirm Reposit is now being used in place of a cash deposit.
                                </div>
                              }
                              helpText=""
                              isChecked={existingTenancyChecks.tenantWillNeedToSignAddendum}
                              onChange={() =>
                                onUpdateExistingTenancyChecks({
                                  ...existingTenancyChecks,
                                  tenantWillNeedToSignAddendum: !existingTenancyChecks.tenantWillNeedToSignAddendum
                                })
                              }
                            />
                            <br />

                            <Checkbox
                              name="tenantsPassedReferencing"
                              label={
                                <div>
                                  The tenant(s) passed referencing in accordance with Reposit’s{' '}
                                  <Hyperlink href={REFERENCING_CRITERIA} target="_blank">
                                    referencing criteria
                                  </Hyperlink>{' '}
                                  at the start of the tenancy.
                                </div>
                              }
                              helpText=""
                              isChecked={existingTenancyChecks.tenantsPassedReferencing}
                              onChange={() =>
                                onUpdateExistingTenancyChecks({
                                  ...existingTenancyChecks,
                                  tenantsPassedReferencing: !existingTenancyChecks.tenantsPassedReferencing
                                })
                              }
                            />
                            <br />

                            <Checkbox
                              name="tenantHaveNoRentArrearsOrRentPaymentHolidays"
                              label="The tenant(s) have not been in rent arrears or agreed any rent payment holidays since the start of their tenancy. "
                              helpText="If the tenant has been in rent arrears or has agreed a rent payment holiday within this period, they will not be eligible for a  Switchover and any claim for end of tenancy charges will not be valid."
                              isChecked={existingTenancyChecks.tenantHaveNoRentArrearsOrRentPaymentHolidays}
                              onChange={() =>
                                onUpdateExistingTenancyChecks({
                                  ...existingTenancyChecks,
                                  tenantHaveNoRentArrearsOrRentPaymentHolidays: !existingTenancyChecks.tenantHaveNoRentArrearsOrRentPaymentHolidays
                                })
                              }
                            />
                            <br />

                            <Checkbox
                              name="noChangeToTenancyOrTenantFinances"
                              label="There has been no material change to the tenancy or the tenant(s) financial situation that would give reason for the tenancy to be ended."
                              helpText=""
                              isChecked={existingTenancyChecks.noChangeToTenancyOrTenantFinances}
                              onChange={() =>
                                onUpdateExistingTenancyChecks({
                                  ...existingTenancyChecks,
                                  noChangeToTenancyOrTenantFinances: !existingTenancyChecks.noChangeToTenancyOrTenantFinances
                                })
                              }
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12}>
                            <P2>
                              Please note that if a new tenancy agreement is being signed, the property, landlord and tenant
                              details must match those of the existing tenancy agreement for the Switchover to be valid.
                            </P2>
                            <P2>
                              If the rent is being increased, tenants do not have to be re-referenced provided that the original
                              referencing was completed 12 months ago or less, and shows that the tenants met the affordability
                              requirements (income of 2.5x monthly rent) for the increased rent.
                            </P2>
                          </Col>
                        </Row>
                      </>
                    )}
                    <Row>
                      <Col lg={12} style={{ padding: 0 }}>
                        <Action>
                          <Link to="#" onClick={() => dispatch(clearActiveForms())}>
                            Cancel edit details
                          </Link>
                          <Button disabled={isLoading || (values.isNewTenancy === 'NO' && !allChecked())}>{`Update`}</Button>
                        </Action>
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </form>
          );
        }}
      </Formik>
    );
  }

  if (props.isEditable && !isComplete) {
    return (
      <Container fluid>
        <Row>
          <Col lg={12} style={{ padding: 0 }}>
            <TenancyInfo>
              <P1>This section is not yet complete</P1>
            </TenancyInfo>
            <div style={{ textAlign: 'right' }}>
              <Button buttonType="secondary" onClick={() => dispatch(setActiveForms([ActiveFormOptions.TENANCY_DETAILS]))}>
                Edit
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container fluid>
      <Row>
        <Col lg={6}>
          <ItemTitle>New tenancy</ItemTitle>
          <P1>{tenancy.isNewTenancy ? 'Yes' : 'No'}</P1>
        </Col>
        {!isOrgLandlord && (
          <Col lg={6}>
            <ItemTitle>Tenancy type</ItemTitle>
            <P1>{tenancy.letOnly ? 'Let Only' : 'Fully managed'}</P1>
          </Col>
        )}
        <Col lg={6}>
          <ItemTitle>PPM</ItemTitle>
          <P1 style={{ marginBottom: ppmError ? 5 : 16 }}>£{initialValues.PPM}</P1>
          {ppmError ? <FormErrorMessage noIcon error={ppmError}></FormErrorMessage> : null}
        </Col>
        <Col lg={6}>
          <ItemTitle>Tenancy term</ItemTitle>
          <TenancyDates editMode={false}>
            <P1>{moment(tenancy.startDate).format('DD/MM/YYYY')}</P1>
            <Until editMode={false}>Until</Until>
            <P1>{tenancy.expiryDates && moment(getExpiryDate(tenancy.expiryDates).endDate).format('DD/MM/YYYY')}</P1>
          </TenancyDates>
        </Col>

        <Col lg={12} style={{ padding: 0 }}>
          <TenancyInfo>
            <Item></Item>
          </TenancyInfo>
          {props.isEditable && (
            <div style={{ paddingTop: 24, textAlign: 'right' }}>
              <Button buttonType="secondary" onClick={() => dispatch(setActiveForms([ActiveFormOptions.TENANCY_DETAILS]))}>
                Edit
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default (props: TenancyDetailsProps) => {
  const dispatch = useDispatch();
  const [existingTenancyChecks, setExistingTenancyChecks] = useState<ExistingTenancyChecks>({
    tenantHaveNoRentArrearsOrRentPaymentHolidays: false,
    tenantWillNeedToSignAddendum: false,
    noChangeToTenancyOrTenantFinances: false,
    tenantsPassedReferencing: false
  });
  const isOrgLandlord = useIsOrganizationLandlord();
  const content = useRenderContent(props, dispatch, existingTenancyChecks, setExistingTenancyChecks, isOrgLandlord);

  return (
    <TenancyDetails>
      <RepositCard
        title="Tenancy Details"
        icon={TenancyIcon}
        tooltip={`These details should match the proposed tenancy agreement. ${
          !isOrgLandlord
            ? "If the landlord will be managing the claims process at the end of the tenancy, please select 'let only' as the tenancy type."
            : ''
        } `}
        flashMessage={
          props.flashMessage ? (
            <FlashMessage onDismiss={props.onDismissFlashMessage} timeRemaining={5000} payload={props.flashMessage} />
          ) : (
            undefined
          )
        }
      >
        {content}
      </RepositCard>
    </TenancyDetails>
  );
};
