import { unwrapResult } from '@reduxjs/toolkit';
import { ClaimProposalDTOStatusEnum, CreateClaimItemProposalDTO } from '@reposit/api-client';
import { get } from 'lodash';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../index';
import {
  createClaimItemDocumentThunk,
  deleteClaimItemDocumentThunk
} from '../../../../redux/claim-item-document/claim-item-document.thunk';
import { createDocumentThunk } from '../../../../redux/document/document.thunk';
import { ClaimItemProposalEntity, ClaimProposalEntity, DocumentEntity } from '../../../../redux/entities/entities.types';
import { AppState } from '../../../../redux/root.reducer';
import {
  AgentDocumentsAndProposals,
  getAllAgentDocumentsAndProposalsForClaimItem,
  getAllTenantDocumentsAndProposalsForClaimItem,
  TenantDocumentsAndProposal
} from '../../../../redux/selectors/mediation.selectors';
import { Header4, P2 } from '../../../Typography/index';
import AuditTrail from '../AuditTrail/AuditTrail';
import { TENANT_ROUND } from '../AuditTrail/History';
import { Explanation } from '../Explanation';
import { UploadEvidence } from '../UploadEvidence';
import { ClaimItem, ClaimItemState, ClaimProposal, ItemProposal } from './ClaimItem';
import { CREATE_CLAIM_ITEM_DOCUMENT_STORE_KEY } from '../../../../redux/claim-item-document/claim-item-document.actions';
import { FlashState } from '../../../FlashMessage/index';
import { setFlashMessage } from '../../../../redux/flash-messages/flash-messages.actions';

interface ClaimItemDropdownProps {
  initialAgentProposal?: ItemProposal;
  secondAgentProposal?: ItemProposal;
  tenantProposal?: ItemProposal;
  claimItemState: ClaimItemState;
  setClaimItemState: (claimItemState: ClaimItemState) => void;
  claimItem: ClaimItem;
  allSupplierDocuments?: DocumentEntity[];
  createCounterProposal: (payload: CreateClaimItemProposalDTO) => Promise<ClaimItemProposalEntity>;
  updateCounterProposal: (payload: CreateClaimItemProposalDTO) => Promise<ClaimItemProposalEntity>;
}

export const isProposalPublished = (proposal: ClaimProposalEntity | ClaimProposal | undefined) => {
  if (!proposal) return false;
  else if (proposal.round === TENANT_ROUND) {
    return proposal.status !== ClaimProposalDTOStatusEnum.DRAFT && proposal.status !== ClaimProposalDTOStatusEnum.AWAITINGPAYMENT;
  } else {
    return proposal.status !== ClaimProposalDTOStatusEnum.DRAFT;
  }
};

export const getAgentDocumentsToShow = (docsAndProposals: AgentDocumentsAndProposals | undefined): DocumentEntity[] => {
  if (!docsAndProposals) return [];
  const agentFirstDocs =
    docsAndProposals && isProposalPublished(docsAndProposals.firstProposal) ? docsAndProposals.firstDocuments : [];
  const agentSecondDocs =
    docsAndProposals && isProposalPublished(docsAndProposals.secondProposal) ? docsAndProposals.secondDocuments : [];
  return [...agentFirstDocs, ...agentSecondDocs];
};
export const getTenantDocumentsToShow = (docsAndProposals: TenantDocumentsAndProposal | undefined): DocumentEntity[] => {
  if (!docsAndProposals) return [];
  return docsAndProposals && isProposalPublished(docsAndProposals.proposal) ? docsAndProposals.documents : [];
};

export const ClaimItemDropdown: React.FC<ClaimItemDropdownProps> = ({
  initialAgentProposal,
  secondAgentProposal,
  tenantProposal,
  claimItemState,
  setClaimItemState,
  claimItem,
  allSupplierDocuments,
  createCounterProposal,
  updateCounterProposal
}) => {
  const initialAgentProposalAmount = get(initialAgentProposal, 'amount', 0);
  const showRejectForm = claimItemState === ClaimItemState.REJECTED_TENANT_COUNTER;

  const proposalText = `Propose a settlement and add the settlement amount below. Add evidence and a supporting explanation to increase the chances that your tenant(s) will accept and pay. Your evidence and explanation will be shared with the tenant(s).`;
  const proposalTitle = secondAgentProposal ? 'Edit your final proposal' : 'Provide a final proposal';

  const agentDocumentsAndProposals = useSelector((state: AppState) =>
    getAllAgentDocumentsAndProposalsForClaimItem(state, claimItem.id)
  );
  const tenantDocumentsAndProposals = useSelector((state: AppState) =>
    getAllTenantDocumentsAndProposalsForClaimItem(state, claimItem.id)
  );

  const agentDocumentsToShow = getAgentDocumentsToShow(agentDocumentsAndProposals);
  const tenantDocumentsToShow = getTenantDocumentsToShow(tenantDocumentsAndProposals);

  const dispatch = useAppDispatch();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFileUpload = (file: File) => {
    if (secondAgentProposal) {
      return dispatch(
        createClaimItemDocumentThunk({
          claimItemId: claimItem.id,
          claimItemProposalId: secondAgentProposal.id,
          file
        })
      ).then(unwrapResult);
    } else if (initialAgentProposal && initialAgentProposal.claimProposal.status === ClaimProposalDTOStatusEnum.AWAITINGPAYMENT) {
      return dispatch(
        createClaimItemDocumentThunk({
          claimItemId: claimItem.id,
          claimItemProposalId: initialAgentProposal.id,
          file
        })
      ).then(unwrapResult);
    } else {
      return dispatch(createDocumentThunk({ file, type: 'CLAIM_ITEM', isClaimItem: true })).then(unwrapResult);
    }
  };

  const handleDeleteFile = (documentId: string) => {
    return dispatch(deleteClaimItemDocumentThunk(documentId)).then(unwrapResult);
  };

  const onFileError = (error: string) =>
    dispatch(setFlashMessage({ key: CREATE_CLAIM_ITEM_DOCUMENT_STORE_KEY, message: error, state: FlashState.ERROR }));

  return (
    <>
      {claimItemState === ClaimItemState.VIEW_EVIDENCE ? (
        <AuditTrail
          agentDocuments={agentDocumentsToShow}
          tenantDocuments={tenantDocumentsToShow}
          itemProposals={claimItem.itemProposals}
        />
      ) : null}
      {claimItemState === ClaimItemState.UPLOADING_EVIDENCE ? (
        <>
          <div style={{ borderBottom: '1px solid #ccc', paddingBottom: 20, paddingTop: 10 }}>
            <UploadEvidence
              initialDocuments={allSupplierDocuments}
              handleFileUpload={handleFileUpload}
              itemType={claimItem.type}
              claimId={claimItem.claimId}
            />
          </div>
          <AuditTrail
            agentDocuments={agentDocumentsToShow}
            tenantDocuments={tenantDocumentsToShow}
            itemProposals={claimItem.itemProposals}
          />
        </>
      ) : null}

      {showRejectForm ? (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              paddingTop: 24,
              paddingBottom: 20,
              borderBottom: '1px solid #ccc'
            }}
          >
            <Header4>{proposalTitle}</Header4>
            <P2>{proposalText}</P2>
            <Explanation
              showBorder
              showOffer
              required={false}
              uploadFile={handleFileUpload}
              deleteFile={secondAgentProposal ? handleDeleteFile : undefined}
              onError={onFileError}
              showDeleteButton
              previousAmount={initialAgentProposalAmount}
              onSubmit={async values => {
                try {
                  setIsSubmitting(true);
                  const func = secondAgentProposal ? updateCounterProposal : createCounterProposal;
                  await func({
                    claimItemId: claimItem.id,
                    amount: values.amount as number,
                    explanation: values.explanation,
                    documentIds: values.documentIds
                  });
                  setIsSubmitting(false);
                  setClaimItemState(ClaimItemState.INITIAL);
                } catch {
                  setIsSubmitting(false);
                }
              }}
              isSubmitting={isSubmitting}
              negotiationDocuments={get(secondAgentProposal, 'documents')}
              negotiationExplanation={get(secondAgentProposal, 'explanation')}
              negotiationAmount={get(secondAgentProposal, 'amount')}
              tenantProposalAmount={get(tenantProposal, 'amount')}
            />
          </div>
          <AuditTrail
            agentDocuments={agentDocumentsToShow}
            tenantDocuments={tenantDocumentsToShow}
            itemProposals={claimItem.itemProposals}
          />
        </>
      ) : null}
    </>
  );
};
