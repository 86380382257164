export enum RepositFilter {
  ALL = '',
  DRAFT = 'DRAFT',
  PENDING = 'PENDING',
  COMPLETE = 'COMPLETE',
  CHECKED_OUT = 'CHECKED_OUT'
}

export enum RepositSort {
  CREATION_DATE = 'CREATION_DATE',
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE'
}
