import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Button from '../../../components/Button/index';
import SecondaryPanel from '../../../components/SecondaryPanel/index';
import { P1, StyledLink } from '../../../components/Typography/index';
import { setCreateClaimItemsFormOpen } from '../../../redux/claim/claim.actions';
import { getClaimItemFormOpen } from '../../../redux/claim/claim.selector';
import { DocumentEntity } from '../../../redux/entities/entities.types';
import ClaimItemForm, { ClaimItemFormValues, ComponentAction } from './ClaimItemForm';

const Link = styled(StyledLink)`
  margin: 0 20px;
`;

interface ClaimItemsEmptyProps {
  onSubmit: (values: ClaimItemFormValues) => void;
  claimItemDocs: (DocumentEntity)[];
  claimId: string;
}

const ClaimItemsEmpty: React.FC<ClaimItemsEmptyProps> = ({ onSubmit, claimItemDocs, claimId }) => {
  const dispatch = useDispatch();
  const isClaimItemsFormOpen = useSelector(getClaimItemFormOpen);
  const [isUploading, setIsUploading] = useState(false);

  return (
    <SecondaryPanel>
      {isClaimItemsFormOpen ? (
        <ClaimItemForm
          onSubmit={onSubmit}
          claimItemDocs={claimItemDocs}
          showDeleteButton
          publishedAndEditable={false}
          claimId={claimId}
          formActions={
            <Fragment>
              <Link to="#" onClick={() => dispatch(setCreateClaimItemsFormOpen(false))}>
                Cancel
              </Link>
              <Button buttonType="secondary" type="submit" disabled={isUploading}>
                Save Claim Item
              </Button>
            </Fragment>
          }
          action={ComponentAction.CREATE}
          onUploadComplete={() => setIsUploading(false)}
          onUploadStart={() => setIsUploading(true)}
        />
      ) : (
        <Fragment>
          <P1>You haven’t added any claim items to this Reposit.</P1>
          <Button buttonType="secondary" onClick={() => dispatch(setCreateClaimItemsFormOpen(true))}>
            Add a claim item now
          </Button>
        </Fragment>
      )}
    </SecondaryPanel>
  );
};

export default ClaimItemsEmpty;
