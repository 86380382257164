import React from 'react';
import styled from 'styled-components';
import HelpIcon from '../../../assets/svg/help.svg';
import PendingIcon from '../../../assets/svg/live-warning.svg';
import LiveIcon from '../../../assets/svg/live.svg';
import { getBreakpoint } from '../../../base/style';
import { TenancyOrderWithActions } from '../../../redux/selectors/index';
import Button, { RoundButton } from '../../Button/index';
import CardMessage from '../../CardMessage/index';
import { Header4 } from '../../Typography/index';

interface RepositStatusProps {
  tenancyOrder: TenancyOrderWithActions;
  isOrgLandlord: boolean;
}

const RepositStatusBar = styled.div`
  background: #fff;
  border-radius: 12px;
  border: solid 1px ${props => props.theme.colors.inactive};
  margin: 0 5px 12px;
  padding: 11px 36px 10px;
  display: flex;
  justify-content: center;
`;

const Message = styled(Header4)`
  align-content: center;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  justify-content: center;
  margin: 0;
  padding: 0;
  text-align: center;

  &.COMPLETE {
    color: ${props => props.theme.colors.positive};
    &:after {
      background: transparent url(${LiveIcon}) center center no-repeat;
    }
  }

  &.PENDING {
    color: ${props => props.theme.colors.warning};
    &:after {
      background: transparent url(${PendingIcon}) center center no-repeat;
    }
  }

  &.CLOSED {
    color: ${props => props.theme.colors.negative};
  }

  @media all and (min-width: ${getBreakpoint('lg')}) {
    &:before {
      display: none;
    }

    &:after {
      content: '';
      display: block;
      height: 24px;
      margin: 1px 12px 0;
      width: 24px;
    }
  }
`;

const HelpButton = styled(RoundButton)`
  margin: 2px 12px 0;
`;

const LandlordLink = styled.a`
  display: inline;
  text-decoration: none;
`;

interface RepositStatusOption {
  message: JSX.Element;
  condition: (tenancyOrder: TenancyOrderWithActions) => boolean;
  tooltip?: string;
}

const RepositStatusOptions: (isOrgLandlord: boolean) => RepositStatusOption[] = (isOrgLandlord: boolean) => [
  {
    message: <Message className="CLOSED">This Reposit is Closed</Message>,
    condition: tenancyOrder =>
      tenancyOrder.order.statusId === 'COMPLETE' && !!tenancyOrder.tenancy.completedAt && !tenancyOrder.tenancy.checkout,
    tooltip: 'This Reposit was automatically closed 14 days after the end date of the tenancy.'
  },
  {
    message: <Message className="COMPLETE">This Reposit is LIVE!</Message>,
    condition: tenancyOrder => tenancyOrder.order.statusId === 'COMPLETE' && !tenancyOrder.tenancy.checkout,
    tooltip: `${isOrgLandlord ? 'You are' : 'Your landlord is'} fully covered for the duration of the Reposit.`
  },
  {
    message: <Message className="PENDING">Awaiting Tenant Actions</Message>,
    condition: tenancyOrder => tenancyOrder.order.statusId === 'PENDING',
    tooltip:
      "We're waiting for the tenants to complete their actions. You can follow their progress from the tenants section below."
  },
  {
    message: <Message>This Reposit is Checked Out</Message>,
    condition: tenancyOrder => tenancyOrder.order.statusId === 'COMPLETE' && !!tenancyOrder.tenancy.checkout,
    tooltip: "You've confirmed the tenants are moving out and you can now decide whether you need to make a claim."
  }
];

const RepositStatus: React.SFC<RepositStatusProps> = ({ tenancyOrder, isOrgLandlord }) => {
  const currentStatus = RepositStatusOptions(isOrgLandlord).find(option => option.condition(tenancyOrder));
  const tenancy = tenancyOrder.tenancy;
  const needsTransferring = tenancy.letOnly && tenancy.tenancyTransfers && tenancy.tenancyTransfers.some(tt => !tt.completedAt);
  const landlordEmail = tenancyOrder.tenancy.property.landlord.email;
  return (
    <>
      <RepositStatusBar>
        {currentStatus && currentStatus.message}
        {currentStatus && currentStatus.tooltip && <HelpButton icon={HelpIcon} data-tip={currentStatus.tooltip} />}
      </RepositStatusBar>
      {needsTransferring && landlordEmail && (
        <CardMessage
          title={'The landlord has not yet accepted this let-only Reposit'}
          cta={
            <LandlordLink href={`mailto:${landlordEmail}?subject=Reminder - You have a new tenancy to manage`} target="_blank">
              <Button buttonType="white">Remind landlord</Button>
            </LandlordLink>
          }
          type="primary"
        />
      )}
    </>
  );
};

export default RepositStatus;
