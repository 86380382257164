// [x] BROKEN WINDOW [x]
// if adding to a component library - look at rebuilding from scratch. This component was taken from the admin app and tweaked.
// but if we want to use it elsewhere it's worth looking at completely rebuilding to simplify
import React, { useState, useEffect } from 'react';
import { useDebounce } from 'use-debounce';
import { get } from 'lodash';

import LoadingIcon from '../../assets/svg/loader.svg';
import { Container, OptionsList, OptionItem, SelectContainer, LoaderContainer } from './styles';

interface SearchableTextInputProps<T> {
  name: string;
  items: Array<T>;
  keyPath: string;
  placeholder?: string;
  searching: boolean;
  hasSearched: boolean;
  clearHasSearched: () => void;
  onItemSelected: (selected: T) => void;
  onChange?: (term: string) => void;
  initialValue?: T;
  getValue: (item: T) => string;
}

export interface SearchResult {
  key: string;
  value: string;
}

enum KeyCode {
  ENTER = 13,
  ARROW_UP = 38,
  ARROW_DOWN = 40
}

export function SearchableTextInput<T>({
  name,
  placeholder,
  items,
  keyPath,
  searching,
  onItemSelected,
  onChange,
  initialValue,
  getValue,
  hasSearched,
  clearHasSearched
}: SearchableTextInputProps<T>) {
  const initialTerm = initialValue ? getValue(initialValue) : '';
  // stop flashing
  const [hasShownResults, setHasShownResults] = useState(false);
  const [hasSelectedSuggestion, setHasSelectedSuggestion] = useState(false);

  const [shouldSearch, setShouldSearch] = useState(false);
  const [term, setTerm] = useState<string>(initialTerm);
  const [showResults, setShowResults] = useState<boolean>();
  const [activeSuggestion, setActiveSuggestion] = useState<number>(-1);
  const [searchResults, setSearchResults] = useState<Array<any>>([]);
  const [typing, setTyping] = useState<boolean>(false);
  const [debouncedTerm] = useDebounce(term, 500);

  const shouldShowResults = !typing && !!term && showResults && searchResults && hasSearched && !searching;
  /**
   * Rules for showing suggestions:
   * - User should not be typing
   * - Should have a search term
   * - State showResults should be true
   * - Should have searched
   * - Should not be loading
   */

  // extra show results is important to hide when it has been clicked
  const hasResults = (shouldShowResults || (hasShownResults && showResults)) && searchResults.length > 0 && term !== '';
  const hasNoResults =
    (shouldShowResults || hasShownResults) && searchResults.length === 0 && term !== '' && debouncedTerm !== '';

  useEffect(() => {
    if ((hasResults || hasNoResults) && !hasShownResults) {
      setHasShownResults(true);
    }
  }, [hasResults, hasNoResults, hasShownResults, setHasShownResults]);

  /**
   * Only dispatch search action when the debounced term
   * has been updated.
   */
  useEffect(() => {
    if (debouncedTerm) {
      setShouldSearch(true);
    }
  }, [debouncedTerm]);

  /**
   * Should clear has searched if the term is empty string
   * This stops flashing after clearing and then retyping
   */
  useEffect(() => {
    if (!term) {
      clearHasSearched();
    }
  }, [term, clearHasSearched]);

  /**
   * Fire this effect every time the component receive new items.
   * Resets the active suggestion index and only show the results
   * if the items received are different from the previous ones.
   */
  useEffect(() => {
    if (items) {
      setActiveSuggestion(-1);
      setSearchResults(items);
      const diff = JSON.stringify(items) !== JSON.stringify(searchResults);
      if (diff) setShowResults(true);
    }
  }, [items, searchResults]);

  /**
   * Fire this effect every time the debouncedTerm receives a new value.
   * Here's the moment when the user stop typing and we're allowed to call the onChange
   * function and do a new search.
   */
  useEffect(() => {
    setTyping(false);
    // seems to be an issue with debounced not going back to ''
    if (debouncedTerm && shouldSearch && debouncedTerm !== initialTerm && term !== '' && !hasSelectedSuggestion) {
      if (onChange) onChange(debouncedTerm);
      setShouldSearch(false);
    }
  }, [debouncedTerm, onChange, shouldSearch, term, initialTerm, hasSelectedSuggestion]);

  /**
   * While the user is typing, the results should be hidden.
   * This effect listen to the typing state and checks if the user stop typing
   * so we can show the results again.
   */
  useEffect(() => {
    if (!typing) {
      setShowResults(true);
    }
  }, [typing]);

  /**
   * Handles the user selection, whether the user used the mouse or keyboard.
   * Also, hides the suggestions and stops searching.
   * @param suggestion the selected suggestion
   */
  const handleSelectSuggestion = (suggestion: any) => {
    setShowResults(false);
    setHasSelectedSuggestion(true);
    setShouldSearch(false);
    setTerm(getValue(suggestion));
    if (onItemSelected) {
      onItemSelected(suggestion);
    }
  };

  /**
   * Handles the search term change, hides the suggestions and flags the typing state.
   * @param term the search term
   */
  const handleChange = (term: string) => {
    setShowResults(false);
    setTyping(true);
    setTerm(term);
    setHasSelectedSuggestion(false);
  };

  /**
   * Handles keyboard events (up arrow, down arrow and enter)
   * @param event the keyboard event
   */
  const handleKeyboardSelection = (event: React.KeyboardEvent) => {
    const keyCode = event.keyCode;
    if (searchResults && searchResults.length) {
      switch (keyCode) {
        case KeyCode.ENTER:
          event.preventDefault();
          if (activeSuggestion >= 0) {
            handleSelectSuggestion(searchResults[activeSuggestion]);
          }
          return setActiveSuggestion(-1);
        case KeyCode.ARROW_UP:
          return setActiveSuggestion(activeSuggestion - 1);
        case KeyCode.ARROW_DOWN:
          if (activeSuggestion === searchResults.length - 1) return;
          return setActiveSuggestion(activeSuggestion + 1);
      }
    }
  };

  return (
    <Container>
      <SelectContainer>
        <input
          type="text"
          autoComplete="off"
          name={name}
          placeholder={placeholder || 'Search here...'}
          onChange={e => handleChange(e.target.value)}
          onKeyDown={e => handleKeyboardSelection(e)}
          value={term}
        />
        {(typing || searching) && (
          <LoaderContainer>
            <img src={LoadingIcon} width="20" height="20" alt="Loading" />
          </LoaderContainer>
        )}
      </SelectContainer>
      {hasResults && (
        <>
          <OptionsList onMouseLeave={() => setActiveSuggestion(-1)}>
            {searchResults.map((suggestion, index) => (
              <OptionItem
                onMouseOver={() => setActiveSuggestion(index)}
                onClick={e => {
                  handleSelectSuggestion(searchResults[activeSuggestion]);
                  setActiveSuggestion(-1);
                }}
                key={get(suggestion, keyPath)}
                selected={index === activeSuggestion}
              >
                {getValue(suggestion)}
              </OptionItem>
            ))}
          </OptionsList>
        </>
      )}
      {hasNoResults && (
        <OptionsList>
          <OptionItem>No results have been found</OptionItem>
        </OptionsList>
      )}
    </Container>
  );
}
