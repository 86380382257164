import { all } from 'redux-saga/effects';
import * as AccountSagas from './account/account.saga';
import * as AddressSagas from './address/address.saga';
import * as ClaimDocumentSagas from './claim-document/claim-document.saga';
import * as ClaimItemDocumentSagas from './claim-item-document/claim-item-document.saga';
import * as ClaimItemSagas from './claim-item/claim-item.saga';
import * as ClaimListSagas from './claim-list/claim-list.saga';
import * as ClaimSagas from './claim/claim.saga';
import * as DocumentSagas from './document/document.saga';
import * as FeedbackSagas from './feedback/feedback.saga';
import * as InviteSagas from './invite/invite.saga';
import * as RecipientSagas from './organization-recipient/organization-recipient.saga';
import * as OrganizationUserSagas from './organization-user/organization-user.saga';
import * as PropertySagas from './property/property.saga';
import * as RepositListSagas from './reposit-list/reposit-list.saga';
import * as RepositSagas from './reposit/reposit.saga';
import * as UserSagas from './user/user.saga';
import * as AuthSagas from './auth/auth.saga';
import * as BankAccountSagas from './bank-account/bank-account.saga';
import * as GuarantorSagas from './guarantor/guarantor.saga';

export default function* rootSaga() {
  yield all([
    RepositListSagas.watchRepositListSagas(),
    AccountSagas.watchAccountSagas(),
    RepositSagas.watchRepositSagas(),
    PropertySagas.watchPropertiesSagas(),
    AddressSagas.watchAddressesSagas(),
    ClaimSagas.watchClaimsSagas(),
    ClaimListSagas.watchClaimListSagas(),
    ClaimItemSagas.watchClaimItemsSagas(),
    UserSagas.watchUserSagas(),
    InviteSagas.watchInviteSagas(),
    OrganizationUserSagas.watchOrganizationUserSagas(),
    ClaimDocumentSagas.watchClaimDocumentsSagas(),
    DocumentSagas.watchDocumentsSagas(),
    ClaimItemDocumentSagas.watchClaimItemDocumentsSagas(),
    FeedbackSagas.watchFeedbackSagas(),
    RecipientSagas.watchRecipientSagas(),
    AuthSagas.watchAuthSagas(),
    BankAccountSagas.watchBankAccountsSagas(),
    GuarantorSagas.watchGuarantorSagas()
  ]);
}
