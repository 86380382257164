import { get } from 'lodash';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { ContentContainer, InnerWrapper } from '../../../components/Common/index';
import { Header1 } from '../../../components/Typography/index';
import { getCurrentOrganizationId } from '../../../redux/auth/auth.selectors';
import { getOrganization } from '../../../redux/selectors/organization.selectors';
import IntegrationInfo from '../Integration/IntegrationInfo';

interface IntegrationViewProps {}

const IntegrationView: React.FC<IntegrationViewProps> = () => {
  const currentOrgId = useSelector(getCurrentOrganizationId);

  const organization = useSelector(getOrganization(currentOrgId));
  const integrationToken = get(organization, 'attributes.legacyIntegrationToken', '');

  return (
    <InnerWrapper>
      <Container>
        <Row>
          <Col sm={12}>
            <Header1>Integration</Header1>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg={12}>
            <ContentContainer>
              <IntegrationInfo integrationToken={integrationToken} />
            </ContentContainer>
          </Col>
        </Row>
      </Container>
    </InnerWrapper>
  );
};

export default IntegrationView;
