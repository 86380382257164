import { ClaimItemDTOTypeEnum } from '@reposit/api-client';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import HelpIcon from '../../../assets/svg/help.svg';
import { getEvidenceInformation } from '../../../containers/Claim/ClaimBuilder/ClaimItemForm';
import { DocumentEntity } from '../../../redux/entities/entities.types';
import { AppState } from '../../../redux/root.reducer';
import { getHasTenantDisputed } from '../../../redux/selectors/mediation.selectors';
import { RoundButton } from '../../Button/index';
import { P3 } from '../../Typography/index';
import { FileUpload, FileUploadProgress } from '../FileUpload/FileUpload';
import { setFlashMessage } from '../../../redux/flash-messages/flash-messages.actions';
import { CREATE_CLAIM_ITEM_DOCUMENT_STORE_KEY } from '../../../redux/claim-item-document/claim-item-document.actions';
import { FlashState } from '../../FlashMessage/index';

interface UploadEvidenceProps {
  handleFileUpload: (file: File) => Promise<DocumentEntity>;
  //   onError: (error: string) => void;
  initialDocuments?: DocumentEntity[];
  itemType: ClaimItemDTOTypeEnum;
  claimId: string;
}
const HelpButton = styled(RoundButton)`
  display: flex;
  flex: 0 0 24px;
  margin: 0 12px;
`;

export function delay(duration: number) {
  const promise = new Promise(resolve => {
    setTimeout(() => resolve(true), duration);
  });
  return promise;
}

export const UploadEvidence: React.FC<UploadEvidenceProps> = ({ initialDocuments, handleFileUpload, itemType, claimId }) => {
  const [documents, setDocuments] = useState<DocumentEntity[]>(initialDocuments || []);
  const [fileUploadProgress, setFileUploadProgress] = useState<FileUploadProgress | undefined>();
  const dispatch = useDispatch();

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const hasTenantDisputed = useSelector((state: AppState) => getHasTenantDisputed(state, claimId));

  const tooltipText = `We require you to upload evidence relating to the claim items to substantiate your charges for ${
    hasTenantDisputed ? 'adjudication' : 'our insurer'
  }. This ${
    hasTenantDisputed ? 'still applies' : 'also applies'
  } to claim items that you or the tenant(s) have already agreed to${
    hasTenantDisputed ? ', in the event of resorting to our insurer to recover the funds' : ''
  }.`;

  const onFileError = (error: string) =>
    dispatch(setFlashMessage({ key: CREATE_CLAIM_ITEM_DOCUMENT_STORE_KEY, message: error, state: FlashState.ERROR }));

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <P3 style={{ margin: 0 }}>Why do I need to upload evidence at this stage?</P3>
        <HelpButton icon={HelpIcon} data-tip={tooltipText} />
      </div>
      {getEvidenceInformation(itemType)}
      <FileUpload
        required
        documents={documents}
        uploadFile={handleFileUpload}
        // dont think we need this here
        deleteFile={(id: string) => Promise.resolve()}
        onError={onFileError}
        showDeleteButton={false}
        onUploadComplete={docs => {
          if (docs) {
            setDocuments(currDocs => [...currDocs, ...docs]);
          }
        }}
        fileUploadProgress={fileUploadProgress}
        setFileUploadProgress={setFileUploadProgress}
      />
    </>
  );
};
