import { sortBy } from 'lodash';
import { AppState } from '../root.reducer';
import { getCurrentOrganizationUserIds } from '../user/user.selectors';
import { getCurrentOrganizationInviteIds } from '../invite/invite.selectors';
import { getUserById } from './user.selectors';
import { getOrganizationInviteById } from './organizationInvite.selectors';
import { UserEntity, OrganizationInviteEntity } from '../entities/entities.types';

const alphabeticalSortOfUsersAndInvites = (item: UserEntity | OrganizationInviteEntity) => {
  const firstName = (item as UserEntity).firstName;
  const lastName = (item as UserEntity).lastName;
  if (firstName && lastName) {
    return `${firstName.toLowerCase()} ${lastName.toLowerCase()}`;
  } else {
    return item.email.toLowerCase();
  }
};

export const getAccountData = (state: AppState) => {
  const userIds = getCurrentOrganizationUserIds(state);
  const inviteIds = getCurrentOrganizationInviteIds(state);

  const users = userIds.map(userId => getUserById(state, { userId }));
  const organizationInvites = inviteIds.map(organizationInviteId => getOrganizationInviteById(state, { organizationInviteId }));

  return sortBy([...organizationInvites, ...users], alphabeticalSortOfUsersAndInvites);
};
