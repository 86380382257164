import { Formik, FormikProps } from 'formik';
import { get } from 'lodash';
import React from 'react';
import { Col, Container, Row } from 'react-grid-system';
import styled from 'styled-components';
import * as Yup from 'yup';
import { getBreakpoint } from '../../base/style';
import { phoneNumberStringMatcher } from '../../utils/regex/phoneNumber.regex';
import { Input } from '../FormFields/index';
import FieldWithLabel from '../FormFields/FieldWithLabel/index';

const Field = styled.div`
  min-height: 86px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    max-width: 96%;
  }
`;

const Action = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding: 36px 0 0;
`;

export interface GuarantorFormValues {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber?: string;
}

interface GuarantorFormProps {
  onSubmit: (values: GuarantorFormValues) => void;
  formActions: JSX.Element;
  initialValues?: GuarantorFormValues;
}

const Schema = Yup.object().shape(
  {
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    email: Yup.string()
      .email('Must be a valid email')
      .required('Required'),
    mobileNumber: Yup.string()
      .nullable()
      .when('mobileNumber', {
        is: value => !!value,
        then: Yup.string()
          .min(11, 'Incorrectly formatted phone number')
          .max(15, 'Incorrectly formatted phone number')
          .matches(phoneNumberStringMatcher, 'Incorrectly formatted phone number')
      })
  },
  // needed for cyclic dependency on mobileNumber
  [['mobileNumber', 'mobileNumber']]
);

const GuarantorForm: React.SFC<GuarantorFormProps> = ({ onSubmit, formActions, initialValues }) => {
  const initValues = {
    firstName: get(initialValues, 'firstName', ''),
    lastName: get(initialValues, 'lastName', ''),
    email: get(initialValues, 'email', ''),
    mobileNumber: get(initialValues, 'mobileNumber', '')
  };

  return (
    <Formik initialValues={initValues} onSubmit={onSubmit} validationSchema={Schema}>
      {({ values, handleChange, handleSubmit, handleBlur, touched, errors }: FormikProps<GuarantorFormValues>) => {
        return (
          <form onSubmit={handleSubmit}>
            <Container fluid>
              <Row style={{ paddingTop: 15 }}>
                <Col md={12} lg={6}>
                  <Field>
                    <FieldWithLabel
                      label="First name*"
                      touched={get(touched, 'firstName', false)}
                      error={get(errors, 'firstName', '')}
                    >
                      <Input
                        value={get(values, 'firstName', '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="firstName"
                        touched={get(touched, 'firstName', false)}
                        error={get(errors, 'firstName', '')}
                      />
                    </FieldWithLabel>
                  </Field>
                </Col>
                <Col md={12} lg={6}>
                  <Field>
                    <FieldWithLabel
                      label="Last name*"
                      touched={get(touched, 'lastName', false)}
                      error={get(errors, 'lastName', '')}
                    >
                      <Input
                        value={get(values, 'lastName', '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="lastName"
                        touched={get(touched, 'lastName', false)}
                        error={get(errors, 'lastName', '')}
                      />
                    </FieldWithLabel>
                  </Field>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Field>
                    <FieldWithLabel
                      label="Email address*"
                      touched={get(touched, 'email', false)}
                      error={get(errors, 'email', '')}
                    >
                      <Input
                        value={get(values, 'email', '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="email"
                        touched={get(touched, 'email', false)}
                        error={get(errors, 'email', '')}
                      />
                    </FieldWithLabel>
                  </Field>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Field>
                    <FieldWithLabel
                      label="Mobile number"
                      touched={get(touched, 'mobileNumber', false)}
                      error={get(errors, 'mobileNumber', '')}
                    >
                      <Input
                        value={get(values, 'mobileNumber', '')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="mobileNumber"
                        touched={get(touched, 'mobileNumber', false)}
                        error={get(errors, 'mobileNumber', '')}
                      />
                    </FieldWithLabel>
                  </Field>
                </Col>
              </Row>
              <Row>
                <Col lg={12} style={{ padding: 0 }}>
                  <Action>{formActions}</Action>
                </Col>
              </Row>
            </Container>
          </form>
        );
      }}
    </Formik>
  );
};

export default GuarantorForm;
