import {
  OrganizationWithRepositsAndRolesOrganizationTypeIdEnum,
  OrganizationWithRepositsAndRolesRolesEnum
} from '@reposit/api-client';
import { getCurrentOrganizationId, getHasOrganizationRole } from '../auth/auth.selectors';
import { AppState } from '../root.reducer';
import {
  getIsBankAccountsConfirmed,
  getIsInformedLandlordsComplete,
  getIsLandlordInformationCompleted,
  getIsUpdatedASTComplete,
  getIsWelcomeLandlordCompleted,
  getIsYourCompanyComplete,
  getIsAcceptedTermsComplete,
  getOrganization
} from './organization.selectors';
import { getIsAboutYouComplete } from './user.selectors';

export enum OnboardingRoutes {
  WELCOME = '/account/get-started/welcome',
  ABOUT_YOU = '/account/get-started/about-you',
  YOUR_COMPANY = '/account/get-started/your-company',
  UPDATE_YOUR_AST = '/account/get-started/update-your-ast',
  BANK_ACCOUNTS = '/account/get-started/bank-accounts',
  USEFUL_DOCUMENTS = '/account/get-started/useful-documents',
  TERMS_AND_CONDITIONS = '/account/get-started/terms-and-conditions',
  COMPLETED_ONBOARDING = '/account/get-started/complete',
  TENANCY_GUIDE = '/account/get-started/tenancy-guide',
  BLOCKED_BY_TERMS = '/account/get-started/nearly-there'
}

export const getIsOnboardingCompleteForUser = (state: AppState) => {
  const aboutYouComplete = getIsAboutYouComplete(state);
  const isUserComplete = aboutYouComplete;

  return isUserComplete;
};

export const getIsOnboardingCompleteForOrg = (state: AppState) => {
  const currentOrgId = getCurrentOrganizationId(state);
  const currentOrg = getOrganization(currentOrgId)(state);
  const isOrgLandlord = !!(
    currentOrg && currentOrg.organizationTypeId === OrganizationWithRepositsAndRolesOrganizationTypeIdEnum.PRIVATE
  );

  const isWelcomeLandlordCompleted = getIsWelcomeLandlordCompleted(state);
  const isLandlordInformationCompleted = getIsLandlordInformationCompleted(state);
  const yourCompanyComplete = getIsYourCompanyComplete(state);
  const updatedAST = getIsUpdatedASTComplete(state);
  const usefulDocumentsComplete = getIsInformedLandlordsComplete(state);
  const acceptedTerms = getIsAcceptedTermsComplete(state);

  const isOrgComplete = isOrgLandlord
    ? updatedAST && usefulDocumentsComplete && isWelcomeLandlordCompleted && isLandlordInformationCompleted && acceptedTerms
    : yourCompanyComplete && updatedAST && usefulDocumentsComplete && acceptedTerms;

  return isOrgComplete;
};

export const getNextOnboardingRoute = (state: AppState): string | undefined => {
  const hasOnboardingRole = getHasOrganizationRole(OrganizationWithRepositsAndRolesRolesEnum.ONBOARDING)(state);
  const isWelcomeLandlordCompleted = getIsWelcomeLandlordCompleted(state);
  const isLandlordInformationCompleted = getIsLandlordInformationCompleted(state);
  const aboutYouComplete = getIsAboutYouComplete(state);
  const yourCompanyComplete = getIsYourCompanyComplete(state);
  const updatedAST = getIsUpdatedASTComplete(state);
  const bankAccountsConfirmed = getIsBankAccountsConfirmed(state);
  const isUsefulDocumentsComplete = getIsInformedLandlordsComplete(state);
  const acceptedTerms = getIsAcceptedTermsComplete(state);

  const currentOrgId = getCurrentOrganizationId(state);
  const currentOrg = getOrganization(currentOrgId)(state);
  const isOrgLandlord = !!(
    currentOrg && currentOrg.organizationTypeId === OrganizationWithRepositsAndRolesOrganizationTypeIdEnum.PRIVATE
  );

  // if user is complete and is not admin - but org is not complete - block them
  if (aboutYouComplete && !hasOnboardingRole && !acceptedTerms) {
    return OnboardingRoutes.BLOCKED_BY_TERMS;
  }

  if (hasOnboardingRole && isOrgLandlord && !isWelcomeLandlordCompleted) {
    return OnboardingRoutes.WELCOME;
  }

  if (!aboutYouComplete) {
    return OnboardingRoutes.ABOUT_YOU;
  }
  if (hasOnboardingRole && !yourCompanyComplete && !isOrgLandlord) {
    return OnboardingRoutes.YOUR_COMPANY;
  }
  if (hasOnboardingRole && !updatedAST) {
    return OnboardingRoutes.UPDATE_YOUR_AST;
  }
  if (hasOnboardingRole && !bankAccountsConfirmed) {
    return OnboardingRoutes.BANK_ACCOUNTS;
  }
  if (hasOnboardingRole && !isUsefulDocumentsComplete) {
    return OnboardingRoutes.USEFUL_DOCUMENTS;
  }
  if (hasOnboardingRole && isOrgLandlord && !isLandlordInformationCompleted) {
    return OnboardingRoutes.TENANCY_GUIDE;
  }
  if (hasOnboardingRole && !acceptedTerms) {
    return OnboardingRoutes.TERMS_AND_CONDITIONS;
  }
  return OnboardingRoutes.COMPLETED_ONBOARDING;
};
