import { startCase, toLower } from 'lodash';
import React, { Fragment, useCallback, useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { useDebounce } from 'use-debounce';
import FilterIcon from '../../../assets/svg/filter.svg';
import SortIcon from '../../../assets/svg/sort.svg';
import { getBreakpoint } from '../../../base/style';
import Button from '../../../components/Button';
import { ContentContainer, InnerWrapper } from '../../../components/Common';
import Error from '../../../components/Error';
import Filter from '../../../components/Filter';
import FlashMessage from '../../../components/FlashMessage/index';
import Loading from '../../../components/Loading';
import Pagination from '../../../components/Pagination';
import NoReposits from '../../../components/Reposit/NoRepositsList';
import RepositList from '../../../components/Reposit/RepositList';
import Search from '../../../components/Search';
import { Header1, P1 } from '../../../components/Typography';
import { RepositFilter, RepositSort } from '../../../constants/reposit';
import { useIsOrganizationLandlord } from '../../../hooks/useIsLandlord';
import { getCurrentOrganizationId } from '../../../redux/auth/auth.selectors';
import { createErrorMessageSelector } from '../../../redux/error/error.selector';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import {
  fetchRepositsRequested,
  FETCH_REPOSITS_STORE_KEY,
  goToPage,
  Page,
  updateFilters as updateFiltersActionCreator
} from '../../../redux/reposit-list/reposit-list.actions';
import {
  getRepositList,
  getRepositListFilters,
  getRepositListPagination
} from '../../../redux/reposit-list/reposit-list.selectors';
import { RepositListFilters } from '../../../redux/reposit-list/reposit-list.types';
import { DISCARD_REPOSIT_STORE_KEY } from '../../../redux/reposit/reposit.actions';
import { getOrganization } from '../../../redux/selectors/organization.selectors';
import { FLASH_MESSAGE_TIMEOUT, useFlashMessage } from '../../FlashMessage/index';

export interface RepositFilterOption {
  value: RepositFilter;
  label: string;
}

const filters: RepositFilterOption[] = [
  {
    value: RepositFilter.ALL,
    label: 'All'
  },
  {
    value: RepositFilter.DRAFT,
    label: 'Draft'
  },
  {
    value: RepositFilter.PENDING,
    label: 'Pending'
  },
  {
    value: RepositFilter.COMPLETE,
    label: 'Active'
  },
  {
    value: RepositFilter.CHECKED_OUT,
    label: 'Checked Out'
  }
];

export default () => {
  const dispatch = useDispatch();
  const getRepositListError = createErrorMessageSelector([FETCH_REPOSITS_STORE_KEY]);
  const repositListLoadingSelector = createLoadingSelector([FETCH_REPOSITS_STORE_KEY]);

  const isRepositListFetching = useSelector(repositListLoadingSelector);
  const currentOrganizationId = useSelector(getCurrentOrganizationId);
  const currentOrg = useSelector(getOrganization(currentOrganizationId));
  const error = useSelector(getRepositListError);

  const reposits = useSelector(getRepositList);
  const { filter, sort, query, tenancyStatus } = useSelector(getRepositListFilters);
  const [debouncedQuery] = useDebounce(query, 500);
  const { page, count } = useSelector(getRepositListPagination);

  const fetchReposits = useCallback(() => dispatch(fetchRepositsRequested()), [dispatch]);
  const repositsGotoPage = useCallback((page: Page) => dispatch(goToPage(page)), [dispatch]);

  const updateFilters = useCallback((filters: Partial<RepositListFilters>) => dispatch(updateFiltersActionCreator(filters)), [
    dispatch
  ]);

  const [flashMessagePayload, onDismissFlashMessage] = useFlashMessage([DISCARD_REPOSIT_STORE_KEY]);

  const isOrgLandlord = useIsOrganizationLandlord();

  const generateSelectedFilter = (filter: any, tenancyStatus: any) => {
    if (filter === RepositFilter.COMPLETE && tenancyStatus === 'CHECKED_OUT') {
      return RepositFilter.CHECKED_OUT;
    }
    return filter;
  };

  const renderContent = (
    isRepositListFetching: boolean,
    reposits: any,
    error: any,
    onNavigate: any,
    totalPages: number,
    currentPage: number,
    filter: string
  ) => {
    if (error) return <Error error={error} />;
    if (isRepositListFetching) return <Loading />;
    if (!isRepositListFetching && reposits.length === 0) {
      return <NoReposits currentFilter={generateSelectedFilter(filter, tenancyStatus)} query={query} />;
    }

    return (
      <Fragment>
        <RepositList reposits={reposits} />
        <Pagination count={totalPages} currentPage={currentPage} onNavigate={onNavigate} />
      </Fragment>
    );
  };

  const sortOptions = Object.values(RepositSort).map(value => ({ label: startCase(toLower(value)), value }));

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      updateFilters({ query });
    },
    [query, updateFilters]
  );

  useEffect(() => {
    fetchReposits();
  }, [filter, sort, tenancyStatus, debouncedQuery, fetchReposits, currentOrganizationId, page]);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const CreateButton = styled(Button)`
    margin-bottom: 24px;
    font-size: 13px;
    width: 100%;
    justify-content: center;
    padding: 8px;

    @media all and (min-width: ${getBreakpoint('md')}) {
      display: none;
    }
  `;

  const initialTitle = isOrgLandlord
    ? 'View, search and filter your Reposits'
    : 'View, search and filter the Reposits associated with';

  return (
    <InnerWrapper>
      <Container>
        <Row>
          <Col sm={12}>
            <NavLink to={'/reposits/new'} style={{ textDecoration: 'none' }}>
              <CreateButton buttonType="secondary">Create a Reposit</CreateButton>
            </NavLink>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <Header1>Reposits</Header1>
            <P1 style={{ display: 'inline' }}>{initialTitle}</P1>
            {!isOrgLandlord && <P1 bold style={{ display: 'inline' }}>{` ${currentOrg && currentOrg.name}`}</P1>}
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <ContentContainer>
              <form onSubmit={handleSubmit}>
                <Search onChange={query => updateFilters({ query })} value={query} placeholder="Search Reposits" />
              </form>
            </ContentContainer>
          </Col>
        </Row>
        <Row>
          <Col lg={12} style={{ paddingBottom: 16 }}>
            <Filter
              label="Filter by status"
              onSelect={filter => {
                if (filter === RepositFilter.CHECKED_OUT) {
                  updateFilters({ filter: RepositFilter.COMPLETE, tenancyStatus: 'CHECKED_OUT' });
                } else if (filter === RepositFilter.COMPLETE) {
                  updateFilters({ filter: RepositFilter.COMPLETE, tenancyStatus: 'ACTIVE' });
                } else {
                  updateFilters({ filter, tenancyStatus: `` });
                }
              }}
              selected={generateSelectedFilter(filter, tenancyStatus)}
              options={filters}
              icon={FilterIcon}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={12} style={{ paddingBottom: 20 }}>
            <Filter
              label="Sort by date"
              onSelect={sort => updateFilters({ sort: sort as RepositSort })}
              options={sortOptions}
              selected={sort}
              icon={SortIcon}
            />
          </Col>
        </Row>
        {flashMessagePayload ? (
          <Row>
            <Col lg={12} style={{ padding: '12px 10px 0' }}>
              <FlashMessage
                jumbo
                onDismiss={onDismissFlashMessage}
                timeRemaining={FLASH_MESSAGE_TIMEOUT}
                payload={flashMessagePayload}
              />
            </Col>
          </Row>
        ) : null}
        <Row>
          <Col lg={12}>
            <ContentContainer>
              {renderContent(isRepositListFetching, reposits, error, repositsGotoPage, count, page, filter)}
            </ContentContainer>
          </Col>
        </Row>
      </Container>
    </InnerWrapper>
  );
};
