import { ClaimDTOStatusEnum } from '@reposit/api-client';
import { Formik, FormikProps } from 'formik';
import moment from 'moment';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import * as Yup from 'yup';
import DocumentIcon from '../../assets/svg/tenancy-details.svg';
import UploadIcon from '../../assets/svg/upload.svg';
import Button from '../../components/Button';
import Dialog from '../../components/Dialog';
import FlashMessage from '../../components/FlashMessage/index';
import TextArea from '../../components/FormFields/TextArea/index';
import { FullPageLoader } from '../../components/Loading/index';
import MultiDocUploader from '../../components/MultiDocUploader';
import RepositCard from '../../components/Reposit/RepositCard';
import SecondaryPanel from '../../components/SecondaryPanel';
import { Header1, P1 } from '../../components/Typography';
import {
  createClaimDocumentRequested,
  deleteClaimDocumentRequested,
  CREATE_CLAIM_DOCUMENT_STORE_KEY
} from '../../redux/claim-document/claim-document.actions';
import {
  clearClaimHasFetched,
  fetchClaimRequested,
  FETCH_CLAIM_STORE_KEY,
  improveClaimRequested,
  IMPROVE_CLAIM_STORE_KEY
} from '../../redux/claim/claim.actions';
import { getClaimHasBeenFetched } from '../../redux/claim/claim.selector';
import { createErrorMessageSelector } from '../../redux/error/error.selector';
import { createLoadingSelector } from '../../redux/loading/loading.selector';
import { AppState } from '../../redux/root.reducer';
import { getAdditionalEvidenceDocumentsFromClaimById, getClaimById } from '../../redux/selectors/claim.selectors';
import { isANotFoundError } from '../../utils/common.utils';
import { FLASH_MESSAGE_TIMEOUT, useFlashMessage } from '../FlashMessage/index';
import NotFoundView from '../NotFound/index';
import ImproveClaimModal from './ActionRequiredActions/modals/ImproveClaimModal';

interface ActionRequiredProps {
  id: string;
}

const InfoText = styled(P1)`
  padding: 0 36px;
`;

const ClaimFlashMessage = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  margin: 24px 0 0 240px;
  padding: 0 50px;
  width: calc(100% - 240px);
  z-index: 9000;
`;

const ActionsContainer = styled.div`
  margin: 16px 0 96px;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

const Schema = Yup.object().shape({
  response: Yup.string()
});

export interface ActionRequiredResponseFormValues {
  response: string;
}
const ActionRequired: React.FC<RouteComponentProps<ActionRequiredProps>> = props => {
  const claimId = props.match.params.id;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchClaimRequested({ claimId }));
    return function cleanup() {
      dispatch(clearClaimHasFetched());
    };
  }, [claimId, dispatch]);

  const isClaimLoadingSelector = createLoadingSelector([FETCH_CLAIM_STORE_KEY]);
  const isDocumentUploadingSelector = createLoadingSelector([CREATE_CLAIM_DOCUMENT_STORE_KEY]);
  const errorSelector = createErrorMessageSelector([FETCH_CLAIM_STORE_KEY]);
  const hasFetched = useSelector(getClaimHasBeenFetched);
  const isClaimLoading = useSelector(isClaimLoadingSelector);
  const isDocumentUploading = useSelector(isDocumentUploadingSelector);
  const error = useSelector(errorSelector);

  const documents = useSelector((state: AppState) => getAdditionalEvidenceDocumentsFromClaimById(state, claimId)) || [];

  const claim = useSelector((state: AppState) => getClaimById(state, claimId));

  const [flashMessagePayload, onDismissFlashMessage] = useFlashMessage([IMPROVE_CLAIM_STORE_KEY]);

  const improveClaim = (values: ActionRequiredResponseFormValues) =>
    dispatch(improveClaimRequested({ claimId: claim!.id, response: values.response }));

  const handleCreateDocument = useCallback(
    (file: File) => {
      dispatch(createClaimDocumentRequested({ file, claimId, type: 'ADDITIONAL_EVIDENCE' }));
    },
    [claimId, dispatch]
  );

  const handleDeleteDocument = useCallback(
    (documentId: string) => {
      dispatch(deleteClaimDocumentRequested({ claimId, documentId }));
    },
    [claimId, dispatch]
  );

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  const [isModalShowing, showModal] = useState(false);

  if (!hasFetched || isClaimLoading) {
    return <FullPageLoader />;
  }

  if (!claim || error) {
    if (isANotFoundError(error)) {
      return <NotFoundView />;
    } else {
      // TODO: we need an error component
      return <div>oh no something went wrong</div>;
    }
  }

  if (claim.status !== ClaimDTOStatusEnum.AWAITINGADDITIONALINFORMATION) {
    return <Redirect to={'/claims'} />;
  }

  return (
    <Formik initialValues={{ response: '' }} onSubmit={() => showModal(true)} validationSchema={Schema}>
      {({ values, handleChange, handleSubmit, handleBlur, touched, errors }: FormikProps<ActionRequiredResponseFormValues>) => {
        return (
          <form onSubmit={handleSubmit}>
            <Fragment>
              {flashMessagePayload ? (
                <ClaimFlashMessage>
                  <FlashMessage
                    onDismiss={onDismissFlashMessage}
                    timeRemaining={FLASH_MESSAGE_TIMEOUT}
                    payload={flashMessagePayload}
                  />
                </ClaimFlashMessage>
              ) : null}
              <Container>
                <Row>
                  <Col lg={10} push={{ lg: 1 }}>
                    <Header1>Action Needed</Header1>
                    <P1>To process your claim we need some more evidence from you.</P1>
                  </Col>
                </Row>
                <Row>
                  <Col lg={10} push={{ lg: 1 }}>
                    <Dialog
                      message={claim.repositMessage!}
                      days={moment().diff(moment(claim.repositMessagePublishedAt), 'day')}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={10} push={{ lg: 1 }}>
                    <RepositCard title="Uploader" flush icon={UploadIcon}>
                      <InfoText>Here you can upload documents to help substanciate your claim.</InfoText>
                      <SecondaryPanel title="Evidence" tooltip="Some help text">
                        <MultiDocUploader
                          createDocument={handleCreateDocument}
                          documents={documents}
                          deleteDocument={handleDeleteDocument}
                          showDeleteButton
                          isDocumentUploading={isDocumentUploading}
                        />
                      </SecondaryPanel>
                    </RepositCard>
                  </Col>
                </Row>
                <Row>
                  <Col lg={10} push={{ lg: 1 }}>
                    <RepositCard title="Anything else?" icon={DocumentIcon}>
                      <P1>If you have any additional information you’d wish to supply, please detail below.</P1>
                      <TextArea
                        name="response"
                        value={values.response || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={errors.response}
                        touched={touched.response}
                      />
                    </RepositCard>
                  </Col>
                </Row>
                <Row>
                  <Col lg={10} push={{ lg: 1 }}>
                    <ActionsContainer>
                      <Button>Send Update</Button>
                    </ActionsContainer>
                  </Col>
                </Row>
              </Container>
            </Fragment>
            {isModalShowing ? (
              <ImproveClaimModal onDismiss={() => showModal(false)} onSubmit={() => improveClaim(values)} />
            ) : null}
          </form>
        );
      }}
    </Formik>
  );
};

export default ActionRequired;
