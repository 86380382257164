import { ArbitrationDTO } from '@reposit/api-client';
import React from 'react';
import styled from 'styled-components';
import ImageIcon from '../../assets/svg/image.svg';
import { FAIR_DISPUTES_FAQS } from '../../constants/links';
import { penceToPounds } from '../../utils/common.utils';
import RepositCard from '../Reposit/RepositCard/index';
import SecondaryPanel from '../SecondaryPanel/index';
import { Header4, P2, P3 } from '../Typography/index';

interface ArbitrationDetailsProps {
  arbitration: ArbitrationDTO;
}

const Inner = styled.div`
  align-items: center;
  display: flex;
`;

const Label = styled(P2)`
  margin: 0;

  span {
    color: ${props => props.theme.colors.body1};
    margin: 0 10px;
    text-decoration: underline;
  }
`;

const SmallLabel = styled(P3)`
  margin: 0;
  display: inline;
  color: ${props => props.theme.colors.body};
  a {
    color: inherit;
  }
`;

const FAQs = styled.a`
  &:hover {
    color: ${props => props.theme.colors.body2};
  }
`;

const DocumentRow = styled.div`
  align-items: center;
  display: flex;
  margin: 16px 0 16px;

  &:before {
    background: transparent url(${ImageIcon}) center center no-repeat;
    content: '';
    display: block;
    margin: 0 10px 0 0;
    height: 20px;
    width: 20px;
  }

  p {
    margin: 0;
  }
`;

const Link = styled(P2)`
  a {
    color: inherit;
  }
`;

const ReportTitle = styled(Header4)`
  font-weight: bold;
  margin: 16px 0 12px;
`;

export const ArbitrationDetails: React.FC<ArbitrationDetailsProps> = ({ arbitration }) => {
  return (
    <>
      <RepositCard subtitle={`£${penceToPounds(arbitration.arbitratedAmount)}`} flush title="Adjudication Result">
        <SecondaryPanel>
          <Inner>
            <div>
              <div>
                <ReportTitle>Independent adjudication report</ReportTitle>
                <Label>Please click the document below to view the full adjudication report.</Label>
                <DocumentRow>
                  <Link style={{ display: 'inline' }}>
                    <a
                      href={arbitration.documents && arbitration.documents[0].document.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {arbitration.documents && arbitration.documents[0].document.name}
                    </a>
                  </Link>
                </DocumentRow>
                <SmallLabel>
                  <FAQs href={FAIR_DISPUTES_FAQS} target="_blank" rel="noopener noreferrer">
                    How do I know this was resolved fairly?
                  </FAQs>
                </SmallLabel>
              </div>
            </div>
          </Inner>
        </SecondaryPanel>
      </RepositCard>
    </>
  );
};
