import { AppState } from '../root.reducer';

export const getBankAccounts = (state: AppState) => {
  const ids = state.bankAccounts.bankAccountIds;
  return ids.map(id => getBankAccountById(id)(state));
};

export const getBankAccountById = (id: string) => (state: AppState) => {
  return state.entities.bankAccount[id];
};

export const getIsClaimAccountShowing = (state: AppState) => {
  return state.bankAccounts.claimAccountShowing;
};
