import { createAction } from '@reduxjs/toolkit';
import { CreateOrganizationRecipientDTO } from '@reposit/api-client';
import { DeleteOrganizationRecipient } from './organization-recipient.types';

export const DELETE_ORG_RECIPIENT_STORE_KEY = 'ORG_RECIPIENT/DELETE_ORG_RECIPIENT';
export const CREATE_ORG_RECIPIENT_STORE_KEY = 'ORG_RECIPIENT/CREATE_ORG_RECIPIENT';
export const SET_ORG_RECIPIENT_STORE_KEY = 'ORG_RECIPIENT/SET_ORG_RECIPIENT';

export const deleteOrganizationRecipientRequested = createAction<DeleteOrganizationRecipient>(
  'ORG_RECIPIENT/DELETE_ORG_RECIPIENT_API_REQUESTED'
);
export const deleteOrganizationRecipientSuccess = createAction<string>('ORG_RECIPIENT/DELETE_ORG_RECIPIENT_API_SUCCESS');
export const deleteOrganizationRecipientFailed = createAction<string>('ORG_RECIPIENT/DELETE_ORG_RECIPIENT_API_FAILED');

export const createOrganizationRecipientRequested = createAction<CreateOrganizationRecipientDTO>(
  'ORG_RECIPIENT/CREATE_ORG_RECIPIENT_API_REQUESTED'
);
export const createOrganizationRecipientSuccess = createAction<string>('ORG_RECIPIENT/CREATE_ORG_RECIPIENT_API_SUCCESS');
export const createOrganizationRecipientFailed = createAction<string>('ORG_RECIPIENT/CREATE_ORG_RECIPIENT_API_FAILED');

export const setOrganizationRecipientRequested = createAction('ORG_RECIPIENT/SET_ORG_RECIPIENT_API_REQUESTED');
export const setOrganizationRecipientSuccess = createAction<string[]>('ORG_RECIPIENT/SET_ORG_RECIPIENT_API_SUCCESS');
export const setOrganizationRecipientFailed = createAction<string[]>('ORG_RECIPIENT/SET_ORG_RECIPIENT_API_FAILED');

export const setIsAddAdminRecipientFormOpen = createAction<boolean>('ORG_RECIPIENT/SET_IS_ADD_ADMIN_RECIPIENT_FORM_OPEN');
export const setIsAddInitialRecipientFormOpen = createAction<boolean>('ORG_RECIPIENT/SET_IS_ADD_INITIAL_RECIPIENT_FORM_OPEN');
