import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getBreakpoint } from '../../../base/style';
import Button from '../../../components/Button/index';
import { GuarantorFormValues } from '../../../components/TenantList/guarantor-form';
import { GuarantorModal } from '../../../components/TenantList/guarantor-modal';
import { Header4, P2, P3 } from '../../../components/Typography/index';
import { TenantEntity } from '../../../redux/entities/entities.types';
import { addGuarantorRequested, setGuarantorModal, updateGuarantorRequested } from '../../../redux/guarantor/guarantor.actions';
import { getGuarantorModal } from '../../../redux/guarantor/guarantor.selectors';
import { GuarantorModalPayload } from '../../../redux/guarantor/guarantor.types';

const TenantName = styled(Header4)`
  margin: 0;
`;

const TenantAndGuarantorItemWrapper = styled.div<{ isOpen?: boolean }>`
  background: ${props => props.theme.colors.bgAccent};
  margin: 0 0 6px;
  padding: 0 24px 6px;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    padding: 0 34px 6px;
  }
`;

const TenantAndGuarantorItemInner = styled.li`
  border-radius: 8px;
  cursor: default;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 6px;
  padding-top: 15px;
`;

const Tenant = styled.div`
  align-items: center;
  display: flex;
  width: 100%;
`;

const Guarantor = styled.div`
  padding-bottom: 10px;
  align-items: left;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
`;

const GuarantorDetails = styled(P3)`
  display: flex;
  justify-content: space-between;
  margin: 0;
  flex-shrink: 0;
`;

const GuarantorName = styled(P2)`
  flex-shrink: 0;
  font-size: 1em;
  margin: 0 10px 0 0;
`;

const NoGuarantor = styled(P2)`
  flex-shrink: 0;
  font-size: 1em;
  margin: 0 10px 0 0;
  font-style: italic;
`;

const TextLink = styled.span`
  margin: 0;
  padding: 0;
  button {
    background: none;
    color: ${props => props.theme.colors.primaryBrand};
    cursor: pointer;
    display: inline;
    border: none;
    margin: 0 0 0 10px;
    padding: 0;
    text-decoration: underline;
  }
`;

interface TenantAndGuarantorItemProps {
  tenant: TenantEntity;
  tenancyId: string;
  editable: boolean;
}

const TenantAndGuarantorItem: React.FC<TenantAndGuarantorItemProps> = ({ tenant, tenancyId, editable }) => {
  const dispatch = useDispatch();
  const guarantor = tenant.guarantor;
  const guarantorModal = useSelector(getGuarantorModal);

  const guarantorRequest = (values: GuarantorFormValues) => {
    const payload = { tenancyId, tenancyUserId: tenant.id, ...values };
    if (guarantorModal.modalState === 'add') {
      dispatch(addGuarantorRequested(payload));
    } else {
      dispatch(updateGuarantorRequested(payload));
    }
  };

  const dispatchGuarantorModal = (payload: GuarantorModalPayload) => {
    dispatch(setGuarantorModal(payload));
  };

  return (
    <TenantAndGuarantorItemWrapper key={tenant.id}>
      <TenantAndGuarantorItemInner>
        <Tenant>
          <TenantName>
            {tenant.firstName} {tenant.lastName}
          </TenantName>
        </Tenant>
        <Guarantor>
          {guarantor ? (
            <Fragment>
              <GuarantorName>
                Guarantor: {guarantor.firstName} {guarantor.lastName}
              </GuarantorName>
              <GuarantorDetails>
                {guarantor.email} {guarantor.mobileNumber && guarantor.mobileNumber}
                {editable ? (
                  <TextLink>
                    <button onClick={() => dispatchGuarantorModal({ isOpen: true, modalState: 'edit', tenantId: tenant.id })}>
                      Edit
                    </button>
                  </TextLink>
                ) : null}
              </GuarantorDetails>
            </Fragment>
          ) : (
            <Fragment>
              <NoGuarantor>This tenant has no guarantor</NoGuarantor>
              {editable ? (
                <div style={{ paddingTop: 10 }}>
                  <Button
                    buttonType="secondary"
                    mini="true"
                    onClick={() => dispatchGuarantorModal({ isOpen: true, tenantId: tenant.id, modalState: 'add' })}
                  >
                    Add Guarantor
                  </Button>
                </div>
              ) : null}
            </Fragment>
          )}
        </Guarantor>

        {guarantorModal.isOpen && guarantorModal.modalState && guarantorModal.tenantId === tenant.id ? (
          <GuarantorModal
            modalType={guarantor ? 'edit' : 'add'}
            tenant={tenant}
            isLoading={false}
            onDismiss={() => dispatchGuarantorModal({ isOpen: false, modalState: undefined })}
            initialValues={guarantor}
            onSubmit={guarantorRequest}
          />
        ) : null}
      </TenantAndGuarantorItemInner>
    </TenantAndGuarantorItemWrapper>
  );
};

export default TenantAndGuarantorItem;
