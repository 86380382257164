import { ClaimDTOStatusEnum } from '@reposit/api-client';
import { get } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { getBreakpoint } from '../../../base/style';
import { RepositTheme } from '../../../base/theme';
import {
  ClaimFlashMessageProposalNeedsFinishing,
  ClaimFlashMessageProposalNeedsResponse,
  ClaimFlashMessagePublish,
  ClaimFlashMessageSubstantiate
} from '../../../containers/Claim/ClaimMessage/index';
import { AddressEntity, ClaimEntity, ClaimItemEntity } from '../../../redux/entities/entities.types';
import { AppState } from '../../../redux/root.reducer';
import {
  getIsAgentDeciding,
  getIsAgentNegotiating,
  getTenantProposalResponseDeadlineDaysRemaining
} from '../../../redux/selectors/mediation.selectors';
import { getAddressFirstLine } from '../../../utils/common.utils';
import Card from '../../Card/index';
import { Caption, Header3, P2, P3 } from '../../Typography/index';
import ClaimItemSummary from '../ClaimItemSummary/index';

interface ClaimSummaryProps {
  claim: ClaimEntity;
  items: ClaimItemEntity[];
  address?: AddressEntity;
  outcomeDeadline?: string;
  isAgentDeciding: boolean;
  isTenantDeciding: boolean;
}

interface ClaimStatusProps {
  claim: ClaimEntity;
}

const ClaimStatusColors: { [key in ClaimDTOStatusEnum]?: string } = {
  [ClaimDTOStatusEnum.AWAITINGREVIEW]: RepositTheme.colors.warning,
  [ClaimDTOStatusEnum.AWAITINGRESPONDENTS]: RepositTheme.colors.warning,
  [ClaimDTOStatusEnum.AWAITINGARBITRATION]: RepositTheme.colors.warning,
  [ClaimDTOStatusEnum.AWAITINGARBITRATIONADMINFEE]: RepositTheme.colors.warning,
  [ClaimDTOStatusEnum.AWAITINGADDITIONALINFORMATION]: RepositTheme.colors.warning,
  [ClaimDTOStatusEnum.AWAITINGSUBSTANTIATION]: RepositTheme.colors.warning,
  [ClaimDTOStatusEnum.RESOLVED]: RepositTheme.colors.positive,
  [ClaimDTOStatusEnum.DRAFT]: RepositTheme.colors.body2,
  [ClaimDTOStatusEnum.DECLINED]: RepositTheme.colors.warning,
  [ClaimDTOStatusEnum.ARBITRATED]: RepositTheme.colors.warning,
  [ClaimDTOStatusEnum.MEDIATION]: RepositTheme.colors.warning
};

const ClaimStatusLabel: { [key in ClaimDTOStatusEnum]?: string } = {
  [ClaimDTOStatusEnum.AWAITINGREVIEW]: `Awaiting Review`,
  [ClaimDTOStatusEnum.AWAITINGRESPONDENTS]: `Awaiting Tenants Response`,
  [ClaimDTOStatusEnum.AWAITINGARBITRATION]: `Awaiting Arbitration`,
  [ClaimDTOStatusEnum.AWAITINGARBITRATIONADMINFEE]: `Awaiting Tenants Response`,
  [ClaimDTOStatusEnum.AWAITINGADDITIONALINFORMATION]: `Awaiting Additional Information`,
  [ClaimDTOStatusEnum.AWAITINGSUBSTANTIATION]: `Awaiting Your Remaining Evidence`,
  [ClaimDTOStatusEnum.RESOLVED]: `Complete`,
  [ClaimDTOStatusEnum.DRAFT]: `Draft`,
  [ClaimDTOStatusEnum.DECLINED]: `Declined`,
  [ClaimDTOStatusEnum.ARBITRATED]: `Awaiting Tenants Settlement`
};

const getClaimStatusLabel = (claim: ClaimEntity, isAgentDeciding: boolean, isTenantDeciding: boolean) => {
  if (claim.supplierPaidAt) {
    return ClaimStatusLabel[ClaimDTOStatusEnum.RESOLVED];
  }
  if (claim.status === ClaimDTOStatusEnum.MEDIATION) {
    if (isAgentDeciding) {
      return `Awaiting your response`;
    } else if (isTenantDeciding) {
      return ClaimStatusLabel[ClaimDTOStatusEnum.AWAITINGRESPONDENTS];
    } else {
      return 'Awaiting final proposal';
    }
  }
  return ClaimStatusLabel[claim.status];
};

const getClaimStatusColour = (claim: ClaimEntity) => {
  if (claim.supplierPaidAt) {
    return ClaimStatusColors[ClaimDTOStatusEnum.RESOLVED];
  }
  return ClaimStatusColors[claim.status];
};

const SummaryContainer = styled.div`
  @media all and (min-width: ${getBreakpoint('lg')}) {
    display: flex;
    justify-content: space-between;
  }
`;

const ColLeft = styled.div`
  width: 100%;
`;

const ColRight = styled.div`
  flex: 0 0 280px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    flex-direction: column;
    max-width: 280px;
  }
`;

const ClaimStatus = styled(P2)<ClaimStatusProps>`
  align-items: center;
  color: ${props => getClaimStatusColour(props.claim)};
  display: flex;

  &:before {
    background: ${props => getClaimStatusColour(props.claim)};
    border-radius: 12px;
    content: '';
    display: block;
    height: 10px;
    margin: -2px 10px 0 0;
    width: 10px;
  }
`;

const Action = styled.div`
  display: flex;
  justify-content: space-between;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    justify-content: flex-end;
  }
`;

const SmallLabel = styled(P3)`
  margin: 0;
`;

const NoAddress = styled(P2)`
  font-style: italic;
`;

const Address = styled.div`
  margin-bottom: 1rem;
  width: 100%;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
  }
`;

const FirstLineAddress = styled(Header3)`
  line-height: 1.2;
  margin: 0;
  padding: 90;
`;

const TownPostcode = styled(Caption)`
  padding: 2px 0 0;
  @media screen and (min-width: ${getBreakpoint('lg')}) {
    margin: 0 0 0 12px;
  }
`;

interface ClaimSummaryClaimFlashMessageProps {
  claim?: ClaimEntity;
  outcomeDeadline?: string;
}

const ClaimSummaryClaimFlashMessage: React.FC<ClaimSummaryClaimFlashMessageProps> = ({ claim, outcomeDeadline }) => {
  const claimId = get(claim, 'id', '');
  const outcomeDeadlineDaysRemaining = claim ? moment(outcomeDeadline).diff(moment().startOf('day'), 'day') : 0;

  const substantiationDeadlineDaysRemaining =
    claim && claim.substantiationDeadline ? moment(claim.substantiationDeadline).diff(moment().startOf('day'), 'day') : 0;
  const tenantProposalResponseDeadlineDaysRemaining = useSelector((state: AppState) =>
    getTenantProposalResponseDeadlineDaysRemaining(state, claimId)
  );

  const claimPublishable = claim && outcomeDeadlineDaysRemaining > 0 && claim.status === ClaimDTOStatusEnum.DRAFT;
  const claimNeedsSubstantiation = claim && claim.status === ClaimDTOStatusEnum.AWAITINGSUBSTANTIATION;
  const claimProposalNeedsResponse = useSelector((state: AppState) => getIsAgentDeciding(state, claimId));
  const claimProposalNeedsFinishing = useSelector((state: AppState) => getIsAgentNegotiating(state, claimId));

  if (claim && claimPublishable) {
    return <ClaimFlashMessagePublish claimId={claim.id} daysRemaining={outcomeDeadlineDaysRemaining} />;
  }
  if (claim && claimNeedsSubstantiation) {
    return <ClaimFlashMessageSubstantiate claimId={claim.id} daysRemaining={substantiationDeadlineDaysRemaining} />;
  }

  if (claim && claimProposalNeedsResponse) {
    return (
      <ClaimFlashMessageProposalNeedsResponse claimId={claim.id} daysRemaining={tenantProposalResponseDeadlineDaysRemaining} />
    );
  }

  if (claim && claimProposalNeedsFinishing) {
    return (
      <ClaimFlashMessageProposalNeedsFinishing claimId={claim.id} daysRemaining={tenantProposalResponseDeadlineDaysRemaining} />
    );
  }
  return null;
};

const ClaimSummary: React.FC<ClaimSummaryProps> = ({
  claim,
  items,
  address,
  outcomeDeadline,
  isAgentDeciding,
  isTenantDeciding
}) => {
  return (
    <NavLink to={`/claims/${claim.id}`} style={{ textDecoration: 'none' }}>
      <Card
        hover
        flush={false}
        style={{ padding: '12px 20px 32px 40px' }}
        flashMessage={<ClaimSummaryClaimFlashMessage claim={claim} outcomeDeadline={outcomeDeadline} />}
      >
        <SummaryContainer>
          <ColLeft>
            <ClaimStatus claim={claim}>{getClaimStatusLabel(claim, isAgentDeciding, isTenantDeciding)}</ClaimStatus>
            {address ? (
              <Address>
                <FirstLineAddress>{getAddressFirstLine(address)}</FirstLineAddress>
                <TownPostcode>{address.postcode}</TownPostcode>
              </Address>
            ) : (
              <NoAddress>You haven't added a property to this Reposit yet.</NoAddress>
            )}
            <ClaimItemSummary items={items} />
          </ColLeft>
          <ColRight>
            <Action>
              <SmallLabel>Created {moment(claim.createdAt).fromNow()}</SmallLabel>
            </Action>
          </ColRight>
        </SummaryContainer>
      </Card>
    </NavLink>
  );
};

export default ClaimSummary;
