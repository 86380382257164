import React from 'react';

import Card from '../../Card/index';
import { Header3 } from '../../Typography/index';
import styled from 'styled-components';

const ClaimCard = styled(Card)`
  padding: 18px 5px 2px;
  a {
    text-decoration: none;
  }
`;

interface NoClaimsListProps {
  query: string;
}

const NoClaimsList: React.FC<NoClaimsListProps> = ({ query }) => (
  <ClaimCard>
    <Header3>You don’t have any Claims {query ? `matching the search term "${query}"` : 'yet'}.</Header3>
  </ClaimCard>
);

export default NoClaimsList;
