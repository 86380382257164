import { AppState } from '../root.reducer';
import { CheckoutEntity } from '../entities/entities.types';

export interface GetCheckoutProps {
  checkoutId?: string;
}

export const getCheckoutById = (state: AppState, { checkoutId }: GetCheckoutProps): CheckoutEntity | undefined => {
  if (!state.entities.checkout) return undefined;

  if (!checkoutId) return undefined;

  return state.entities.checkout[checkoutId];
};

export const getCheckouts = (state: AppState): { [key: string]: CheckoutEntity } => {
  return state.entities.checkout;
};

export interface GetCheckoutByTenancyOrderIdProps {
  tenancyOrderId?: string;
}

export const getCheckoutByTenancyOrderId = (
  state: AppState,
  { tenancyOrderId }: GetCheckoutByTenancyOrderIdProps
): CheckoutEntity | undefined => {
  if (!state.entities.tenancyOrder) return undefined;
  if (!tenancyOrderId) return undefined;
  const tenanyOrder = state.entities.tenancyOrder[tenancyOrderId];
  const tenancy = tenanyOrder && state.entities.tenancy[tenanyOrder.tenancy];
  const checkout = tenancy && state.entities.checkout[tenancy.checkout];

  return checkout;
};
