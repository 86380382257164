import {
  CLEAR_CLAIM_ITEM_DOCUMENT_IDS,
  CREATE_DOCUMENT_API_SUCCESS,
  DocumentActionTypes,
  REMOVE_CLAIM_ITEM_DOCUMENT_ID
} from './document.actions';
import { DocumentState } from './document.types';

const initialState: DocumentState = {
  claimItemDocumentIds: []
};

export default (state = initialState, action: DocumentActionTypes): DocumentState => {
  switch (action.type) {
    case CREATE_DOCUMENT_API_SUCCESS:
      if (action.payload.isClaimItem) {
        return {
          ...state,
          claimItemDocumentIds: [...state.claimItemDocumentIds, action.payload.id]
        };
      }
      return state;
    case CLEAR_CLAIM_ITEM_DOCUMENT_IDS:
      return {
        ...state,
        claimItemDocumentIds: []
      };
    case REMOVE_CLAIM_ITEM_DOCUMENT_ID:
      return {
        ...state,
        claimItemDocumentIds: state.claimItemDocumentIds.filter(id => id !== action.payload)
      };
    default:
      return state;
  }
};
