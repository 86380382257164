import { AddressDTO, TenancyExpiryDTO } from '@reposit/api-client';
import { get, sortBy } from 'lodash';
import moment from 'moment';

type excludedKeys = 'id' | 'organizationId' | 'createdAt' | 'updatedAt';

interface CoreAddress extends Omit<AddressDTO, excludedKeys> {}

export const getAddressFirstLine = (address: CoreAddress): string => {
  // TODO: this should not have to deal with an undefined address
  if (!address) return '';
  const { roomNumber, flatNumber, buildingNumber, buildingName, street } = address;
  const streetConcat = buildingNumber ? `${buildingNumber} ${street}` : street;
  const firstLineItems = {
    room: roomNumber ? `${roomNumber}` : null,
    flat: flatNumber ? `${flatNumber}` : null,
    buildingName,
    street: streetConcat
  };

  const result = Object.values(firstLineItems);
  return result.filter(Boolean).join(', ');
};

export const getAddressTownPostcode = (address: CoreAddress): string => {
  // TODO: this should not have to deal with an undefined address
  if (!address) return '';
  const result = [address.town, address.postcode];
  return result.filter(Boolean).join(', ');
};

export const getDisplayAddress = (address: CoreAddress) => {
  // TODO: this should not have to deal with an undefined address
  if (!address) return '';
  return `${getAddressFirstLine(address)}, ${getAddressTownPostcode(address)}`;
};

export const isANotFoundError = (error: string) => {
  return error.includes('not found');
};

export const getErrorMessage = (error: any) => {
  const err = get(error, 'response.data.message', error);
  return Array.isArray(err) ? 'Something went wrong.' : err;
};

export const getExpiryDate = (tenancyExpiryDates: TenancyExpiryDTO[]): TenancyExpiryDTO => {
  const sortedDates = sortBy(tenancyExpiryDates, (o: TenancyExpiryDTO) => {
    return moment(o.endDate);
  }).reverse();

  return sortedDates[0];
};

export const penceToPounds = (value: number, decimalPlaces: number = 2): string => {
  return (value / 100).toFixed(decimalPlaces);
};

export const poundsToPence = (value: number): number => Math.round(value * 100);
