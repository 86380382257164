import React, { Fragment } from 'react';
import WelcomeIcon from '../../../assets/svg/onboarding/waving-hand.svg';
import CelebrationIcon from '../../../assets/svg/onboarding/celebration.svg';
import SpeedIcon from '../../../assets/svg/onboarding/speed.svg';
import PiggyBankIcon from '../../../assets/svg/onboarding/piggy-bank.svg';
import Title from '../../../components/Onboarding/Title/index';
import { useDispatch, useSelector } from 'react-redux';
import { updateWelcomeLandlordRequested, UPDATE_WELCOME_LANDLORD_STORE_KEY } from '../../../redux/account/account.actions';
import Button from '../../../components/Button/index';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import { Header3, P1 } from '../../../components/Typography/index';
import Actions from '../../../components/Onboarding/Actions/index';
import RepositCard from '../../../components/Reposit/RepositCard/index';

export const WelcomeLandlord: React.SFC<any> = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(createLoadingSelector([UPDATE_WELCOME_LANDLORD_STORE_KEY]));
  const onSubmit = () => dispatch(updateWelcomeLandlordRequested());
  return (
    <Fragment>
      <Title icon={WelcomeIcon}>Welcome to Reposit </Title>
      <Header3>Please see below for a quick refresher on the benefits of Reposit</Header3>
      <RepositCard icon={CelebrationIcon} title="More cover than a cash deposit">
        <P1>Cash deposits are capped at 5 weeks' rent. Reposit gives you 8 weeks' worth of cover, 60% more protection.</P1>
      </RepositCard>
      <RepositCard icon={PiggyBankIcon} title="Increased demand, fewer voids">
        <P1>
          Void periods cost UK landlords over £3bn per year, with individual landlords losing an average of £1,869 per year.
          Properties advertised with a 'deposit free option' have been shown to attract 26% more click-throughs online, and will
          typically let quicker.
        </P1>
      </RepositCard>
      <RepositCard icon={SpeedIcon} title="Faster dispute resolution">
        <P1>
          If end of tenancy charges are disputed, Reposit will obtain an adjudication verdict within 14 days, much faster than the
          traditional cash deposit schemes, which can take 6 weeks or more.
        </P1>
      </RepositCard>
      <Actions>
        <Button onClick={onSubmit} disabled={isLoading}>
          Continue
        </Button>
      </Actions>
    </Fragment>
  );
};
