export const addDashesToSortCode = (current: string, previous: string) => {
  let output = [];
  let dashCountdown = 2;
  for (let i = 0; i < current.length; i++) {
    if (dashCountdown === 0) {
      output.push('-');
      dashCountdown = 1;
    } else {
      dashCountdown--;
    }
    output.push(current[i]);
  }
  if (previous.length < current.length) {
    if (output.length === 2 || output.length === 5) {
      output.push('-');
    }
  }
  return output.join('');
};
