import { CreateOrganizationRecipientDTOGroupEnum, OrganizationRecipientDTOGroupEnum } from '@reposit/api-client';
import { sortBy } from 'lodash';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import TenantsIcon from '../../../assets/svg/tenants.svg';
import AccountForm, { AccountFormValues } from '../../../components/AccountForm/index';
import Button from '../../../components/Button/index';
import FlashMessage from '../../../components/FlashMessage/index';
import RepositCard from '../../../components/Reposit/RepositCard/index';
import { P1, StyledLink } from '../../../components/Typography/index';
import { OrganizationRecipientEntity } from '../../../redux/entities/entities.types';
import {
  createOrganizationRecipientRequested,
  CREATE_ORG_RECIPIENT_STORE_KEY,
  DELETE_ORG_RECIPIENT_STORE_KEY
} from '../../../redux/organization-recipient/organization-recipient.actions';
import { getOrganizationRecipientsByGroup } from '../../../redux/selectors/organization.selectors';
import { FLASH_MESSAGE_TIMEOUT, useFlashMessage } from '../../FlashMessage/index';
import OrganizationRecipientComponent from './OrganizationRecipient';

import { NavLink } from 'react-router-dom';

const TeamListWrapper = styled.ul`
  background: #fff;
  margin: 0 0 36px;
  min-width: 200px;
  overflow: hidden;
  padding: 0;
`;

const TeamListContainer = styled.div`
  padding: 12px 36px 0;
`;

interface ManageRecipientGroupsProps {
  group: OrganizationRecipientDTOGroupEnum;
  isFormOpen: boolean;
  setIsFormOpen: (value: boolean) => void;
  isLoading: boolean;
}

const Link = styled(StyledLink)`
  margin: 0 20px;
`;

const SummaryText = styled.div`
  padding: 1px 36px;
  /* margin-bottom: 36px; */
`;

const ManageRecipientGroups: React.FC<ManageRecipientGroupsProps> = ({ group, isFormOpen, setIsFormOpen, isLoading }) => {
  const dispatch = useDispatch();

  const [flashMessagePayload, onDismissFlashMessage] = useFlashMessage([
    CREATE_ORG_RECIPIENT_STORE_KEY,
    DELETE_ORG_RECIPIENT_STORE_KEY
  ]);

  const organizationRecipients: OrganizationRecipientEntity[] = useSelector(getOrganizationRecipientsByGroup(group));

  const groupValue =
    group === OrganizationRecipientDTOGroupEnum.ADMIN
      ? CreateOrganizationRecipientDTOGroupEnum.ADMIN
      : CreateOrganizationRecipientDTOGroupEnum.INITIAL;

  const groupText = groupValue === CreateOrganizationRecipientDTOGroupEnum.INITIAL ? `pre-tenancy` : 'tenancy management';

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const onSubmit = (values: AccountFormValues) => {
    dispatch(createOrganizationRecipientRequested({ email: values.email, group: groupValue }));
  };

  return (
    <RepositCard
      title={group === OrganizationRecipientDTOGroupEnum.INITIAL ? 'Pre-Tenancy Recipients' : 'Tenancy Management Recipients'}
      icon={TenantsIcon}
      flush
      flashMessage={
        // Decided this was a better short term option, rather than overhauling flash message component and typing
        // ...sorry
        (flashMessagePayload && flashMessagePayload.message.includes(groupText)) ||
        (flashMessagePayload && flashMessagePayload.group === groupText) ? (
          <FlashMessage onDismiss={onDismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMEOUT} payload={flashMessagePayload} />
        ) : (
          undefined
        )
      }
    >
      {groupValue === CreateOrganizationRecipientDTOGroupEnum.INITIAL ? (
        <SummaryText>
          <P1>
            Add additional email addresses to receive pre-tenancy email notifications for all Reposits related to this
            organisation, for example when a Reposit is completed.
          </P1>
          <P1>
            This is useful for notifying recipients that don’t need a full Reposit account, for example a shared email inbox. If
            the recipient needs to create Reposits, you should <NavLink to={`/account/team`}>add them to your team</NavLink>.
          </P1>
        </SummaryText>
      ) : (
        <SummaryText>
          <P1>
            Add additional email addresses to receive tenancy management email notifications for all Reposits related to this
            organisation, for example when Reposit is coming to an end.
          </P1>
          <P1>
            This is useful for notifying recipients that don’t need a full Reposit account, for example a shared email inbox. If
            the recipient needs to manage Reposit tenancies, you should{' '}
            <NavLink to={`/account/team`}>add them to your team</NavLink>.
          </P1>
        </SummaryText>
      )}
      <TeamListWrapper>
        {sortBy(organizationRecipients, ['email']).map(or => (
          <OrganizationRecipientComponent key={or.id} data={or} />
        ))}
        <TeamListContainer>
          {isFormOpen ? (
            <AccountForm
              onSubmit={onSubmit}
              formActions={
                <Fragment>
                  <Link to="#" onClick={() => setIsFormOpen(false)}>
                    Cancel
                  </Link>
                  <Button disabled={isLoading}>Add email</Button>
                </Fragment>
              }
            />
          ) : (
            <Button buttonType="secondary" onClick={() => setIsFormOpen(true)}>
              Add someone to the list
            </Button>
          )}
        </TeamListContainer>
      </TeamListWrapper>
    </RepositCard>
  );
};

export default ManageRecipientGroups;
