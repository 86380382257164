import { OrganizationWithRepositsAndRolesRolesEnum } from '@reposit/api-client';
import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getBreakpoint } from '../../../base/style';
import Button from '../../../components/Button/index';
import ConfirmationModal from '../../../components/ConfirmationModal/index';
import { Header4, P2, StyledLink } from '../../../components/Typography/index';
import { getCurrentUserId } from '../../../redux/account/account.selectors';
import { getHasOrganizationRole } from '../../../redux/auth/auth.selectors';
import { OrganizationInviteEntity, UserEntity } from '../../../redux/entities/entities.types';
import { deleteInviteRequested, resendInviteRequested } from '../../../redux/invite/invite.actions';
import { deleteOrganizationUserRequested } from '../../../redux/organization-user/organization-user.actions';

const TeamMemberItem = styled.div<{ isOpen?: boolean }>`
  background: ${props => props.theme.colors.bgAccent};
  margin: 0 0 6px;
  padding: 0 24px 0;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    padding: ${props => (props.isOpen ? `34px` : `0 34px`)};
  }

  &:hover {
    background: ${props => (!props.isOpen ? props.theme.colors.bgHoverAccent : props.theme.colors.bgAccent)};
  }
`;

const TeamMemberItemInner = styled.li`
  border-radius: 8px;
  cursor: default;
  box-sizing: border-box;
  margin: 0 0 6px;
  padding: 12px 0;
  height: 96px;

  @media all and (min-width: ${getBreakpoint('md')}) {
    height: 112px;
  }

  @media all and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
    height: 48px;
    margin: 0 0 6px;
    padding: 0;
  }
`;

const TeamMember = styled.div`
  @media all and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
  }
`;

const Name = styled(Header4)`
  font-size: 1em;
  margin: 0;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    margin: 0 10px 0 0;
  }
`;

const Email = styled(P2)`
  margin: 0;
`;

const RemoveButton = styled(Button)`
  background: #ffe6e6;
  border-color: #ffe6e6;
  color: ${props => props.theme.colors.negative};

  &:hover {
    background: ${props => props.theme.colors.negative};
    border-color: ${props => props.theme.colors.negative};
    color: #ffe6e6;
  }
`;

const ResendInvite = styled(StyledLink)`
  font-size: 13px;
  margin: 0 12px 0 0;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    margin: 0 12px;
  }
`;

const Actions = styled.div`
  align-items: center;
  display: flex;
  margin: 12px 0 0;
  @media all and (min-width: ${getBreakpoint('lg')}) {
    margin: 0 0 0 auto;
  }
`;

interface TeamMemberProps {
  data: OrganizationInviteEntity | UserEntity;
}

const TeamMemberComponent: React.FC<TeamMemberProps> = props => {
  const currentUserId = useSelector(getCurrentUserId);
  const [showingResendModal, setShowingResendModal] = useState(false);
  const [showingDeleteModal, setShowingDeleteModal] = useState(false);
  const dispatch = useDispatch();
  const firstName = (props.data as UserEntity).firstName;
  const lastName = (props.data as UserEntity).lastName;

  const hasManageTeamRole = useSelector(getHasOrganizationRole(OrganizationWithRepositsAndRolesRolesEnum.MANAGETEAM));

  const isUser = firstName && lastName;
  const emailText = isUser ? props.data.email : `${props.data.email} (pending)`;

  const isCurrentUser = currentUserId === props.data.id;

  const handleResend = () => {
    dispatch(resendInviteRequested(props.data.id));
    setShowingResendModal(false);
  };
  const handleDismissResend = () => setShowingResendModal(false);

  const handleDelete = () => {
    if (isUser) {
      //delete orgUser
      const userId = props.data.id;
      dispatch(deleteOrganizationUserRequested(userId));
    } else {
      // delete invite
      const inviteId = props.data.id;
      dispatch(deleteInviteRequested(inviteId));
    }
    setShowingResendModal(false);
  };
  const handleDismissDelete = () => setShowingDeleteModal(false);
  const deleteTitle = isUser ? 'Are you sure you want to remove this user?' : 'Are you sure you want to remove this invite?';
  return (
    <Fragment>
      {showingResendModal && (
        <ConfirmationModal
          title="Are you sure you would like to resend this invite?"
          text={`It will send another email to ${props.data.email}.`}
          onDismiss={handleDismissResend}
          onSubmit={handleResend}
        />
      )}

      {showingDeleteModal && (
        <ConfirmationModal
          title={deleteTitle}
          text={`Once removed they will no longer be able to access this account or any associated Reposits or Claims`}
          onDismiss={handleDismissDelete}
          onSubmit={handleDelete}
        />
      )}
      <TeamMemberItem>
        <TeamMemberItemInner>
          <TeamMember>
            {isUser && <Name>{`${firstName} ${lastName}${isCurrentUser ? ' (me)' : ''}`}</Name>}
            <Email>{emailText}</Email>
          </TeamMember>
          <Actions>
            {!isUser && hasManageTeamRole && (
              <ResendInvite to="#" onClick={() => setShowingResendModal(true)}>
                Re-send Invite
              </ResendInvite>
            )}
            {!isCurrentUser && hasManageTeamRole && (
              <RemoveButton buttonType="tertiary" mini="true" onClick={() => setShowingDeleteModal(true)}>
                Remove
              </RemoveButton>
            )}
          </Actions>
        </TeamMemberItemInner>
      </TeamMemberItem>
    </Fragment>
  );
};

export default TeamMemberComponent;
