import { Formik, FormikProps } from 'formik';
import moment from 'moment';
import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';
import Button from '../../../../components/Button/index';
import DatePicker from '../../../../components/FormFields/DatePicker/index';
import FieldWithLabel from '../../../../components/FormFields/FieldWithLabel/index';
import Loading from '../../../../components/Loading';
import Modal from '../../../../components/Modal/index';
import { Header3, P2 } from '../../../../components/Typography/index';
import { createLoadingSelector } from '../../../../redux/loading/loading.selector';
import { extendRepositRequested, EXTEND_REPOSIT_STORE_KEY } from '../../../../redux/reposit/reposit.actions';
import { RepositActionModalProps } from '../index';
import TenancyDateActions from '../../../../components/TenancyDetails/TenancyDateActions';
import { getExpiryDate } from '../../../../utils/common.utils';
import { DATE_FORMAT } from '../../../../constants/dates';

const Action = styled.div`
  padding: 12px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

export interface ExtendRepositValues {
  endDate?: Date;
}

const Schema = (currentEndDate: Date) =>
  Yup.object().shape({
    endDate: Yup.date()
      .required('Required')
      .test('endDate', 'The end date cannot be more than 2 years in the future', function(value: Date) {
        const latestPossibleDate = moment().add(2, 'years');
        return moment(value).isBefore(latestPossibleDate);
      })
      .test('endDate', 'The new end date must be after the existing one', function(value: Date) {
        return moment(value).isAfter(currentEndDate);
      })
  });

const RepositExtendModal: React.SFC<RepositActionModalProps> = ({ tenancyOrder, onDismiss }) => {
  const dispatch = useDispatch();
  const extendRepositLoadingSelector = createLoadingSelector([EXTEND_REPOSIT_STORE_KEY]);
  const isLoading = useSelector(extendRepositLoadingSelector);

  const currentEndDate = tenancyOrder.tenancy.expiryDates.length && getExpiryDate(tenancyOrder.tenancy.expiryDates).endDate;

  const handleSubmit = (values: ExtendRepositValues) => {
    dispatch(
      extendRepositRequested({
        tenancyId: tenancyOrder.tenancy.id,
        tenancyOrderId: tenancyOrder.id,
        endDate: moment(values.endDate).format('YYYY-MM-DD')
      })
    );
  };

  const dataPickerRef = useRef<any>(null);

  const maxDate = moment()
    .startOf('day')
    .add(2, 'years');
  const minDateFromCurrentEndDate = moment(currentEndDate)
    .startOf('day')
    .add(1, 'day');
  // taking the lower of the current end date +1 day or the max date as need to check the max date isn't 2 years in the future
  const minDate = moment.min(minDateFromCurrentEndDate, maxDate);
  const extendable = moment(currentEndDate)
    .startOf('day')
    .isBefore(maxDate);

  const content = (
    <Formik
      initialValues={{ endDate: undefined }}
      validationSchema={Schema(moment(currentEndDate).toDate())}
      onSubmit={handleSubmit}
    >
      {(props: FormikProps<ExtendRepositValues>) => {
        return extendable ? (
          <form onSubmit={props.handleSubmit}>
            <Header3 style={{ marginBottom: 6 }}>Are you sure you wish to extend this Reposit?</Header3>
            <P2>
              The current end date is <span style={{ fontWeight: 'bold' }}>{moment(currentEndDate).format('Do MMM YYYY')}</span>
            </P2>
            <P2>Please select a new end date</P2>
            <FieldWithLabel
              label="End date"
              style={{ marginBottom: 24 }}
              error={props.errors.endDate}
              touched={props.touched.endDate}
            >
              <DatePicker
                name="end_date"
                value={props.values.endDate}
                onBlur={props.handleBlur}
                dateFormat={DATE_FORMAT}
                touched={props.touched.endDate}
                error={props.errors.endDate}
                forwardedRef={dataPickerRef}
                children={
                  <TenancyDateActions
                    initialDate={moment(currentEndDate).toDate()}
                    onDateSelect={date => {
                      dataPickerRef.current.setOpen(false);
                      props.setFieldValue('endDate', date);
                    }}
                  />
                }
                placeholder={'DD/MM/YYYY'}
                minDate={minDate.toDate()}
                maxDate={maxDate.toDate()}
                onChange={date => props.setFieldValue('endDate', date)}
              />
            </FieldWithLabel>
            <Action>
              <Button buttonType="secondary" noArrow={true} onClick={onDismiss}>
                No, return to form
              </Button>
              <Button type="submit">Yes</Button>
            </Action>
          </form>
        ) : (
          <>
            <Header3 style={{ marginBottom: 6 }}>Reposit cannot be extended</Header3>
            <P2>
              The current end date is <span style={{ fontWeight: 'bold' }}>{moment(currentEndDate).format('Do MMM YYYY')}</span>.
              The end date cannot be more than two years in the future
            </P2>
            <Action>
              <Button buttonType="secondary" noArrow={true} onClick={onDismiss}>
                Dismiss
              </Button>
            </Action>
          </>
        );
      }}
    </Formik>
  );

  return <Modal onDismiss={onDismiss}>{isLoading ? <Loading /> : content}</Modal>;
};

export default RepositExtendModal;
