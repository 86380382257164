import { OrganizationsApi, AddressDTO, AddressesApi, ExternalAddressDTO } from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import SCHEMA from '../schema';
import { createOrganizationsApi, createAddressesApi, runSagaWithAuth } from '../utils/api.utils';
import {
  fetchOrganizationAddressesFailed,
  fetchOrganizationAddressesSuccess,
  FETCH_ORGANIZATION_ADDRESSES_API_REQUESTED,
  AddressesActionTypes,
  searchAddressesSuccess,
  searchAddressesFailed,
  SEARCH_ADDRESSES_API_REQUESTED,
  setIsShowingSearchModal,
  SEARCH_ADDRESSES_STORE_KEY
} from './address.actions';
import { FlashMessagesActionTypes, setFlashMessage } from '../flash-messages/flash-messages.actions';
import { FlashState } from '../../components/FlashMessage/index';
import { getCurrentOrganizationId } from '../auth/auth.selectors';

// ****************
// WORKERS
// ****************

export function* fetchAddressesForOrganization() {
  try {
    const organizationId: string = yield select(getCurrentOrganizationId);
    const organizationsApi: OrganizationsApi = yield createOrganizationsApi();
    const apiResponse: AxiosResponse<AddressDTO[]> = yield call(
      runSagaWithAuth(() => organizationsApi.fetchAddressesByOrganizationId(organizationId))
    );
    const ids: string[] = yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.addresses);
    yield put<AddressesActionTypes>(fetchOrganizationAddressesSuccess(ids));
  } catch (e) {
    yield put<AddressesActionTypes>(fetchOrganizationAddressesFailed(get(e, 'response.data.message', e)));
  }
}

export function* searchAddressesByPostcode({ payload }: { type: string; payload: string }) {
  try {
    const addressesApi: AddressesApi = yield createAddressesApi();
    const apiResponse: AxiosResponse<ExternalAddressDTO[]> = yield call(
      runSagaWithAuth(() => addressesApi.searchAddressesByPostcode(payload))
    );
    yield put<AddressesActionTypes>(searchAddressesSuccess(apiResponse.data));
    yield put<AddressesActionTypes>(setIsShowingSearchModal(true));
    if (apiResponse.data && !apiResponse.data.length) {
      yield put<FlashMessagesActionTypes>(
        setFlashMessage({
          key: SEARCH_ADDRESSES_STORE_KEY,
          message: 'No addresses were found for this postcode.',
          state: FlashState.WARNING
        })
      );
    }
  } catch (e) {
    yield put<AddressesActionTypes>(searchAddressesFailed(get(e, 'response.data.message', e)));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchAddressesSagas() {
  yield takeLatest(FETCH_ORGANIZATION_ADDRESSES_API_REQUESTED, fetchAddressesForOrganization);
  yield takeLatest(SEARCH_ADDRESSES_API_REQUESTED, searchAddressesByPostcode);
}
