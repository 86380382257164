import { Location } from 'history';
import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import AssignmentIcon from '../../../assets/svg/onboarding/assignment.svg';
import ChecklistIconActive from '../../../assets/svg/onboarding/checklist-purple.svg';
import ChecklistIcon from '../../../assets/svg/onboarding/checklist.svg';
import CompanyIconActive from '../../../assets/svg/onboarding/company-purple.svg';
import CompanyIcon from '../../../assets/svg/onboarding/company.svg';
import TenancyDetailsIconActive from '../../../assets/svg/onboarding/tenancy-details-purple.svg';
import TenancyDetailsIcon from '../../../assets/svg/onboarding/tenancy-details.svg';
import WelcomeIconActive from '../../../assets/svg/onboarding/waving-hand.svg';
import YouIconActive from '../../../assets/svg/onboarding/you-purple.svg';
import YouIcon from '../../../assets/svg/onboarding/you.svg';
import TickIcon from '../../../assets/svg/tick-positive.svg';
import { useIsOrganizationLandlord } from '../../../hooks/useIsLandlord';
import {
  getIsBankAccountsConfirmed,
  getIsInformedLandlordsComplete,
  getIsLandlordInformationCompleted,
  getIsUpdatedASTComplete,
  getIsWelcomeLandlordCompleted,
  getIsYourCompanyComplete,
  getIsAcceptedTermsComplete
} from '../../../redux/selectors/organization.selectors';
import { getIsAboutYouComplete } from '../../../redux/selectors/user.selectors';
import { Header4, P2 } from '../../Typography/index';

interface OnboardingSidebarProps {
  hasOnboardingRole: boolean;
  currentLocation: Location<any>;
}

interface ItemProps {
  active?: boolean;
  icon?: string;
  activeIcon?: string;
  isComplete?: boolean;
}

const Sidebar = styled.div`
  padding: 0 0 36px;
`;

const SidebarList = styled.ul`
  margin: 0;
  padding: 0;
`;

const SidebarListItem = styled.ul`
  margin: 16px 0;
`;

const Item = styled(P2)<ItemProps>`
  align-items: center;
  border-radius: 6px;
  box-shadow: ${props => (props.active ? '0 2px 3px 0 rgba(0, 0, 0, 0.1)' : 'none')};
  display: inline-flex;
  background: ${props => (props.active ? '#fff' : 'none')};
  margin: 0;
  padding: 3px 12px;

  &:before {
    background: transparent url(${props => (props.isComplete ? TickIcon : props.active ? props.activeIcon : props.icon)})
      no-repeat center center;
    background-size: ${props => (props.isComplete ? '14px' : '12px')};
    content: '';
    display: block;
    margin: 0 10px 0 0;
    opacity: ${props => (props.active ? 1 : 0.5)};
    height: 12px;
    width: 12px;
  }
`;

const AboutYouItem = styled(Item)`
  &:before {
    background-size: ${props => (props.isComplete ? '12px' : '8px')};
  }
`;

const welcomeLandlordLocation = '/account/get-started/welcome';
const aboutYouLocation = '/account/get-started/about-you';
const companyLocation = '/account/get-started/your-company';
const astLocation = '/account/get-started/update-your-ast';
const usefulDocumentsLocation = '/account/get-started/useful-documents';
const termsLocation = '/account/get-started/terms-and-conditions';
const tenancyGuideLocation = '/account/get-started/tenancy-guide';
const bankAccountsLocation = '/account/get-started/bank-accounts';

const OnboardingSidebar: React.SFC<OnboardingSidebarProps> = props => {
  const isWelcomeLandlordCompleted = useSelector(getIsWelcomeLandlordCompleted);
  const isAboutYouComplete = useSelector(getIsAboutYouComplete);
  const isYourCompanyComplete = useSelector(getIsYourCompanyComplete);
  const isUpdatedASTComplete = useSelector(getIsUpdatedASTComplete);
  const isUsefulDocumentsComplete = useSelector(getIsInformedLandlordsComplete);
  const isAcceptedTermsComplete = useSelector(getIsAcceptedTermsComplete);
  const isLandlordInformationCompleted = useSelector(getIsLandlordInformationCompleted);
  const isBankAccountsConfirmed = useSelector(getIsBankAccountsConfirmed);

  const isOrgLandlord = useIsOrganizationLandlord();

  return (
    <Sidebar>
      <Header4>Your progress:</Header4>
      <SidebarList>
        {props.hasOnboardingRole && isOrgLandlord ? (
          <SidebarListItem>
            <Item
              icon={WelcomeIconActive}
              active={props.currentLocation.pathname === welcomeLandlordLocation}
              isComplete={isWelcomeLandlordCompleted}
              activeIcon={WelcomeIconActive}
            >
              Welcome
            </Item>
          </SidebarListItem>
        ) : null}
        <SidebarListItem>
          <AboutYouItem
            active={props.currentLocation.pathname === aboutYouLocation}
            icon={YouIcon}
            activeIcon={YouIconActive}
            isComplete={isAboutYouComplete}
          >
            About You
          </AboutYouItem>
        </SidebarListItem>
        {props.hasOnboardingRole && !isOrgLandlord ? (
          <SidebarListItem>
            <Item
              icon={CompanyIcon}
              active={props.currentLocation.pathname === companyLocation}
              isComplete={isYourCompanyComplete}
              activeIcon={CompanyIconActive}
            >
              Your Company
            </Item>
          </SidebarListItem>
        ) : null}
        {props.hasOnboardingRole ? (
          <SidebarListItem>
            <Item
              icon={ChecklistIcon}
              active={props.currentLocation.pathname === astLocation}
              activeIcon={ChecklistIconActive}
              isComplete={isUpdatedASTComplete}
            >
              Update Your AST
            </Item>
          </SidebarListItem>
        ) : null}
        {props.hasOnboardingRole ? (
          <SidebarListItem>
            <Item
              icon={ChecklistIcon}
              active={props.currentLocation.pathname === bankAccountsLocation}
              activeIcon={ChecklistIconActive}
              isComplete={isBankAccountsConfirmed}
            >
              Bank Accounts
            </Item>
          </SidebarListItem>
        ) : null}
        {props.hasOnboardingRole ? (
          <SidebarListItem>
            <Item
              icon={ChecklistIcon}
              active={props.currentLocation.pathname === usefulDocumentsLocation}
              activeIcon={ChecklistIconActive}
              isComplete={isUsefulDocumentsComplete}
            >
              Useful Documents
            </Item>
          </SidebarListItem>
        ) : null}
        {props.hasOnboardingRole && isOrgLandlord ? (
          <SidebarListItem>
            <Item
              icon={AssignmentIcon}
              active={props.currentLocation.pathname === tenancyGuideLocation}
              activeIcon={AssignmentIcon}
              isComplete={isLandlordInformationCompleted}
            >
              Tenancy Guide
            </Item>
          </SidebarListItem>
        ) : null}
        {props.hasOnboardingRole ? (
          <SidebarListItem>
            <Item
              icon={TenancyDetailsIcon}
              active={props.currentLocation.pathname === termsLocation}
              activeIcon={TenancyDetailsIconActive}
              isComplete={isAcceptedTermsComplete}
            >
              Terms &amp; Conditions
            </Item>
          </SidebarListItem>
        ) : null}
      </SidebarList>
    </Sidebar>
  );
};

export default OnboardingSidebar;
