export const PRIMARY_PURPLE = '#3A315E';

export const RepositTheme = {
  colors: {
    body: PRIMARY_PURPLE,
    body2: '#665E83',
    primaryBrand: PRIMARY_PURPLE,
    secondaryBrand: '#665E83',
    tertiaryBrand: '#918BA8',
    placeholder: '#918BA8',
    disabled: '#cccccc',
    inactive: '#dfe4ed',
    positive: '#40C9A2',
    negative: '#E83151',
    warning: '#FF5A1F',
    bgAccent: '#FAF9FC',
    bgHoverAccent: '#E7E4F1',
    dividerLight: '#eeeeee',
    dividerDark: '#dddddd'
  },
  typography: {
    face: {
      primary: `"Poppins", sans-serif`,
      secondary: `"SourceSansPro", sans-serif`
    }
  }
};

//
