import { CreateAddressWithOrgDTO, ExternalAddressDTO } from '@reposit/api-client';
import { Formik, FormikProps } from 'formik';
import React, { Fragment, useEffect, useState, MutableRefObject } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { getBreakpoint } from '../../base/style';
import { AddressEntity } from '../../redux/entities/entities.types';
import { AddressForm } from '../../redux/reposit/reposit.actions';
import { UK_POSTCODE } from '../../utils/regex/address.regex';
import AddressList from '../AddressList/index';
import Button from '../Button';
import FieldWithLabel from '../FormFields/FieldWithLabel/index';
import { Input } from '../FormFields/index';
import Select from '../FormFields/Select/index';
import SelectAddressModal from '../SelectAddressModal/index';
import { P2, StyledLink } from '../Typography/index';
import { scroller } from 'react-scroll';

const AddressLookupWrapper = styled.div`
  @media screen and (min-width: ${getBreakpoint('lg')}) {
    display: flex;
    align-items: flex-end;
  }
`;

const Field = styled.div`
  flex: 0 0 260px;
  margin: 12px 20px 0 0;
`;

const Action = styled.div`
  padding: 14px 0 6px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    padding: 0 0 10px;
  }
`;

const ManualLink = styled(StyledLink)`
  display: inline;
  margin: 0 6px;
`;

const AddressFormContainer = styled.div`
  margin: 12px 0 0;
  max-width: 65%;
`;

const PostcodeForm = ({
  searchAddresses,
  isSearchAddressesLoading
}: {
  searchAddresses: (postcode: string) => void;
  isSearchAddressesLoading: boolean;
}) => {
  const [postcode, setPostcode] = useState('');
  const onSubmit = () => {
    searchAddresses(postcode);
  };
  return (
    <AddressLookupWrapper>
      <Field>
        <FieldWithLabel label="Postcode">
          <Input
            name="postcode"
            placeholder="e.g. EC1M 3HH"
            value={postcode}
            onChange={e => setPostcode(e.target.value)}
            onBlur={() => {}}
          />
        </FieldWithLabel>
      </Field>
      <Action>
        <Button disabled={!postcode} buttonType="secondary" type="button" onClick={onSubmit}>
          {isSearchAddressesLoading ? 'Loading' : 'Search Postcode'}
        </Button>
      </Action>
    </AddressLookupWrapper>
  );
};

const Link = styled(StyledLink)`
  font-size: 12px;
  margin: 0;
`;

const FormActions = styled.div`
  align-items: center;
  border-top: 1px solid ${props => props.theme.colors.dividerDark};
  display: flex;
  justify-content: flex-end;
  margin: 36px 0 0;
  padding: 22px 0 0;
`;

const PostcodeLinkWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 6px 0 0;
`;

interface AddressLookupProps {
  onCancel: () => void;
  fetchOrganizationAddresses: () => void;
  currentOrgId: string;
  currentOrganizationAddresses?: (AddressEntity)[];
  selectAddressId: (id?: string) => void;
  addressId?: string;
  searchAddresses: (postcode: string) => void;
  isShowingSearchModal: boolean;
  searchedAddresses?: ExternalAddressDTO[];
  setShowingSearchModal: (value: boolean) => void;
  currentAddressForm: AddressForm;
  setCurrentAddressForm: (value: AddressForm) => void;
  isSearchAddressesLoading: boolean;
  createManualAddress: (address: CreateAddressWithOrgDTO) => void;
  landlordRef: MutableRefObject<HTMLDivElement | null>;
}

const Schema = Yup.object().shape(
  {
    buildingNumber: Yup.string().when('buildingName', {
      is: buildingName => !buildingName,
      then: Yup.string().required('Either a building number or name is required'),
      otherwise: Yup.string()
    }),
    buildingName: Yup.string().when('buildingNumber', {
      is: buildingNumber => {
        return !buildingNumber;
      },
      then: Yup.string().required('Either a building number or name is required'),
      otherwise: Yup.string()
    }),
    street: Yup.string().required('Required'),
    postcode: Yup.string()
      .matches(UK_POSTCODE, 'Must be a UK postcode')
      .required('Required'),
    country: Yup.string().required('Required')
  },
  [['buildingNumber', 'buildingName']]
);

export interface AddressFormValues {
  buildingNumber: string;
  street: string;
  postcode: string;
  country: string;
  roomNumber?: string;
  flatNumber?: string;
  buildingName?: string;
  town?: string;
  county?: string;
  externalId?: string;
}

const AddressManualEntry = ({
  showAddressForm,
  createManualAddress,
  currentOrgId,
  manualDefaultValues,
  landlordRef
}: {
  showAddressForm: (value: AddressForm) => void;
  createManualAddress: (address: CreateAddressWithOrgDTO) => void;
  currentOrgId: string;
  manualDefaultValues?: ExternalAddressDTO;
  landlordRef: MutableRefObject<HTMLDivElement | null>;
}) => {
  return (
    <Formik
      initialValues={
        manualDefaultValues
          ? manualDefaultValues
          : { buildingNumber: '', buildingName: '', street: '', postcode: '', country: 'GBR', externalId: '' }
      }
      validationSchema={Schema}
      onSubmit={address => {
        createManualAddress({ ...address, buildingNumber: `${address.buildingNumber}`, organizationId: currentOrgId });
        // This scrolls to the correct place even when the above Redux action fails
        if (landlordRef.current) {
          scroller.scrollTo(landlordRef.current.className, {
            duration: 500,
            smooth: true
          });
        }
      }}
    >
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }: FormikProps<AddressFormValues>) => {
        const hasChanged = JSON.stringify(manualDefaultValues) !== JSON.stringify(values);

        if (hasChanged && values.externalId !== '') {
          setFieldValue('externalId', '');
        }

        return (
          <form onSubmit={handleSubmit}>
            <AddressFormContainer>
              <FieldWithLabel label="Room Number / Letter" touched={touched.roomNumber} error={errors.roomNumber}>
                <Input
                  name="roomNumber"
                  value={values.roomNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.roomNumber}
                  error={errors.roomNumber}
                />
              </FieldWithLabel>
              <FieldWithLabel label="Flat Number" touched={touched.flatNumber} error={errors.flatNumber}>
                <Input
                  name="flatNumber"
                  value={values.flatNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.flatNumber}
                  error={errors.flatNumber}
                />
              </FieldWithLabel>
              <FieldWithLabel label="Building Number*" touched={touched.buildingNumber} error={errors.buildingNumber}>
                <Input
                  name="buildingNumber"
                  value={values.buildingNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.buildingNumber}
                  error={errors.buildingNumber}
                />
              </FieldWithLabel>
              <FieldWithLabel label="Building Name*" touched={touched.buildingName} error={errors.buildingName}>
                <Input
                  name="buildingName"
                  value={values.buildingName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.buildingName}
                  error={errors.buildingName}
                />
              </FieldWithLabel>
              <FieldWithLabel label="Street*" touched={touched.street} error={errors.street}>
                <Input
                  name="street"
                  value={values.street}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.street}
                  error={errors.street}
                />
              </FieldWithLabel>
              <FieldWithLabel label="Town" touched={touched.town} error={errors.town}>
                <Input
                  name="town"
                  value={values.town}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.town}
                  error={errors.town}
                />
              </FieldWithLabel>
              <FieldWithLabel label="County" touched={touched.county} error={errors.county}>
                <Input
                  name="county"
                  value={values.county}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.county}
                  error={errors.county}
                />
              </FieldWithLabel>
              <FieldWithLabel label="Postcode*" touched={touched.postcode} error={errors.postcode}>
                <Input
                  name="postcode"
                  value={values.postcode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.postcode}
                  error={errors.postcode}
                />
              </FieldWithLabel>
              <FieldWithLabel label="Country*" touched={touched.country} error={errors.country}>
                <Select name="country" value={values.country} onChange={handleChange} onBlur={handleBlur}>
                  <option value="GBR">United Kingdom</option>
                </Select>
              </FieldWithLabel>
            </AddressFormContainer>
            <FormActions>
              <Link to="#" onClick={() => showAddressForm(AddressForm.POSTCODE)} style={{ margin: '0 16px' }}>
                Cancel adding an address
              </Link>
              <Button>Save address</Button>
            </FormActions>
          </form>
        );
      }}
    </Formik>
  );
};

const Postcode = ({
  showAddressForm,
  searchAddresses,
  isSearchAddressesLoading,
  currentOrganizationAddresses
}: {
  showAddressForm: (value: AddressForm) => void;
  searchAddresses: (postcode: string) => void;
  isSearchAddressesLoading: boolean;
  currentOrganizationAddresses: AddressEntity[] | undefined;
}) => {
  return (
    <Fragment>
      <PostcodeForm searchAddresses={searchAddresses} isSearchAddressesLoading={isSearchAddressesLoading} />
      <PostcodeLinkWrapper>
        <P2 style={{ marginTop: 0, fontSize: '1em', marginBottom: 0 }}>
          or
          <ManualLink to="#" onClick={() => showAddressForm(AddressForm.FULL_ADDRESS)}>
            enter address manually
          </ManualLink>
        </P2>
        {currentOrganizationAddresses && currentOrganizationAddresses.length ? (
          <Link to="#" onClick={() => showAddressForm(AddressForm.ADDRESS_LIST)}>
            Cancel adding an address
          </Link>
        ) : null}
      </PostcodeLinkWrapper>
    </Fragment>
  );
};

const AddressLookup = (props: AddressLookupProps) => {
  const { currentOrganizationAddresses, setCurrentAddressForm, setShowingSearchModal, currentAddressForm } = props;
  const [manualDefaultValues, setManualDefaultValues] = useState<ExternalAddressDTO>();

  useEffect(() => {
    // form renders for the first time as ADDRESS_LIST
    // we want to manually change it to postcode if there are no addresses so they can create one
    if (currentAddressForm === AddressForm.ADDRESS_LIST && currentOrganizationAddresses && !currentOrganizationAddresses.length) {
      setCurrentAddressForm(AddressForm.POSTCODE);
    }
  }, [currentOrganizationAddresses, setCurrentAddressForm, currentAddressForm]);

  if (props.currentAddressForm === AddressForm.FULL_ADDRESS) {
    return (
      <AddressManualEntry
        {...props}
        showAddressForm={props.setCurrentAddressForm}
        manualDefaultValues={manualDefaultValues}
        landlordRef={props.landlordRef}
      />
    );
  }

  if (props.currentAddressForm === AddressForm.POSTCODE) {
    const handleSetManualDefaultValues = (address: ExternalAddressDTO) => {
      setManualDefaultValues(address);
      setCurrentAddressForm(AddressForm.FULL_ADDRESS);
      setShowingSearchModal(false);
    };

    return (
      <Fragment>
        {props.isShowingSearchModal && props.searchedAddresses && props.searchedAddresses.length ? (
          <SelectAddressModal
            addresses={props.searchedAddresses}
            onDismiss={() => props.setShowingSearchModal(false)}
            onSubmit={handleSetManualDefaultValues}
            onManualAddressSelect={() => {
              props.setShowingSearchModal(false);
              setCurrentAddressForm(AddressForm.FULL_ADDRESS);
            }}
          />
        ) : null}
        <Postcode
          showAddressForm={props.setCurrentAddressForm}
          searchAddresses={props.searchAddresses}
          isSearchAddressesLoading={props.isSearchAddressesLoading}
          currentOrganizationAddresses={currentOrganizationAddresses}
        />
      </Fragment>
    );
  }

  if (currentOrganizationAddresses && currentOrganizationAddresses.length) {
    return (
      <Fragment>
        <AddressList
          setAddressId={props.selectAddressId}
          addressId={props.addressId}
          currentOrganizationAddresses={currentOrganizationAddresses}
        />
        <P2 style={{ marginTop: 16, fontSize: '1em', marginBottom: 0 }}>
          Don't see your address?
          <ManualLink
            to="#"
            onClick={() => {
              props.selectAddressId('');
              props.setCurrentAddressForm(AddressForm.POSTCODE);
            }}
          >
            add a new address
          </ManualLink>
        </P2>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <PostcodeForm searchAddresses={props.searchAddresses} isSearchAddressesLoading={props.isSearchAddressesLoading} />
      <P2 style={{ marginTop: 12, fontSize: '1em', marginBottom: 0 }}>
        or
        <ManualLink
          to="#"
          onClick={() => {
            props.setCurrentAddressForm(AddressForm.POSTCODE);
          }}
        >
          add new address
        </ManualLink>
      </P2>
    </Fragment>
  );
};

export default AddressLookup;
