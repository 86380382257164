import {
  OrganizationBankAccountDTO,
  OrganizationBankAccountsApi,
  CreateOrganizationBankAccountDTO,
  OrganizationBankAccountDTOAccountTypeEnum,
  CreateOrganizationBankAccountDTOAccountTypeEnum
} from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { FlashState } from '../../components/FlashMessage/index';
import { getErrorMessage } from '../../utils/common.utils';
import { getCurrentOrganizationId } from '../auth/auth.selectors';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import { BankAccountEntity } from '../entities/entities.types';
import { FlashMessagesActionTypes, setFlashMessage } from '../flash-messages/flash-messages.actions';
import SCHEMA from '../schema';
import { createOrganizationBankAccountApi, runSagaWithAuth } from '../utils/api.utils';
import {
  createBankAccountFailed,
  createBankAccountRequested,
  createBankAccountSuccess,
  CREATE_BANK_ACCOUNT_STORE_KEY,
  deleteBankAccountFailed,
  deleteBankAccountRequested,
  deleteBankAccountSuccess,
  DELETE_BANK_ACCOUNT_STORE_KEY,
  getBankAccountsFailed,
  getBankAccountsRequested,
  getBankAccountsSuccess
} from './bank-account.actions';
import { getBankAccountById } from './bank-account.selectors';
import { fetchMeRequested } from '../account/account.actions';

export function* createBankAccount({ payload }: { payload: CreateOrganizationBankAccountDTO; type: string }) {
  const formattedAccountType =
    payload.accountType === CreateOrganizationBankAccountDTOAccountTypeEnum.DEFAULT ? 'primary' : 'claim';
  try {
    const organizationId: string = yield select(getCurrentOrganizationId);
    const bankAccountsApi: OrganizationBankAccountsApi = yield createOrganizationBankAccountApi();
    const { data }: AxiosResponse<OrganizationBankAccountDTO> = yield call(
      runSagaWithAuth(() => bankAccountsApi.create(organizationId, payload))
    );
    yield syncEntitiesAndGetResults(data, SCHEMA.bankAccount);
    yield put(createBankAccountSuccess(data.id));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: CREATE_BANK_ACCOUNT_STORE_KEY,
        message: `Success! You have added your ${formattedAccountType} bank account`,
        state: FlashState.SUCCESS
      })
    );
    yield put(fetchMeRequested());
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: CREATE_BANK_ACCOUNT_STORE_KEY,
        message: `${error} for ${formattedAccountType} account`,
        state: FlashState.ERROR
      })
    );
    yield put(createBankAccountFailed(error));
  }
}

export function* deleteBankAccount({ payload }: { payload: string; type: string }) {
  try {
    const organizationId: string = yield select(getCurrentOrganizationId);
    const bankAccountId = payload;
    const bankAccountsApi: OrganizationBankAccountsApi = yield createOrganizationBankAccountApi();
    yield call(runSagaWithAuth(() => bankAccountsApi.deleteBankAccount(organizationId, bankAccountId)));
    yield put(deleteBankAccountSuccess(bankAccountId));
    const bankAccount: BankAccountEntity = yield select(getBankAccountById(bankAccountId));
    const formattedAccountType =
      bankAccount.accountType === OrganizationBankAccountDTOAccountTypeEnum.DEFAULT ? 'primary' : 'claim';
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: DELETE_BANK_ACCOUNT_STORE_KEY,
        message: `Success! You have removed your ${formattedAccountType} bank account`,
        state: FlashState.SUCCESS
      })
    );
    yield put(fetchMeRequested());
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: DELETE_BANK_ACCOUNT_STORE_KEY,
        message: error,
        state: FlashState.ERROR
      })
    );
    yield put(deleteBankAccountFailed(error));
  }
}

export function* getBankAccounts() {
  try {
    const organizationId: string = yield select(getCurrentOrganizationId);
    const bankAccountsApi: OrganizationBankAccountsApi = yield createOrganizationBankAccountApi();
    const { data }: AxiosResponse<OrganizationBankAccountDTO[]> = yield call(
      runSagaWithAuth(() => bankAccountsApi.getBankAccounts(organizationId))
    );
    const ids: string[] = yield syncEntitiesAndGetResults(data, SCHEMA.bankAccounts);
    yield put(getBankAccountsSuccess(ids));
  } catch (e) {
    const error = getErrorMessage(e);
    yield put(getBankAccountsFailed(error));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchBankAccountsSagas() {
  yield takeLatest(createBankAccountRequested.type, createBankAccount);
  yield takeLatest(deleteBankAccountRequested.type, deleteBankAccount);
  yield takeLatest(getBankAccountsRequested.type, getBankAccounts);
}
