import React from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../../assets/svg/calendar-purple.svg';

interface DatePickerProps {
  onChange: (date: Date | null, event: React.SyntheticEvent<any, Event> | undefined) => void;
  onBlur: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  touched?: boolean;
  name: string;
  value?: Date;
  excludeDates?: Date[];
  minDate?: Date;
  maxDate?: Date;
  placeholder?: string;
  children?: JSX.Element | JSX.Element[];
  dateFormat?: string;
  forwardedRef?: any;
}

const DatePickerWrapper = styled.div<{ error?: string; touched?: boolean }>`
  position: relative;

  &:before {
    background: transparent url(${CalendarIcon}) center center;
    background-size: 14px;
    content: '';
    display: block;
    height: 14px;
    width: 14px;
    margin: 11px 14px;
    z-index: 999;
    position: absolute;
    right: 0;
    pointer-events: none;
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    border-top: none;
    border-bottom-color: #e0ecf7;
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    border-bottom-color: #aeaeae;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block;
  }

  .react-datepicker__header {
    background: ${props => props.theme.colors.bgAccent};
  }

  .react-datepicker__navigation--previous {
    border-right-color: ${props => props.theme.colors.primaryBrand};
    &:hover {
      border-right-color: ${props => props.theme.colors.secondaryBrand};
    }
  }

  .react-datepicker__navigation--next {
    border-left-color: ${props => props.theme.colors.primaryBrand};
    &:hover {
      border-left-color: ${props => props.theme.colors.secondaryBrand};
    }
  }

  .react-datepicker__current-month {
    color: ${props => props.theme.colors.primaryBrand};
  }
  .react-datepicker__day {
    color: ${props => props.theme.colors.primaryBrand};
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__day--selected,
  .react-datepicker__day--selected:hover {
    background: ${props => props.theme.colors.primaryBrand};
    color: white;
  }

  .react-datepicker__day--disabled {
    color: ${props => props.theme.colors.disabled};
  }

  input {
    background: #fff;
    border: 1px solid ${props => (props.error && props.touched ? props.theme.colors.negative : 'rgba(0, 0, 0, 0.1)')};
    border-radius: 3px;
    box-sizing: border-box;
    box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    color: ${props => props.theme.colors.body};
    height: 36px;
    padding: 0 20px;
    width: 100%;
    font-family: ${props => props.theme.typography.face.secondary};
    font-size: 1em;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.1px;
    outline: none;

    &:focus {
      box-shadow: inset 0 1px 5px 0 rgba(0, 0, 0, 0.15);
      border: solid 1px ${props => props.theme.colors.body};
    }
  }
`;

export default ({
  onChange,
  onBlur,
  value,
  dateFormat,
  name,
  error,
  touched,
  excludeDates,
  minDate,
  maxDate,
  placeholder,
  children,
  forwardedRef
}: DatePickerProps) => (
  <DatePickerWrapper error={error} touched={touched}>
    <DatePicker
      name={name}
      ref={forwardedRef}
      selected={value}
      onChange={onChange}
      autoComplete="off"
      dateFormat={dateFormat}
      onBlur={onBlur}
      excludeDates={excludeDates}
      minDate={minDate}
      maxDate={maxDate}
      placeholderText={placeholder}
      children={children}
    />
  </DatePickerWrapper>
);
