import React from 'react';
import styled from 'styled-components';
import DownArrow from '../../../assets/svg/down-purple.svg';

interface SelectProps {
  onChange: (option: string) => void;
  onBlur?: (option: string) => void;
  value: any;
  defaultValue?: any;
  name?: string;
}

const StyledSelectContainer = styled.div`
  border-radius: 18px;
  flex: 1 1 auto;
  transition: all 0.2s;
  position: relative;

  &:after {
    background: transparent url('${DownArrow}') 1px -1px;
    background-size:10px;
    content: "";
    display: "block";
    position: absolute;
    pointer-events: none;
    top: 13px;
    right: 1em;
    height: 10px;
    width: 10px;
  }

  &:hover {
    background: ${props => props.theme.colors.bgAccent};
  }
`;

const StyledSelect = styled.select`
  background: #fff;
  border: none;
  border-radius: 18px;
  border: solid 1px ${props => props.theme.colors.disabled};
  color: ${props => props.theme.colors.body};
  cursor: pointer;
  display: block;
  font-family: ${props => props.theme.typography.face.secondary};
  font-size: 14px;
  outline: none;
  padding: 0 1em;
  height: 36px;
  width: 100%;
  -webkit-appearance: none;
`;

const Select: React.SFC<SelectProps> = ({ onChange, onBlur, value, children, defaultValue, name }) => {
  return (
    <StyledSelectContainer>
      <StyledSelect
        name={name}
        onChange={e => onChange(e.currentTarget.value)}
        onBlur={onBlur ? e => onBlur(e.currentTarget.value) : undefined}
        value={value ? value : 0}
        defaultValue={defaultValue}
      >
        {children}
      </StyledSelect>
    </StyledSelectContainer>
  );
};

export default Select;
