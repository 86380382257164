import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import { Header1, P1, P2, Header3 } from '../Typography';
import Panel from '../Panel/index';
import styled from 'styled-components';
import InfoIcon from '../../assets/svg/info.svg';

interface CreateRepositHeader {
  isNew: boolean;
  isOrgLandlord: boolean;
  status?: string;
}

const Title = styled(Header3)`
  align-items: center;
  display: flex;

  &:before {
    background: transparent url(${InfoIcon}) center center;
    content: '';
    display: block;
    margin: 0 10px 0 0;
    height: 20px;
    width: 20px;
  }
`;

const CreateRepositHeader: React.SFC<CreateRepositHeader> = ({ isNew, status, isOrgLandlord }) => (
  <Container>
    {status && status !== 'DRAFT' ? null : (
      <Row>
        <Col sm={12}>
          <Header1>{isNew ? `Create a Reposit` : `Draft Reposit`}</Header1>
          <P1 style={{ marginBottom: '2.25rem' }}>
            OK, we just need you to complete the following sections before we can create your Reposit. You can come back and edit
            this draft at any time.
          </P1>
        </Col>
      </Row>
    )}
    {isNew && (
      <Row>
        <Col sm={12}>
          <Panel>
            <Title color="#fff">What's a Reposit?</Title>
            <P2 color="#fff">
              A Reposit is an alternative to a traditional tenancy deposit where the tenants pay one week's rent up front instead
              of a cash lump sum. Tenants remain liable for any end-of-tenancy charges and{' '}
              {isOrgLandlord ? 'you are' : 'the Landlord is'} covered by an insurance policy in the event that these charges
              cannot be collected.
            </P2>
          </Panel>
        </Col>
      </Row>
    )}
  </Container>
);

export default CreateRepositHeader;
