import { unwrapResult } from '@reduxjs/toolkit';
import { ClaimItemDTOTypeEnum } from '@reposit/api-client';
import { Formik, FormikProps, validateYupSchema, yupToFormErrors } from 'formik';
import { get, startCase, toLower } from 'lodash';
import numeral from 'numeral';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as Yup from 'yup';
import InfoIcon from '../../../assets/svg/info-alt.svg';
import poundIcon from '../../../assets/svg/pound-sterling.svg';
import { getBreakpoint } from '../../../base/style';
import Button from '../../../components/Button/index';
import { FlashState } from '../../../components/FlashMessage/index';
import FieldWithLabel from '../../../components/FormFields/FieldWithLabel/index';
import { Input } from '../../../components/FormFields/index';
import Select from '../../../components/FormFields/Select/index';
import { FileUpload, FileUploadProgress } from '../../../components/Library/FileUpload/FileUpload';
import SecondaryPanel from '../../../components/SecondaryPanel/index';
import { Header4, P2, P3 } from '../../../components/Typography/index';
import { NumberFormat } from '../../../constants/number-formats';
import { useAppDispatch } from '../../../index';
import { CREATE_CLAIM_ITEM_DOCUMENT_STORE_KEY } from '../../../redux/claim-item-document/claim-item-document.actions';
import {
  createClaimItemDocumentThunk,
  deleteClaimItemDocumentThunk
} from '../../../redux/claim-item-document/claim-item-document.thunk';
import { clearClaimItemDocumentIds } from '../../../redux/document/document.actions';
import { createDocumentThunk } from '../../../redux/document/document.thunk';
import { DocumentEntity } from '../../../redux/entities/entities.types';
import { setFlashMessage } from '../../../redux/flash-messages/flash-messages.actions';
import { AppState } from '../../../redux/root.reducer';
import { getClaimById } from '../../../redux/selectors/claim.selectors';
import {
  getClaimItemProposalByClaimItemIdAndProposalId,
  getFirstAgentProposal
} from '../../../redux/selectors/mediation.selectors';
import { twoDPRegex } from '../../../utils/regex/number.regex';
import { CLAIM_UPLOAD_INFO } from '../../../constants/claims';

const Field = styled.div`
  min-height: 86px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    max-width: 96%;
  }
`;

const FieldAlt = styled.div`
  min-height: 86px;

  @media screen and (min-width: ${getBreakpoint('lg')}) {
    float: right;
    max-width: 96%;
    width: 100%;
  }
`;

const Actions = styled.div`
  border-top: 1px solid ${props => props.theme.colors.dividerDark};
  padding: 24px 0 0;
  text-align: right;
`;

const InfoTitle = styled(Header4)`
  font-size: 16px;
  display: flex;
  margin: 0 0 6px;
  padding: 0;

  &:before {
    background: transparent url(${InfoIcon}) center center no-repeat;
    content: '';
    display: block;
    margin: 0 10px 0 0;
    height: 20px;
    width: 20px;
  }
`;

const BulletList = styled.ul`
  padding: 0 0 0 32px;
`;

export const BulletPoint = styled.li`
  color: ${props => (props.color ? props.color : props.theme.colors.body2)};
  font-family: ${props => props.theme.typography.face.secondary};
  font-size: 0.75em;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.006em;
  list-style-type: disc;
`;

export interface ClaimItemFormValues {
  description?: string;
  type?: ClaimItemDTOTypeEnum;
  amount?: number;
  documentIds?: (string | undefined)[];
}

export enum ComponentAction {
  EDIT = 'EDIT',
  CREATE = 'CREATE'
}

interface ClaimItemFormProps {
  onSubmit: (values: ClaimItemFormValues) => void;
  initialValues?: ClaimItemFormValues;
  formActions: JSX.Element;
  title?: string;
  claimItemDocs: (DocumentEntity)[];
  showDeleteButton: boolean;
  publishedAndEditable: boolean;
  action: ComponentAction;
  claimItemId?: string;
  onUploadComplete: (docs?: (DocumentEntity)[]) => void;
  onUploadStart: () => void;
  claimId: string;
}

const Schema = Yup.object().shape({
  description: Yup.string().required('Required'),
  type: Yup.string()
    .oneOf(Object.values(ClaimItemDTOTypeEnum))
    .required('Required'),
  amount: Yup.number()
    .when(['$publishedAndEditable', '$initialAmount'], (publishedAndEditable: boolean, initialAmount: number) => {
      if (publishedAndEditable) {
        return Yup.number()
          .max(
            initialAmount,
            `New claim item amount cannot exceed £${numeral(initialAmount).format(NumberFormat.THOUSANDS_PENCE)}`
          )
          .typeError('Amount must be a number')
          .moreThan(0, 'Amount has to be above £0')
          .required('Required')
          .test('two-decimals', 'Item amount must have a maximum of two decimal places', value => {
            return twoDPRegex.test(value);
          });
      }
    })
    .typeError('Amount must be a number')
    .moreThan(0, 'Amount has to be above £0')
    .required('Required')
    .test('two-decimals', 'Item amount must have a maximum of two decimal places', value => {
      return twoDPRegex.test(value);
    }),
  // documentIds: Yup.array(Yup.string()).when(['type', '$isUploadShowing'], {
  //   is: (type: string, isUploadShowing: boolean) => type === 'OTHER' && isUploadShowing,
  //   then: Yup.array(Yup.string()),
  //   otherwise: Yup.array(Yup.string()).required('Required')
  // })
  documentIds: Yup.array(Yup.string()).when(
    ['type', '$isUploadShowing', '$isMediationEnabled'],
    (type: string, isUploadShowing: boolean, isMediationEnabled: boolean) => {
      if (isUploadShowing && !isMediationEnabled) {
        return Yup.array(Yup.string()).required('Required');
      } else {
        return Yup.array(Yup.string());
      }
    }
  )
});

const typeOptions = Object.values(ClaimItemDTOTypeEnum).map(value => ({ label: startCase(toLower(value)), value }));

interface InternalFormProps {
  formikProps: FormikProps<ClaimItemFormValues>;
  title: string;
  documents: (DocumentEntity)[];
  formActions: JSX.Element;
  showDeleteButton: boolean;
  publishedAndEditable: boolean;
  addDocuments: (docs: DocumentEntity[]) => void;
  removeDocumentById: (id: string) => void;
  action: ComponentAction;
  claimItemId?: string;
  onUploadComplete: (docs?: (DocumentEntity)[]) => void;
  onUploadStart: () => void;
  isUploadShowing: boolean;
  setIsUploadShowing: (value: boolean) => void;
  claimItemProposalId: string;
  isMediationEnabled: boolean;
}

const Notifier = styled(P3)`
  cursor: pointer;
  text-decoration: underline;
  margin-top: 10px;
  display: inline-block;
`;

const UploadInfo = () => (
  <Notifier data-tip={CLAIM_UPLOAD_INFO} style={{ paddingLeft: 21 }}>
    What can I upload?
  </Notifier>
);

export const getEvidenceInformation = (type: ClaimItemDTOTypeEnum | undefined) => {
  let content;

  const informationShareWarning: JSX.Element = <BulletPoint>Your evidence will be shared with the tenant(s).</BulletPoint>;

  switch (type) {
    case ClaimItemDTOTypeEnum.DAMAGE:
      content = (
        <Fragment>
          <InfoTitle>Damages Evidence</InfoTitle>
          <BulletList>
            <BulletPoint>
              Upload any invoices or receipts for remedial work carried out. If the work has not yet been completed, you will need
              to upload a quote for the cost of work.
            </BulletPoint>

            {informationShareWarning}
          </BulletList>
          <UploadInfo />
        </Fragment>
      );
      break;
    case ClaimItemDTOTypeEnum.CLEANING:
      content = (
        <Fragment>
          <InfoTitle>Cleaning Evidence</InfoTitle>
          <BulletList>
            <BulletPoint>
              Upload invoices or receipts relating to the cleaning carried out. If cleaning has not yet been completed, you will
              need to upload a quote for the cost of work.
            </BulletPoint>
            {informationShareWarning}
          </BulletList>
          <UploadInfo />
        </Fragment>
      );
      break;
    case ClaimItemDTOTypeEnum.ITEMREMOVAL:
      content = (
        <Fragment>
          <InfoTitle>Item Removal Evidence</InfoTitle>
          <BulletList>
            <BulletPoint>
              Upload invoices or receipts relating to the item removal. In the case where this has not yet been completed, you
              will need to upload a quote for the cost of work.
            </BulletPoint>
            {informationShareWarning}
          </BulletList>
          <UploadInfo />
        </Fragment>
      );
      break;
    case ClaimItemDTOTypeEnum.RENTARREARS:
      content = (
        <Fragment>
          <InfoTitle>Rent Arrears Evidence</InfoTitle>
          <BulletList>
            <BulletPoint>
              Upload a full rent statement from the start of the tenancy to the end. This must show the full period of the tenancy
              including the periods of missed payments.
            </BulletPoint>
            <BulletPoint>Upload all evidence of chasers sent to the tenant and guarantor where applicable.</BulletPoint>
            <BulletPoint>If re-let fees apply, these must be evidenced.</BulletPoint>
            {informationShareWarning}
          </BulletList>
          <UploadInfo />
        </Fragment>
      );
      break;
    case ClaimItemDTOTypeEnum.OTHER:
      content = (
        <Fragment>
          <InfoTitle>Evidence For Other Items</InfoTitle>
          <BulletList>
            <BulletPoint>
              For any additional costs being raised against the tenant, these must be evidenced within the standard tenancy
              documents or checkout report.
            </BulletPoint>
            <BulletPoint>Upload any corresponding invoices, receipts or quotes to show the cost of work.</BulletPoint>
            <BulletPoint>Upload any communication with the tenant outlining these additional costs.</BulletPoint>
            {informationShareWarning}
          </BulletList>
          <UploadInfo />
        </Fragment>
      );
      break;

    default:
  }

  return (
    <SecondaryPanel style={{ background: 'white', margin: '12px 0 16px', padding: '20px 20px 12px' }}>{content}</SecondaryPanel>
  );
};

const InternalForm: React.FC<InternalFormProps> = ({
  title,
  documents,
  formActions,
  showDeleteButton,
  publishedAndEditable,
  formikProps: { values, handleChange, handleSubmit, handleBlur, touched, errors, setFieldValue, validateForm },
  addDocuments,
  removeDocumentById,
  action,
  claimItemId,
  onUploadComplete,
  onUploadStart,
  isUploadShowing,
  setIsUploadShowing,
  claimItemProposalId,
  isMediationEnabled
}: InternalFormProps) => {
  useEffect(() => {
    validateForm(values);
  }, [documents, validateForm, values]);
  const [fileUploadProgress, setFileUploadProgress] = useState<FileUploadProgress | undefined>();

  const dispatch = useAppDispatch();

  const onFileError = (error: string) =>
    dispatch(setFlashMessage({ key: CREATE_CLAIM_ITEM_DOCUMENT_STORE_KEY, message: error, state: FlashState.ERROR }));

  const hasType = !!values.type;

  const showUpload = hasType && isUploadShowing;

  const handleDeleteDocument = async (id: string) => {
    if (action === ComponentAction.CREATE) {
      // state removal
      return removeDocumentById(id);
    } else {
      // database removal
      await dispatch(deleteClaimItemDocumentThunk(id));
    }
  };

  const handleUploadDocument = async (file: File) => {
    if (action === ComponentAction.CREATE) {
      // create a base doc here
      const doc = await dispatch(createDocumentThunk({ file, isClaimItem: true, type: 'CLAIM_ITEM' })).then(unwrapResult);
      return doc;
    } else {
      // create a claim item doc
      const doc = await dispatch(
        createClaimItemDocumentThunk({ file, claimItemId: claimItemId as string, claimItemProposalId })
      ).then(unwrapResult);
      return doc;
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <P2>{title}</P2>
      <Container fluid>
        <Row>
          <Col lg={12} style={{ padding: '0 0 12px' }}>
            <FieldWithLabel label="Description" touched={touched.description} error={errors.description}>
              <Input
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                name="description"
                touched={touched.description}
                error={errors.description}
              />
            </FieldWithLabel>
          </Col>
        </Row>
        <Row>
          <Col lg={6} style={{ padding: 0 }}>
            <Field>
              <FieldWithLabel label="Claim Type" touched={touched.type} error={errors.type}>
                <Select onChange={value => setFieldValue('type', value)} value={values.type || 0}>
                  <option value={''}>Please select an item type</option>
                  {typeOptions.map(option => {
                    return (
                      <option value={option.value} key={option.value}>
                        {option.label}
                      </option>
                    );
                  })}
                </Select>
              </FieldWithLabel>
            </Field>
          </Col>
          <Col lg={6} style={{ padding: 0 }}>
            <FieldAlt>
              <FieldWithLabel label="Amount" touched={touched.amount} error={errors.amount}>
                <Input
                  name="amount"
                  value={`${values.amount}`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  icon={poundIcon}
                  error={errors.amount}
                  touched={touched.amount}
                />
              </FieldWithLabel>
            </FieldAlt>
          </Col>
        </Row>
        {/* SHOW IN CLAIM LITE */}
        {hasType && isMediationEnabled ? (
          <Row style={{ marginBottom: 20, alignItems: 'center' }}>
            <Button
              noArrow
              type="button"
              buttonType="secondary"
              onClick={() => setIsUploadShowing(!isUploadShowing)}
              mini="oh, yes!"
            >
              {showUpload ? 'Hide' : 'Upload Evidence'}
            </Button>
            {showUpload ? null : <P3 style={{ marginBottom: 0, marginLeft: 5 }}>(optional)</P3>}
          </Row>
        ) : null}

        {showUpload ? (
          <Row>
            <Col lg={12} style={{ padding: '6px 0 0' }}>
              <FieldWithLabel
                label="Evidence"
                touched={touched.description && touched.amount && touched.type}
                error={errors.documentIds}
                description={
                  // publishedAndEditable && values.type !== ClaimItemDTOTypeEnum.OTHER
                  // ? 'You must have at least one piece of evidence per claim item'
                  // :  : 'You need to upload evidence to support this claim'
                  ''
                }
              >
                {getEvidenceInformation(values.type)}

                <FileUpload
                  big
                  required={!isMediationEnabled && values.type !== ClaimItemDTOTypeEnum.OTHER}
                  documents={documents}
                  onError={onFileError}
                  uploadFile={async (file: File) => {
                    const doc = await handleUploadDocument(file);
                    addDocuments([doc]);
                    return doc;
                  }}
                  deleteFile={async id => {
                    return handleDeleteDocument(id);
                  }}
                  readOnly={false}
                  showDeleteButton={showDeleteButton}
                  onUploadComplete={onUploadComplete}
                  onUploadStart={onUploadStart}
                  fileUploadProgress={fileUploadProgress}
                  setFileUploadProgress={setFileUploadProgress}
                />
              </FieldWithLabel>
            </Col>
          </Row>
        ) : (
          undefined
        )}
        <Row>
          <Col lg={12} style={{ padding: 0 }}>
            <Actions>{formActions}</Actions>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

const ClaimItemForm: React.FC<ClaimItemFormProps> = ({
  onSubmit,
  initialValues,
  formActions,
  title,
  claimItemDocs,
  showDeleteButton,
  publishedAndEditable,
  action,
  claimItemId,
  onUploadComplete,
  onUploadStart,
  claimId
}) => {
  const [localDocuments, setLocalDocuments] = useState<DocumentEntity[]>([]);
  const claim = useSelector((state: AppState) => getClaimById(state, claimId));
  const isMediationEnabled = get(claim, 'mediationEnabled', false);
  const [isUploadShowing, setIsUploadShowing] = useState(!isMediationEnabled);
  const documents = action === ComponentAction.EDIT ? claimItemDocs : localDocuments;
  const firstAgentProposal = useSelector((state: AppState) => getFirstAgentProposal(state, claimId));

  const firstAgentProposalId = get(firstAgentProposal, 'id', '');

  const claimItemProposal = useSelector((state: AppState) =>
    getClaimItemProposalByClaimItemIdAndProposalId(state, claimItemId as string, firstAgentProposalId)
  );

  const claimItemProposalId = get(claimItemProposal, 'proposal.id', '');

  const dispatch = useDispatch();
  useEffect(() => {
    return function cleanup() {
      dispatch(clearClaimItemDocumentIds());
    };
  }, [dispatch]);

  const initValues = {
    description: (initialValues && initialValues.description) || '',
    amount: (initialValues ? initialValues.amount : '') as number,
    type: initialValues && initialValues.type
  };

  return (
    <Formik
      initialValues={initValues}
      onSubmit={formValues => {
        const amount = formValues.amount && Math.round(formValues.amount * 100);
        onSubmit({
          ...formValues,
          amount
        });
      }}
      validate={async values => {
        if (documents && documents.length) {
          values.documentIds = documents.map(doc => doc && doc.id);
        } else {
          // need this for when items are added and then removed
          // values.documentIds stays in state other wise
          values.documentIds = [];
        }

        try {
          await validateYupSchema(values, Schema, true, {
            publishedAndEditable,
            initialAmount: initValues.amount,
            isUploadShowing,
            isMediationEnabled
          });
        } catch (e) {
          const formErrors = yupToFormErrors(e);
          throw formErrors;
        }
      }}
    >
      {(formikProps: FormikProps<ClaimItemFormValues>) => {
        return (
          <InternalForm
            title={title || ''}
            documents={documents}
            formActions={formActions}
            formikProps={formikProps}
            showDeleteButton={showDeleteButton}
            publishedAndEditable={publishedAndEditable}
            addDocuments={docs => setLocalDocuments(curr => [...curr, ...docs])}
            removeDocumentById={id => setLocalDocuments(curr => curr.filter(d => d.id !== id))}
            action={action}
            claimItemId={claimItemId}
            onUploadComplete={onUploadComplete}
            onUploadStart={onUploadStart}
            isUploadShowing={isUploadShowing}
            setIsUploadShowing={setIsUploadShowing}
            claimItemProposalId={claimItemProposalId}
            isMediationEnabled={isMediationEnabled}
          />
        );
      }}
    </Formik>
  );
};

export default ClaimItemForm;
