import { merge } from 'lodash';
import { combineReducers, AnyAction } from 'redux';

import { EntityState } from './entities.types';
import { EntityActionTypes, UPDATE_ENTITIES } from './entities.actions';
import { tenancyEntityReducer } from './reducers/tenancy.entity.reducer';
import { tenantEntityReducer } from './reducers/tenant.entity.reducer';
import { claimEntityReducer } from './reducers/claim.entity.reducer';
import { claimItemEntityReducer } from './reducers/claim-item.entity.reducer';
import { claimItemProposalEntityReducer } from './reducers/claim-item-proposal.entity.reducer';
import { claimProposalEntityReducer } from './reducers/claim-proposal.entity.reducer';

const initialState: EntityState = {
  address: {},
  document: {},
  landlord: {},
  order: {},
  property: {},
  tenancy: {},
  tenancyOrder: {},
  user: {},
  tenant: {},
  organization: {},
  organizationInvite: {},
  orderCustomer: {},
  orderCustomerAction: {},
  claim: {},
  claimProposal: {},
  claimItemProposal: {},
  checkout: {},
  claimItem: {},
  claimDocument: {},
  claimItemDocument: {},
  organizationRecipient: {},
  bankAccount: {}
};

export default (state: EntityState = initialState, action: EntityActionTypes) => {
  switch (action.type) {
    case UPDATE_ENTITIES:
      // this does not mutate the orginal state - v important
      return merge({}, state, action.payload);
  }

  return combineReducers<EntityState>({
    address: (state: any = {}, action: AnyAction) => state,
    document: (state: any = {}, action: AnyAction) => state,
    landlord: (state: any = {}, action: AnyAction) => state,
    order: (state: any = {}, action: AnyAction) => state,
    property: (state: any = {}, action: AnyAction) => state,
    tenancy: tenancyEntityReducer,
    tenancyOrder: (state: any = {}, action: AnyAction) => state,
    user: (state: any = {}, action: AnyAction) => state,
    tenant: tenantEntityReducer,
    organization: (state: any = {}, action: AnyAction) => state,
    organizationInvite: (state: any = {}, action: AnyAction) => state,
    orderCustomer: (state: any = {}, action: AnyAction) => state,
    orderCustomerAction: (state: any = {}, action: AnyAction) => state,
    claim: claimEntityReducer,
    claimItem: claimItemEntityReducer,
    claimProposal: claimProposalEntityReducer,
    claimItemProposal: claimItemProposalEntityReducer,
    claimItemDocument: (state: any = {}, action: AnyAction) => state,
    checkout: (state: any = {}, action: AnyAction) => state,
    claimDocument: (state: any = {}, action: AnyAction) => state,
    organizationRecipient: (state: any = {}, action: AnyAction) => state,
    bankAccount: (state: any = {}, action: AnyAction) => state
  })(state, action);
};
