import styled from 'styled-components';
import { getBreakpoint } from '../../base/style';
import { NetPromoterScoreDTOCategoryEnum } from '@reposit/api-client';
import { getNPSCategory } from './index';

export const Action = styled.div`
  padding: 12px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

export const ScoresContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 3px 0px;

  @media screen and (min-width: ${getBreakpoint('md')}) {
    justify-content: space-around;
  }
`;

interface ScoreButtonProps {
  hoveredScore?: number;
  selectedScore?: number;
  active: boolean;
}

export const ScoreButton = styled.button<ScoreButtonProps>`
  width: 44px;
  height: 44px;
  border-radius: 44px;
  border: none;
  cursor: pointer;
  outline: none;
  margin-top: 10px;
  padding: 0;
  background: ${({ active, hoveredScore, selectedScore, theme }) => {
    const score = hoveredScore || hoveredScore === 0 ? hoveredScore : selectedScore;
    if (active && score !== undefined) {
      const category = getNPSCategory(score);
      switch (category) {
        case NetPromoterScoreDTOCategoryEnum.DETRACTOR:
          return theme.colors.negative;
        case NetPromoterScoreDTOCategoryEnum.PASSIVE:
          return theme.colors.warning;
        default:
          return theme.colors.positive;
      }
    } else {
      return props => props.theme.colors.tertiaryBrand;
    }
  }};

  color: white;
  font-size: 16px;
  margin-right: 5px;

  @media screen and (min-width: ${getBreakpoint('md')}) {
    margin-right: 0px;
  }
`;

export const LegendContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
`;
