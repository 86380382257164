import { useDispatch } from 'react-redux';
import { logout } from '../redux/auth/auth.actions';
import { sendEventToGoogleAnalytics } from '../utils/integrators/analytics.integrator';

const useLogout = () => {
  const dispatch = useDispatch();
  const handleLogout = () => {
    sendEventToGoogleAnalytics({ action: 'Logout', category: 'Auth' });
    dispatch(logout());
  };
  return handleLogout;
};

export default useLogout;
