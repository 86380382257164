import {
  CreateClaimItemDocumentSuccessPayload,
  CreateClaimItemDocumentPayload,
  DeleteClaimItemDocumentSuccessPayload
} from './claim-item-document.types';

export const CREATE_CLAIM_ITEM_DOCUMENT_STORE_KEY = `CLAIM_ITEM_DOCUMENT/CREATE_CLAIM_ITEM_DOCUMENT`;
export const CREATE_CLAIM_ITEM_DOCUMENT_API_REQUESTED = `CLAIM_ITEM_DOCUMENT/CREATE_CLAIM_ITEM_DOCUMENT_API_REQUESTED`;
export const CREATE_CLAIM_ITEM_DOCUMENT_API_SUCCESS = `CLAIM_ITEM_DOCUMENT/CREATE_CLAIM_ITEM_DOCUMENT_API_SUCCESS`;
export const CREATE_CLAIM_ITEM_DOCUMENT_API_FAILED = `CLAIM_ITEM_DOCUMENT/CREATE_CLAIM_ITEM_DOCUMENT_API_FAILED`;

export const DELETE_CLAIM_ITEM_DOCUMENT_STORE_KEY = 'CLAIM_ITEM_DOCUMENT/DELETE_CLAIM_ITEM_DOCUMENT';
export const DELETE_CLAIM_ITEM_DOCUMENT_API_REQUESTED = `CLAIM_ITEM_DOCUMENT/DELETE_CLAIM_ITEM_DOCUMENT_API_REQUESTED`;
export const DELETE_CLAIM_ITEM_DOCUMENT_API_SUCCESS = `CLAIM_ITEM_DOCUMENT/DELETE_CLAIM_ITEM_DOCUMENT_API_SUCCESS`;
export const DELETE_CLAIM_ITEM_DOCUMENT_API_FAILED = `CLAIM_ITEM_DOCUMENT/DELETE_CLAIM_ITEM_DOCUMENT_API_FAILED`;

/* -------------------------------------------- CREATE CLAIM ITEM DOCUMENT -------------------------------------------- */
export function createClaimItemDocumentRequested(payload: CreateClaimItemDocumentPayload) {
  return {
    type: CREATE_CLAIM_ITEM_DOCUMENT_API_REQUESTED,
    payload
  } as const;
}
export function createClaimItemDocumentSuccess(payload: CreateClaimItemDocumentSuccessPayload) {
  return {
    type: CREATE_CLAIM_ITEM_DOCUMENT_API_SUCCESS,
    payload
  } as const;
}
export function createClaimItemDocumentFailed(error: string) {
  return {
    type: CREATE_CLAIM_ITEM_DOCUMENT_API_FAILED,
    payload: error
  } as const;
}

export function deleteClaimItemDocumentRequested(payload: string) {
  return {
    type: DELETE_CLAIM_ITEM_DOCUMENT_API_REQUESTED,
    payload
  } as const;
}

export function deleteClaimItemDocumentSuccess(payload: DeleteClaimItemDocumentSuccessPayload) {
  return {
    type: DELETE_CLAIM_ITEM_DOCUMENT_API_SUCCESS,
    payload
  } as const;
}

export function deleteClaimItemDocumentFailed(error: string) {
  return {
    type: DELETE_CLAIM_ITEM_DOCUMENT_API_FAILED,
    payload: error
  } as const;
}

export type ClaimItemDocumentActionTypes = ReturnType<
  | typeof createClaimItemDocumentRequested
  | typeof createClaimItemDocumentSuccess
  | typeof createClaimItemDocumentFailed
  | typeof deleteClaimItemDocumentRequested
  | typeof deleteClaimItemDocumentSuccess
  | typeof deleteClaimItemDocumentFailed
>;
