export enum ClaimFilter {
  ALL = '',
  DRAFT = 'DRAFT',
  AWAITING_INITIAL_TENANTS_RESPONSE = 'AWAITING_INITIAL_TENANTS_RESPONSE',
  AWAITING_FINAL_TENANTS_RESPONSE = 'AWAITING_FINAL_TENANTS_RESPONSE',
  AWAITING_SUPPLIER_RESPONSE = 'AWAITING_SUPPLIER_RESPONSE',
  AWAITING_SUBSTANTIATION = 'AWAITING_SUBSTANTIATION',
  RESOLVED = 'RESOLVED',
  DISPUTED = 'DISPUTED'
}

export enum ClaimSort {
  CREATION_DATE = 'CREATION_DATE',
  PUBLISH_DEADLINE = 'PUBLISH_DEADLINE'
}

export enum APISortClaim {
  CREATED_AT = 'CREATED_AT',
  PUBLISH_DEADLINE = 'PUBLISH_DEADLINE'
}

export const CLAIM_UPLOAD_INFO =
  'You can upload a range of file types including PDFs, images, videos, documents, zips, and spreadsheets. If your file is in an unsupported format, please convert it to a PDF before uploading. Each file can be up to 30MB in size.';
