import { OrderCustomerActionDTOTypeIdEnum } from '@reposit/api-client';
import { orderBy, values } from 'lodash';
import { TenantWithActions } from './tenancyOrder.selectors';

interface RepositActionLabel {
  label: string;
  status: TenantCompletionStatus;
}

export interface RepositAction extends RepositActionLabel {
  id: string;
  order: number;
  tooltip?: string;
}

export enum TenantCompletionStatus {
  INCOMPLETE = 'INCOMPLETE',
  DONE = 'DONE'
}

const RepositActionLabels: { [key in string]?: any } = {
  REPOSIT_REGISTERED: { label: 'Registered' },
  REPOSIT_REFERENCING: { label: 'Referenced' },
  REPOSIT_CONFIRM: { label: 'Confirmed' },
  REPOSIT_SIGN: { label: 'Signed' },
  REPOSIT_PAY: { label: 'Paid' }
};

export const getTenantActions = (tenant: TenantWithActions): RepositAction[] => {
  const actions = tenant.actions || [];
  let formattedTenantActions: RepositAction[] = [];

  const actionsMap: { [key in OrderCustomerActionDTOTypeIdEnum]?: RepositAction } = actions.reduce((acc, action) => {
    if (action.typeId === 'REPOSIT_ABOUT_YOU' || action.typeId === 'REPOSIT_ADDRESS_HISTORY') return acc;
    return {
      ...acc,
      [action.typeId]: {
        id: action.typeId,
        label: RepositActionLabels[action.typeId].label,
        status: action.completedAt ? TenantCompletionStatus.DONE : TenantCompletionStatus.INCOMPLETE,
        order: action.type.defaultOrder
      }
    };
  }, {});

  formattedTenantActions = values<RepositAction>(actionsMap);

  formattedTenantActions.unshift({
    id: 'REPOSIT_REGISTERED',
    label: RepositActionLabels['REPOSIT_REGISTERED'].label,
    status: tenant.userId ? TenantCompletionStatus.DONE : TenantCompletionStatus.INCOMPLETE,
    order: 1,
    tooltip: RepositActionLabels['REPOSIT_REGISTERED'].tooltip
  });

  return orderBy(formattedTenantActions, action => action.order, 'asc');
};
