import { unwrapResult } from '@reduxjs/toolkit';
import { ClaimDTOStatusEnum, ClaimProposalDTOStatusEnum } from '@reposit/api-client';
import { get } from 'lodash';
import numeral from 'numeral';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import ClaimBuilderIcon from '../../../assets/svg/property.svg';
import { RepositTheme } from '../../../base/theme';
import Button from '../../../components/Button/index';
import ConfirmationModal from '../../../components/ConfirmationModal/index';
import FlashMessage from '../../../components/FlashMessage/index';
import { ClaimItemComponentStatus } from '../../../components/Library/SupplierOffer/ClaimItem/ClaimItem';
import RepositCard from '../../../components/Reposit/RepositCard/index';
import { P2, P3 } from '../../../components/Typography/index';
import { NumberFormat } from '../../../constants/number-formats';
import { useAppDispatch } from '../../../index';
import {
  CREATE_CLAIM_ITEM_DOCUMENT_STORE_KEY,
  DELETE_CLAIM_ITEM_DOCUMENT_STORE_KEY
} from '../../../redux/claim-item-document/claim-item-document.actions';
import {
  createClaimItemRequested,
  CREATE_CLAIM_ITEM_STORE_KEY,
  DISCARD_CLAIM_ITEM_STORE_KEY,
  UPDATE_CLAIM_ITEM_STORE_KEY
} from '../../../redux/claim-item/claim-item.actions';
import { PUBLISH_PROPOSAL_STORE_KEY, setCreateClaimItemsFormOpen } from '../../../redux/claim/claim.actions';
import { acceptProposalThunk, publishProposalThunk } from '../../../redux/claim/claim.thunk';
import { CREATE_DOCUMENT_STORE_KEY } from '../../../redux/document/document.actions';
import { getClaimItemDocuments } from '../../../redux/document/document.selector';
import { ClaimEntity } from '../../../redux/entities/entities.types';
import { AppState } from '../../../redux/root.reducer';
import { getClaimStatusById } from '../../../redux/selectors/claim.selectors';
import {
  getFirstAgentProposal,
  getFirstTenantProposal,
  getFirstTenantProposalItems,
  getFirstTenantProposalTotalAmount,
  getHasAgentAcceptedTenantProposal,
  getHasAgentRespondedToAllItems,
  getIsAgentDeciding,
  getIsAgentNegotiating,
  getIsTenantDeciding,
  getLatestProposalTotalAmount,
  getMediationClaimItemsByClaimId,
  getProposalTotalAmountsAreEqual,
  getSecondAgentProposal
} from '../../../redux/selectors/mediation.selectors';
import { FILE_UPLOAD_STORE_KEY } from '../../../redux/system/system.actions';
import { penceToPounds } from '../../../utils/common.utils';
import { FLASH_MESSAGE_TIMEOUT, useFlashMessage } from '../../FlashMessage/index';
import { ClaimItemFormValues } from './ClaimItemForm';
import { ClaimItems } from './ClaimItems';
import ClaimItemsEmpty from './ClaimItemsEmpty';
import { MediationClaimItems } from './MediationClaimItems';

interface ClaimBuilderProps {
  claim: ClaimEntity;
  hasGoneBack: boolean;
}

const ClaimBuilderContainer = styled.div``;

const IntroText = styled(P2)`
  padding: 1px 36px;
  margin-bottom: 36px;
`;

const ClaimFooter = styled.div`
  display: flex;
  margin: 16px 0 12px;
`;

const ClaimLimitMessage = styled.div`
  align-content: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 40px;

  p {
    margin: 0;
    padding: 0;
  }
`;

const WarningMessage = styled(P2)`
  color: ${props => props.theme.colors.negative};
  font-weight: bold;
`;

const ClaimTotal = styled(P2)<{ warning: boolean }>`
  text-align: right;
  margin: 0;

  span {
    color: ${props => (props.warning ? RepositTheme.colors.negative : RepositTheme.colors.body)};
    font-family: ${props => props.theme.typography.face.primary};
    font-weight: bold;
    font-size: 20px;
    margin: 0 16px 0 10px;
  }
`;

const ClaimTotalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  padding: 16px 16px 28px;
`;

const SubmitButton = styled(Button)`
  margin: 8px 16px 0 0;
`;

const storeKeys = [
  CREATE_CLAIM_ITEM_STORE_KEY,
  UPDATE_CLAIM_ITEM_STORE_KEY,
  DISCARD_CLAIM_ITEM_STORE_KEY,
  FILE_UPLOAD_STORE_KEY,
  DELETE_CLAIM_ITEM_DOCUMENT_STORE_KEY,
  CREATE_CLAIM_ITEM_DOCUMENT_STORE_KEY,
  CREATE_DOCUMENT_STORE_KEY,
  PUBLISH_PROPOSAL_STORE_KEY
];

const ClaimBuilder: React.FC<ClaimBuilderProps> = ({ claim, hasGoneBack }: ClaimBuilderProps) => {
  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);
  const claimStatus = useSelector((state: AppState) => getClaimStatusById(state, claim.id)) as ClaimDTOStatusEnum;
  const dispatch = useAppDispatch();
  const createClaimItem = (values: ClaimItemFormValues) => {
    dispatch(createClaimItemRequested({ claimId: claim.id, data: { ...values, amount: values.amount } as any }));
    dispatch(setCreateClaimItemsFormOpen(false));
  };

  const agentFirstProposal = useSelector((state: AppState) => getFirstAgentProposal(state, claim.id));
  const agentSecondProposal = useSelector((state: AppState) => getSecondAgentProposal(state, claim.id));
  const agentSecondProposalId = get(agentSecondProposal, 'id', '');
  const tenantProposal = useSelector((state: AppState) => getFirstTenantProposal(state, claim.id));
  const tenantProposalId = get(tenantProposal, 'id', '');
  const tenantFirstProposalItems = useSelector((state: AppState) => getFirstTenantProposalItems(state, claim.id));

  const mediationClaimItems = useSelector((state: AppState) => getMediationClaimItemsByClaimId(state, claim.id));

  const hasAgentAcceptedTenantProposal = useSelector((state: AppState) => getHasAgentAcceptedTenantProposal(state, claim.id));

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const publishClaimProposal = async () => {
    try {
      setIsSubmitting(true);
      await dispatch(publishProposalThunk({ claimId: claim.id, claimProposalId: agentSecondProposalId })).then(unwrapResult);
      setIsSubmitting(false);
    } catch (e) {
      setIsSubmitting(false);
      throw e;
    }
  };

  const acceptTenantProposal = async () => {
    let resolvedClaim;
    try {
      setIsSubmitting(true);
      resolvedClaim = await dispatch(acceptProposalThunk({ claimId: claim.id, claimProposalId: tenantProposalId })).then(
        unwrapResult
      );
      setIsSubmitting(false);
      return resolvedClaim;
    } catch (e) {
      setIsSubmitting(false);
      throw e;
    }
  };

  const latestProposalTotalAmount = useSelector((state: AppState) => getLatestProposalTotalAmount(state, claim.id)) || 0;
  const firstTenantProposalTotalAmount =
    useSelector((state: AppState) => getFirstTenantProposalTotalAmount(state, claim.id)) || 0;
  const proposalTotalAmountsAreEqual = useSelector((state: AppState) => getProposalTotalAmountsAreEqual(state, claim.id));

  const claimTotal = hasGoneBack ? firstTenantProposalTotalAmount : latestProposalTotalAmount;

  const [flashMessagePayload, onDismissFlashMessage] = useFlashMessage(storeKeys);

  const [modal, setModal] = useState(false);

  const claimItemDocs = useSelector(getClaimItemDocuments);

  const publishedAndEditable = !!(
    agentFirstProposal && agentFirstProposal.status === ClaimProposalDTOStatusEnum.AWAITINGRESPONSE
  );

  const isTenantDeciding = useSelector((state: AppState) => getIsTenantDeciding(state, claim.id));

  const showTenantProposalClaimItems =
    (claim.status === ClaimDTOStatusEnum.MEDIATION && !isTenantDeciding) ||
    claim.status === ClaimDTOStatusEnum.AWAITINGSUBSTANTIATION;

  const isAgentDeciding = useSelector((state: AppState) => getIsAgentDeciding(state, claim.id));
  const isAgentNegotiating = useSelector((state: AppState) => getIsAgentNegotiating(state, claim.id));
  const isUploadingEvidence = claim.status === ClaimDTOStatusEnum.AWAITINGSUBSTANTIATION;

  const hasAgentRespondedtoAllItems = useSelector((state: AppState) => getHasAgentRespondedToAllItems(state, claim.id));

  const getClaimItemComponentStatus = () => {
    if (isUploadingEvidence) {
      return ClaimItemComponentStatus.UPLOAD_ONLY;
    } else if (isAgentDeciding || claim.status === ClaimDTOStatusEnum.RESOLVED) {
      return ClaimItemComponentStatus.VIEW_ONLY;
    } else {
      return ClaimItemComponentStatus.NEGOTIABLE;
    }
  };

  const isClaimOverLimit = claimTotal > claim.limit;
  const isDraft = claimStatus === ClaimDTOStatusEnum.DRAFT;
  const sameAmountText =
    claimTotal === 0
      ? `If you are satisfied with this total amount (£${penceToPounds(latestProposalTotalAmount)}), please click 'Confirm' to 
      accept the proposal and the case will be considered settled and closed.
      `
      : `If you are satisfied with this total amount (£${penceToPounds(latestProposalTotalAmount)}), please click 'Confirm' to
  immediately collect the funds. This will be transferred within 3-5 working days and the Reposit will be closed and
  settled.`;

  const modalText = proposalTotalAmountsAreEqual ? (
    <>
      <P2>The proposal you have put forward is equal to the proposal you have received from the tenant(s).</P2>
      <P2>{sameAmountText}</P2>
    </>
  ) : (
    <>
      <P2>You are about to submit a final proposed settlement of £{penceToPounds(claimTotal)} to the tenant.</P2>
      <P2>The tenant will then have the option to accept and pay, or proceed to raise a formal dispute.</P2>
    </>
  );

  const renderDescription = () => {
    if (isDraft) {
      return (
        <>
          <IntroText style={{ marginBottom: '1rem' }}>
            You can add items here that you wish to make a claim for. Please enter each item separately.
          </IntroText>
          {/* <IntroText>
            If you wish to save time upfront, you do not need to upload evidence when submitting each item. You will be required
            to provide the remaining evidence at a later stage if the end of tenancy charges are not agreed by you and the tenant.
          </IntroText> */}
        </>
      );
    } else if (publishedAndEditable) {
      return (
        <IntroText>
          You can edit or remove claim items on a claim until there is a response from one of the tenants. Please note that extra
          claim items cannot be added, and charges for existing items cannot be increased.
        </IntroText>
      );
    } else if (isUploadingEvidence) {
      return null;
    } else if (showTenantProposalClaimItems) {
      return (
        <>
          <IntroText style={{ marginBottom: isAgentNegotiating ? '10px' : '36px' }}>
            View the tenant's proposed settlement for each claim item. Click{' '}
            <span style={{ fontStyle: 'italic' }}>"Show proposal evidence"</span> to see any supporting evidence they have
            provided.
          </IntroText>
          {isAgentNegotiating && !hasGoneBack ? (
            <IntroText>
              If you agree with the proposal, you can click <span style={{ fontStyle: 'italic' }}>"Agree"</span> or click{' '}
              <span style={{ fontStyle: 'italic' }}>"Disagree"</span> to propose a final settlement.
            </IntroText>
          ) : null}
        </>
      );
    }
  };

  return (
    <RepositCard
      flashMessage={
        flashMessagePayload ? (
          <FlashMessage onDismiss={onDismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMEOUT} payload={flashMessagePayload} />
        ) : (
          undefined
        )
      }
      title="Claim Items"
      icon={ClaimBuilderIcon}
      flush
      tooltip={
        isDraft
          ? 'Items you wish to claim for can be anything the tenant is liable for in accordance with their AST or initial property inventory.'
          : undefined
      }
    >
      <ClaimBuilderContainer>
        {renderDescription()}
        {showTenantProposalClaimItems || hasAgentAcceptedTenantProposal ? (
          <MediationClaimItems
            componentStatus={getClaimItemComponentStatus()}
            claimItems={mediationClaimItems}
            claimId={claim.id}
            hasGoneBack={hasGoneBack}
          />
        ) : mediationClaimItems && mediationClaimItems.length ? (
          <ClaimItems
            claimId={claim.id}
            claimStatus={claim.status}
            publishedAndEditable={publishedAndEditable}
            claimItems={mediationClaimItems}
            claimItemDocs={claimItemDocs}
            createClaimItem={createClaimItem}
            tenantProposalItems={isTenantDeciding ? [] : tenantFirstProposalItems}
          />
        ) : (
          claim.status === ClaimDTOStatusEnum.DRAFT && (
            <ClaimItemsEmpty onSubmit={createClaimItem} claimItemDocs={claimItemDocs} claimId={claim.id} />
          )
        )}

        <ClaimFooter>
          <ClaimLimitMessage>
            {isClaimOverLimit && (
              <Fragment>
                <WarningMessage>
                  Total exceeds maximum of £{numeral(claim.limit / 100).format(NumberFormat.THOUSANDS_PENCE)}
                </WarningMessage>
                <P3>Your claim will be capped at 8 week's rent.</P3>
              </Fragment>
            )}
          </ClaimLimitMessage>

          <ClaimTotalWrapper>
            <ClaimTotal warning={isClaimOverLimit}>
              Total claim: <span>£{numeral(claimTotal / 100).format(NumberFormat.THOUSANDS_PENCE)}</span>
            </ClaimTotal>
            {isAgentNegotiating && !hasGoneBack ? (
              <SubmitButton
                buttonType="primary"
                type="button"
                onClick={() => setModal(true)}
                disabled={!hasAgentRespondedtoAllItems}
              >
                Submit
              </SubmitButton>
            ) : null}
          </ClaimTotalWrapper>
        </ClaimFooter>
      </ClaimBuilderContainer>
      {modal ? (
        proposalTotalAmountsAreEqual ? (
          <ConfirmationModal
            title={claimTotal === 0 ? 'Accept tenant proposal?' : 'Collect tenant proposal?'}
            text={modalText}
            onSubmit={acceptTenantProposal}
            onDismiss={() => setModal(false)}
            isSubmitting={isSubmitting}
          />
        ) : (
          <ConfirmationModal
            title="Submit final proposal"
            text={modalText}
            onSubmit={publishClaimProposal}
            onDismiss={() => setModal(false)}
            isSubmitting={isSubmitting}
          />
        )
      ) : (
        <></>
      )}
    </RepositCard>
  );
};

export default ClaimBuilder;
