export const AST_ADDENDUM_LINK = 'https://d105lee3jcviqy.cloudfront.net/ast/reposit-ast-addendum.pdf';
export const SUPPLIER_AGREEMENT_LINK =
  'https://d105lee3jcviqy.cloudfront.net/terms/reposit-supplier-terms-and-conditions-november-2022.pdf';

export const REFERENCING_CRITERIA = 'https://d105lee3jcviqy.cloudfront.net/referencing/reposit-referencing-criteria.pdf';

export const REPOSIT_FAQS = 'https://support.reposit.co.uk/en/knowledge';

export const FAIR_DISPUTES_FAQS =
  'https://support.reposit.co.uk/en/knowledge/how-do-i-know-any-disputes-over-a-claim-will-be-resolved-fairly';
