import { OrganizationInviteDTO, OrganizationInvitesApi } from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { get } from 'lodash';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { FlashState } from '../../components/FlashMessage/index';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import { FlashMessagesActionTypes, setFlashMessage } from '../flash-messages/flash-messages.actions';
import SCHEMA from '../schema';
import { fetchOrganizationUsersRequested, UserActionTypes } from '../user/user.actions';
import { createOrganizationInviteApi, runSagaWithAuth } from '../utils/api.utils';
import {
  createInviteFailed,
  createInviteSuccess,
  CREATE_INVITE_API_REQUESTED,
  CREATE_INVITE_STORE_KEY,
  fetchOrganizationInvitesFailed,
  fetchOrganizationInvitesRequested,
  fetchOrganizationInvitesSuccess,
  FETCH_ORGANIZATION_INVITES_API_REQUESTED,
  InviteActionTypes,
  resendInviteFailed,
  resendInviteSuccess,
  RESEND_INVITE_API_REQUESTED,
  RESEND_INVITE_STORE_KEY,
  toggleIsInviteUserFormOpen,
  deleteInviteSuccess,
  DELETE_INVITE_STORE_KEY,
  deleteInviteFailed,
  DELETE_INVITE_API_REQUESTED
} from './invite.actions';
import { getErrorMessage } from '../../utils/common.utils';
import { getCurrentOrganizationId } from '../auth/auth.selectors';

// ****************
// WORKERS
// ****************

export function* fetchInvitesForOrganization() {
  try {
    const organizationId: string = yield select(getCurrentOrganizationId);
    const organizationInviteApi: OrganizationInvitesApi = yield createOrganizationInviteApi();

    const apiResponse: AxiosResponse<OrganizationInviteDTO[]> = yield call(
      runSagaWithAuth(() => organizationInviteApi.fetchInvitesByOrganizationId(organizationId, false))
    );
    const ids: string[] = yield syncEntitiesAndGetResults(apiResponse.data, SCHEMA.organizationInvites);
    yield put<InviteActionTypes>(fetchOrganizationInvitesSuccess(ids));
  } catch (e) {
    yield put<InviteActionTypes>(fetchOrganizationInvitesFailed(get(e, 'response.data.message', e)));
  }
}

export function* createInvite({ payload }: { type: string; payload: string }) {
  try {
    const organizationId: string = yield select(getCurrentOrganizationId);
    const orgInvitesApi: OrganizationInvitesApi = yield createOrganizationInviteApi();

    yield call(
      runSagaWithAuth(() =>
        orgInvitesApi.createOrganizationInvite(organizationId, {
          email: payload
        })
      )
    );
    yield put<InviteActionTypes>(createInviteSuccess());
    yield put<InviteActionTypes>(fetchOrganizationInvitesRequested());
    yield put<UserActionTypes>(fetchOrganizationUsersRequested());
    yield put<InviteActionTypes>(toggleIsInviteUserFormOpen());
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: CREATE_INVITE_STORE_KEY,
        message: 'Success! You have invited a team member.',
        state: FlashState.SUCCESS
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: CREATE_INVITE_STORE_KEY, message: error, state: FlashState.ERROR })
    );
    yield put<InviteActionTypes>(createInviteFailed(get(e, 'response.data.message', e)));
  }
}

export function* resendInvite({ payload }: { type: string; payload: string }) {
  try {
    const orgInvitesApi: OrganizationInvitesApi = yield createOrganizationInviteApi();
    const organizationId: string = yield select(getCurrentOrganizationId);
    yield call(runSagaWithAuth(() => orgInvitesApi.resendOrganizationInvite(payload, organizationId)));
    yield put<InviteActionTypes>(resendInviteSuccess());
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: RESEND_INVITE_STORE_KEY,
        message: 'Success! You have resent an invite.',
        state: FlashState.SUCCESS
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: RESEND_INVITE_STORE_KEY, message: error, state: FlashState.ERROR })
    );
    yield put<InviteActionTypes>(resendInviteFailed(get(e, 'response.data.message', e)));
  }
}

export function* deleteInvite({ payload }: { type: string; payload: string }) {
  try {
    const orgInvitesApi: OrganizationInvitesApi = yield createOrganizationInviteApi();
    const organizationId: string = yield select(getCurrentOrganizationId);
    yield call(runSagaWithAuth(() => orgInvitesApi.deleteInvite(payload, organizationId)));
    yield put<InviteActionTypes>(deleteInviteSuccess(payload));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: DELETE_INVITE_STORE_KEY,
        message: 'Success! You have deleted the invite.',
        state: FlashState.SUCCESS
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({ key: DELETE_INVITE_STORE_KEY, message: error, state: FlashState.ERROR })
    );
    yield put<InviteActionTypes>(deleteInviteFailed(get(e, 'response.data.message', e)));
  }
}

// ****************
// WATCHERS
// ****************
export function* watchInviteSagas() {
  yield takeLatest(FETCH_ORGANIZATION_INVITES_API_REQUESTED, fetchInvitesForOrganization);
  yield takeLatest(CREATE_INVITE_API_REQUESTED, createInvite);
  yield takeLatest(RESEND_INVITE_API_REQUESTED, resendInvite);
  yield takeLatest(DELETE_INVITE_API_REQUESTED, deleteInvite);
}
