import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { ContentContainer, InnerWrapper } from '../../../components/Common/index';
import { FullPageLoader } from '../../../components/Loading/index';
import { Header1 } from '../../../components/Typography/index';
import { getCurrentOrganizationId } from '../../../redux/auth/auth.selectors';
import { fetchOrganizationInvitesRequested, FETCH_ORGANIZATION_INVITES_STORE_KEY } from '../../../redux/invite/invite.actions';
import { getHaveCurrentOrganizationInvitesBeenFetched } from '../../../redux/invite/invite.selectors';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import { getAccountData } from '../../../redux/selectors/account.selectors';
import { fetchOrganizationUsersRequested, FETCH_ORGANIZATION_USERS_STORE_KEY } from '../../../redux/user/user.actions';
import { getHaveCurrentOrganizationUsersBeenFetched } from '../../../redux/user/user.selectors';
import ManageTeam from './ManageTeam';

interface TeamViewProps {}

const TeamView: React.FC<TeamViewProps> = props => {
  const dispatch = useDispatch();
  const currentOrgId = useSelector(getCurrentOrganizationId);

  useEffect(() => {
    dispatch(fetchOrganizationUsersRequested());
    dispatch(fetchOrganizationInvitesRequested());
  }, [dispatch, currentOrgId]);

  const isLoadingSelector = createLoadingSelector([FETCH_ORGANIZATION_INVITES_STORE_KEY, FETCH_ORGANIZATION_USERS_STORE_KEY]);
  const isLoading = useSelector(isLoadingSelector);

  const haveUsersFetched = useSelector(getHaveCurrentOrganizationUsersBeenFetched);
  const haveInvitesFetched = useSelector(getHaveCurrentOrganizationInvitesBeenFetched);
  const hasFetched = haveUsersFetched && haveInvitesFetched;

  const teamData = useSelector(getAccountData);

  if (isLoading || !hasFetched) {
    return <FullPageLoader />;
  }

  return (
    <InnerWrapper>
      <Container>
        <Row>
          <Col sm={12}>
            <Header1>Team</Header1>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg={12}>
            <ContentContainer>
              <ManageTeam teamData={teamData} />
            </ContentContainer>
          </Col>
        </Row>
      </Container>
    </InnerWrapper>
  );
};

export default TeamView;
