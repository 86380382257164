import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { getBreakpoint } from '../../base/style';
import { dismissOrganizationMessageRequested, dismissUserMessageRequested } from '../../redux/account/account.actions';
import Button from '../Button/index';
import { useIsOrganizationLandlord } from '../../hooks/useIsLandlord';

export enum OrganizationMessageType {
  FEATURE_BANK_ACCOUNT = 'FEATURE_BANK_ACCOUNT',
  WARNING_BANK_ACCOUNT = 'WARNING_BANK_ACCOUNT'
}

export enum UserMessageType {
  FEATURE_CLAIM_UPLOADS = 'FEATURE_CLAIM_UPLOADS'
}
interface MessageBarProps {
  type: OrganizationMessageType | UserMessageType;
}

const MessageBarContainer = styled.div<MessageBarProps>`
  align-items: center;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  color: #fff;
  display: flex;
  justify-content: space-between;
  font-family: ${props => props.theme.typography.face.secondary};
  font-size: 14px;
  padding: 8px;
  z-index: 9999;
  margin-top: 50px;
  margin-bottom: 5px;
  border-radius: 8px;
  @media screen and (min-width: ${getBreakpoint('md')}) {
    margin-top: 0;
  }

  ${props =>
    props.type === OrganizationMessageType.WARNING_BANK_ACCOUNT &&
    `
    background: ${props.theme.colors.negative}
  `}
  ${props =>
    props.type === OrganizationMessageType.FEATURE_BANK_ACCOUNT &&
    `
    background: ${props.theme.colors.warning}
  `}
    ${props =>
      props.type === UserMessageType.FEATURE_CLAIM_UPLOADS &&
      `
    background: ${props.theme.colors.positive}
  `}
`;

const Link = styled(NavLink)`
  color: white;
  font-weight: bold;
`;

export default ({ type }: MessageBarProps) => {
  const dispatch = useDispatch();
  const [warningBankAccountsDismissed, setWarningBankAccountsDismissed] = useState(false);
  const isOrgLandlord = useIsOrganizationLandlord();
  if (type === OrganizationMessageType.WARNING_BANK_ACCOUNT && warningBankAccountsDismissed) {
    return null;
  }

  const featureCopy = isOrgLandlord ? 'for claim payments' : 'for commission and claim payments';

  return (
    <MessageBarContainer type={type}>
      {type === OrganizationMessageType.FEATURE_BANK_ACCOUNT && (
        <Fragment>
          <p>
            You can now <Link to={`/account/bank-accounts`}>manage your bank accounts</Link> {featureCopy}
          </p>
          <Button
            buttonType={'white'}
            noArrow
            onClick={() => dispatch(dismissOrganizationMessageRequested({ messageId: 'featureBankAccounts' }))}
          >
            Dismiss
          </Button>
        </Fragment>
      )}
      {type === OrganizationMessageType.WARNING_BANK_ACCOUNT && (
        <Fragment>
          <p>
            Please <Link to={`/account/bank-accounts`}>update your bank account information</Link>
          </p>

          <Button buttonType={'white'} noArrow onClick={() => setWarningBankAccountsDismissed(true)}>
            Dismiss
          </Button>
        </Fragment>
      )}
      {type === UserMessageType.FEATURE_CLAIM_UPLOADS && (
        <Fragment>
          <p style={{ fontWeight: 'bold', fontSize: '15px' }}>
            We have made some changes to file uploads, you can now upload multiple documents at once, drag and drop and upload
            more file types
          </p>
          <Button
            buttonType={'white'}
            noArrow
            onClick={() => dispatch(dismissUserMessageRequested({ messageId: 'featureClaimUploads' }))}
          >
            Dismiss
          </Button>
        </Fragment>
      )}
    </MessageBarContainer>
  );
};
