import { ActiveFormOptions } from './active-forms.types';

export const SET_ACTIVE_FORMS = 'ACTIVE_FORM/SET_ACTIVE_FORMS';
export const CLEAR_ACTIVE_FORMS = 'ACTIVE_FORM/CLEAR_ACTIVE_FORMS';

export function setActiveForms(payload: ActiveFormOptions[]) {
  return {
    type: SET_ACTIVE_FORMS,
    payload
  } as const;
}

export function clearActiveForms() {
  return {
    type: CLEAR_ACTIVE_FORMS
  } as const;
}

export type ActiveFormActionTypes = ReturnType<typeof setActiveForms | typeof clearActiveForms>;
