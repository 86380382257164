import React from 'react';
import styled from 'styled-components';
import { Header4, P2 } from '../Typography';
import { ContentContainer } from '../Common';
import CloseIcon from '../../assets/svg/close.svg';
import TickIcon from '../../assets/svg/tick-positive.svg';

interface ChecklistProps {
  items: ItemsDefinition[];
  label?: string;
  lessMargin?: boolean;
}

export enum ChecklistItemStatus {
  INCOMPLETE = 'Incomplete',
  COMPLETE = 'Complete'
}
export interface ItemsDefinition {
  label: string;
  status: ChecklistItemStatus;
}

const Checklist = styled.ul<{ lessMargin?: boolean }>`
  margin: 24px 0 ${props => (props.lessMargin ? '24px' : '56px')};
`;

const ChecklistItem = styled.li`
  margin: 0 0 20px;
`;

const Label = styled(P2)<{ status: ChecklistItemStatus }>`
  color: ${props => props.theme.colors.primaryBrand};
  margin: 0;
  opacity: ${props => (props.status === 'Complete' ? 0.5 : 1)};
`;

const Status = styled.p<{ status: ChecklistItemStatus }>`
  align-items: center;
  color: ${props => (props.status === 'Complete' ? props.theme.colors.positive : props.theme.colors.placeholder)};
  font-family: ${props => props.theme.typography.face.secondary};
  font-size: 12px;
  font-weight: ${props => (props.status === 'Complete' ? 700 : 'normal')};
  display: flex;
  margin: 2px 0 0;

  &:before {
    background: transparent ${props => (props.status === 'Complete' ? `url(${TickIcon})` : `url(${CloseIcon})`)} center center;
    background-size: 8px;
    content: '';
    display: block;
    height: 8px;
    margin: 0 4px 0 0;
    width: 8px;
  }
`;

const renderListItems = (items: ItemsDefinition[]): JSX.Element[] => {
  return items.map((item: ItemsDefinition) => (
    <ChecklistItem key={item.label}>
      <Label status={item.status}>{item.label}</Label>
      <Status status={item.status}>{item.status}</Status>
    </ChecklistItem>
  ));
};

export default ({ items, label, lessMargin }: ChecklistProps) => {
  return (
    <ContentContainer>
      <Header4>{label || `Things you need to do`}</Header4>
      <Checklist lessMargin={lessMargin}>{renderListItems(items)}</Checklist>
    </ContentContainer>
  );
};
