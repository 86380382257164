import React, { Fragment } from 'react';
import styled from 'styled-components';
import { P3 } from '../Typography/index';
import StatusItem from './StatusItem';
import { getBreakpoint } from '../../base/style';
import { RepositAction, TenantCompletionStatus } from '../../redux/selectors/tenant.selectors';

interface TenantStatusProps {
  actions: RepositAction[];
}

const TenantStatusContainer = styled.div`
  border-top: 1px solid ${props => props.theme.colors.dividerDark};
  padding: 12px 0;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    align-items: center;
    display: flex;
    height: 26px;
  }
`;

const Label = styled(P3)`
  margin: 0 18px 0 0;
  padding: 0 0 12px;
  flex: 1 0 80px;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    padding: 0;
  }
`;

const StatusItems = styled.div`
  border-radius: 6px;
  overflow: hidden;
  display: block;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 16px;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    border-radius: 12px;
    align-items: center;
    display: flex;
    margin: 0;
  }
`;

const AwaitingTenantSignup = styled(P3)`
  margin: 0;
  padding: 0 0 12px;
  flex: 1 0 auto;

  @media all and (min-width: ${getBreakpoint('lg')}) {
    padding: 0;
  }
`;

const TenantStatus: React.SFC<TenantStatusProps> = ({ actions }) => {
  let content: JSX.Element;
  const registerAction = actions.find((action: RepositAction) => action.id === 'REPOSIT_REGISTERED');

  if (registerAction && registerAction.status === TenantCompletionStatus.INCOMPLETE) {
    content = <AwaitingTenantSignup>The tenant has not yet signed up</AwaitingTenantSignup>;
  } else {
    content = (
      <Fragment>
        <Label>Tenant status:</Label>
        <StatusItems>
          {actions.map((action: RepositAction) => (
            <StatusItem key={action.id} label={action.label} status={action.status} tooltip={action.tooltip} />
          ))}
        </StatusItems>
      </Fragment>
    );
  }
  return <TenantStatusContainer>{content}</TenantStatusContainer>;
};

export default TenantStatus;
