import { RepositListFilters } from './reposit-list.types';

export const FETCH_REPOSITS_STORE_KEY = `REPOSIT_LIST/FETCH_REPOSITS`;
export const FETCH_REPOSITS_API_REQUESTED = `REPOSIT_LIST/FETCH_REPOSITS_API_REQUESTED`;
export const FETCH_REPOSITS_API_SUCCESS = `REPOSIT_LIST/FETCH_REPOSITS_API_SUCCESS`;
export const FETCH_REPOSITS_API_FAILED = `REPOSIT_LIST/FETCH_REPOSITS_API_FAILED`;

export const REPOSITS_GOTO_PAGE = 'REPOSIT_LIST/REPOSITS_GOTO_PAGE';

export const REPOSITS_UPDATE_FILTERS = 'REPOSIT_LIST/UPDATE_FILTERS';

export function fetchRepositsRequested() {
  return {
    type: FETCH_REPOSITS_API_REQUESTED
  } as const;
}
export function fetchRepositsSuccess(count: number, ids: string[]) {
  return {
    type: FETCH_REPOSITS_API_SUCCESS,
    payload: {
      count,
      ids
    }
  } as const;
}
export function fetchRepositsFailed(error: string) {
  return {
    type: FETCH_REPOSITS_API_FAILED,
    payload: error
  } as const;
}
export type Page = number | 'next' | 'prev';
export function goToPage(page: Page) {
  return {
    type: REPOSITS_GOTO_PAGE,
    payload: page
  } as const;
}
export function updateFilters(filters: Partial<RepositListFilters>) {
  return {
    type: REPOSITS_UPDATE_FILTERS,
    payload: filters
  } as const;
}

export type RepositListActionTypes = ReturnType<
  | typeof fetchRepositsRequested
  | typeof fetchRepositsSuccess
  | typeof fetchRepositsFailed
  | typeof goToPage
  | typeof updateFilters
>;
