import { ClaimDTOStatusEnum, ClaimItemDTOTypeEnum, CoreAddressDTO } from '@reposit/api-client';
import { push } from 'connected-react-router';
import { get } from 'lodash';
import moment from 'moment';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import { ArbitrationDetails } from '../../../components/Arbitration/ArbitrationDetails';
import { ChecklistItemStatus, ItemsDefinition } from '../../../components/Checklist/index';
import ClaimChecks from '../../../components/Claim/ClaimChecks/index';
import { ClaimDecision } from '../../../components/Claim/ClaimDecision/index';
import ClaimStatus from '../../../components/Claim/ClaimStatus/index';
import CreateClaimHeader from '../../../components/Claim/CreateClaimHeader/index';
import { ContentContainer, InnerWrapper } from '../../../components/Common/index';
import FlashMessage from '../../../components/FlashMessage/index';
import { FullPageLoader } from '../../../components/Loading/index';
import {
  ACCEPT_TENANT_PROPOSAL_STORE_KEY,
  clearClaimHasFetched,
  fetchClaimRequested,
  FETCH_CLAIM_STORE_KEY,
  PUBLISH_CLAIM_STORE_KEY,
  SUBMIT_EVIDENCE_STORE_KEY
} from '../../../redux/claim/claim.actions';
import { getClaimHasBeenFetched } from '../../../redux/claim/claim.selector';
import { ClaimItemEntity, DocumentEntity } from '../../../redux/entities/entities.types';
import { createErrorMessageSelector } from '../../../redux/error/error.selector';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import { fetchTenancyOrderRequested, FETCH_TENANCY_ORDER_STORE_KEY } from '../../../redux/reposit/reposit.actions';
import { AppState } from '../../../redux/root.reducer';
import { getClaimItemsByClaimId } from '../../../redux/selectors/claim-item.selectors';
import {
  getClaimById,
  getDocumentsFromClaimById,
  getTenancyByClaimId,
  getTenancyOrderIdFromClaimId,
  getTenantsByClaimId
} from '../../../redux/selectors/claim.selectors';
import { getTenancyOrder } from '../../../redux/selectors/index';
import {
  getFirstAgentProposalTotalAmount,
  getFirstTenantProposal,
  getFirstTenantProposalTotalAmount,
  getHasAgentAcceptedTenantProposal,
  getHasAgentUploadedEvidenceForEachClaimItem,
  getHasTenantDisputed,
  getIsAgentDeciding,
  getIsTenantDeciding,
  getTenantProposalResponseDeadline
} from '../../../redux/selectors/mediation.selectors';
import { getDisplayAddress, isANotFoundError } from '../../../utils/common.utils';
import { FLASH_MESSAGE_TIMEOUT, useFlashMessage } from '../../FlashMessage/index';
import NotFoundView from '../../NotFound/index';
import ClaimActions from '../ClaimActions';
import ClaimBuilder from '../ClaimBuilder';
import ClaimDocuments from '../ClaimDocuments/index';
import GuarantorConfirmation from '../GuarantorConfirmation/index';
import SupportingInformation from '../SupportingInformation/index';

type ClaimProps = {
  id: string;
};

const ClaimFlashMessage = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  margin: 24px 0 0 240px;
  padding: 0 50px;
  width: calc(100% - 240px);
  z-index: 9000;
`;

const areDocsComplete = (claimDocs: DocumentEntity[], requiredIds: string[]) => {
  return requiredIds.every(id => {
    return !!claimDocs.find(doc => {
      if (!doc) return false;
      return id === doc.typeId;
    });
  });
};

const rentArrearsRequiredIds = ['SIGNED_TENANCY_AGREEMENT', 'INVENTORY_CHECK_IN', 'REFERENCING'];
const standardRequiredIds = ['SIGNED_TENANCY_AGREEMENT', 'INVENTORY_CHECK_OUT', 'INVENTORY_CHECK_IN', 'REFERENCING'];

const getClaimDocStatus = (
  claimDocs: (DocumentEntity)[] | undefined,
  claimItems: ClaimItemEntity[],
  isMediationEnabled: boolean
) => {
  if (!claimDocs) return ChecklistItemStatus.INCOMPLETE;
  const allRentArrears = claimItems.every(item => item.type === ClaimItemDTOTypeEnum.RENTARREARS);

  if (isMediationEnabled) {
    if (allRentArrears) {
      // no docs required
      return ChecklistItemStatus.COMPLETE;
    } else {
      // just checkout report required
      const requiredIds = ['INVENTORY_CHECK_OUT'];
      const complete = areDocsComplete(claimDocs, requiredIds);
      return complete ? ChecklistItemStatus.COMPLETE : ChecklistItemStatus.INCOMPLETE;
    }
  } else {
    if (allRentArrears) {
      const complete = areDocsComplete(claimDocs, rentArrearsRequiredIds);
      return complete ? ChecklistItemStatus.COMPLETE : ChecklistItemStatus.INCOMPLETE;
    } else {
      const complete = areDocsComplete(claimDocs, standardRequiredIds);
      return complete ? ChecklistItemStatus.COMPLETE : ChecklistItemStatus.INCOMPLETE;
    }
  }
};

const Claim: React.FC<RouteComponentProps<ClaimProps>> = props => {
  const claimId = props.match.params.id;

  const [claimChecks, setClaimChecks] = useState({
    confirmClaim: false,
    confirmGuarantors: false
  });
  const [hasGoneBack, setHasGoneBack] = useState(false);

  const dispatch = useDispatch();

  const tenancyOrderId = useSelector((state: AppState) => getTenancyOrderIdFromClaimId(state, claimId)) || '';
  const tenancyOrder = useSelector((state: AppState) => getTenancyOrder(state, { tenancyOrderId }));
  const address = tenancyOrder && tenancyOrder.tenancy && tenancyOrder.tenancy.property && tenancyOrder.tenancy.property.address;

  const redirectToReposit = useCallback(() => {
    dispatch(push(`/reposits/${tenancyOrderId}`));
  }, [tenancyOrderId, dispatch]);

  // load claim
  useEffect(() => {
    dispatch(fetchClaimRequested({ claimId }));

    return function cleanup() {
      dispatch(clearClaimHasFetched());
    };
  }, [claimId, dispatch]);

  // load tenancy order
  useEffect(() => {
    if (tenancyOrderId) {
      dispatch(fetchTenancyOrderRequested(tenancyOrderId));
    }
  }, [dispatch, tenancyOrderId]);

  const isLoadingSelector = createLoadingSelector([FETCH_CLAIM_STORE_KEY, FETCH_TENANCY_ORDER_STORE_KEY]);
  const errorSelector = createErrorMessageSelector([FETCH_CLAIM_STORE_KEY]);
  const hasClaimFetched = useSelector(getClaimHasBeenFetched);
  const isLoading = useSelector(isLoadingSelector);
  const error = useSelector(errorSelector);
  const claim = useSelector((state: AppState) => getClaimById(state, claimId));
  const isMediationEnabled = !!(claim && claim.mediationEnabled);

  const { pathname, search } = props.location;
  const [urlFlagAdded, setUrlFlagAdded] = useState(false);

  useEffect(() => {
    if (isMediationEnabled && !search && !urlFlagAdded) {
      dispatch(push(`${pathname}?mediationEnabled=true`));
      setUrlFlagAdded(true);
    }
  }, [isMediationEnabled, dispatch, search, pathname, urlFlagAdded, setUrlFlagAdded]);

  const tenantTotalOffer = useSelector((state: AppState) => getFirstTenantProposalTotalAmount(state, claimId));
  const tenantProposingNonZeroAmount = tenantTotalOffer !== null && tenantTotalOffer !== undefined ? tenantTotalOffer > 0 : false;
  const firstAgentTotalOffer = useSelector((state: AppState) => getFirstAgentProposalTotalAmount(state, claimId));
  const isAgentDeciding = useSelector((state: AppState) => getIsAgentDeciding(state, claimId));
  const tenantProposal = useSelector((state: AppState) => getFirstTenantProposal(state, claimId));
  const tenantProposalId = get(tenantProposal, 'id', '');
  const isTenantDeciding = useSelector((state: AppState) => getIsTenantDeciding(state, claimId));
  const tenantProposalResponseDeadline = useSelector((state: AppState) => getTenantProposalResponseDeadline(state, claimId));

  const claimItems = useSelector((state: AppState) => getClaimItemsByClaimId(state, { claimId }));
  const claimDocuments = useSelector((state: AppState) => getDocumentsFromClaimById(state, claimId));
  const tenancy = useSelector((state: AppState) => getTenancyByClaimId(state, claimId));
  const tenants = useSelector((state: AppState) => getTenantsByClaimId(state, claimId));

  const hasAgentAcceptedTenantProposal = useSelector((state: AppState) => getHasAgentAcceptedTenantProposal(state, claimId));

  const items: ItemsDefinition[] = [
    {
      label: 'Add a claim item',
      status: (() => {
        if (claim && claim.status !== ClaimDTOStatusEnum.DRAFT) return ChecklistItemStatus.COMPLETE;
        return claimItems.length > 0 ? ChecklistItemStatus.COMPLETE : ChecklistItemStatus.INCOMPLETE;
      })()
    },
    {
      label: 'Upload tenancy documents',
      status: (() => {
        if (claim && claim.status !== ClaimDTOStatusEnum.DRAFT) return ChecklistItemStatus.COMPLETE;
        return getClaimDocStatus(claimDocuments, claimItems, isMediationEnabled);
      })()
    },
    {
      label: 'Confirm guarantor details',
      status: (() => {
        if (claim && claim.status !== ClaimDTOStatusEnum.DRAFT) return ChecklistItemStatus.COMPLETE;
        return claimChecks.confirmGuarantors ? ChecklistItemStatus.COMPLETE : ChecklistItemStatus.INCOMPLETE;
      })()
    }
  ];
  const isClaimDisputed = useSelector((state: AppState) => getHasTenantDisputed(state, claimId));

  const hasFetched = hasClaimFetched;

  const [claimDetails, setClaimDetails] = useState<string | undefined>(undefined);
  const [flashMessagePayload, onDismissFlashMessage] = useFlashMessage([
    PUBLISH_CLAIM_STORE_KEY,
    ACCEPT_TENANT_PROPOSAL_STORE_KEY,
    SUBMIT_EVIDENCE_STORE_KEY
  ]);
  const haveAllClaimItemsGotEvidence = useSelector((state: AppState) =>
    getHasAgentUploadedEvidenceForEachClaimItem(state, claimId)
  );

  if (!hasFetched || isLoading) {
    return <FullPageLoader />;
  }

  if (!claim || error || !tenants || !tenancy) {
    if (isANotFoundError(error)) {
      return <NotFoundView />;
    } else {
      return <div>oh no something went wrong</div>;
    }
  }
  const showClaimDecision = claim.status === ClaimDTOStatusEnum.MEDIATION && !isTenantDeciding;
  const outcomeDeadlineDaysRemaining = moment(tenancy && tenancy.outcomeDeadline, '').diff(moment().startOf('day'), 'day');
  const responseDeadlineDaysRemaining = moment(tenantProposalResponseDeadline, '').diff(moment().startOf('day'), 'day');
  const substantiationDeadlineDaysRemaining = moment(claim && claim.substantiationDeadline, '').diff(
    moment().startOf('day'),
    'day'
  );
  const isClaimInDraft = claim.status === ClaimDTOStatusEnum.DRAFT;
  const isClaimInAwaitingSubstantiation = claim.status === ClaimDTOStatusEnum.AWAITINGSUBSTANTIATION;

  const isAllStepsComplete = items.every(item => item.status === ChecklistItemStatus.COMPLETE);
  const isClaimConfirmed = claimChecks.confirmClaim && claimChecks.confirmGuarantors;
  const isPublishable = isAllStepsComplete && isClaimConfirmed;
  const claimLimit: number = get(claim, 'limit', 0);
  const claimLimitExceeded = !!(firstAgentTotalOffer && firstAgentTotalOffer > claimLimit);

  // this needs to be wired based on docs and claim item docs
  // FIX FOR CLAIM LITE
  const areClaimDocsFinalised = areDocsComplete(claimDocuments, standardRequiredIds);
  const isClaimFinalised = areClaimDocsFinalised && haveAllClaimItemsGotEvidence;

  return (
    <InnerWrapper>
      {!isClaimInDraft && (
        <Container>
          <Row>
            <Col sm={12}>
              <ClaimStatus
                claim={claim}
                isAgentDeciding={isAgentDeciding}
                isTenantDeciding={isTenantDeciding}
                isClaimDisputed={isClaimDisputed}
                hasGoneBack={hasGoneBack}
              />
            </Col>
          </Row>
        </Container>
      )}
      <CreateClaimHeader
        substantiationDeadlineDaysRemaining={substantiationDeadlineDaysRemaining}
        responseDeadlineDaysRemaining={responseDeadlineDaysRemaining}
        outcomeDeadlineDaysRemaining={outcomeDeadlineDaysRemaining}
        status={claim.status}
        claimId={claim.id}
        address={getDisplayAddress(address as CoreAddressDTO)}
        showAddress
        redirectToReposit={redirectToReposit}
        initialAmount={firstAgentTotalOffer}
        disputed={isClaimDisputed}
        proposalAmount={tenantTotalOffer}
        isAgentDeciding={isAgentDeciding}
        hasGoneBack={hasGoneBack}
        hasAgentAcceptedTenantProposal={hasAgentAcceptedTenantProposal}
        isMediationEnabled={isMediationEnabled}
      />

      {showClaimDecision ? (
        <ClaimDecision
          proposalAmount={tenantTotalOffer || 0}
          isAgentDeciding={isAgentDeciding}
          proposalId={tenantProposalId}
          claimId={claimId}
          hasGoneBack={hasGoneBack}
          setHasGoneBack={setHasGoneBack}
          tenantProposingNonZeroAmount={tenantProposingNonZeroAmount}
        />
      ) : null}

      <ContentContainer
        style={{
          marginBottom: '96px'
        }}
      >
        <Fragment>
          {flashMessagePayload ? (
            <ClaimFlashMessage>
              <FlashMessage
                onDismiss={onDismissFlashMessage}
                timeRemaining={FLASH_MESSAGE_TIMEOUT}
                payload={flashMessagePayload}
              />
            </ClaimFlashMessage>
          ) : null}
          <Container>
            <Row>
              <Col md={12}>
                {claim && claim.arbitration ? <ArbitrationDetails arbitration={claim.arbitration} /> : null}
                <ClaimBuilder claim={claim} hasGoneBack={hasGoneBack} />
                <ClaimDocuments claim={claim} claimItems={claimItems} />
                <SupportingInformation
                  claimId={claim.id}
                  isReadOnly={!isClaimInDraft && !isClaimInAwaitingSubstantiation}
                  setSupportingInformation={setClaimDetails}
                />
                <GuarantorConfirmation tenants={tenants} tenancyId={tenancy.id} claim={claim} />
                {isClaimInDraft && (
                  <ClaimChecks
                    editMode={isClaimInDraft}
                    disabled={!isClaimInDraft}
                    checks={claimChecks}
                    onUpdate={setClaimChecks}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <ClaimActions
                  claim={claim}
                  isClaimFinalised={isClaimFinalised}
                  isPublishable={isPublishable}
                  isClaimConfirmed={isClaimConfirmed}
                  claimLimitExceeded={claimLimitExceeded}
                  claimDetails={claimDetails}
                />
              </Col>
            </Row>
          </Container>
        </Fragment>
      </ContentContainer>
    </InnerWrapper>
  );
};

export default Claim;
