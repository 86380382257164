import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

interface TypographyProps {
  bold?: boolean;
  color?: string;
}

export const Header1 = styled.h1`
  color: ${props => (props.color ? props.color : props.theme.colors.body)};
  font-family: ${props => props.theme.typography.face.primary};
  font-size: 3em;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: 0.006em;
  margin-bottom: 1rem;
`;

export const Header2 = styled.h2`
  color: ${props => (props.color ? props.color : props.theme.colors.body)};
  font-family: ${props => props.theme.typography.face.primary};
  font-size: 2.25em;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: 0.006em;
  margin-bottom: 1rem;
`;

export const Header3 = styled.h3`
  color: ${props => (props.color ? props.color : props.theme.colors.body)};
  font-family: ${props => props.theme.typography.face.primary};
  font-size: 1.25em;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.8;
  letter-spacing: 0.006em;
  margin-bottom: 1rem;
`;

export const Header4 = styled.h4`
  color: ${props => (props.color ? props.color : props.theme.colors.body)};
  font-family: ${props => props.theme.typography.face.secondary};
  font-size: 1em;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.013em;
  margin-bottom: 1rem;
`;

export const Header5 = styled.h5`
  color: ${props => (props.color ? props.color : props.theme.colors.body)};
  font-family: ${props => props.theme.typography.face.secondary};
  font-size: 0.875em;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.013em;
  margin-bottom: 1rem;
`;

export const P1 = styled.p<TypographyProps>`
  color: ${props => (props.color ? props.color : props.theme.colors.body2)};
  font-family: ${props => props.theme.typography.face.secondary};
  font-size: 1.125em;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: 0.006em;
  margin-bottom: 1rem;
`;

export const P2 = styled.p<TypographyProps>`
  color: ${props => (props.color ? props.color : props.theme.colors.body2)};
  font-family: ${props => props.theme.typography.face.secondary};
  font-size: 1em;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.006em;
  margin-bottom: 1rem;
`;

export const P3 = styled.p<TypographyProps>`
  color: ${props => (props.color ? props.color : props.theme.colors.body2)};
  font-family: ${props => props.theme.typography.face.secondary};
  font-size: 0.75em;
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.006em;
  margin-bottom: 1rem;
`;

export const Caption = styled.p<TypographyProps>`
  color: ${props => props.theme.colors.placeholder};
  font-family: ${props => props.theme.typography.face.secondary};
  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
  font-size: 1.25em;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.4;
  letter-spacing: 0.007em;
  margin-bottom: 1rem;
`;

export const Label = styled.label<TypographyProps>`
  color: ${props => props.theme.colors.body2};
  font-family: ${props => props.theme.typography.face.secondary};
  font-size: 0.75em;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: 0.006em;
`;

export const StyledLink = styled(NavLink)`
  color: ${props => props.theme.colors.primaryBrand};
  display: inline;
  font-family: ${props => props.theme.typography.face.secondary};
  font-size: inherit;
  text-decoration: underline;
`;
