import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentRoute } from '../../redux/auth/auth.selectors';
import { REPOSIT_FAQS } from '../../constants/links';
import { push } from 'connected-react-router';

const Wrapper = styled.div`
  /* background: rgba(0, 0, 0, 0.5); */
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
`;

export const ResourcesView = () => {
  const currentRoute = useSelector(getCurrentRoute);
  const dispatch = useDispatch();
  useEffect(() => {
    if (currentRoute === '/resources') {
      window.open(REPOSIT_FAQS, '_blank');
      // ignoring prev route as they are being navigated away from the page anyway
      dispatch(push('/'));
    }
  }, [currentRoute, dispatch]);
  return <Wrapper />;
};
