import { get } from 'lodash';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Container, Row, Visible } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { Sticky, StickyContainer } from 'react-sticky';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import Checklist, { ChecklistItemStatus, ItemsDefinition } from '../../../components/Checklist';
import DocumentsList from '../../../components/DocumentsList';
import Panel from '../../../components/Panel/index';
import PropertySummary from '../../../components/PropertySummary/index';
import RepositChecks from '../../../components/Reposit/RepositChecks/index';
import { Header3, P2 } from '../../../components/Typography/index';
import { useIsOrganizationLandlord } from '../../../hooks/useIsLandlord';
import { clearActiveForms } from '../../../redux/active-form/active-form.actions';
import { getActiveForms } from '../../../redux/active-form/active-form.selector';
import { ActiveFormOptions } from '../../../redux/active-form/active-forms.types';
import { dismissAllFlashMessages } from '../../../redux/flash-messages/flash-messages.actions';
import { setIsPropertyFormOpen, setIsTenantFormOpen } from '../../../redux/reposit/reposit.actions';
import { AppState } from '../../../redux/root.reducer';
import { getDocuments, TenancyOrderWithActions } from '../../../redux/selectors/index';
import RepositActions from '../RepositActions/index';
import PropertyInfoContainer from './Property/index';
import TenancyContainer from './Tenancy/index';
import TenantsContainer from './Tenants/index';

const ChecklistContainer = styled.div``;

export interface IRepositProps {
  theme?: any;
  isEditable: boolean;
  isNew?: boolean;
  tenancyOrder?: TenancyOrderWithActions;
}

export default ({ isEditable, isNew, tenancyOrder }: IRepositProps) => {
  const dispatch = useDispatch();
  const isInCreate = !!isNew;
  const tenancy = tenancyOrder ? tenancyOrder.tenancy : undefined;
  const tenants = get(tenancyOrder, 'tenancy.tenants', []);
  const { type: landlordTypeId, firstName, lastName, companyName } = get(tenancyOrder, 'tenancy.property.landlord', {});
  const propertyId = get(tenancyOrder, 'tenancy.property.id');
  const documents = useSelector((state: AppState) => getDocuments(state, tenancyOrder && tenancyOrder.id));
  const activeForms = useSelector(getActiveForms);
  const [repositChecks, setRepositChecks] = useState({
    confirmRepositOfferedAsChoice: false,
    confirmTenantUnderstandsTerms: false,
    confirmReferenceChecks: false
  });

  const isOrgLandlord = useIsOrganizationLandlord();

  useEffect(() => {
    ReactTooltip.rebuild();
    dispatch(clearActiveForms());

    return function cleanup() {
      dispatch(dismissAllFlashMessages());
      dispatch(clearActiveForms());
      dispatch(setIsPropertyFormOpen(false));
      dispatch(setIsTenantFormOpen(false));
    };
  }, [dispatch]);

  const isRepositLive = !!(tenancyOrder && tenancyOrder.order.statusId === 'COMPLETE');
  const isRepositPending = !!(tenancyOrder && tenancyOrder.order.statusId === 'PENDING');
  const isTenancyCompleted = !!(tenancy && tenancy.PPM);

  const items: ItemsDefinition[] = [
    {
      label: 'Property Information',
      status: propertyId ? ChecklistItemStatus.COMPLETE : ChecklistItemStatus.INCOMPLETE
    },
    {
      label: 'Tenancy Details',
      status: isTenancyCompleted ? ChecklistItemStatus.COMPLETE : ChecklistItemStatus.INCOMPLETE
    },
    {
      label: 'Tenants',
      status: tenants.length ? ChecklistItemStatus.COMPLETE : ChecklistItemStatus.INCOMPLETE
    }
  ];

  const isAllStepsComplete = items.every(item => item.status === ChecklistItemStatus.COMPLETE);
  const isRepositConfirmed =
    repositChecks.confirmRepositOfferedAsChoice &&
    repositChecks.confirmTenantUnderstandsTerms &&
    repositChecks.confirmReferenceChecks;
  const isPublishable = isAllStepsComplete && isRepositConfirmed;

  const landlord = landlordTypeId === 'SOLE_PERSON' ? `${firstName} ${lastName}` : `${companyName}`;

  const isNotActive = (formName: ActiveFormOptions) => activeForms.length > 0 && !activeForms.includes(formName);

  const title = isOrgLandlord ? 'You are covered!' : 'Your landlord is covered!';
  const text = isOrgLandlord
    ? `Good news! You are covered for the duration of the Reposit, as the end date approaches we
    will be in contact to remind you to either extend this Reposit or begin the checkout process.`
    : `Good news! The landlord ${landlord} is covered for the duration of the Reposit, as the end date approaches we
  will be in contact to remind you to either extend this Reposit or begin the checkout process.`;

  return (
    <Fragment>
      <Container>
        <Row>
          <Col md={12}>
            <Visible md sm xs>
              {(isRepositLive || isRepositPending) && !!tenancyOrder && !!tenancyOrder.tenancy.property && (
                <Fragment>
                  <PropertySummary address={tenancyOrder.tenancy.property.address} />
                  {isRepositLive && (
                    <Panel style={{ marginBottom: 28 }}>
                      <Header3 color="#fff">{title}</Header3>
                      <P2 color="#fff">{text}</P2>
                    </Panel>
                  )}
                </Fragment>
              )}
            </Visible>
          </Col>
          <Col md={12}>
            <Visible md sm xs>
              <ChecklistContainer>
                {!isRepositLive && <Checklist lessMargin items={items} />}
                {isRepositLive && <DocumentsList documents={documents} />}
              </ChecklistContainer>
            </Visible>
          </Col>
          <Col md={12} lg={3}>
            <Visible lg>
              <StickyContainer style={{ height: '100%' }}>
                <Sticky topOffset={-28} bottomOffset={28}>
                  {({ style }) => (
                    <div style={style}>
                      {!isRepositLive && <Checklist items={items} />}
                      {isRepositLive && <DocumentsList documents={documents} />}
                    </div>
                  )}
                </Sticky>
              </StickyContainer>
            </Visible>
          </Col>
          <Col md={12} lg={9}>
            <Visible lg>
              {(isRepositLive || isRepositPending) && !!tenancyOrder && !!tenancyOrder.tenancy.property && (
                <Fragment>
                  <PropertySummary address={tenancyOrder.tenancy.property.address} />
                  {isRepositLive && (
                    <Panel style={{ marginBottom: 28 }}>
                      <Header3 color="#fff">{title}</Header3>
                      <P2 color="#fff">{text}</P2>
                    </Panel>
                  )}
                </Fragment>
              )}
            </Visible>
            <PropertyInfoContainer
              isEditable={isEditable}
              isNew={!!isNew}
              disabled={isNotActive(ActiveFormOptions.PROPERTY)}
              editMode={activeForms.indexOf(ActiveFormOptions.PROPERTY) !== -1}
              tenancyOrder={tenancyOrder}
            />
            <TenancyContainer
              isEditable={isEditable}
              editMode={activeForms.indexOf(ActiveFormOptions.TENANCY_DETAILS) !== -1}
              tenancyOrder={tenancyOrder}
              disabled={isInCreate || isNotActive(ActiveFormOptions.TENANCY_DETAILS)}
            />
            <TenantsContainer
              isInCreate={isInCreate}
              tenancyOrder={tenancyOrder}
              editMode={!isRepositLive && !isRepositPending}
              disabled={isInCreate || isNotActive(ActiveFormOptions.TENANTS)}
              disableAddNew={isRepositLive || isRepositPending}
              disableRemove={isRepositLive || isRepositPending}
              tenancyOrderStatus={(tenancyOrder && tenancyOrder.order.statusId) || ''}
            />
            {!isRepositLive && !isRepositPending && (
              <RepositChecks
                editMode={isEditable}
                disabled={isInCreate || isNotActive(ActiveFormOptions.REPOSIT_CHECKS)}
                checks={repositChecks}
                onUpdate={setRepositChecks}
              />
            )}
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <RepositActions
              disabled={isInCreate || isNotActive(ActiveFormOptions.REPOSIT_CONFIRM)}
              tenancyOrder={tenancyOrder}
              isPublishable={isPublishable}
              isRepositConfirmed={isRepositConfirmed}
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};
