import { createSelector } from 'reselect';
import { get, last } from 'lodash';

import { getTenancyOrder } from './tenancyOrder.selectors';
import { getClaims } from './claim.selectors';

export interface RepositSummaryData {
  id: string;
  tenancyId: string;
  dueDate?: string;
  claimId?: string;
  checkoutId?: string;
  status: string;
  address?: any;
  landlord?: string;
  startDate: string;
  endDate: string;
  createdAt: string;
}

export const makeGetRepositSummary = () => {
  return createSelector(
    [getTenancyOrder, getClaims],
    (tenancyOrder, claims): RepositSummaryData | undefined => {
      if (!tenancyOrder) return undefined;
      const tenancy = get(tenancyOrder, 'tenancy');
      const tenancyId = get(tenancy, 'id');
      const checkoutId = get(tenancy, 'checkout.id');
      const expiryDates = get(tenancy, 'expiryDates', []);
      const property = get(tenancy, 'property');
      const landlord = get(property, 'landlord');
      const address = get(property, 'address');
      const lastEndDate = last(expiryDates);
      const endDate = get(lastEndDate, 'endDate') as string;
      let claim;

      if (tenancy.checkout && tenancy.checkout.claim) {
        claim = claims[tenancy.checkout.claim.id];
      }

      let ll;

      if (landlord) {
        ll = landlord.companyName ? landlord.companyName : `${landlord.firstName} ${landlord.lastName}`;
      }
      return {
        id: tenancyOrder.id,
        tenancyId,
        checkoutId,
        dueDate: tenancy.outcomeDeadline,
        claimId: claim && claim.id,
        status: tenancyOrder.order.statusId,
        address,
        startDate: tenancy.startDate,
        endDate,
        landlord: ll,
        createdAt: tenancyOrder.createdAt
      };
    }
  );
};

export default makeGetRepositSummary;
