import { unwrapResult } from '@reduxjs/toolkit';
import { ClaimDTOStatusEnum, ClaimItemDTOTypeEnum } from '@reposit/api-client';
import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import ClaimDocumentUploader from '../../../components/ClaimDocumentUploader/index';
import { useAppDispatch } from '../../../index';
import {
  CREATE_CLAIM_DOCUMENT_STORE_KEY,
  DELETE_CLAIM_DOCUMENT_STORE_KEY
} from '../../../redux/claim-document/claim-document.actions';
import { createClaimDocumentThunk, deleteClaimDocumentThunk } from '../../../redux/claim-document/claim-document.thunk';
import { getClaimItemFormOpen } from '../../../redux/claim/claim.selector';
import { ClaimEntity, ClaimItemEntity } from '../../../redux/entities/entities.types';
import { AppState } from '../../../redux/root.reducer';
import { getDocumentsFromClaimById } from '../../../redux/selectors/claim.selectors';
import { useFlashMessage } from '../../FlashMessage/index';

interface ClaimDocumentsProps {
  claim: ClaimEntity;
  claimItems: ClaimItemEntity[];
}

const ClaimDocuments: React.FC<ClaimDocumentsProps> = ({ claim, claimItems }) => {
  const dispatch = useAppDispatch();

  const isClaimInDraft = claim.status === ClaimDTOStatusEnum.DRAFT;
  const isClaimAwaitingEvidence = claim.status === ClaimDTOStatusEnum.AWAITINGSUBSTANTIATION;
  const documents = useSelector((state: AppState) => getDocumentsFromClaimById(state, claim.id));
  const isClaimItemsFormOpen = useSelector(getClaimItemFormOpen);

  const isDisabled = isClaimItemsFormOpen;

  const handleDelete = (docId: string) =>
    dispatch(deleteClaimDocumentThunk({ claimId: claim.id, documentId: docId })).then(unwrapResult);

  const createDocument = (file: File, type: string) =>
    dispatch(createClaimDocumentThunk({ claimId: claim.id, file, type })).then(unwrapResult);

  const [flashMessagePayload, onDismissFlashMessage] = useFlashMessage([
    CREATE_CLAIM_DOCUMENT_STORE_KEY,
    DELETE_CLAIM_DOCUMENT_STORE_KEY
  ]);

  const disableUpload = !isClaimInDraft && !isClaimAwaitingEvidence;

  const isAllRentArrears =
    claimItems && claimItems.length ? claimItems.every(item => item.type === ClaimItemDTOTypeEnum.RENTARREARS) : false;

  return (
    <ClaimDocumentUploader
      disableUpload={disableUpload}
      isClaimInDraft={isClaimInDraft}
      documents={documents}
      handleDelete={handleDelete}
      createDocument={createDocument}
      flashMessage={flashMessagePayload}
      onDismissFlashMessage={onDismissFlashMessage}
      isDisabled={isDisabled}
      // TODO: IS THIS WHAT WE WANT????
      isClaimAwaitingEvidence={isClaimAwaitingEvidence}
      isMediationEnabled={get(claim, 'mediationEnabled', false)}
      isAllRentArrears={isAllRentArrears}
    />
  );
};

export default ClaimDocuments;
