import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ThumbUpIcon from '../../../assets/svg/onboarding/thumbs-up-green.svg';
import Button from '../../../components/Button/index';
import Actions from '../../../components/Onboarding/Actions/index';
import Title from '../../../components/Onboarding/Title/index';
import { UPDATE_COMPLETED_ONBOARDING_STORE_KEY, setIsOnboarding } from '../../../redux/account/account.actions';
import { P1 } from '../../../components/Typography';
import styled from 'styled-components';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import { FLASH_MESSAGE_TIMEOUT, useFlashMessage } from '../../FlashMessage';
import FlashMessage from '../../../components/FlashMessage';
import { getCurrentOrganizationId } from '../../../redux/auth/auth.selectors';
import { getOrganization } from '../../../redux/selectors/organization.selectors';

const Text = styled(P1)`
  margin: 0 0 60px 70px;
`;

const Complete: React.SFC<any> = () => {
  const dispatch = useDispatch();
  const onSubmit = () => dispatch(setIsOnboarding(false));
  const isLoading = useSelector(createLoadingSelector([UPDATE_COMPLETED_ONBOARDING_STORE_KEY]));
  const [flashMessagePayload, onDismissFlashMessage] = useFlashMessage([UPDATE_COMPLETED_ONBOARDING_STORE_KEY]);
  const orgId = useSelector(getCurrentOrganizationId);
  const org = useSelector(getOrganization(orgId));

  const integratorText =
    org && org.integrationPartnerName
      ? `We have sent your details over to ${org.integrationPartnerName} in order for them to activate the Reposit integration.`
      : '';
  return (
    <Fragment>
      <Title icon={ThumbUpIcon}>Looking good!</Title>
      <Text>Thanks for that! Looks like you’re all set up and ready to start using Reposit. {integratorText}</Text>
      {flashMessagePayload && (
        <FlashMessage onDismiss={onDismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMEOUT} payload={flashMessagePayload} />
      )}
      <Actions style={{ margin: 0 }}>
        <Button disabled={isLoading} onClick={onSubmit}>
          Continue to dashboard
        </Button>
      </Actions>
    </Fragment>
  );
};

export default Complete;
