import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppState } from '../root.reducer';
import { UpdateClosingRemindersEnabledPayload } from './reposit.types';
import { createStandardTenanciesApi, runThunkWithAuth } from '../utils/api.utils';
import { TenanciesApi, TenancyDTO } from '@reposit/api-client/dist';
import { AxiosResponse } from 'axios';
import { standardSyncEntitiesAndGetResults } from '../entities/entities.sagas';
import SCHEMA from '../schema';
import {
  updateClosingRemindersEnabledSuccess,
  updateClosingRemindersEnabledFailed,
  UPDATE_CLOSING_REMINDERS_ENABLED_STORE_KEY
} from './reposit.actions';
import { getErrorMessage } from '../../utils/common.utils';
import { setFlashMessage } from '../flash-messages/flash-messages.actions';
import { FlashState } from '../../components/FlashMessage/index';

export const updateClosingRemindersEnabledThunk = createAsyncThunk<
  void,
  UpdateClosingRemindersEnabledPayload,
  {
    state: AppState;
  }
>('reposit/update-closing-reminders-enabled', async (payload, thunkAPI) => {
  const state = thunkAPI.getState();
  const dispatch = thunkAPI.dispatch;
  try {
    const tenanciesAPI: TenanciesApi = createStandardTenanciesApi(state);

    const apiResponse: AxiosResponse<TenancyDTO> = await runThunkWithAuth(
      () => tenanciesAPI.closingRemindersEnabled(payload.tenancyId, { closingRemindersEnabled: payload.closingRemindersEnabled }),
      dispatch
    );
    standardSyncEntitiesAndGetResults(apiResponse.data, SCHEMA.tenancy, dispatch);
    dispatch(updateClosingRemindersEnabledSuccess());
    dispatch(
      setFlashMessage({
        key: UPDATE_CLOSING_REMINDERS_ENABLED_STORE_KEY,
        message: `Closing reminders ${payload.closingRemindersEnabled ? 'enabled' : 'disabled'} for Reposit`,
        state: FlashState.SUCCESS
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch(updateClosingRemindersEnabledFailed(error));
    dispatch(setFlashMessage({ key: UPDATE_CLOSING_REMINDERS_ENABLED_STORE_KEY, message: error, state: FlashState.ERROR }));
    throw e;
  }
});
