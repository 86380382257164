import { createAction } from '@reduxjs/toolkit';
import { LoginDTO, OrganizationWithRepositsAndRolesRolesEnum, ResetPasswordDTO } from '@reposit/api-client';
import { ResetPasswordPayload } from './auth.types';

export const LOGIN_STORE_KEY = 'AUTH/LOGIN';
export const SET_TOKEN = 'AUTH/SET_TOKEN';
export const SET_CURRENT_ROUTE = 'AUTH/SET_CURRENT_ROUTE';
export const LOGOUT = 'AUTH/LOGOUT';
export const SET_ORGANIZATION_ID = 'AUTH/SET_ORGANIZATION_ID';
export const SET_CURRENT_ORGANIZATION_ROLE = 'AUTH/SET_CURRENT_ORGANIZATION_ROLE';

export const RESET_PASSWORD_REQUEST_STORE_KEY = 'AUTH/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_STORE_KEY = 'AUTH/RESET_PASSWORD';
export const SET_PASSWORD_STORE_KEY = 'AUTH/SET_PASSWORD';

export const setOrganizationId = createAction<string>(SET_ORGANIZATION_ID);
export const setCurrentOrganizationRoles = createAction<OrganizationWithRepositsAndRolesRolesEnum[]>(
  SET_CURRENT_ORGANIZATION_ROLE
);
export const setToken = createAction<string>(SET_TOKEN);
export const setCurrentRoute = createAction<string>(SET_CURRENT_ROUTE);
export const logout = createAction(LOGOUT);

export const loginRequested = createAction<LoginDTO>(`${LOGIN_STORE_KEY}_API_REQUESTED`);
export const loginSuccess = createAction(`${LOGIN_STORE_KEY}_API_SUCCESS`);
export const loginFailed = createAction<string>(`${LOGIN_STORE_KEY}_API_FAILED`);

export const resetPasswordRequestRequested = createAction<string>(`${RESET_PASSWORD_REQUEST_STORE_KEY}_API_REQUESTED`);
export const resetPasswordRequestSuccess = createAction(`${RESET_PASSWORD_REQUEST_STORE_KEY}_API_SUCCESS`);
export const resetPasswordRequestFailed = createAction<string>(`${RESET_PASSWORD_REQUEST_STORE_KEY}_API_FAILED`);

export const resetPasswordRequested = createAction<ResetPasswordPayload>(`${RESET_PASSWORD_STORE_KEY}_API_REQUESTED`);
export const resetPasswordSuccess = createAction<string>(`${RESET_PASSWORD_STORE_KEY}_API_SUCCESS`);
export const resetPasswordFailed = createAction<string>(`${RESET_PASSWORD_STORE_KEY}_API_FAILED`);

export const setPasswordRequested = createAction<ResetPasswordDTO>(`${SET_PASSWORD_STORE_KEY}_API_REQUESTED`);
export const setPasswordSuccess = createAction(`${SET_PASSWORD_STORE_KEY}_API_SUCCESS`);
export const setPasswordFailed = createAction<string>(`${SET_PASSWORD_STORE_KEY}_API_FAILED`);
