import { TenancyOrderWithTenancyDTO, TenantDTO } from '@reposit/api-client';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import moment from 'moment';
import { get } from 'lodash';

import Button from '../../../components/Button/index';
import { DisableComponent } from '../../../components/Common/index';
import { setCurrentRepositActionModal } from '../../../redux/reposit/reposit.actions';
import { getCurrentRepositActionModal } from '../../../redux/reposit/reposit.selectors';
import RepositCheckoutModal from './modals/RepositCheckoutModal';
import RepositCreateModal from './modals/RepositCreateModal';
import RepositDiscardModal from './modals/RepositDiscardModal';
import RepositExtendModal from './modals/RepositExtendModal';

interface RepositActionProps {
  disabled: boolean;
  tenancyOrder: any;
  isPublishable: boolean;
  isRepositConfirmed: boolean;
}

export interface RepositActionModalProps {
  tenancyOrder: any;
  isPublishable: boolean;
  onDismiss: () => void;
}

const ActionsContainer = styled.div`
  margin: 36px 0 24px;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

interface ActionDefinition {
  id: string;
  label: string;
  renderModal: (props: RepositActionModalProps) => JSX.Element;
  buttonType: 'primary' | 'warning' | 'secondary' | 'tertiary' | undefined;
  noArrow: boolean;
  isDisabled: boolean;
  shouldRender: () => boolean;
}

const generateActions = (isRepositConfirmed: boolean, tenancyOrder: TenancyOrderWithTenancyDTO): ActionDefinition[] => {
  const now = moment();
  const isAfterStartDate =
    tenancyOrder && tenancyOrder.tenancy ? now.isAfter(moment(tenancyOrder.tenancy.startDate, 'YYYY-MM-DD')) : false;
  if (!tenancyOrder || !tenancyOrder.tenancy) {
    return [];
  }
  return [
    {
      id: 'EXTEND',
      label: 'Extend Reposit',
      buttonType: 'secondary',
      noArrow: true,
      isDisabled: false,
      shouldRender: () =>
        tenancyOrder.order.statusId === 'COMPLETE' &&
        !tenancyOrder.tenancy.checkout &&
        !tenancyOrder.tenancy.completedAt &&
        isAfterStartDate,
      renderModal: props => (
        <RepositExtendModal isPublishable={props.isPublishable} tenancyOrder={props.tenancyOrder} onDismiss={props.onDismiss} />
      )
    },
    {
      id: 'CHECKOUT',
      label: 'Start Checkout',
      buttonType: 'warning',
      noArrow: true,
      isDisabled: false,
      shouldRender: () =>
        tenancyOrder.order.statusId === 'COMPLETE' &&
        !tenancyOrder.tenancy.checkout &&
        !tenancyOrder.tenancy.completedAt &&
        isAfterStartDate,
      renderModal: props => (
        <RepositCheckoutModal isPublishable={props.isPublishable} tenancyOrder={props.tenancyOrder} onDismiss={props.onDismiss} />
      )
    },
    {
      id: 'CANCEL',
      label: 'Cancel Reposit',
      buttonType: 'warning',
      noArrow: true,
      isDisabled: false,
      shouldRender: () => {
        const tenants: TenantDTO[] = get(tenancyOrder, 'tenancy.tenants', []);
        const hasTenantSwap = !!tenants.find(t => t.isTenantSwap);
        return (
          (tenancyOrder.order.statusId === 'PENDING' && !hasTenantSwap) ||
          (tenancyOrder.order.statusId === 'COMPLETE' && !isAfterStartDate)
        );
      },
      renderModal: props => (
        <RepositDiscardModal isPublishable={props.isPublishable} tenancyOrder={props.tenancyOrder} onDismiss={props.onDismiss} />
      )
    },
    {
      id: 'DISCARD',
      label: 'Discard Reposit',
      buttonType: 'warning',
      noArrow: true,
      isDisabled: false,
      shouldRender: () => tenancyOrder.order.statusId === 'DRAFT',
      renderModal: props => (
        <RepositDiscardModal isPublishable={props.isPublishable} tenancyOrder={props.tenancyOrder} onDismiss={props.onDismiss} />
      )
    },
    {
      id: 'PUBLISH',
      label: 'Create Reposit',
      buttonType: 'primary',
      noArrow: false,
      isDisabled: !isRepositConfirmed,
      shouldRender: () => tenancyOrder.order.statusId === 'DRAFT',
      renderModal: props => (
        <RepositCreateModal isPublishable={props.isPublishable} tenancyOrder={props.tenancyOrder} onDismiss={props.onDismiss} />
      )
    }
  ];
};

const RepositActions: React.SFC<RepositActionProps> = ({ disabled, tenancyOrder, isPublishable, isRepositConfirmed }) => {
  const dispatch = useDispatch();
  const showModal = (actionId: string) => dispatch(setCurrentRepositActionModal(actionId));
  const currentModal = useSelector(getCurrentRepositActionModal);

  if (!tenancyOrder) return null;
  const actions = generateActions(isRepositConfirmed, tenancyOrder);

  const content = (
    <ActionsContainer>
      {actions.map(
        ({ id, buttonType, label, renderModal, noArrow, isDisabled, shouldRender }) =>
          shouldRender() && (
            <Fragment key={id}>
              <Button buttonType={buttonType} disabled={isDisabled} onClick={() => showModal(id)} noArrow={noArrow}>
                {label}
              </Button>
              {currentModal === id ? renderModal({ tenancyOrder, onDismiss: () => showModal(''), isPublishable }) : null}
            </Fragment>
          )
      )}
    </ActionsContainer>
  );

  return disabled ? <DisableComponent disabled={disabled}>{content}</DisableComponent> : content;
};

export default RepositActions;
