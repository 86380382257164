import {
  ClaimItemActionTypes,
  DISCARD_CLAIM_ITEM_API_SUCCESS,
  CREATE_CLAIM_ITEM_API_SUCCESS,
  CREATE_CLAIM_ITEM_PROPOSAL_SUCCESS
} from '../../claim-item/claim-item.actions';

export const claimProposalEntityReducer = (state: any = {}, action: ClaimItemActionTypes) => {
  switch (action.type) {
    case DISCARD_CLAIM_ITEM_API_SUCCESS:
      const claimProposal = state[action.payload.claimProposalId];
      return {
        ...state,
        [action.payload.claimProposalId]: {
          ...claimProposal,
          itemProposals: claimProposal.itemProposals.filter((ip: string) => ip !== action.payload.claimItemProposalId)
        }
      };
    case CREATE_CLAIM_ITEM_API_SUCCESS:
      if (action.payload.proposals) {
        const firstItemProposal = action.payload.proposals[0];
        const claimProp = state[firstItemProposal.claimProposalId];
        return {
          ...state,
          [claimProp.id]: {
            ...claimProp,
            itemProposals: [...claimProp.itemProposals, firstItemProposal.id]
          }
        };
      }
      return state;
    case CREATE_CLAIM_ITEM_PROPOSAL_SUCCESS:
      const cProposal = state[action.payload.claimProposalId];
      return {
        ...state,
        [cProposal.id]: {
          ...cProposal,
          itemProposals: [...cProposal.itemProposals, action.payload.id]
        }
      };
    default:
      return state;
  }
};
