import React from 'react';
import styled from 'styled-components';
import { isString } from 'lodash';

import { Label } from '../Typography';

import { Option } from '../../interfaces/option.interface';
import Select from '../FormFields/Select/index';
import { getBreakpoint } from '../../base/style';

const SortWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 80px;

  @media screen and (max-width: ${getBreakpoint('md')}) {
    flex: 0 0 auto;
    margin: 0 0 12px;
  }
`;

const FilterLabel = styled(Label)`
  margin: 0 20px 0 0;
  width: 80px;
`;

interface SortProps {
  label?: string;
  onSelect: (option: any) => void;
  selected: string;
  options: (string | Option)[];
  icon?: string;
}

const renderOptions = (props: SortProps): JSX.Element[] => {
  return props.options.map(option => {
    if (isString(option)) {
      return (
        <option value={option} key={option}>
          {option}
        </option>
      );
    } else {
      return (
        <option value={option.value} key={option.value}>
          {option.label}
        </option>
      );
    }
  });
};

export default (props: SortProps): JSX.Element => (
  <SortWrapper>
    {props.icon ? <img src={props.icon} style={{ marginRight: 10 }} alt="filter icon" /> : null}
    <FilterLabel>{props.label}</FilterLabel>
    <Select onChange={value => props.onSelect(value)} value={props.selected ? props.selected : 0}>
      {renderOptions(props)}
    </Select>
  </SortWrapper>
);
