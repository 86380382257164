import { TOGGLE_FEEDBACK_MODAL } from './feedback.actions';
import { FeedbackActionTypes } from './feedback.actions';
import { FeedbackState } from './feedback.types';

const initialState: FeedbackState = {
  isFeedbackModalOpen: false
};

export default (state = initialState, action: FeedbackActionTypes): FeedbackState => {
  switch (action.type) {
    case TOGGLE_FEEDBACK_MODAL:
      return {
        ...state,
        isFeedbackModalOpen: !state.isFeedbackModalOpen
      };

    default:
      return state;
  }
};
