import { AppState } from '../root.reducer';
import { OrganizationInviteEntity } from '../entities/entities.types';

interface GetInviteById {
  organizationInviteId: string;
}

export const getOrganizationInviteById = (state: AppState, { organizationInviteId }: GetInviteById): OrganizationInviteEntity => {
  return state.entities.organizationInvite[organizationInviteId];
};
