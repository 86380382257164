import React from 'react';
import styled from 'styled-components';
import { Header4, P3 } from '../Typography';
import { ContentContainer } from '../Common';
import PDFLogo from '../../assets/svg/acrobat-doc.svg';
import { RoundButton } from '../Button';
import DownloadIcon from '../../assets/svg/download.svg';
import { DocumentDTO } from '@reposit/api-client';

interface RepositDocumentsProps {
  documents: DocumentDTO[];
  label?: string;
}
const Documentlist = styled.ul`
  margin: 24px 0 56px;
  padding: 0 16px 0 0;
`;

const DocumentListItem = styled.li`
  margin: 0 0 20px;
`;

const Label = styled.div`
  color: ${props => (props.color ? props.color : props.theme.colors.body2)};
  font-family: ${props => props.theme.typography.face.secondary};
  line-height: 1.5;
  letter-spacing: 0.006em;
  margin-bottom: 1rem;
  align-items: center;
  display: flex;
  font-size: 12px;
  margin: 0;

  &:before {
    background: transparent url(${PDFLogo}) no-repeat center center;
    background-size: 20px;
    content: '';
    display: block;
    height: 20px;
    margin: 0 7px 0 0;
    width: 20px;
  }
`;

const renderListItems = (items: DocumentDTO[]): JSX.Element[] => {
  return items.map((item: DocumentDTO) => (
    <DocumentListItem key={item.name}>
      <Label>
        {item.name}
        <a href={item.url} target="_blank" rel="noopener noreferrer" style={{ margin: '0 0 0 auto' }}>
          <RoundButton icon={DownloadIcon} />
        </a>
      </Label>
    </DocumentListItem>
  ));
};

const DocumentsList: React.SFC<RepositDocumentsProps> = ({ documents, label }) => {
  return (
    <ContentContainer>
      <Header4>{label || 'Documents'}</Header4>
      {documents.length ? (
        <Documentlist>{renderListItems(documents)}</Documentlist>
      ) : (
        <P3>You do not have any documents associated with this Reposit</P3>
      )}
    </ContentContainer>
  );
};

export default DocumentsList;
