import { OrganizationWithRepositsAndRolesRolesEnum } from '@reposit/api-client';
import React, { Fragment, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { MainContent } from './components/Common';
import GeneralError from './components/GeneralError/GeneralError';
import MessageBar, { OrganizationMessageType } from './components/MessageBar/index';
import MobileHeader from './components/MobileHeader';
import NoAccount from './components/NoAccount/index';
import { PrivateRoute } from './components/PrivateRoute';
import SideMenu from './components/SideMenu';
import BankAccountsView from './containers/AccountSettings/BankAccounts/index';
import IntegrationView from './containers/AccountSettings/Integration/index';
import NotificationsView from './containers/AccountSettings/Notifications/index';
import TeamView from './containers/AccountSettings/Team/index';
import ActionRequired from './containers/ActionRequired';
import AppHeader from './containers/AppHeader';
import AuthenticateView from './containers/Authenticate';
import ClaimView from './containers/Claim/Claim/index';
import ClaimsList from './containers/Claim/ClaimsList';
import { Login } from './containers/Auth/Login/login';
import NotFoundView from './containers/NotFound';
import OnboardingContainer from './containers/Onboarding';
import { OrganizationSelection } from './containers/OrganizationSelection/index';
import CreateRepositView from './containers/Reposit/Create';
import RepositsView from './containers/Reposit/List';
import RepositView from './containers/Reposit/View';
import { ResourcesView } from './containers/Resources/index';
import { history } from './index';
import { getAccessToken, getCurrentOrganizationId, getHasOrganizationRole } from './redux/auth/auth.selectors';
import { getCurrentLocation } from './redux/selectors/index';
import { getIsOnboardingCompleteForUser, getIsOnboardingCompleteForOrg } from './redux/selectors/onboarding.selectors';
import { getShowBankAccountsFeature, getShowBankAccountsWarning } from './redux/selectors/organization.selectors';
import { getCurrentUser } from './redux/selectors/user.selectors';
import { toggleMenu } from './redux/system/system.actions';
import { getIsMobileMenuOpen } from './redux/system/system.selectors';
import { ResetPasswordRequest } from './containers/Auth/ResetPasswordRequest/reset-password-request';
import { ResetPassword } from './containers/Auth/ResetPassword/reset-password';
import { SetPassword } from './containers/Auth/SetPassword/set-password';
import { Maintenance } from './containers/Maintenance/maintenance';
import { appConfig } from './appConfig';

interface RouteDefinition {
  path: string;
  exact: boolean;
  mobileMenu: boolean;
  isFullWidth: boolean;
  nav?: () => JSX.Element | null;
  header?: () => JSX.Element | null;
  main: (props?: any) => JSX.Element;
}

const navigation = <SideMenu />;
const routes: RouteDefinition[] = [
  {
    path: '/',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader showCreateRepositButton />,
    main: (props: any) => <Redirect to="/reposits" />
  },
  {
    path: '/reposits/new',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader showBackButton />,
    main: (props: any) => <CreateRepositView {...props} />
  },
  {
    path: '/reposits/:id',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader showCreateRepositButton />,
    main: (props: any) => <RepositView {...props} />
  },
  {
    path: '/claims/:id',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader showUserMessages />,
    main: (props: any) => <ClaimView {...props} />
  },
  {
    path: '/reposits',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader showCreateRepositButton />,
    main: (props: any) => <RepositsView {...props} />
  },
  {
    path: '/claims',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader />,
    main: (props: any) => <ClaimsList {...props} />
  },
  {
    path: '/claims/:id/action-required',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader />,
    main: (props: any) => <ActionRequired {...props} />
  },
  {
    path: '/account/team',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader />,
    main: (props: any) => <TeamView {...props} />
  },
  {
    path: '/account/notifications',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader />,
    main: (props: any) => <NotificationsView {...props} />
  },
  {
    path: '/account/integration',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader />,
    main: (props: any) => <IntegrationView {...props} />
  },
  {
    path: '/account/bank-accounts',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => navigation,
    header: () => <AppHeader />,
    main: (props: any) => <BankAccountsView {...props} />
  },
  {
    path: '/account/get-started',
    exact: false,
    mobileMenu: false,
    isFullWidth: true,
    nav: () => null,
    header: () => null,
    main: (props: any) => <OnboardingContainer {...props} />
  },
  {
    path: '/organization-selection',
    exact: false,
    mobileMenu: false,
    isFullWidth: true,
    nav: () => null,
    header: () => null,
    main: (props: any) => <OrganizationSelection />
  },
  {
    path: '/resources',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => null,
    header: () => null,
    main: () => <ResourcesView />
  },
  {
    path: '/authenticate',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => null,
    header: () => null,
    main: (props: any) => <AuthenticateView {...props} />
  },
  {
    path: '/error',
    exact: true,
    mobileMenu: true,
    isFullWidth: false,
    nav: () => null,
    header: () => null,
    main: (props: any) => <GeneralError />
  },
  {
    path: '/auth/login',
    exact: true,
    mobileMenu: false,
    isFullWidth: true,
    nav: () => null,
    header: () => null,
    main: (props: any) => <Login />
  },
  {
    path: '/auth/reset-password-request',
    exact: true,
    mobileMenu: false,
    isFullWidth: true,
    nav: () => null,
    header: () => null,
    main: (props: any) => <ResetPasswordRequest />
  },
  {
    path: '/auth/reset-password',
    exact: true,
    mobileMenu: false,
    isFullWidth: true,
    nav: () => null,
    header: () => null,
    main: (props: any) => <ResetPassword />
  },
  {
    path: '/auth/set-password',
    exact: true,
    mobileMenu: false,
    isFullWidth: true,
    nav: () => null,
    header: () => null,
    main: (props: any) => <SetPassword />
  },
  {
    path: '*',
    exact: false,
    mobileMenu: false,
    isFullWidth: true,
    nav: () => null,
    header: () => null,
    main: (props: any) => <NotFoundView {...props} />
  }
];

const isPublicRoute = (path: string) => {
  return (
    path === '/login' ||
    path === '/authenticate' ||
    path === '/error' ||
    path === '/auth/login' ||
    path === '/auth/reset-password-request' ||
    path === '/auth/reset-password' ||
    path === '/auth/set-password'
  );
};

const Routes = () => {
  const dispatch = useDispatch();
  const toggleSideMenu = () => dispatch(toggleMenu());
  const token = useSelector(getAccessToken);
  const hasUserCompletedOnboarding = useSelector(getIsOnboardingCompleteForUser);
  const hasOrgCompletedOnboarding = useSelector(getIsOnboardingCompleteForOrg);
  const isMobileMenuOpen = useSelector(getIsMobileMenuOpen);
  const currentLocation = useSelector(getCurrentLocation);
  const currentlyInOnboarding = currentLocation.pathname.startsWith('/account/get-started');
  const currentUser = useSelector(getCurrentUser);
  const hasBankAccountRole = useSelector(getHasOrganizationRole(OrganizationWithRepositsAndRolesRolesEnum.BANKACCOUNTS));
  const orgId = useSelector(getCurrentOrganizationId);
  const currentlyInSelectOrg = currentLocation.pathname.startsWith('/organization-selection');

  const showBankAccountWarning = useSelector(getShowBankAccountsWarning);
  const showBankAccountFeature = useSelector(getShowBankAccountsFeature);
  let messageType: OrganizationMessageType | undefined;
  if (showBankAccountFeature) {
    messageType = OrganizationMessageType.FEATURE_BANK_ACCOUNT;
  }
  if (showBankAccountWarning) {
    messageType = OrganizationMessageType.WARNING_BANK_ACCOUNT;
  }

  if (appConfig.inMaintenanceMode) {
    return (
      <MainContent isMobileMenuOpen={false} isFullWidth={true}>
        <Maintenance />
      </MainContent>
    );
  }

  const noAccountFound = currentUser && !currentUser.organizations.length;

  if (noAccountFound) {
    return <NoAccount />;
  }

  // only want to redirect the user if they exist in the system (stops the redirect bug)
  const onBoardingNotComplete =
    currentUser &&
    (!hasUserCompletedOnboarding || !hasOrgCompletedOnboarding) &&
    !currentlyInOnboarding &&
    currentUser.organizations;

  if (onBoardingNotComplete && !currentlyInSelectOrg) {
    return <Redirect to="/account/get-started" />;
  }

  // token is important here because this route is a private route and requires a token
  // goes into an infinite loop without this *******************************************************
  if (!orgId && !currentlyInSelectOrg && token && currentUser.organizations.length > 1) {
    return <Redirect to="/organization-selection" />;
  }

  const routesCopy = [...routes];
  routesCopy.shift();
  const catchAllRoute = routesCopy.pop();
  const currentRoute = routesCopy.find(route => history.location.pathname.startsWith(route.path)) || catchAllRoute;

  return (
    <Fragment>
      <Switch>
        {routes.map((route, index) => {
          const props = {
            key: index,
            path: route.path,
            exact: route.exact,
            component: route.nav
          };
          if (isPublicRoute(route.path)) {
            return <Route {...props} />;
          }
          return <PrivateRoute {...props} />;
        })}
      </Switch>
      <MainContent isFullWidth={currentRoute && currentRoute.isFullWidth} isMobileMenuOpen={isMobileMenuOpen}>
        {hasBankAccountRole && messageType && <MessageBar type={messageType} />}
        <Switch>
          {routes.map((route, index) => {
            const props = {
              key: index,
              path: route.path,
              exact: route.exact,
              component: () =>
                route.mobileMenu ? <MobileHeader onMenuClick={toggleSideMenu} isMobileMenuOpen={isMobileMenuOpen} /> : null
            };
            if (isPublicRoute(route.path)) {
              return <Route {...props} />;
            }
            return <PrivateRoute {...props} />;
          })}
        </Switch>
        <Suspense fallback={null}>
          <Switch>
            {routes.map((route, index) => {
              const props = {
                key: index,
                path: route.path,
                exact: route.exact,
                component: route.header
              };
              if (isPublicRoute(route.path)) {
                return <Route {...props} />;
              }
              return <PrivateRoute {...props} />;
            })}
          </Switch>
          <Switch>
            {routes.map((route, index) => {
              const props = {
                key: index,
                path: route.path,
                exact: route.exact,
                component: route.main
              };
              if (isPublicRoute(route.path)) {
                return <Route {...props} />;
              }
              return <PrivateRoute {...props} />;
            })}
          </Switch>
        </Suspense>
      </MainContent>
    </Fragment>
  );
};

export default Routes;
