import React, { Fragment } from 'react';
import styled from 'styled-components';
import TenantsIcon from '../../assets/svg/tenants.svg';
import Button from '../Button';
import { DisableComponent } from '../Common/index';
import RepositCard from '../Reposit/RepositCard';
import TenantList from '../TenantList/index';
import { P1, StyledLink } from '../Typography';
import { FlashMessagePayload } from '../../redux/flash-messages/flash-messages.types';
import FlashMessage from '../FlashMessage/index';
import TenantForm, { TenantFormValues } from '../TenantForm/index';
import { TenantWithActions } from '../../redux/selectors/index';
import { useParams } from 'react-router';

const TenantListContainer = styled.div`
  padding: 0 36px;
`;

interface TenantProps {
  tenancyId: string;
  editMode: boolean;
  disabled: boolean;
  tenants: TenantWithActions[];
  disableAddNew: boolean;
  disableRemove: boolean;
  addTenant: (tenant: TenantFormValues) => void;
  isTenantFormShowing: boolean;
  showTenantForm: (value: boolean) => void;
  deleteTenant: (id: string) => void;
  flashMessage?: FlashMessagePayload;
  onDismissFlashMessage: () => void;
  tenancyOrderStatus: string;
}

const Link = styled(StyledLink)`
  margin: 0 20px;
`;

export default ({
  tenancyId,
  editMode,
  disabled,
  tenants,
  addTenant,
  isTenantFormShowing,
  showTenantForm,
  deleteTenant,
  onDismissFlashMessage,
  flashMessage,
  disableAddNew,
  disableRemove,
  tenancyOrderStatus
}: TenantProps) => {
  const { id: tenancyOrderId } = useParams<{ id: string }>();

  const tenantFormActions = (
    <Fragment>
      <Link to="#" onClick={() => showTenantForm(false)}>
        Cancel add tenant
      </Link>
      <Button>Add Tenant</Button>
    </Fragment>
  );

  const content = (
    <RepositCard
      title="Tenants"
      icon={TenantsIcon}
      tooltip="Details of all tenants named on the tenancy agreement. Tenants will not be invited to complete their steps until the Reposit is created."
      flush
      flashMessage={
        flashMessage ? <FlashMessage onDismiss={onDismissFlashMessage} timeRemaining={3000} payload={flashMessage} /> : undefined
      }
    >
      {tenants.length > 0 ? (
        <div style={{ marginBottom: 36 }}>
          <TenantList
            tenancyId={tenancyId}
            tenants={tenants}
            onDelete={deleteTenant}
            editMode={editMode}
            disableRemove={disableRemove}
            tenancyOrderStatus={tenancyOrderStatus}
            tenancyOrderId={tenancyOrderId}
          />
          {isTenantFormShowing ? (
            <TenantListContainer>
              <TenantForm onSubmit={addTenant} formActions={tenantFormActions} />
            </TenantListContainer>
          ) : (
            <TenantListContainer>
              {!disableAddNew && (
                <div style={{ textAlign: 'right' }}>
                  <Button buttonType="secondary" onClick={() => showTenantForm(true)}>
                    Add another tenant
                  </Button>
                </div>
              )}
            </TenantListContainer>
          )}
        </div>
      ) : (
        <div style={{ marginBottom: 36 }}>
          {isTenantFormShowing ? (
            <TenantListContainer>
              <TenantForm onSubmit={addTenant} formActions={tenantFormActions} />
            </TenantListContainer>
          ) : (
            <TenantListContainer>
              <P1 style={{ marginBottom: '1.25em' }}>You haven’t added any tenants to this Reposit yet.</P1>
              {!disableAddNew && (
                <div style={{ textAlign: 'right' }}>
                  <Button buttonType="secondary" onClick={() => showTenantForm(true)}>
                    Add a tenant
                  </Button>
                </div>
              )}
            </TenantListContainer>
          )}
        </div>
      )}
    </RepositCard>
  );

  return disabled ? <DisableComponent disabled={disabled}>{content}</DisableComponent> : content;
};
