import { CreateGuarantorDTO, TenancyUsersApi, TenantDTO } from '@reposit/api-client';
import { AxiosResponse } from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { FlashState } from '../../components/FlashMessage/index';
import { getErrorMessage } from '../../utils/common.utils';
import { syncEntitiesAndGetResults } from '../entities/entities.sagas';
import { FlashMessagesActionTypes, setFlashMessage } from '../flash-messages/flash-messages.actions';
import SCHEMA from '../schema';
import { createTenancyUsersApi, runSagaWithAuth } from '../utils/api.utils';
import {
  addGuarantorFailed,
  addGuarantorRequested,
  addGuarantorSuccess,
  ADD_GUARANTOR_STORE_KEY,
  setGuarantorModal,
  updateGuarantorFailed,
  updateGuarantorRequested,
  updateGuarantorSuccess,
  UPDATE_GUARANTOR_STORE_KEY
} from './guarantor.actions';
import { AddGuarantor, UpdateGuarantor } from './guarantor.types';

export function* addGuarantor({ payload }: { payload: AddGuarantor; type: string }) {
  try {
    const { tenancyUserId, tenancyId, email, mobileNumber, firstName, lastName } = payload;
    const createGuarantorDTO: CreateGuarantorDTO = { firstName, lastName, email, mobileNumber };
    const tenancyUsersApi: TenancyUsersApi = yield createTenancyUsersApi();
    const { data }: AxiosResponse<TenantDTO> = yield call(
      runSagaWithAuth(() => tenancyUsersApi.addGuarantor(tenancyUserId, tenancyId, createGuarantorDTO))
    );

    yield syncEntitiesAndGetResults(data, SCHEMA.tenant);

    yield put(addGuarantorSuccess());
    yield put(setGuarantorModal({ isOpen: false, modalState: undefined }));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: ADD_GUARANTOR_STORE_KEY,
        message: 'Success! You have added a guarantor for this tenant',
        state: FlashState.SUCCESS
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put(addGuarantorFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: ADD_GUARANTOR_STORE_KEY,
        message: error,
        state: FlashState.ERROR
      })
    );
  }
}

export function* updateGuarantor({ payload }: { payload: UpdateGuarantor; type: string }) {
  try {
    const { tenancyUserId, tenancyId, email, mobileNumber, firstName, lastName } = payload;
    const createGuarantorDTO: CreateGuarantorDTO = { firstName, lastName, email, mobileNumber };
    const tenancyUsersApi: TenancyUsersApi = yield createTenancyUsersApi();
    const { data }: AxiosResponse<TenantDTO> = yield call(
      runSagaWithAuth(() => tenancyUsersApi.updateGuarantor(tenancyUserId, tenancyId, createGuarantorDTO))
    );

    yield syncEntitiesAndGetResults(data, SCHEMA.tenant);
    yield put(updateGuarantorSuccess());
    yield put(setGuarantorModal({ isOpen: false, modalState: undefined }));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_GUARANTOR_STORE_KEY,
        message: 'Success! You have updated the guarantor for this tenant ',
        state: FlashState.SUCCESS
      })
    );
  } catch (e) {
    const error = getErrorMessage(e);
    yield put(updateGuarantorFailed(error));
    yield put<FlashMessagesActionTypes>(
      setFlashMessage({
        key: UPDATE_GUARANTOR_STORE_KEY,
        message: error,
        state: FlashState.ERROR
      })
    );
  }
}

// ****************
// WATCHERS
// ****************
export function* watchGuarantorSagas() {
  yield takeLatest(addGuarantorRequested.type, addGuarantor);
  yield takeLatest(updateGuarantorRequested.type, updateGuarantor);
}
