import { createReducer } from '@reduxjs/toolkit';

import { AccountState } from './account.types';
import {
  fetchMeSuccess,
  fetchMeFailed,
  setAccountCurrentAddressForm,
  updateNPSModal,
  setHasRequestedPasswordReset,
  setIsOnboarding
} from './account.actions';
import { AddressForm } from '../reposit/reposit.actions';
import { resetPasswordSuccess } from '../auth/auth.actions';

const initialState: AccountState = {
  currentUserId: '',
  hasFetched: false,
  currentAddressForm: AddressForm.POSTCODE,
  isNPSModalOpen: false,
  collectingTrustpilotReview: false,
  requiresNPSResponse: false,
  surveyEvent: undefined,
  hasRequestedResetPassword: false,
  hasResetPassword: false,
  persistedEmail: '',
  isOnboarding: false
};

export default createReducer(initialState, builder => {
  builder
    .addCase(fetchMeSuccess, (state, action) => {
      const { currentUserId, requiresNPSResponse } = action.payload;
      state.requiresNPSResponse = requiresNPSResponse;
      state.currentUserId = currentUserId;
      state.hasFetched = true;
    })
    .addCase(fetchMeFailed, (state, action) => {
      state.error = action.payload;
      state.hasFetched = true;
    })
    .addCase(setAccountCurrentAddressForm, (state, action) => {
      state.currentAddressForm = action.payload;
    })
    .addCase(updateNPSModal, (state, action) => {
      state.isNPSModalOpen = action.payload.isOpen;
      state.surveyEvent = action.payload.surveyEvent;
      state.collectingTrustpilotReview = action.payload.collectingTrustpilotReview;
    })
    .addCase(setHasRequestedPasswordReset, (state, action) => {
      state.hasRequestedResetPassword = action.payload;
    })
    .addCase(resetPasswordSuccess, (state, action) => {
      state.hasResetPassword = true;
      state.persistedEmail = action.payload;
    })
    .addCase(setIsOnboarding, (state, action) => {
      state.isOnboarding = action.payload;
    });
});
