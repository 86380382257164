import { ClaimDTOStatusEnum, ClaimItemDTOTypeEnum } from '@reposit/api-client';
import { omit, startCase, toLower } from 'lodash';
import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SlideDown from 'react-slidedown';
import styled from 'styled-components';
import Button from '../../../components/Button';
import AuditTrail from '../../../components/Library/SupplierOffer/AuditTrail/AuditTrail';
import { ClaimItem } from '../../../components/Library/SupplierOffer/ClaimItem/ClaimItem';
import {
  getAgentDocumentsToShow,
  getTenantDocumentsToShow
} from '../../../components/Library/SupplierOffer/ClaimItem/ClaimItemDropdown';
import Loading from '../../../components/Loading/index';
import Modal from '../../../components/Modal/index';
import SecondaryPanel from '../../../components/SecondaryPanel';
import { Header3, Header4, P2, P3, StyledLink } from '../../../components/Typography';
import {
  discardClaimItemRequested,
  DISCARD_CLAIM_ITEM_STORE_KEY,
  updateClaimItemRequested
} from '../../../redux/claim-item/claim-item.actions';
import { ClaimItemProposalEntity } from '../../../redux/entities/entities.types';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import { DELETE_TENANT_STORE_KEY } from '../../../redux/reposit/reposit.actions';
import { AppState } from '../../../redux/root.reducer';
import {
  getAllAgentDocumentsAndProposalsForClaimItem,
  getAllTenantDocumentsAndProposalsForClaimItem,
  getDocumentsByItemProposalId
} from '../../../redux/selectors/mediation.selectors';
import { penceToPounds } from '../../../utils/common.utils';
import ClaimItemForm, { ClaimItemFormValues, ComponentAction } from './ClaimItemForm';

interface ClaimLineItemProps {
  claimId: string;
  claimStatus: ClaimDTOStatusEnum;
  item: ClaimItem;
  proposal: ClaimItemProposalEntity;
  publishedAndEditable: boolean;
  lastClaimItem: boolean;
}

const ItemDescription = styled(Header4)`
  margin: 0 auto 0 0;
`;

const LineItem = styled.div`
  display: flex;

  button {
    height: 25px;
  }
`;

const Amount = styled(Header4)`
  margin: 0 34px 0 34px;
  padding: 0;
`;

const RemoveButton = styled(Button)`
  background: #ffe6e6;
  border-color: #ffe6e6;
  color: #f03838;
  margin: 0 0 0 10px;

  &:hover {
    background: #f03838;
    border-color: #f03838;
    color: #ffe6e6;
  }
`;

const EvidenceSummary = styled.div`
  align-items: center;
  margin: 8px 0 0;
  display: flex;

  p {
    margin: 0 6px 0 0;
    padding: 0;
  }
`;

const Link = styled(StyledLink)`
  font-size: 12px;
  margin: 0 30px;
  padding: 0;
`;

const EvidenceChecklist = styled(P3)`
  color: ${props => props.theme.colors.primaryBrand};
  cursor: pointer;
  display: block;
  display: flex;
  margin: 0 0 12px 30px;

  span {
    text-decoration: underline;
  }

  &:after {
    content: '+';
    display: block;
    margin: 0 6px;
  }
`;

const Action = styled.div`
  padding: 12px 0 0;
  text-align: right;

  button {
    margin: 0 6px;
  }
`;

const BoldText = styled.span`
  font-weight: bold;
`;

const ClaimLineItem: React.FC<ClaimLineItemProps> = ({
  claimId,
  claimStatus,
  item,
  publishedAndEditable,
  lastClaimItem,
  proposal
}) => {
  const dispatch = useDispatch();
  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const claimItemId = item.id;
  const claimInDraft = claimStatus === ClaimDTOStatusEnum.DRAFT;

  const documents = useSelector((state: AppState) => getDocumentsByItemProposalId(state, proposal.id));
  const onDeleteItem = (deactivateClaim: boolean) => {
    dispatch(discardClaimItemRequested({ claimId: item.claimId, claimItemId, deactivateClaim }));
  };

  const [isFormShowing, setIsFormShowing] = useState(false);
  const [isModalShowing, showModal] = useState(false);

  const deleteTenantLoadingSelector = createLoadingSelector([DELETE_TENANT_STORE_KEY]);
  const deleteClaimItemLoadingSelector = createLoadingSelector([DISCARD_CLAIM_ITEM_STORE_KEY]);
  const isDeleteTenantLoading = useSelector(deleteTenantLoadingSelector);
  const isDeleteClaimItemLoading = useSelector(deleteClaimItemLoadingSelector);

  const agentDocumentsAndProposals = useSelector((state: AppState) =>
    getAllAgentDocumentsAndProposalsForClaimItem(state, item.id)
  );
  const tenantDocumentsAndProposals = useSelector((state: AppState) =>
    getAllTenantDocumentsAndProposalsForClaimItem(state, item.id)
  );

  const agentDocumentsToShow = getAgentDocumentsToShow(agentDocumentsAndProposals);
  const tenantDocumentsToShow = getTenantDocumentsToShow(tenantDocumentsAndProposals);

  const moreThanOneDocument = !!documents.length && documents.length > 1;
  const claimItemTypeIsOther = item.type === ClaimItemDTOTypeEnum.OTHER;

  const showDeleteButton =
    (moreThanOneDocument && (publishedAndEditable || claimStatus === ClaimDTOStatusEnum.DRAFT)) || claimItemTypeIsOther;

  const modalText =
    lastClaimItem && publishedAndEditable ? (
      <span>
        You are about to delete the only claim item for this claim.{' '}
        <BoldText>This will deactivate the claim, and cannot be undone.</BoldText>
      </span>
    ) : (
      ''
    );

  const editClaimItem = (values: ClaimItemFormValues) => {
    dispatch(
      updateClaimItemRequested({ claimItemId, claimId, data: { ...omit(values, 'documentIds'), amount: values.amount as any } })
    );
    setIsFormShowing(false);
  };

  if (isDeleteClaimItemLoading && lastClaimItem) {
    return <Loading />;
  }

  return isFormShowing ? (
    <SecondaryPanel style={{ marginBottom: 6 }}>
      <ClaimItemForm
        title="Edit claim item"
        onSubmit={editClaimItem}
        claimItemDocs={documents}
        showDeleteButton={showDeleteButton}
        publishedAndEditable={publishedAndEditable}
        claimId={claimId}
        initialValues={{
          type: item.type,
          description: item.description,
          amount: proposal.amount / 100
        }}
        action={ComponentAction.EDIT}
        claimItemId={claimItemId}
        formActions={
          <Fragment>
            <Link to="#" onClick={() => setIsFormShowing(false)}>
              Cancel
            </Link>
            <Button type="submit" buttonType="secondary" disabled={isUploading}>
              Save Claim Item
            </Button>
          </Fragment>
        }
        onUploadComplete={() => setIsUploading(false)}
        onUploadStart={() => setIsUploading(true)}
      />
    </SecondaryPanel>
  ) : (
    <SecondaryPanel style={{ marginBottom: 6 }}>
      <LineItem>
        <ItemDescription>{item.description}</ItemDescription>
        {/* <Amount>£{numeral(proposal.amount).format(NumberFormat.THOUSANDS_PENCE)}</Amount> */}
        <Amount>£{penceToPounds(proposal.amount)}</Amount>

        {/* TODO: Display info post draft status properly */}
        {(claimStatus === ClaimDTOStatusEnum.DRAFT || publishedAndEditable) && (
          <Fragment>
            <Button
              mini={'true'}
              buttonType="secondary"
              noArrow={true}
              onClick={() => {
                setIsFormShowing(true);
              }}
            >
              Edit
            </Button>

            <RemoveButton mini={'true'} noArrow={true} onClick={() => showModal(true)}>
              Remove
            </RemoveButton>
          </Fragment>
        )}
        {isModalShowing ? (
          <Modal onDismiss={() => showModal(false)}>
            {isDeleteTenantLoading ? (
              <Loading />
            ) : (
              <Fragment>
                <Header3 style={{ marginBottom: 6 }}>Are you sure you wish to remove this claim item?</Header3>
                <P2 style={{ marginBottom: 24 }}>{modalText}</P2>
                <Action>
                  <Button buttonType="secondary" noArrow={true} onClick={() => showModal(false)}>
                    No, return to form
                  </Button>
                  <Button onClick={() => onDeleteItem(lastClaimItem && publishedAndEditable)}>Yes</Button>
                </Action>
              </Fragment>
            )}
          </Modal>
        ) : null}
      </LineItem>
      <EvidenceSummary>
        <P3>{startCase(toLower(item.type))}</P3>
        {!claimInDraft ? (
          <EvidenceChecklist onClick={() => setIsSliderOpen(!isSliderOpen)}>
            <span>Show details</span>
          </EvidenceChecklist>
        ) : null}
      </EvidenceSummary>
      <SlideDown closed={!isSliderOpen} className={`evidence-slider`}>
        <AuditTrail
          agentDocuments={agentDocumentsToShow}
          tenantDocuments={tenantDocumentsToShow}
          itemProposals={item.itemProposals}
        />
      </SlideDown>
    </SecondaryPanel>
  );
};

export default ClaimLineItem;
