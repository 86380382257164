import { RepositSort } from '../../constants/reposit';

export interface RepositListFilters {
  query: string;
  filter: string;
  sort: RepositSort;
  tenancyStatus: string;
}

export interface RepositListState {
  results: string[];
  filters: RepositListFilters;
  count: number;
  page: number;
  lastPage?: number;
}

export enum APISortOrder {
  CREATED_AT = 'CREATED_AT',
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE'
}
