import { AppState } from '../root.reducer';
import { OrganizationWithRepositsAndRolesRolesEnum } from '@reposit/api-client';

export const getAccessToken = (state: AppState) => state.auth.token;
export const getCurrentRoute = (state: AppState) => state.auth.currentRoute;
export const getCurrentOrganizationId = (state: AppState) => state.auth.currentOrganizationId;
export const getCurrentOrganizationRoles = (state: AppState) => state.auth.currentOrganizationRoles;
export const getHasOrganizationRole = (role: OrganizationWithRepositsAndRolesRolesEnum) => (state: AppState) => {
  const hasRole = state.auth.currentOrganizationRoles && state.auth.currentOrganizationRoles.some(cor => cor === role);
  return hasRole ? true : false;
};
