import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { Label } from '../../Typography/index';
import { RoundButton } from '../../Button';
import HelpIcon from '../../../assets/svg/help.svg';
import TickIcon from '../../../assets/svg/tick.svg';
interface CheckboxProps {
  name: string;
  label: string | JSX.Element;
  isChecked: boolean;
  helpText?: string;
  onChange?: (e: ChangeEvent<any>) => void;
}

const StyledWrapper = styled.div`
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  display: flex;
`;

const CheckBoxItem = styled.div`
  flex: 1;
`;

const TooltipItem = styled.div`
  width: 30px;
`;

const Checkbox = styled(Label)<{ isChecked: boolean }>`
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  font-size: 1em;

  &:hover {
    text-decoration: underline;

    &:before {
      background-color: #f1f1f1;
    }
  }

  span {
    display: flex;
    a:hover {
      text-decoration: none;
      transition: smooth;
      color: ${props => props.theme.colors.tertiaryBrand};
    }
  }

  &:before {
    background: #fff ${props => (props.isChecked ? `url(${TickIcon}) no-repeat center center` : ``)};
    background-size: 12px;
    box-sizing: border-box;
    border: ${props =>
      props.isChecked ? `2px solid ${props.theme.colors.primaryBrand}` : `2px solid ${props.theme.colors.disabled}`};
    content: '';
    display: block;
    flex: 0 0 18px;
    margin: 2px 12px 0 0;
    height: 18px;
    width: 18px;
  }
`;

const HelpButton = styled(RoundButton)`
  display: flex;
  flex: 0 0 24px;
  margin: 0 12px;
`;

const LabelText = styled.div``;

export default ({ name, label, isChecked, helpText, onChange }: CheckboxProps): JSX.Element => {
  return (
    <StyledWrapper>
      <CheckBoxItem>
        <Checkbox isChecked={isChecked} htmlFor={name}>
          <input id={name} name={name} type="checkbox" onChange={onChange} />
          <span>
            <LabelText>{label}</LabelText>
          </span>
        </Checkbox>
      </CheckBoxItem>
      <TooltipItem>{helpText && <HelpButton icon={HelpIcon} data-tip={helpText} />}</TooltipItem>
    </StyledWrapper>
  );
};
