import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { toUpper } from 'lodash';
import PDFIcon from '../../../assets/svg/acrobat-doc.svg';
import CompanyIcon from '../../../assets/svg/onboarding/company.svg';
import AgreeableCard from '../../../components/AgreeableCard/index';
import Button from '../../../components/Button/index';
import Actions from '../../../components/Onboarding/Actions/index';
import Title from '../../../components/Onboarding/Title/index';
import SecondaryPanel from '../../../components/SecondaryPanel/index';
import { Header4 } from '../../../components/Typography/index';
import { updateInformedLandlordsRequested, UPDATE_INFORMED_LANDLORDS_STORE_KEY } from '../../../redux/account/account.actions';
import { createLoadingSelector } from '../../../redux/loading/loading.selector';
import { useFlashMessage, FLASH_MESSAGE_TIMEOUT } from '../../FlashMessage/index';
import FlashMessage from '../../../components/FlashMessage/index';
import { useIsOrganizationLandlord } from '../../../hooks/useIsLandlord';

const CardTitle = styled(Header4)`
  padding: 0 36px;
`;

const DownloadLink = styled.a`
  align-items: center;
  display: flex;
  margin: 24px 0;
  text-decoration: none;

  &:before {
    background: transparent url(${PDFIcon}) no-repeat 0 center;
    background-size: 24px;
    content: '';
    display: block;
    margin: 0 10px 0 0;
    height: 24px;
    width: 24px;
  }
`;

interface Document {
  title: string;
  link: string;
}

const tobUpdate: Document = {
  title: 'Suggested update to your terms of business',
  link: 'https://d105lee3jcviqy.cloudfront.net/terms/reposit-terms-of-business-addendum.pdf'
};

const landlordLeaflet: Document = {
  title: 'Landlord leaflet',
  link: 'https://d105lee3jcviqy.cloudfront.net/landlords/reposit-landlord-leaflet.pdf'
};

const landlordGuide: Document = {
  title: 'Landlord Guide',
  link: 'https://d105lee3jcviqy.cloudfront.net/landlords/reposit-landlord-guide.pdf'
};

const landlordOnboardingLetter: Document = {
  title: 'Landlord onboarding letter',
  link: 'https://d105lee3jcviqy.cloudfront.net/landlords/reposit-landlord-introduction.pdf'
};

const partnerGuide: Document = {
  title: 'Partner Guide',
  link: 'https://d105lee3jcviqy.cloudfront.net/agents/reposit-partner-guide.pdf'
};

const eotEvidence: Document = {
  title: 'Evidence requirements',
  link: 'https://d105lee3jcviqy.cloudfront.net/end-of-tenancy/reposit-end-of-tenancy-evidence-requirements.pdf'
};

const landlordDocs: Document[] = [landlordGuide, eotEvidence];
const allDocs: Document[] = [tobUpdate, landlordLeaflet, landlordOnboardingLetter, partnerGuide, eotEvidence];

const UsefulDocuments: React.SFC<any> = () => {
  const [isChecked, changeChecked] = useState(false);
  const onChange = () => changeChecked(!isChecked);
  const dispatch = useDispatch();
  const onSubmit = () => dispatch(updateInformedLandlordsRequested());
  const isLoading = useSelector(createLoadingSelector([UPDATE_INFORMED_LANDLORDS_STORE_KEY]));
  const [flashMessagePayload, onDismissFlashMessage] = useFlashMessage([UPDATE_INFORMED_LANDLORDS_STORE_KEY]);
  const isOrgLandlord = useIsOrganizationLandlord();
  const docs = isOrgLandlord ? landlordDocs : allDocs;
  return (
    <Fragment>
      <Title
        icon={CompanyIcon}
        caption="Below are a number of helpful resources, created to assist you with your Reposit journey"
      >
        Useful Documents
      </Title>

      <AgreeableCard flush info="" label="Got it!" isChecked={isChecked} onChange={onChange}>
        <CardTitle>Please download the following documents</CardTitle>
        <SecondaryPanel>
          {docs.map(doc => (
            <DownloadLink target="_blank" href={doc.link}>
              <Button buttonType="secondary">{toUpper(doc.title)}</Button>
            </DownloadLink>
          ))}
        </SecondaryPanel>
      </AgreeableCard>

      {flashMessagePayload && (
        <FlashMessage onDismiss={onDismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMEOUT} payload={flashMessagePayload} />
      )}
      <Actions>
        <Button disabled={!isChecked || isLoading} onClick={onSubmit}>
          Continue
        </Button>
      </Actions>
    </Fragment>
  );
};

export default UsefulDocuments;
