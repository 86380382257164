import React from 'react';
import { Container, Row, Col } from 'react-grid-system';
import styled from 'styled-components';
import Button from '../../components/Button';
import { InnerWrapper } from '../../components/Common';
import { getBreakpoint } from '../../base/style';
import { NavLink } from 'react-router-dom';
import { history } from '../../index';
import MessageBar, { UserMessageType } from '../../components/MessageBar/index';
import { useSelector } from 'react-redux';
import { getShowClaimUploadsFeature } from '../../redux/selectors/user.selectors';

interface AppHeaderProps {
  showCreateRepositButton?: boolean;
  showBackButton?: boolean;
  showUserMessages?: boolean;
}

const AppHeaderContainer = styled.div<{ flush: boolean }>`
  display: flex;
  justify-content: space-between;
  height: 36px;
  margin: ${props => (props.flush ? `1em 0` : `2.625em 0 3.25em`)};
  @media screen and (max-width: ${getBreakpoint('md')}) {
    display: none;
  }
`;

export default ({ showCreateRepositButton, showBackButton, showUserMessages }: AppHeaderProps) => {
  const claimUploadsFeature = useSelector(getShowClaimUploadsFeature);
  const hasButton = showBackButton || showCreateRepositButton;
  return (
    <>
      {showUserMessages && claimUploadsFeature ? <MessageBar type={UserMessageType.FEATURE_CLAIM_UPLOADS} /> : null}
      <InnerWrapper>
        <Container>
          <Row>
            <Col sm={12}>
              <AppHeaderContainer flush={!hasButton}>
                {showBackButton && (
                  <Button buttonType="secondary" reverse onClick={() => history.goBack()}>
                    Go Back
                  </Button>
                )}

                {showCreateRepositButton && (
                  <NavLink to="/reposits/new" style={{ marginLeft: 'auto', textDecoration: 'none' }}>
                    <Button>Create a Reposit</Button>
                  </NavLink>
                )}
              </AppHeaderContainer>
            </Col>
          </Row>
        </Container>
      </InnerWrapper>
    </>
  );
};
