import { createReducer } from '@reduxjs/toolkit';
import { logout, setCurrentRoute, setToken, setOrganizationId, setCurrentOrganizationRoles } from './auth.actions';
import { AuthState } from './auth.types';

const initialState: AuthState = {
  token: '',
  currentRoute: '',
  currentOrganizationId: ''
};

export default createReducer(initialState, builder => {
  builder
    .addCase(setToken, (state, action) => {
      state.token = action.payload;
    })
    .addCase(setCurrentRoute, (state, action) => {
      state.currentRoute = action.payload;
    })
    .addCase(logout, state => {
      state.currentRoute = '';
    })
    .addCase(setOrganizationId, (state, action) => {
      state.currentOrganizationId = action.payload;
    })
    .addCase(setCurrentOrganizationRoles, (state, action) => {
      state.currentOrganizationRoles = action.payload;
    });
});
