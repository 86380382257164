import React from 'react';
import styled from 'styled-components';
import { SUPPLIER_AGREEMENT_LINK, REFERENCING_CRITERIA } from '../../../constants/links';
import { DisableComponent } from '../../Common/index';
import Checkbox from '../../FormFields/Checkbox';

interface RepositChecks {
  confirmRepositOfferedAsChoice: boolean;
  confirmTenantUnderstandsTerms: boolean;
  confirmReferenceChecks: boolean;
}
interface RepositChecksProps {
  editMode: boolean;
  disabled: boolean;
  onUpdate: (values: any) => void;
  checks: RepositChecks;
}

const QuestionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TermsLink = styled.a`
  color: ${props => props.theme.colors.primaryBrand};
  font-family: ${props => props.theme.typography.face.secondary};
  font-size: inherit;
  text-decoration: underline;
  display: block;
  margin: 38px 0;
`;

const ReferenceCriteriaLink = styled.a`
  color: ${props => props.theme.colors.primaryBrand};
  font-family: ${props => props.theme.typography.face.secondary};
  font-size: inherit;
  text-decoration: underline;
`;

const Question = styled.div`
  margin: 0 0 24px;
`;

export interface RepositChecksFormValues {
  tenantChoice: boolean;
  tenantAware: boolean;
  referenceChecks: boolean;
}

const RepositChecks: React.FC<RepositChecksProps> = ({ disabled, checks, onUpdate }) => {
  const content = (
    <form>
      <TermsLink href={SUPPLIER_AGREEMENT_LINK} target="_blank">
        Terms and Conditions
      </TermsLink>
      <QuestionsWrapper>
        <Question>
          <Checkbox
            name="tenantChoice"
            label="I confirm the tenants were offered Reposit as a choice alongside a traditional deposit"
            helpText="Reposit must be offered to tenants as a choice in order to comply with the lettings fee ban"
            isChecked={checks.confirmRepositOfferedAsChoice}
            onChange={() =>
              onUpdate({
                ...checks,
                confirmRepositOfferedAsChoice: !checks.confirmRepositOfferedAsChoice
              })
            }
          />
        </Question>
        <Question>
          <Checkbox
            name="tenantAware"
            label="I confirm the tenants were given a detailed explanation of the product and understand they are liable for end of tenancy charges"
            helpText="In order to provide a fair and smooth service at the end of the tenancy we require that tenants are made fully aware of how the product works"
            isChecked={checks.confirmTenantUnderstandsTerms}
            onChange={() =>
              onUpdate({
                ...checks,
                confirmTenantUnderstandsTerms: !checks.confirmTenantUnderstandsTerms
              })
            }
          />
        </Question>
        <Question>
          <Checkbox
            name="referenceCheck"
            label={
              <div>
                I have conducted comprehensive reference checks on all tenants to ensure they comply with{' '}
                <ReferenceCriteriaLink href={REFERENCING_CRITERIA} target="_blank">
                  Reposit’s referencing criteria
                </ReferenceCriteriaLink>
                . I understand I may be asked to provide evidence of this in the event of a claim at the end of the tenancy, and
                failing to provide this will invalidate the Reposit cover.
              </div>
            }
            helpText="I will adhere to Reposit’s referencing guidelines as per the T’s and C’s above. I understand I may be asked for evidence of this at the end of the tenancy."
            isChecked={checks.confirmReferenceChecks}
            onChange={() =>
              onUpdate({
                ...checks,
                confirmReferenceChecks: !checks.confirmReferenceChecks
              })
            }
          />
        </Question>
      </QuestionsWrapper>
    </form>
  );

  return disabled ? <DisableComponent disabled={disabled}>{content}</DisableComponent> : content;
};

export default RepositChecks;
