import { AppState } from '../root.reducer';

export const getCurrentUserId = (state: AppState) => state.account.currentUserId;
export const getMeHasFetched = (state: AppState) => state.account.hasFetched;
export const getAccountCurrentAddressForm = (state: AppState) => state.account.currentAddressForm;
export const getRequiresNPSResponse = (state: AppState) => state.account.requiresNPSResponse;
export const getIsNPSModalOpen = (state: AppState) => state.account.isNPSModalOpen;
export const getIsCollectingTrustpilotReview = (state: AppState) => state.account.collectingTrustpilotReview;
export const getSurveyEvent = (state: AppState) => state.account.surveyEvent;
export const getHasRequestedResetPassword = (state: AppState) => state.account.hasRequestedResetPassword;
export const getHasResetPassword = (state: AppState) => state.account.hasResetPassword;
export const getPersistedEmail = (state: AppState) => state.account.persistedEmail;
export const getIsOnboarding = (state: AppState) => state.account.isOnboarding;
