import { createAsyncThunk } from '@reduxjs/toolkit';
import { AppState } from '../root.reducer';
import { ClaimProposalEntity, ClaimItemProposalEntity, ClaimEntity } from '../entities/entities.types';
import { getErrorMessage } from '../../utils/common.utils';
import {
  ACCEPT_TENANT_PROPOSAL_STORE_KEY,
  COUNTER_TENANT_PROPOSAL_STORE_KEY,
  fetchClaimRequested,
  PUBLISH_PROPOSAL_STORE_KEY,
  CREATE_ITEM_PROPOSAL_STORE_KEY,
  UPDATE_ITEM_PROPOSAL_STORE_KEY,
  removeProposalFromClaim,
  SUBMIT_EVIDENCE_STORE_KEY
} from './claim.actions';
import { setFlashMessage } from '../flash-messages/flash-messages.actions';
import { FlashState } from '../../components/FlashMessage/index';
import {
  createStandardClaimProposalsApi,
  createStandardClaimItemProposalsApi,
  createStandardClaimsApi,
  runThunkWithAuth
} from '../utils/api.utils';
import { standardSyncEntitiesAndGetResults } from '../entities/entities.sagas';
import SCHEMA from '../schema';
import {
  CreateClaimItemProposalDTO,
  ClaimProposalDTOSourceEnum,
  ClaimProposalDTO,
  ClaimItemProposalDTO,
  ClaimDTO
} from '@reposit/api-client';
import { getProposalsByClaimId } from '../selectors/mediation.selectors';
import { createClaimItemProposalSuccess, updateClaimItemProposalSuccess } from '../claim-item/claim-item.actions';
import { AxiosResponse } from 'axios';

interface AcceptTenantProposalPayload {
  claimId: string;
  claimProposalId: string;
}

interface CounterTenantProposalPayload {
  claimId: string;
  claimProposalId: string;
}

interface PublishProposalPayload {
  claimId: string;
  claimProposalId: string;
}

interface CreateItemProposalPayload {
  claimId: string;
  claimProposalId: string;
  payload: CreateClaimItemProposalDTO;
}

interface UpdateItemProposalPayload {
  claimId: string;
  claimProposalId: string;
  claimItemProposalId: string;
  payload: CreateClaimItemProposalDTO;
}

interface VerifyClaimPayload {
  claimId: string;
}

export const acceptProposalThunk = createAsyncThunk<
  ClaimProposalEntity,
  AcceptTenantProposalPayload,
  {
    state: AppState;
  }
>('claim/accept-proposal', async (payload, thunkAPI) => {
  const dispatch = thunkAPI.dispatch;
  const state = thunkAPI.getState();
  try {
    const api = createStandardClaimProposalsApi(state);
    const { data }: AxiosResponse<ClaimProposalDTO> = await runThunkWithAuth(
      () => api.agreeClaimProposal(payload.claimId, payload.claimProposalId),
      dispatch
    );
    const proposals = getProposalsByClaimId(state, payload.claimId);
    // agent second proposal can be created if they reject the first time
    const foundProposalToBeDeleted = proposals.find(p => p.round === 3 && p.source === ClaimProposalDTOSourceEnum.SUPPLIER);
    if (foundProposalToBeDeleted) {
      dispatch(removeProposalFromClaim({ claimId: payload.claimId, proposalId: foundProposalToBeDeleted.id }));
    }
    const entity: ClaimProposalEntity = standardSyncEntitiesAndGetResults(data, SCHEMA.claimProposal, dispatch);
    dispatch(fetchClaimRequested({ claimId: payload.claimId }));
    dispatch(
      setFlashMessage({
        key: ACCEPT_TENANT_PROPOSAL_STORE_KEY,
        message: 'Success! You have accepted the tenant(s) proposal.',
        state: FlashState.SUCCESS
      })
    );
    return entity;
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch(setFlashMessage({ key: ACCEPT_TENANT_PROPOSAL_STORE_KEY, message: error, state: FlashState.ERROR }));
    throw e;
  }
});

export const counterTenantProposalThunk = createAsyncThunk<
  ClaimProposalEntity,
  CounterTenantProposalPayload,
  {
    state: AppState;
  }
>('claim/counter-tenant-proposal', async (payload, thunkAPI) => {
  const dispatch = thunkAPI.dispatch;
  const state = thunkAPI.getState();
  try {
    const api = createStandardClaimProposalsApi(state);
    const { data }: AxiosResponse<ClaimProposalDTO> = await runThunkWithAuth(
      () => api.counterClaimProposal(payload.claimId, payload.claimProposalId),
      dispatch
    );
    const entity: ClaimProposalEntity = standardSyncEntitiesAndGetResults(data, SCHEMA.claimProposal, dispatch);
    dispatch(fetchClaimRequested({ claimId: payload.claimId }));
    return entity;
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch(setFlashMessage({ key: COUNTER_TENANT_PROPOSAL_STORE_KEY, message: error, state: FlashState.ERROR }));
    throw e;
  }
});

export const publishProposalThunk = createAsyncThunk<
  ClaimProposalEntity,
  PublishProposalPayload,
  {
    state: AppState;
  }
>('claim/publish-proposal', async (payload, thunkAPI) => {
  const dispatch = thunkAPI.dispatch;
  const state = thunkAPI.getState();
  try {
    const api = createStandardClaimProposalsApi(state);
    const { data }: AxiosResponse<ClaimProposalDTO> = await runThunkWithAuth(
      () => api.publishClaimProposal(payload.claimId, payload.claimProposalId),
      dispatch
    );
    const entity: ClaimProposalEntity = standardSyncEntitiesAndGetResults(data, SCHEMA.claimProposal, dispatch);
    dispatch(fetchClaimRequested({ claimId: payload.claimId }));
    dispatch(
      setFlashMessage({ key: PUBLISH_PROPOSAL_STORE_KEY, message: 'Final proposal has been sent!', state: FlashState.SUCCESS })
    );
    return entity;
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch(setFlashMessage({ key: PUBLISH_PROPOSAL_STORE_KEY, message: error, state: FlashState.ERROR }));
    throw e;
  }
});

export const createClaimItemProposalThunk = createAsyncThunk<
  ClaimItemProposalEntity,
  CreateItemProposalPayload,
  {
    state: AppState;
  }
>('claim/create-claim-item-proposal', async (payload, thunkAPI) => {
  const dispatch = thunkAPI.dispatch;
  const state = thunkAPI.getState();
  try {
    const api = createStandardClaimItemProposalsApi(state);
    const { data }: AxiosResponse<ClaimItemProposalDTO> = await runThunkWithAuth(
      () => api.createClaimItemProposal(payload.claimId, payload.claimProposalId, payload.payload),
      dispatch
    );
    const entity: ClaimItemProposalEntity = standardSyncEntitiesAndGetResults(data, SCHEMA.claimItemProposal, dispatch);
    dispatch(createClaimItemProposalSuccess(data));
    return entity;
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch(setFlashMessage({ key: CREATE_ITEM_PROPOSAL_STORE_KEY, message: error, state: FlashState.ERROR }));
    throw e;
  }
});

export const updateClaimItemProposalThunk = createAsyncThunk<
  ClaimItemProposalEntity,
  UpdateItemProposalPayload,
  {
    state: AppState;
  }
>('claim/update-claim-item-proposal', async (payload, thunkAPI) => {
  const dispatch = thunkAPI.dispatch;
  const state = thunkAPI.getState();
  try {
    const api = createStandardClaimItemProposalsApi(state);
    const { data }: AxiosResponse<ClaimItemProposalDTO> = await runThunkWithAuth(
      () => api.updateClaimItemProposal(payload.claimId, payload.claimProposalId, payload.claimItemProposalId, payload.payload),
      dispatch
    );
    const entity: ClaimItemProposalEntity = standardSyncEntitiesAndGetResults(data, SCHEMA.claimItemProposal, dispatch);
    updateClaimItemProposalSuccess(data);
    return entity;
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch(setFlashMessage({ key: UPDATE_ITEM_PROPOSAL_STORE_KEY, message: error, state: FlashState.ERROR }));
    throw e;
  }
});

export const verifyClaimEvidenceThunk = createAsyncThunk<
  ClaimEntity,
  VerifyClaimPayload,
  {
    state: AppState;
  }
>('claim/verify-evidence', async (payload, thunkAPI) => {
  const dispatch = thunkAPI.dispatch;
  const state = thunkAPI.getState();
  try {
    const api = createStandardClaimsApi(state);
    const { data }: AxiosResponse<ClaimDTO> = await runThunkWithAuth(() => api.verifyClaim(payload.claimId), dispatch);
    const entity = await standardSyncEntitiesAndGetResults(data, SCHEMA.claim, dispatch);
    dispatch(
      setFlashMessage({
        key: SUBMIT_EVIDENCE_STORE_KEY,
        message: 'Success! You have submitted your remaining evidence',
        state: FlashState.SUCCESS
      })
    );
    return entity;
  } catch (e) {
    const error = getErrorMessage(e);
    dispatch(setFlashMessage({ key: UPDATE_ITEM_PROPOSAL_STORE_KEY, message: error, state: FlashState.ERROR }));
    throw e;
  }
});
