import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import FlashMessage from '../../components/FlashMessage/index';
import { SUBMIT_NPS_STORE_KEY } from '../../redux/account/account.actions';
import { SUBMIT_FEEDBACK_STORE_KEY } from '../../redux/feedback/feedback.actions';
import { dismissFlashMessage } from '../../redux/flash-messages/flash-messages.actions';
import {
  createFailedFlashMessagesSelector,
  createFlashMessagesSelector
} from '../../redux/flash-messages/flash-messages.selector';
import { FlashMessagePayload } from '../../redux/flash-messages/flash-messages.types';
import {
  CHECKOUT_REPOSIT_STORE_KEY,
  DISCARD_REPOSIT_STORE_KEY,
  EXTEND_REPOSIT_STORE_KEY,
  PUBLISH_REPOSIT_STORE_KEY,
  RESEND_TENANT_INVITE_STORE_KEY,
  SEND_TENANT_REMINDER_EMAIL_STORE_KEY,
  UPDATE_CLOSING_REMINDERS_ENABLED_STORE_KEY
} from '../../redux/reposit/reposit.actions';

interface FlashMessageContainerProps {}

const subscribedMessages: string[] = [
  DISCARD_REPOSIT_STORE_KEY,
  PUBLISH_REPOSIT_STORE_KEY,
  CHECKOUT_REPOSIT_STORE_KEY,
  EXTEND_REPOSIT_STORE_KEY,
  RESEND_TENANT_INVITE_STORE_KEY,
  SUBMIT_FEEDBACK_STORE_KEY,
  SUBMIT_NPS_STORE_KEY,
  SEND_TENANT_REMINDER_EMAIL_STORE_KEY,
  UPDATE_CLOSING_REMINDERS_ENABLED_STORE_KEY
];

const StyledFlashMessageContainer = styled.div`
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  margin: 24px 0 0 240px;
  padding: 0 50px;
  width: calc(100% - 240px);
  z-index: 9000;
`;

export const FLASH_MESSAGE_TIMEOUT = 5000;

export const useFlashMessage = (storeKeys: string[]): [FlashMessagePayload, () => void] => {
  const dispatch = useDispatch();
  const getFlashMessageSelector = createFlashMessagesSelector(storeKeys);
  const flashMessage = useSelector(getFlashMessageSelector);
  const onDismissFlashMessage = useCallback(() => dispatch(dismissFlashMessage(storeKeys)), [storeKeys, dispatch]);

  return [flashMessage, onDismissFlashMessage];
};

export const useFailedFlashMessage = (storeKeys: string[]): [FlashMessagePayload, () => void] => {
  const dispatch = useDispatch();
  const getFlashMessageSelector = createFailedFlashMessagesSelector(storeKeys);
  const flashMessage = useSelector(getFlashMessageSelector);
  const onDismissFlashMessage = () => dispatch(dismissFlashMessage(storeKeys));

  return [flashMessage, onDismissFlashMessage];
};

const FlashMessageContainer: React.FC<FlashMessageContainerProps> = props => {
  const [flashMessage, onDismissFlashMessage] = useFlashMessage(subscribedMessages);
  return flashMessage ? (
    <StyledFlashMessageContainer>
      <FlashMessage onDismiss={onDismissFlashMessage} timeRemaining={FLASH_MESSAGE_TIMEOUT} payload={flashMessage} />
    </StyledFlashMessageContainer>
  ) : null;
};

export default FlashMessageContainer;
